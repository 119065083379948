import React, { createRef, useState } from "react";
import '../assets/css/add_lead.scss'
import LeadAdded from "./LeadAdded";
import {Alert, Button, Modal} from "react-bootstrap";
import DiscoveryModal from "./DiscoveryModal";
import PerilOptionsEdit from "./PerilOptionsEdit";
import axios from 'axios'
import {url} from '../config';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Slider from "react-slick";
import Select from 'react-select'
import CarrierModal from "./CarrierModal";
import {connect} from 'react-redux';
import {carrierModalCondition, optionEditModalCondition, optionTeamsModalCondition} from "../redux/actions";
import TeamsModal from "./TeamsModal";
import NumberFormat from 'react-number-format';
import EmailModal from "./settings/EmailModal";
const obj = {};
let intervalId
let current_basic = {};
const oldData = [];
let current_building = {};
let type =0;
const sliders = [
    []
];

const customStyles = {
    menu: (provided) => ({
        ...provided,

        zIndex: 10000
    }),

    input: (provided) => ({
        ...provided,

    }),

    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return {...provided, opacity, transition};
    }
}

const basic_info = {
    exist_contact_check: '0',
    zono_account: '0',
    cell_number: null,
    work_number: null,
    home_number: null,
    lead_id:null
};

let related = [];
let count = 0
let arr = []
let form = {
    primary_contact: '0',
    policy_holder: '0'
}

const loss_info = [
    {
        hot_lead: '0',
        peril: null,
        DOL: null,
        loss_reported: '0',
        date_loss_reported: null,
        denial: '0',
        denial_letter: '0',
        loss_reported_note: null,
        previous_payment: null,
        discovery_description: null,
        photos: '0'
    },
    {
        affected_rooms: null,
        kind_of_floors: null,
        floor_lead: '0',
        repaired_plumbing: '0',
        EMS_vendor: null,
        EMS_notes: null,
        repairs_since_DOL: null,
        mitigation_efforts: null,
        carrier_vendor: '0'
    },
    {
        carrier_id: null,
        carrier_number: null,
        carrier_notes: null,
    }
]

const loss_address = [
    {
        existing_property: '0',
        property_type: '0',
        street_name: null,
        street_number: null,
        unit_number: null,
        city: null,
        state: null,
        zip: null,
        country: "USA",
        contact_id: null
    },
    {
        building_id: null,
        size_of_building: null,
        year_build: null,
        beds: null,
        baths: null,
        prior_claims: '0',
        est_property_value: null,
        building_note: null,
        age_of_home: null,
        years_occupied: null
    },
    {
        company_name: null,
        address: null,
        phone_number: null,
        email: null
    }
]

const values = {
    source_notes: '',
    step2_notes: '',
    loss_reported_notes: '',
    ems_notes: '',
    carrier_notes: '',
    building_notes: '',
    dol_notes: '',
    mitigation_notes: ""
};


class AddSteps extends React.Component {
    constructor(props) {
        super(props)

        this.slider_ref = React.createRef();
        this.related_ref = React.createRef();
        this.select_ref = React.createRef();
        this.peril_select_ref = React.createRef();


        this.state = {
            currentStep: 1,
            checked: false,
            children: [],
            modalShow: false,
            disc_value: '',
            disc_save_value: '',
            perilCounter: 0,
            perilModal: false,
            newArray: [],
            perilArray: [],
            check: false,
            peril_inputs: [1, 2],
            peril_list_modal: false,
            perilEdit: false,
            peril_list: '',
            value: '',
            dol_date_value: '',
            click: false,
            date_value: null,
            date_loss_reported: '',
            slides: [[], []],
            sliders:[],
            team_default_select:'',
            isChecked: false,
            send_values: [],
            basic_data: [],
            relation_form_iteration: 0,
            floor_lead: 1,
            repaired_plumbing: 0,
            exist_contact_check: 0,
            carrier: '',
            kind_floor: '',
            country: true,
            countrys_date: [],
            state: true,
            state_date: '',
            city: true,
            cities_date: '',
            building_types: '',
            success_alert: false,
            lead_id: '',
            contact_id: '',
            valid_form: [],
            notes_show: false,
            step2_notes: false,
            loss_reported_notes: false,
            ems_notes: false,
            carrier_notes: false,
            building_notes: false,
            number_require: false,
            notes_id: null,
            which_note: [],
            save_draft: true,
            errors: {},
            optionValue: [],
            existing_value: '',
            contacts_disabled: false,
            default_select: 'DEFAULT',
            modalName:'Peril',
            loss_address_property:'',
            zono_account: null,
            current_loss_address:'',
            current_building:'',
            current_basic:'',
            property: false,
            email_validate:'',

            forceUpdate: false,
            dol_notes: false,
            mitigation_open: false,
            redirectedByModal: false,
            redirectedByDOLModal: false,
            redirectedByEMSNotesModal: false,
            redirectedByDiscoveryModal: false,
            redirectedByMitigationNotes: false,
            redirectedByLossReportedModal: false,
            redirectedByBuildingNotesModal: false,
            isExistingPropertyChecked: false,
            hot_lead_checked: false,
            type:false,

        }

    }

    dolRef = createRef(null);
    sourceRef = createRef(null);
    emsNotesRef = createRef(null);
    discoveryRef = createRef(null);
    carrierNoteRef = createRef(null);
    lossReportedRef = createRef(null);
    mitigationEffRef = createRef(null);
    buildingNotesRef = createRef(null);

    /**
   * For setting this value
   * in other components without passing
   * the whole state of this component as a prop
   * This function is used in step 1
   *
   * @param {Boolean} value
   */
  setRedirectedByModal = (value) => this.setState({ redirectedByModal: value });

  /**
   * This function is used in step 3
   *
   * field: Loss Report
   * @param {Boolean} value
   */
  setRedirectedByLossReportedModal = (value) =>
    this.setState({ redirectedByLossReportedModal: value });

  /**
   * This function is used in step 3
   *
   * field: EMS Notes
   * @param {Boolean} value
   */
  setRedirectedByEMSNotesModal = (value) =>
    this.setState({ redirectedByEMSNotesModal: value });

  /**
   * This function is used in step 3
   *
   * field: Carrier Note
   * @param {Boolean} value
   */
  setRedirectedByCarrierNoteModal = (value) =>
    this.setState({ redirectedByCarrierNoteModal: value });

  /**
   * This function is used in step 4
   *
   * field: Building Notes
   * @param {Boolean} value
   */
  setRedirectedByBuildingNotesModal = (value) =>
    this.setState({ redirectedByBuildingNotesModal: value });

      /**
   * This function is used in step 4
   *
   * field: Building Notes
   * @param {Boolean} value
   */
  setRedirectedByDiscoveryModal = (value) => this.setState({ redirectedByDiscoveryModal: value });

  setRedirectedByDOLModal = (value) => this.setState({ redirectedByDOLModal: value })
 
  setRedirectedByMitigationNotes = (value) => this.setState({ redirectedByMitigationNotes: value })

    getZip = (zipKey = null) => {

        const data = {};
        const errors = this.state.errors && this.state.errors

        loss_address[0].state = null
        loss_address[0].city = null

        data.parameter = zipKey;

        if(zipKey){

            axios.post(url + 'get-location-by-zip', data)
                .then(res => {

                    this.setState({
                        state_date:res.data.result,
                        cities_date:res.data.result,
                    })

                    if(res.data && res.data.result.length === 1){
                        loss_address[0].state = res.data.result[0].state
                        loss_address[0].city = res.data.result[0].city
                    }


                    if(errors){

                        if(errors && errors.state){
                            errors.state = null
                        }

                        if(errors && errors.city){
                            errors.city = null
                        }

                        return this.setState({
                            errors:errors
                        })
                    }


                })
                .catch(err => {
                    console.log(err)
                })
        }

    }


    periListDataFetch = () => {
        axios.get(url + 'create-lead-third')
            .then(res => {

                const perilList = []
                const carrier = [];
                const kind_floor = [];

                res.data.loss_types.map((val, key) => {
                    return perilList[key] = val
                })

                this.setState({
                    peril_list: perilList
                })


                res.data.carrier_types.map((val, key) => {
                    return carrier[key] = val;
                })

                this.setState({
                    carrier: carrier
                })


                res.data.kind_of_floor.map((val, key) => {
                    return kind_floor[key] = val;
                })

                this.setState({
                    kind_floor: kind_floor
                })

            })
            .catch(err => {
                console.log(err)
            })
    }

    addSlide = () => {
        const {slides,email_validate} = this.state;



        if (!email_validate) {
           
            let addKey = sliders.length
            sliders[addKey] = [];
            this.setState({
                sliders: [sliders],
                valid_form: []

            });
            this.slider_ref.current.slickNext()
           
            if (this.state.sliders.length > 1) {
                this.props.callbackFromParent(0, this.state.sliders.length);
            }
        }else {
            return false
        }


    }

    dateSave = (name, date) => {

        if (this.state.errors) {
            const errors = this.state.errors ? this.state.errors : ''

            if(errors[name]){
                errors[name] = ''
            }

            this.setState({
                errors: errors
            })
        }

        if (name === 'DOL') {

            loss_info[0].DOL = date.toString()
            this.setState({
                dol_date_value: date
            })


        }

        if (name === 'date_loss_reported') {

            this.setState({
                date_loss_reported: date
            })

            loss_info[0].date_loss_reported = date.toString();
        }


        if (name === 'first_contact') {

            this.setState({
                date_value: date
            })
        }
    }


    handleChange = event => {
        const {name, value} = event.target
        this.setState({
            [name]: value
        })
    }

    handleCheckboxChange = (event,step=null,key=null) => {
        const {name} = event.target;
        if (name === 'exist_contact_check') {

            if (Number(basic_info.exist_contact_check) === 0) {
                this.setState({
                    exist_contact_check: 1,
                    contacts_disabled: true
                })
                basic_info.exist_contact_check = "1"
            } else {
                basic_info.exist_contact_check = "0"
                basic_info.contact_number = null;
                
                this.setState({
                    exist_contact_check: 0,
                    contacts_disabled: false,
                    existing_value: '',
                    contact_id: '',
                })
            }
        }


        if (name === 'zono_account') {
            if (basic_info.zono_account === '0') {
                basic_info.zono_account = '1'
            } else {
                basic_info.zono_account = '0'
            }
        }


        if (name === 'existing_property') {
            if (loss_address[0].existing_property === '0') {
                loss_address[0].existing_property = '1';
            } else {
                loss_address[0].existing_property = '0';
                loss_address[0].id = null;
             }
             
        }

        if (name === 'primary_contact') {
            let pc = '0';
            if (event.target.value === 'on') {
               pc = '1'
            } 
            sliders.map((item,key) => {
                if(item.primary_contact) {
                    item.primary_contact = '0'
                }
            })
            if(key != null ) {
                sliders[key]['primary_contact'] = pc
                this.setState({
                    sliders: [sliders]
                })
            }
        }
        if (name === 'policy_holder') {
            let ph = '0';
            if (event.target.value === 'on') {
               ph = '1'
            } 
            sliders.map((item,key) => {
                if(item.policy_holder) {
                    item.policy_holder = '0'
                }
            })
            if(key != null ) {
                sliders[key]['policy_holder'] = ph
                this.setState({
                    sliders: [sliders]
                })
            }

        }

        if (name === 'prior_claims') {
            if (loss_address[1].prior_claims === '0') {
                loss_address[1].prior_claims = '1';
            } else {
                loss_address[1].prior_claims = '0';
            }
        }



        if (name === 'hot_lead') {
            if (event.target.checked == true) { 
                loss_info[0].hot_lead = '1'
            }else {
                loss_info[0].hot_lead = '0'
            }

            this.setState({
                hot_lead_checked: event.target.checked
            });
        }


        if (name === 'loss_reported') {
            if (loss_info[0].loss_reported === '0') {
                loss_info[0].loss_reported = '1'
            } else {
                loss_info[0].loss_reported = '0'
            }
        }


        if (name === 'denial') {
            if (loss_info[0].denial === '0') {
                loss_info[0].denial = '1'
            } else {
                loss_info[0].denial = '0'
            }
        }


        if (name === 'denial_letter') {
            if (loss_info[0].denial_letter === '0') {
                loss_info[0].denial_letter = '1'
            } else {
                loss_info[0].denial_letter = '0'
            }
        }


        if (name === 'photos') {
            if (loss_info[0].photos === '0') {
                loss_info[0].photos = '1'
            } else {
                loss_info[0].photos = '0'
            }
        }


        if (name === 'carrier_vendor') {
            if (loss_info[1].carrier_vendor === '0') {
                loss_info[1].carrier_vendor = '1'
            } else {
                loss_info[1].carrier_vendor = '0'
            }
        }

    }

componentWillMount() {
    if(this.props.type){
      //  this.getSelectValue(this.props.contact_number)
        //this.handleSelectOption(this.props.contact_number)
        this.setState({
            // type:this.props.type,
            exist_contact_check:1,
            // contact_id:this.props.type,
            client_id:this.props.contact.client_id,
           // existing_contact_selected_value:this.props.contact_number
        })
        if(this.props.type) {
            basic_info.client_id = this.props.type
        }
        type = this.props.type;
    }
}

    componentDidMount() {
      if(this.props.contact){
      this.handleSelectOption(this.props.contact);
      }
        this.getBasicInfoData();
        if (this.state.peril_list) {
            this.setState({
                perilCounter: this.state.peril_list.length + this.state.newArray.length
            })
        }

    }

    getBasicInfoData = () => {
        this.setState({
            basic_data:''
        })
        axios.get(url + 'create-lead', {})
            .then(res => {
                if (res) {
                    this.setState({
                        basic_data: [...this.state.basic_data, res.data]
                    })

                    //basic_info.team_id = this.state.basic_data[0] && this.state.basic_data[0].team[0].id

                }

            })
            .catch(err => {

                if (err.response && err.response.status === 401) {
                    window.location.href = '/login'
                    localStorage.clear()
                }
            })
    }

    handle_add = () => {
        this.setState({
            checked: true,
            children: [
                ...this.state.children,
                <LeadAdded/>
            ],
            check: true
        });

    }

    someFn = (Step) => {

        this.props.callbackFromParent(Step);
    }

    secondStepAxios = async (relatedContacts,lead_id,contact_id) => {
        let data = {};
        data.lead_id = lead_id;
        data.contact_id = contact_id;
        let objectContacts = [];
        sliders.map((value,key) => {
            objectContacts.push(JSON.parse(JSON.stringify(Object.assign({}, value))))
        });
        data.relatedContacts = Object.values(objectContacts);

        await axios.post(url + 'create-related-contacts', data)
            .then(res => {
                if (res.data.errors) {
                    const errors = {}
                    this.setState({
                        errors: errors
                    })
                } else {

                    this.setState({
                        check: true
                    })
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    sendBasicInfo = async (data) => {
  return axios.post(url + 'store-lead', data)
                    .then(res => {

            if (res.data.errors) {
                this.setState({
                    errors: {}
                })
                const errors = {}

                Object.entries(res.data.errors.errors).map((v,k) => {

                    const key = v[0]
                    return errors[key] = v[1]
                })
                this.setState({
                    errors: errors
                })
                return null

            } else {
                this.setState({
                    lead_id: res.data.id ? res.data.id : res.data.lead_id,
                    contact_id: res.data.contact_id,
                    errors: {}
                })

                

                this.setState({
                    errors: {}
                })
                return res
            }

        })
        .catch(err => {
            console.log(err)
        })

    }
 sendLossInfo = async (data) => {
    axios.post(url + 'store-lead', data)
    .then(res => {

        if (res.data.errors) {

            this.setState({
                errors: {}
            })
            const errors = {}

            Object.entries(res.data.errors.errors).map((v) => {
                const key = v[0]
                return errors[key] = v[1]
            })
            this.setState({
                errors: errors
            })
        } else {
            console.log(res)

        }

    })
    .catch(err => {
        console.log(err)
    })
}

sendLossAddress = async (data) => {

    axios.post(url + 'store-lead', data)
                .then(res => {

                    this.secondStepAxios(sliders,this.state.lead_id,this.state.contact_id)

                    if (res.data.errors) {
                        this.setState({
                            errors: {}
                        })
                        const errors = {}

                        Object.entries(res.data.errors.errors).map((v) => {
                            const key = v[0]
                            return errors[key] = v[1]
                        })
                        this.setState({
                            errors: errors
                        })
                    } else {
                        this.setState({
                            success_alert: true
                        })
                        window.scrollTo(0, 0)
                        setTimeout(() => {

                            window.location.href = '/'
                        }, 2000)
                    }

                })
                .catch(err => {
                    console.log(err)
                })
        }

    handleSubmit = async (event) => {
        this.refs.btn.setAttribute("disabled", "disabled");
      if(event){
          event.preventDefault();
      }
        basic_info.first_contact = this.state.date_value !== null  ?  this.state.date_value.toString() : null

        if(!this.state.email_validate){



        if (this.state.currentStep === 1) {




            if (Number(event.target.dataset.id) === 1) {

                const data = {};
                data.basic_info = basic_info;

                axios.post(url + 'create-draft', data)
                    .then(res => {
                        if (res.data.errors) {
                            const errors = {}

                            Object.entries(res.data.errors.errors).map((v) => {
                                const key = v[0]

                                return errors[key] = v[1]
                            })
                            this.setState({
                                errors: errors
                            })
                        } else {
                            this.setState({
                                lead_id: res.data.lead_id,
                                contact_id: res.data.contact_id
                            })
                            window.location.href = '/'
                        }


                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        }

        if (this.state.currentStep === 2) {
         }

        if (this.state.currentStep === 3) {

           //this._next();
        }

        if (this.state.currentStep === 4) {

            const basicInfoDataSet = {};
            basicInfoDataSet.basic_info = basic_info;
        let client_id;
          this.sendBasicInfo(basicInfoDataSet).then( async res => {
                if(res) {
                    const lossInfoDataSet = {};
                    lossInfoDataSet.lead_id = res.data.id;
                    lossInfoDataSet.loss_info = loss_info
                    lossInfoDataSet.lead_id = res.data.id;
                    client_id = res.data.client_id
                   await this.sendLossInfo(lossInfoDataSet)

                    const lossAddressDataSet = {
                        lead_id:res.data.id,
                        loss_address:loss_address,
                        contact_id:res.data.contact_id,
                        client_id: client_id
                    };
                    // lossAddressDataSet.lead_id = res.data.id;
                    // lossAddressDataSet.loss_address = loss_address;
                    // lossAddressDataSet.contact_id = res.data.contact_id;
                    await  this.sendLossAddress(lossAddressDataSet)
                }else {
                    this.handleChangeStep(1)
                }
            })
        }
    }

}


    getCities = () => {
        axios.get(url + 'get-cities')
            .then(res => {
                this.setState({
                    cities_date: res.data,
                    city: false
                })

            })
            .catch(err => {
                console.log(err)
            })
    }

    _next = () => {

        this.setState({
            errors: ""
        })

        let currentStep = this.state.currentStep;

        currentStep = currentStep >= 3 ? 4 : currentStep + 1
        this.setState({
            currentStep: currentStep
        })

        if (currentStep >= 2 && this.state.check) {
            this.someFn(this.state.currentStep);
        }

        if (currentStep === 3) {
            this.periListDataFetch();
        }


        if (currentStep === 4) {
            this.leadsIntakeDataFetch();
            this.getCountyis();
            this.getStates();
            this.getCities();
        }

    }

    leadsIntakeDataFetch = () => {
        const building_date = [];
        axios.get(url + 'create-lead-fourth?id='+this.state.contact_id)
            .then(res => {
                res.data.building_types.map((val, key) => {
                    return building_date[key] = val

                })

                this.setState({
                    building_types: building_date,
                    loss_address_property:res.data.properties
                })

            })
            .catch(err => {
                console.log(err)
            })
    }


    getStates = () => {
        axios.get(url + 'get-states')
            .then(res => {

                this.setState({
                    state_date: res.data,
                    state: false
                })

            })
            .catch(err => {
                console.log(err)
            })
    }

    getCountyis = () => {

        axios.get(url + 'get-countries')
            .then(response => {

                    return this.setState({
                        countrys_date:response.data
                    })

            })
            .catch(err => {
                console.log(err)
            })
    }


    saveDraft() {
        const currentStep = this.state.currentStep;

        return (
            <button data-id={1} type={'button'} onClick={this.handleSubmit}
                    className={'btn add_lead_next_button default_hover' + (Number(currentStep) === 1 ? ' float-left' : ' float-right save_draft_button')}>
                Save Draft
            </button>
        )

    }

    nextButton() {
        const currentStep = this.state.currentStep;

        // if(currentStep === 1){
        //     return    <button
        //         className="btn float-right add_lead_next_button default_hover"
        //         data-id={2}
        //         type={'button'}
        //         onClick={this.handleSubmit}
        //     >
        //         Next
        //     </button>
        // }
        if (currentStep < 4) {

            return (
                <button
                    className="btn float-right add_lead_next_button default_hover"
                    data-id={2}
                    type={'button'}
                    onClick={this.handleChangeStep.bind(this,this.state.currentStep + 1)}
                    ref={'btn'}
                >
                    Next
                </button>
            )
        }

        if (currentStep === 4) {
            return (
                <button
                    className="btn float-right add_lead_next_button default_hover"
                    type="button"
                    onClick={this.handleSubmit}
                    ref={'btn'}
                >
                    Save
                </button>
            )
        }
        return null;
    }

    handleDeletePeril = (newPerilArray) => {
       this.state.perilArray.concat(newPerilArray)


    }

    _prev = () => {
        if(this.state.lead_id){
           related = [];

            basic_info.lead_id = this.state.lead_id
        }
        let currentStep = this.state.currentStep
        currentStep = currentStep <= 1 ? 1 : currentStep - 1
        this.setState({
            currentStep: currentStep
        })

    }

    previousButton() {
        const currentStep = this.state.currentStep;
        if (currentStep !== 1) {
            return (

                <button
                    className="btn  add_lead_prev_button"
                    type="button" onClick={this._prev}>
                    Back
                </button>
            )
        }

    }

    handleDiscoverChange = () => {
        intervalId = setTimeout(this.inputOnFocus,600)

        this.setState({
            modalShow: true,
        })
    }


    handlePerilChange = (optionName) => {
        if(optionName === "carrier"){
           return this.props.dispatch(carrierModalCondition(true))
        }
        this.setState({
            perilModal: true
        })
    }

    handleDiscValue = (e) => {

        const errors = this.state;
        if (e.target.value.trim()) {
            this.setState({
                disc_value: e.target.value
            })
            errors.discovery_description = '';

            this.setState((prevState) => ({
                errors: Object.assign(prevState.errors, errors)
            }));

        } else {
            this.setState({
                disc_value: ''
            })
        }

    }

    disc_save = () => {
        this.setRedirectedByDiscoveryModal(true);
        loss_info[0].discovery_description = this.state.disc_value;

        this.setState({
            disc_save_value: this.state.disc_value,
            modalShow: false
        })
    }

    handleClose = (modalName) => {


        switch (modalName) {
            case "discoverModal":
                this.setRedirectedByDiscoveryModal(true);
                this.setState({
                    modalShow: false
                })
                break;
            case "perilList":
                this.setState({
                    peril_list_modal: false
                })

                break;


            case 'ems_notes':
                this.setRedirectedByEMSNotesModal(true);
                this.setState({
                    ems_notes: false
                })

                break;

            case 'carrier_notes':
                this.setRedirectedByCarrierNoteModal(true);
                this.setState({
                    carrier_notes: false
                })

                break;
            case 'building_notes':
                this.setRedirectedByBuildingNotesModal(true);
                this.setState({
                    building_notes: false,
                    notes_show: false
                })

                break;

            case 'perilEdit':
                this.props.dispatch(optionEditModalCondition(false))
                this.setState({
                    perilEdit: false,
                    peril_list_modal: false,
                    perilModal:true
                })
                break;
            case 'back':
                this.setState({
                    perilEdit: false,
                    peril_list_modal: true
                })
                break;


            case 'source_notes':
                this.setRedirectedByModal(true);
                this.setState({
                    notes_show: false
                })

                break;


            case 'step2_notes':
                this.setState({
                    step2_notes: false
                })

                break;


            case 'loss_reported_notes':
                this.setRedirectedByLossReportedModal(true)
                this.setState({
                    loss_reported_notes: false
                })
                break;

            case "dol_notes": {
                this.setRedirectedByDOLModal(true);
                this.setState({
                    dol_notes: false
                })
                break;
            }

            case "mitigation_notes": {
                this.setRedirectedByMitigationNotes(true);
                this.setState({
                    mitigation_open: false
                })
                break
            }

            default:

                this.setState({
                    perilModal: false
                })

                return  this.handleDefaultValue('peril_select')


        }

    }


    handlePerilValue = (e) => {
        e.preventDefault();

        const perilArray = this.state.perilArray;
        perilArray[e.target.dataset.id] = e.target.value;


        if (e.target.value.trim()) {

            this.setState({
                perilArray: perilArray,
            })
        }

    }

    handlePerilList = () => {

        this.setState({
            perilModal: false,
            peril_list_modal: false,
            click: true,
            perilEdit:true

        })
    }

    handlePerilAdd = (peril) => {

        if(peril.length && peril.length > 0){


            return  axios.post(url + 'add-loss-info', {peril})
                .then(res => {
                    this.handleDefaultValue('peril_select')
                    this.setState({
                        perilModal: false,
                        peril_list_modal: false,
                        perilArray:[]
                    })

                    this.periListDataFetch();

                })
                .catch(err => {
                    console.log(err,'errrrr')
                })
        }else {
            this.handleDefaultValue('peril_select')
            this.setState({
                perilModal: false,
                peril_list_modal: false
            })
        }

    }

    onChange = (e) => {

        if (e.target.value === 'Add More') {
            this.handlePerilChange();
        }
        this.setState({
            value: e.target.value
        })


    }

    handlePerilInputs = () => {

        this.setState({
            peril_inputs: [...this.state.peril_inputs, this.state.peril_inputs.length + 1]
        })
    }

    handleLossAddressValue = (event) => {
        const {name, value} = event.target;

        const errors = this.state.errors ? this.state.errors : ''

        if(errors[name]){
            errors[name] = '';
        }

        this.setState({
            errors: errors
        })

        if(name === 'zip'){
            this.setState({
                state_date:'',
                cities_date:''
            })

            this.getZip(value);
        }

        if (name === 'existing_property') {
            if (event.target.checked == true) {
                loss_address[0].existing_property = '1';
            } else {
                loss_address[0].existing_property = '0';
                loss_address[0].id = null;
            }
            if (this.state.property) {
                this.setState({
                    property: true,

                })
            } else {
                this.setState({
                    current_basic: {},
                    current_building: {},
                    property: false,

                })
            }
            this.setState({
                isExistingPropertyChecked: event.target.checked
            });
        }

        if (name === 'country') {

            const data = {};

            data.country_id = event.target.value;

            this.setState({
                country: false
            })
        }

        loss_address[0][name] = value;
    }

    handlePerilDelete = (index) => {

        this.setState({
            perilArray: this.state.perilArray.filter((item, key) => {
                return key !== index;
            })
        })
    }

    perilEditModal = () => {
        this.setState({
            perilEdit: true,
            modalName:'Peril'
        })
    }


    validateEmail (email) {
        const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        if(!re.test(email)){
           this.setState({
               email_validate:'Invalid e-mail address'
           })
        }else {
            this.setState({
                email_validate:''
            })
            this.refs.btn.removeAttribute("disabled");
        }
    }

 

    handleInputValues = (event, leads_form, require = '',key=null) => {

        const {name, value, dataset} = event.target

        if (leads_form === 1) {
            if (this.state.errors) {
                const errors = this.state.errors ? this.state.errors : ''

                if(errors[name]){
                    errors[name] = ''
                }

                this.setState({
                    errors: errors
                })
            }
            //hishi
            if(this.props.type){
                basic_info.contact_number = null
            }

            if(name === 'email'){
                if(!value){
                     this.setState({
                      email_validate:''
                    })
                }
                if(value.trim()){
                    this.validateEmail(value)
                }
            }

            if (require === 'require') {

            }

            if(value === 'Add More'){
               this.props.dispatch(optionTeamsModalCondition(true))
            }

            basic_info[name] = value;

            //basic_info.first_contact = this.state.date_value !== null &&  this.state.date_value.length > 0 ? this.state.date_value.toString() : null;

            if (basic_info.cell_number || basic_info.work_number || basic_info.home_number) {
                this.setState({
                    number_require: true
                })

            }

        }

        if (leads_form === 2) {
            if (count === Number(dataset.id)) {

                if(name === 'email'){
                    if(!value){
                        this.setState({
                            email_validate:''
                        })
                    }
                    if(value.trim()){
                        this.validateEmail(value)
                    }

                }

                //this.setState([key][name] = value)
                sliders[key][name] = value

                if (name === 'email') {
                    this.setState({
                        valid_form: value
                    })
                }

            } else {

                if(name === 'email'){
                    if(!value){
                        this.setState({
                            email_validate:''
                        })
                    }
                    if(value.trim()){
                        this.validateEmail(value)
                    }

                }
                
                //form[key] = {primary_contact: '0', policy_holder: '0'}
                count = Number(dataset.id)
            }
            this.setState({
                sliders: [sliders]
            });

            if (Object.keys(this.state.errors).length > 0) {

                const errors = this.state.errors ? this.state.errors : ''

                if(errors[name]){
                    errors[name] = ''
                }

                this.setState({
                    errors: errors
                })

                related = [];

            }

        }

        if (leads_form === 3) {

            if (this.state.errors) {
                const errors = this.state.errors ? this.state.errors : ''

                if(errors[name]){
                    errors[name] = ''
                }
                this.setState((prevState) => ({
                    errors: Object.assign(prevState.errors, errors)
                }));

            }

            loss_info[0][name] = value
            loss_info[0].DOL = this.state.dol_date_value.toString();
            loss_info[0].date_loss_reported = this.state.date_loss_reported ?  this.state.date_loss_reported.toString() : null;



            if (value === 'Add More') {
                this.handlePerilChange();
            }

        }

    }

    handleExtentDamageValues = (event) => {
        const {name, value} = event.target;
        const errors = this.state.errors ? this.state.errors : ''

        if(errors[name]){
            errors[name] = ''
        }

        this.setState({
            errors: errors
        })

        loss_info[1][name] = value;

    }

    handleCarrierInfoValues = (event) => {
        const {name, value} = event.target;
       if(value === "Add More"){
           return this.handlePerilChange('carrier');
       }
        loss_info[2][name] = value

        const errors = this.state.errors ? this.state.errors : ''
        if( errors[name]){
            errors[name] = ''
        }

        this.setState({
            errors: errors
        })
    }

    getBuildingInfoData = (event) => {
        
        if(  event.target.value){
                axios.get(url + 'get-building-info?id=' + event.target.value)
                    .then(res => {
                        current_building = res.data
                        loss_address[0] = current_building.lossAddress
                        loss_address[1] = current_building.building
                        current_basic = current_building.lossAddress;
                        this.setState({
                            current_building:current_building.building,
                            current_basic:current_building.lossAddress,
                         })
                       
                       
                    })
                    .catch(err => {
                        console.log(err)
                    })

            return   this.setState({
                current_loss_address:current_building.lossAddress
            })
        }

    }


    handleBuildingInfoValues = (event) => {
        const {name, value} = event.target;

        const errors = this.state.errors ? this.state.errors : '';

        if(errors[name]){
            errors[name] = '';
        }

        this.setState({
            errors: errors
        })

        loss_address[1][name] = value;
    }

    handleMortgageCompanyInformationValue = (event) => {
        const {name, value} = event.target
        const errors = this.state.errors ? this.state.errors : '';


        if(name === 'email'){
            if(!value){
                this.setState({
                    email_validate:''
                })
            }
            if(value.trim()){
                this.validateEmail(value)
            }

        }

        if(errors[name]){
            errors[name] = ''
        }

        this.setState({
            errors: errors
        })
        loss_address[2][name] = value;
    }


    handleChangeNotes = (event, name = '') => {

        clearInterval(intervalId);

        if (name === 'loss_reported_notes') {
            return values.loss_reported_notes = event.target.value
        }


        if (name === 'building_notes') {
            return values.building_notes = event.target.value
        }

        if (name === 'carrier_notes') {
            return values.carrier_notes = event.target.value
        }

        if (name === 'ems_notes') {
            return values.ems_notes = event.target.value
        }

        if (name === 'source_notes') {
            
            return values.step2_notes = event.target.value;
        }

        if (name === "dol_notes") {
            return values.dol_notes = event.target.value;
        }

        if (name === "mitigation_notes") {
            return values.mitigation_notes = event.target.value;
        }
       
        values.source_value = event.target.value
    }


    inputOnFocus = () =>  {
        if(this.nameInput){

            return this.nameInput.focus()
        }

    }

    handleNoteValue = (event, name = '', key) => {
        intervalId = setTimeout(this.inputOnFocus,600)

        if (name === 'loss_reported_notes') {

            return this.setState({
                loss_reported_notes: true,
                notes_show: false
            })
        }

        if (name === 'carrier_notes') {
            return this.setState({
                carrier_notes: true
            })
        }

        if (name === 'step2_notes') {
            // sliders[key]['notes'] = '';
            this.setState({
                notes_id:key
            })

            return this.setState({
                step2_notes: true,
            })
     
        }

        if (name === 'ems_notes') {
            return this.setState({
                ems_notes: true
            })
        }


        if (name === 'building_notes') {
            this.setState({
                building_notes: true
            })
        }

        if (name === 'source_notes') {
            this.setState({
                notes_show: true
            })
        }

        if (name === "dol_notes") {
            this.setState({
                dol_notes: true
            })
        }

        if (name === "mitigation_note") {
            this.setState({
                mitigation_open: true
            })
        }

    }
    handleDefaultValue = (select_name = '') => {

        if(select_name === 'peril_select'){
            return this.peril_select_ref.current.value = ''
        }
      this.select_ref.current.value = ''
    }


    handleSaveNotes = (name = '',key) => {

        if (name === 'source_notes') {
            form.notes = values.step2_notes;
            // this.setState({
            //     which_note: [...this.state.which_note, values.step2_notes]
            // })

            //obj[key] = values.step2_notes;
            sliders[key]['notes'] = values.step2_notes;

            this.setState({
                sliders: [sliders]
            })



            return this.setState({
                step2_notes: false
            })
        }

        if (name === 'loss_reported_notes') {

            this.setRedirectedByLossReportedModal(true)
            loss_info[0].loss_reported_note = values.loss_reported_notes

            return this.setState({
                loss_reported_notes: false
            })
        }


        if (name === 'building_notes') {
            this.setRedirectedByBuildingNotesModal(true);
            loss_address[1].building_notes = values.building_notes
            return this.setState({
                building_notes: false,
                notes_show: false
            })
        }


        if (name === 'carrier_notes') {
            this.setRedirectedByCarrierNoteModal(true)
            loss_info[2].carrier_notes = values.carrier_notes
            const errors = this.state.errors ? this.state.errors : '';

            if(errors){
                errors.carrier_notes = '';
            }


            this.setState((prevState) => ({
                errors: Object.assign(prevState.errors, errors)
            }));
            return this.setState({
                carrier_notes: false
            })
        }

        if (name === 'ems_notes') {
            this.setRedirectedByEMSNotesModal(true);
            loss_info[1].EMS_notes = values.ems_notes


            return this.setState({
                ems_notes: false
            })
        }

        if (name === "dol_notes") {
            this.setRedirectedByDOLModal(true);
            loss_info[1].repairs_since_DOL = values.dol_notes

            return this.setState({ dol_notes: false })
        }

        if (name === "mitigation_note") {
            this.setRedirectedByMitigationNotes(true);
            loss_info[1].mitigation_efforts = values.mitigation_notes;

            return this.setState({ mitigation_open: false });
        }

        this.setRedirectedByModal(true);
        basic_info.source_notes = values.source_value
        values.source_notes = values.source_value
        const errors = this.state;

        errors.source_notes = ''

        this.setState((prevState) => ({
            errors: Object.assign(prevState.errors, errors)
        }));

        this.setState({
            notes_show: false
        })
    }


    getSelectValue = (value) => {

        const arr = [];

        axios.post(url + 'search-existing-contact', {parameter: value})
            .then(res => {
                let lable;
                res.data.map((i,k) => {
                        // Object.keys(i).map((item,key)=>{
                        //    if(i[item] && i[item].toString().toLowerCase().search(value.toLowerCase()) ==0){
                              //lable=item
                              lable= i.contact_number+' | '+i.first_name+' '+i.last_name +' | '+ i.email+' '+ (i.cell_number !=null ? '| '+i.cell_number : '')
                        //    }
                        // });
                    // if(!value) lable = 'contact_number';
                    const obj = {}
                    obj.value = i.contact_number
                    obj.label = lable
                 
                    obj.cell_number = i.cell_number
                    obj.client_id = i.client_id
                    obj.contact_method = i.contact_method
                    obj.contact_number = i.contact_number
                    obj.email = i.email
                    obj.first_name = i.first_name
                    obj.home_number = i.home_number
                    obj.id = i.id
                    obj.last_name = i.last_name
                    obj.work_number = i.work_number
                    obj.zono_account = i.zono_account
                    return arr.push(obj)
                })
                
                this.setState({
                    optionValue: arr
                })

            })
            .catch(err => {
                console.log(err)
            })

    }

    handleChangeOption = (event) => {
        this.getSelectValue(event)
    }
    handleSelectOption = (event) => {

        const errors = this.state;
        let name = '';

        this.setState({
            existing_value: event,
            contacts_disabled: false,
            default_select: event.contact_method,
            zono_account: event.zono_account,
            contact_id: event.id
        })
        if(event.contact_number) {
            basic_info.contact_number = event.contact_number ? event.contact_number : null;
        }else {
            basic_info.contact_number = event.value ? event.value : null;
        }
        basic_info.first_name = event.first_name ? event.first_name : null;
        basic_info.last_name = event.last_name ? event.last_name : null;
        basic_info.cell_number = event.cell_number ? event.cell_number : null;
        basic_info.work_number = event.work_number ? event.work_number : null;
        basic_info.home_number = event.home_number ? event.home_number : null;
        basic_info.email = event.email ? event.email : null;
        basic_info.contact_method = event.contact_method ? event.contact_method : null
        basic_info.zono_account = event.zono_account ? event.zono_account : null

        name= basic_info.contact_number+' | '+basic_info.first_name+' '+basic_info.last_name +' | '+ basic_info.email+' '+ (basic_info.cell_number !=null ? '| '+basic_info.cell_number : '')
        this.setState({
            existing_contact_selected_value:name
        });
        errors.first_name = '';
        errors.last_name = '';
        errors.cell_number = '';
        errors.work_number = '';
        errors.home_number = '';
        errors.email = '';

        this.setState((prevState) => ({
            errors: Object.assign(prevState.errors, errors)
        }));


    }


    handleChangeStep = (step_number) => {


        if(step_number === 2){
             this.someFn(1);
        }   else {
        }

        this.someFn(step_number);

            this.periListDataFetch();
            this.leadsIntakeDataFetch();
            this.getCountyis();
            this.getStates();
            this.getCities();


        if( this.state.currentStep <= 4){
            //this.handleSubmit(null)
            this.setState({
                currentStep:step_number
            })
        }

    }


    render() {
        return (
            <React.Fragment>

                <div className="row">
                    <div className="col-lg-5 col-12  add_lead_title">
                        <h1>Leads Intake Form</h1>
                    </div>
                    <div className="col-lg-7 col-12">

                        <ul className="steps">
                            <li onClick={this.handleChangeStep.bind(this,1)} className={"step" + (this.state.currentStep === 1 ? ' is-active' : '')}>Lead Info</li>
                            <li onClick={this.handleChangeStep.bind(this,2)} className={"step" + (this.state.currentStep === 2 ? ' is-active' : '')}>Related Contacts
                            </li>
                            <li onClick={this.handleChangeStep.bind(this,3)} className={"step" + (this.state.currentStep === 3 ? ' is-active' : '')}>Loss Info</li>
                            <li onClick={this.handleChangeStep.bind(this,4)} className={"step" + (this.state.currentStep === 4 ? ' is-active' : '')}>Loss Address
                            </li>
                        </ul>

                    </div>
                </div>

                {/*------Discovery modal------*/}


                <DiscoveryModal   show={this.state.modalShow} onHide={this.handleClose.bind(this, 'discoverModal')}
                                handleSave={this.disc_save} disc_value={this.state.disc_value}
                                handleChange={this.handleDiscValue}/>


                {/*------Peril option edit------*/}


                <PerilOptionsEdit show={this.state.perilEdit}
                                  periListDataFetch = {this.periListDataFetch}
                                  onHide={this.handleClose.bind(this, 'perilEdit')}
                                  manulAdd={this.state.perilArray}
                                  handleClose={this.handleClose.bind(this, 'perilEdit')}
                                  handleDeletePeril = {this.handleDeletePeril}
                                  name = {this.state.modalName}
                                  perilList={this.state.peril_list} back={this.handleClose.bind(this, 'back')}
                                  click={this.state.click}/>

                {/*Peril options modal*/}

                <Modal show={this.state.peril_list_modal} onHide={this.handleClose.bind(this,'perilList')} centered
                       className="discover_modal_content peril_list" dialogClassName='discover_modal'>
                    <Modal.Header closeButton>
                        <span className={'peril_list_edit'} onClick={this.perilEditModal}>
                            <i className={'apa-pencil'}/>
                        </span>

                        <Modal.Title>Peril Options</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={'panir'}>



                            {/*{this.state.peril_list && this.state.peril_list.length > 0 && this.state.peril_list.map((item, key) => {*/}
                            {/*    return (*/}
                            {/*        <p key={key}>{item.name}</p>*/}
                            {/*    )*/}
                            {/*})}*/}

                            {this.state.perilArray && this.state.perilArray.length > 0 && this.state.perilArray.map((item, key) => {

                                return (
                                    <React.Fragment key={key}>

                                        <i className={'apa-icon-delete delete_icon'} onClick={this.handlePerilDelete.bind(this, key)}/>
                                        <p>{item}</p>

                                    </React.Fragment>
                                )
                            })}

                        </div>
                        <button className={'add_lead_button'} onClick={this.handlePerilChange}><i className={'apa-icon-add '}/> <span>Add More</span></button>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="primary" onClick={this.handlePerilAdd.bind(this,this.state.perilArray)}>Submit</Button>

                    </Modal.Footer>
                </Modal>


                {/*------Add peril option modal------*/}

                <Modal show={this.state.perilModal} onHide={this.handleClose} centered
                       className="discover_modal_content peril_modal" dialogClassName='discover_modal'>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Peril Option</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>


                        {this.state.peril_inputs.map((item, key) => {
                            return (<input name={'peril_desc' + this.state.perilCounter + key}
                                           key={this.state.perilCounter + key}
                                           data-id = {key}
                                           onChange={this.handlePerilValue}/>)
                        })}

                        <button onClick={this.handlePerilInputs}>Add More<span><i className={'apa-icon-add '}/></span></button>

                    </Modal.Body>


                    <Modal.Footer>

                        <button className={'peril_edit'} onClick={this.handlePerilList}><span><i className={'apa-pencil'}/></span>Edit List</button>

                        <Button variant="primary" onClick={this.handlePerilAdd.bind(this,this.state.perilArray)}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/*------Carrier Modal------*/}
                <CarrierModal  handleDefaultValue = {this.handleDefaultValue} periListDataFetch = {this.periListDataFetch} show = {this.props.carrier_modal} onHide={this.handleClose.bind(this, 'perilEdit')}/>


                {/*------Teams Modal------*/}
                <TeamsModal handleDefaultValue = {this.handleDefaultValue} getBasicInfoData = {this.getBasicInfoData} periListDataFetch = {this.periListDataFetch} />
                {/*------Step1 Notes modal------*/}

                <Modal show={this.state.notes_show} onHide={this.handleClose.bind(this, 'source_notes')} centered
                       className="discover_modal_content" dialogClassName='discover_modal'>
                    <Modal.Header closeButton>
                        <Modal.Title>Source Notes</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <textarea   ref={(input) => { this.nameInput = input; }}    id="noter-text-area" className={'steps_text_area'} name="source_notes"
                                    defaultValue={values.source_notes && values.source_notes}
                                    onChange={this.handleChangeNotes}/>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="primary" onClick={this.handleSaveNotes}>Save</Button>
                    </Modal.Footer>
                </Modal>


                {/*------Step2 Notes------*/}


                <Modal show={this.state.step2_notes} onHide={this.handleClose.bind(this, 'step2_notes')} centered
                       className="discover_modal_content" dialogClassName='discover_modal'>
                    <Modal.Header closeButton>
                        <Modal.Title>Notes</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <textarea
                            ref={(input) => { this.nameInput = input; }}
                            id="noter-text-area"
                            className={'steps_text_area'}
                            name="source_notes"
                            // value={sliders[this.state.notes_id] && sliders[this.state.notes_id]['notes']}
                            defaultValue={this.state.sliders[0] && this.state.sliders[0][this.state.notes_id] && this.state.sliders[0][this.state.notes_id]['notes']}
                            onChange={(e) => this.handleChangeNotes(e, "source_notes")}/>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="primary" onClick={this.handleSaveNotes.bind(this, 'source_notes',this.state.notes_id)}>Save</Button>
                    </Modal.Footer>
                </Modal>


                {/*------Step3 Loss Reported Notes------*/}

                <Modal show={this.state.loss_reported_notes} onHide={this.handleClose.bind(this, 'loss_reported_notes')}
                       centered className="discover_modal_content" dialogClassName='discover_modal'>
                    <Modal.Header closeButton>
                        <Modal.Title>Loss Reported Note</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <textarea
                                ref={(input) => { this.nameInput = input; }}
                                id="noter-text-area"
                                className={'steps_text_area'}
                                name="loss_reported_notes"
                                defaultValue={values.loss_reported_notes && values.loss_reported_notes}
                                onChange={(e) => this.handleChangeNotes(e, "loss_reported_notes")}/>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="primary" onClick={this.handleSaveNotes.bind(this, 'loss_reported_notes')}>Save</Button>
                    </Modal.Footer>
                </Modal>

                {/*------Step3 EMS  Notes------*/}

                <Modal show={this.state.ems_notes} onHide={this.handleClose.bind(this, 'ems_notes')} centered
                       className="discover_modal_content" dialogClassName='discover_modal'>
                    <Modal.Header closeButton>
                        <Modal.Title>EMS notes</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <textarea
                            ref={(input) => { this.nameInput = input; }}
                            id="noter-text-area"
                            className={'steps_text_area'}
                            name="ems_notes"
                            defaultValue={values.ems_notes && values.ems_notes}
                            onChange={(e) => this.handleChangeNotes(e, "ems_notes")}/>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="primary" onClick={this.handleSaveNotes.bind(this, 'ems_notes')}>Save</Button>
                    </Modal.Footer>
                </Modal>


                {/*------Step3 Carrier  Notes------*/}

                <Modal show={this.state.carrier_notes} onHide={this.handleClose.bind(this, 'carrier_notes')} centered
                       className="discover_modal_content" dialogClassName='discover_modal'>
                    <Modal.Header closeButton>
                        <Modal.Title>Carrier Note</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <textarea ref={(input) => { this.nameInput = input; }} id="noter-text-area" className={'steps_text_area'} name="ems_notes"
                                  defaultValue={values.carrier_notes && values.carrier_notes}
                                  onChange={(e) => this.handleChangeNotes(e, "carrier_notes")}/>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="primary"
                                onClick={this.handleSaveNotes.bind(this, 'carrier_notes')}>Save</Button>
                    </Modal.Footer>
                </Modal>


                {/*------Step3 Building Notes------*/}


                <Modal show={this.state.building_notes} onHide={this.handleClose.bind(this, 'building_notes')} centered
                       className="discover_modal_content" dialogClassName='discover_modal'>
                    <Modal.Header closeButton>
                        <Modal.Title>Building Notes</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <textarea ref={(input) => { this.nameInput = input; }} id="noter-text-area" className={'steps_text_area'} name="building_notes"
                                  defaultValue={values.building_notes && values.building_notes}
                                  onChange={(e) => this.handleChangeNotes(e, "building_notes")}/>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="primary"
                                onClick={this.handleSaveNotes.bind(this, 'building_notes')}>Save</Button>
                    </Modal.Footer>
                </Modal>

                {/* Step 3: DOL */}
                <Modal show={this.state.dol_notes} onHide={this.handleClose.bind(this, 'dol_notes')} centered
                      className="discover_modal_content" dialogClassName='discover_modal'>
                    <Modal.Header closeButton>
                        <Modal.Title>Repairs since DOL</Modal.Title>
                    </Modal.Header>
                    
                    <Modal.Body>
                        <textarea ref={(input) => { this.nameInput = input; }}  id="dol-text-area" 
                                  className={'steps_text_area'} 
                                  name="dol_notes"
                                  defaultValue={values?.dol_notes}
                                  onChange={(e) => this.handleChangeNotes(e, "dol_notes")}/>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="primary" onClick={this.handleSaveNotes.bind(this, 'dol_notes')}>Save</Button>
                    </Modal.Footer>
                </Modal>

                {/* Step 3: Mitigation Efforts */}
                <Modal show={this.state.mitigation_open} onHide={this.handleClose.bind(this, 'mitigation_notes')} centered
                      className="discover_modal_content" dialogClassName='discover_modal'>
                    <Modal.Header closeButton>
                        <Modal.Title>Mitigation efforts</Modal.Title>
                    </Modal.Header>
                    
                    <Modal.Body>
                        <textarea ref={(input) => { this.nameInput = input; }}  id="mitig_eff-text-area" 
                                  className={'steps_text_area'} 
                                  name="mit-eff_notes"
                                  defaultValue={values?.mitigation_notes}
                                  onChange={(e) => this.handleChangeNotes(e, "mitigation_notes")}/>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="primary" onClick={this.handleSaveNotes.bind(this, 'mitigation_note')}>Save</Button>
                    </Modal.Footer>
                </Modal>

                <form onSubmit={this.handleSubmit} noValidate={true}>

                    <Step1
                        sourceRef={this.sourceRef}
                        redirectedByModal={this.state.redirectedByModal}
                        setRedirectedByModal={this.setRedirectedByModal}
                        existing_contact_selected_value={this.state.existing_contact_selected_value}
                        currentStep={this.state.currentStep}
                        handleChange={this.handleChange}
                        dateSave={this.dateSave}
                        date={this.state.date_value}
                        handleInputValues={this.handleInputValues}
                        basic_data={this.state.basic_data}
                        exist_contact_check={this.state.exist_contact_check}
                        handleCheckboxChange={this.handleCheckboxChange}
                        handleNoteValue={this.handleNoteValue}
                        notes_value={values.source_notes}
                        number_require={this.state.number_require}
                        save_draft={this.state.save_draft}
                        errors={this.state.errors}
                        handleChangeOption={this.handleChangeOption}
                        handleSelectOption={this.handleSelectOption}
                        optionValue={this.state.optionValue}
                        styles={customStyles}
                        contacts_disabled={this.state.contacts_disabled}
                        existing_value={this.state.existing_value}
                        default_select={this.state.default_select}
                        zono_account={this.state.zono_account}
                        email_validate = {this.state.email_validate}
                        select_ref = {this.select_ref}
                        type ={this.state.type}



                    />


                    <Step2
                        currentStep={this.state.currentStep}
                        handleChange={this.handleChange}
                        checked={this.state.checked}
                        handle_add={this.handle_add}
                        children={this.state.children}
                        slides={this.state.slides}
                        sliders={sliders}
                        addSlide={this.addSlide}
                        slider_ref={this.slider_ref}
                        handleInputValues={this.handleInputValues}
                        relation_form_iteration={this.state.relation_form_iteration}
                        related_ref={this.related_ref}
                        handleNoteValue={this.handleNoteValue}
                        handleCheckboxChange={this.handleCheckboxChange}
                        step2_value={values.step2_notes}
                        which_note={this.state.which_note}
                        errors={this.state.errors}
                        related={related}
                        arr={arr}
                        obj = {obj}
                        form={form}
                        email_validate = {this.state.email_validate}

                    />

                    <Step3
                        lossReportedRef={this.lossReportedRef}
                        setRedirectedByLossReportedModal={this.setRedirectedByModal}
                        redirectedByLossReportedModal={this.state.redirectedByLossReportedModal}
                        
                        discoveryRef={this.discoveryRef}
                        redirectedByDiscoveryModal={this.state.redirectedByDiscoveryModal}
                        setRedirectedByDiscoveryModal={this.setRedirectedByModal}

                        // EMS Notes
                        emsNotesRef={this.emsNotesRef}
                        redirectedByEMSNotesModal={this.state.redirectedByEMSNotesModal}
                        setRedirectedByEMSNotesModal={this.setRedirectedByEMSNotesModal}

                        // Carrier Notes
                        carrierNoteRef={this.carrierNoteRef}
                        redirectedByCarrierNoteModal={
                          this.state.redirectedByCarrierNoteModal
                        }
                        setRedirectedByCarrierNoteModal={this.setRedirectedByCarrierNoteModal}

                        // DOL
                        dolRef={this.dolRef}
                        dolVal={values.dol_notes}
                        redirectedByDOLModal={this.state.redirectedByDOLModal}
                        setRedirectedByDOLModal={this.setRedirectedByDOLModal}

                        // Mitigation Effortsw
                        mitigationEffRef={this.mitigationEffRef}
                        mitigationEffVal={values.mitigation_notes}
                        redirectedByMitigationNotes={this.state.redirectedByMitigationNotes}
                        setRedirectedByMitigationNotes={this.setRedirectedByMitigationNotes}

                        currentStep={this.state.currentStep}
                        team_default_select = {this.state.team_default_select}
                        handleChange={this.handleChange}
                        handleNoteValue={this.handleNoteValue}
                        loss_reported_note={values.loss_reported_notes}
                        carrier_notes={values.carrier_notes}
                        ems_value={values.ems_notes}
                        handleDiscoverChange={this.handleDiscoverChange}
                        handlePerilChange={this.handlePerilChange}
                        disc_save_value={this.state.disc_save_value}
                        peril_array={this.state.perilArray}
                        peril_list={this.state.peril_list}
                        onChange={this.onChange}
                        value={this.state.value}
                        handleInputValues={this.handleInputValues}
                        dateSave={this.dateSave}
                        date={this.state.dol_date_value}
                        date_loss_reported={this.state.date_loss_reported}
                        floor_lead={this.state.floor_lead}
                        repaired_plumbing={this.state.repaired_plumbing}
                        handleCheckboxChange={this.handleCheckboxChange}
                        handleExtentDamageValues={this.handleExtentDamageValues}
                        handleCarrierInfoValues={this.handleCarrierInfoValues}
                        carrier={this.state.carrier && this.state.carrier}
                        kind_floor={this.state.kind_floor && this.state.kind_floor}
                        errors={this.state.errors}
                        select_ref = {this.select_ref}
                        peril_select_ref = {this.peril_select_ref}
                        hot_lead_checked={this.state.hot_lead_checked}
                    />

                    <Step4
                        buildingNotesRef={this.buildingNotesRef}
                        redirectedByBuildingNotesModal={this.state.redirectedByBuildingNotesModal}
                        setRedirectedByBuildingNotesModal={this.setRedirectedByBuildingNotesModal}

                        buidlingNotesValue={values.building_notes}
                        setState={this.setState.bind(this)}

                        currentStep={this.state.currentStep}
                        handleChange={this.handleChange}
                        isChecked={this.state.isChecked}
                        isExistingPropertyChecked={this.state.isExistingPropertyChecked}
                        handleOnChange={this.handleOnChange}
                        handleLossAddressValue={this.handleLossAddressValue}
                        handleBuildingInfoValues={this.handleBuildingInfoValues}
                        handleCheckboxChange={this.handleCheckboxChange}
                        handleMortgageCompanyInformationValue={this.handleMortgageCompanyInformationValue}
                        county={this.state.country}
                        countrys_date={this.state.countrys_date}
                        state={this.state.state}
                        state_date={this.state.state_date}
                        city={this.state.city}
                        cities_date={this.state.cities_date}
                        building_types={this.state.building_types}
                        success_alert={this.state.success_alert}
                        building_notes={values.building_notes}
                        handleNoteValue={this.handleNoteValue}
                        errors={this.state.errors}
                        loss_address_property = {this.state.loss_address_property}
                        getBuildingInfoData = {this.getBuildingInfoData}
                        current_loss_address = {this.current_loss_address}
                        current_basic = {this.state.current_basic}
                        current_building = {this.state.current_building}
                        email_validate = {this.state.email_validate}
                        exist_contact_check= {this.state.exist_contact_check}
                        
                    />

                    <div className={'add_lead_slider_buttons'}>
                        {this.previousButton()}
                        {this.nextButton()}
                        {this.state.currentStep === 1 && this.saveDraft()}

                    </div>

                </form>

            </React.Fragment>
        );
    }
}

function Step1(props) {
    if (props.currentStep !== 1) {
        return null
    }

    return (

        <>
            <div>
                <div className="row">
                    <div className="col-lg-6 col-12 mobile_space ">
                        <div className={'add_lead_basic'}>
                            <h3>Basic Info</h3>

                            <div className="row">


                                <div className="col-4">
                                    <p>Existing Contact</p>
                                </div>

                                <div className="col-8">
                                    {props.type  ?
                                        <p>{props.existing_contact_selected_value}</p>
                                        :
                                        <input
                                            defaultChecked={(basic_info.exist_contact_check && Number(basic_info.exist_contact_check))}
                                            type="checkbox" id="exist_contact_check1"
                                            name="exist_contact_check"
                                            value={props.exist_contact_check}
                                            onChange={(e) => {props.handleCheckboxChange(e, 1)
                                            }}/>
                                    }

                                    <label htmlFor="exist_contact_check1"/>
                                </div>

                                <div className="col-4" style={{display: props.exist_contact_check === 1  ? 'block' : 'none'}}>
                                    <p>Contact</p>
                                </div>
                                <div className="col-8" style={{display: props.exist_contact_check === 1? 'block' : 'none'}}>
                                    <Select styles={props.styles} placeholder='' className={'search_select'}
                                            options={props.optionValue}
                                            {...props}
                                            value = {
                                            props.optionValue.filter(option => 
                                                option.label === props.existing_contact_selected_value)
                                            }
                                            onInputChange={props.handleChangeOption}
                                            onChange={props.handleSelectOption}/>

                                </div>

                                <div className="col-4">
                                    <p>Lead Rep</p>
                                </div>
                                <div className="col-8">

                                    <div className="form-group selectdiv">

                                        <select
                                            defaultValue={basic_info.lead_rep && basic_info.lead_rep}
                                            className={"form-control alterationTypeSelect" + (props.errors.lead_rep && ' error_input')}
                                            name="lead_rep" onChange={(e) => {
                                            props.handleInputValues(e, 1)
                                        }} required={props.save_draft && true}>
                                            <option value={''}/>
                                            {props.basic_data && props.basic_data.map((item) => {

                                                return (
                                                    item.rep.map((value, key) => {

                                                        return (
                                                            <option value={value.id} key={key}>{value.name}</option>
                                                        )
                                                    })
                                                )
                                            })}

                                        </select>

                                        {props.errors.lead_rep &&
                                        <span style={{color: 'red'}}>{props.errors.lead_rep}</span>}
                                    </div>
                                </div>

                                <div className="col-4">
                                    <p>Category</p>
                                </div>

                                <div className="col-8">
                                    <div className="form-group selectdiv">

                                        <select
                                            defaultValue={basic_info.category_id && basic_info.category_id}
                                            className={"form-control alterationTypeSelect" + (props.errors.category_id && ' error_input')}
                                            name="category_id" onChange={(e) => {
                                            props.handleInputValues(e, 1)
                                        }} required={props.save_draft && true}>
                                            <option value=''/>
                                            {props.basic_data && props.basic_data.map((item) => {

                                                return (
                                                    item.category.map((value, key) => {
                                                        return (
                                                            <option value={value.id} key={key}>{value.type}</option>
                                                        )
                                                    })
                                                )
                                            })}
                                        </select>
                                        {props.errors.category_id &&
                                        <span style={{color: 'red'}}>{props.errors.category_id}</span>}
                                    </div>
                                </div>

                                <div className="col-4">
                                    <p>Status</p>
                                </div>

                                <div className="col-8">
                                    <div className="form-group selectdiv">

                                        <select
                                            defaultValue={basic_info.status_id && basic_info.status_id}
                                            className={"form-control alterationTypeSelect" + (props.errors.status_id && ' error_input')}
                                            name="status_id"
                                            onChange={(e) => {props.handleInputValues(e, 1)
                                            }} required={props.save_draft && true}>
                                            <option value=''/>
                                            {props.basic_data && props.basic_data.map((item) => {

                                                return (
                                                    item.status.map((value, key) => {
                                                        return (
                                                            <option value={value.id} key={key}>{value.type}</option>
                                                        )
                                                    })
                                                )
                                            })}
                                        </select>

                                        {props.errors.status_id &&
                                        <span style={{color: 'red'}}>{props.errors.status_id}</span>}
                                    </div>
                                </div>


                                <div className="col-4">
                                    <p>First Contact</p>
                                </div>

                                <div className="col-8">
                                    <DatePicker
                                                value={basic_info.first_contact && basic_info.first_contact.toString()}
                                                className={'input_date' + (props.errors.first_contact && ' error_input')}
                                                onChange={props.dateSave.bind(this, 'first_contact')}
                                                selected={props.date}
                                                name={'first_contact'}

                                    />
                                    {props.errors.first_contact &&
                                    <span style={{color: 'red'}}>{props.errors.first_contact}</span>}
                                </div>

                                <div className="col-4">
                                    <p>Lead Source</p>
                                </div>

                                <div className="col-8">
                                    <div className="form-group selectdiv">

                                        <select
                                            defaultValue={basic_info.lead_source && basic_info.lead_source}
                                            className={"form-control alterationTypeSelect" + (props.errors.lead_source && ' error_input')}
                                            name="lead_source" onChange={(e) => {
                                            props.handleInputValues(e, 1)
                                        }} required={props.save_draft && true}>
                                            <option value=''/>
                                            {props.basic_data && props.basic_data.map((item) => {

                                                return (
                                                    item.source.map((value, key) => {

                                                        return (
                                                            <option value={value.id} key={key}>{value.name}</option>
                                                        )
                                                    })
                                                )
                                            })}
                                        </select>
                                        {props.errors.lead_source &&
                                        <span style={{color: 'red'}}>{props.errors.lead_source}</span>}
                                    </div>
                                </div>

                                <div className="col-4">
                                    <p>Source Notes</p>
                                </div>

                                <div className="col-8">

                                    {/*<input type={'text'} name={'source_notes'} onChange={(e) => {props.handleInputValues(e, 1)}} required={true}/>*/}
                                    <input 
                                           ref={props.sourceRef}
                                           type={'text'}
                                           readOnly={true}
                                           name={'source_notes'}
                                           className={props.errors.source_notes && ' error_input'}
                                            onFocus={(e)=>{
                                                if (!props.redirectedByModal) props.handleNoteValue(e, 'source_notes');
                                                else props.setRedirectedByModal(false)
                                            }}
                                            onClick={(e)=> {
                                                if (!props.redirectedByModal) props.handleNoteValue(e, 'source_notes');
                                                else props.setRedirectedByModal(false) 
                                            }}
                                           value={basic_info.source_notes && basic_info.source_notes}
                                           defaultValue={props.notes_value}
                                           required={props.save_draft && true}/>
                                    {props.errors.source_notes &&
                                    <span style={{color: 'red'}}>{props.errors.source_notes}</span>}
                                </div>

                                <div className="col-4">
                                    <p>Team</p>
                                </div>

                                <div className="col-8">
                                    <div className="form-group selectdiv">

                                        <select
                                            ref={props.select_ref}
                                            className={"form-control alterationTypeSelect" + (props.errors.team_id && ' error_input')}
                                            name="team_id"
                                            onChange={(e) => {
                                                props.handleInputValues(e, 1)
                                            }}
                                            required={props.save_draft && true}>
                                            <option value={'null'}/>
                                            {props.basic_data && props.basic_data.map((item) => {

                                                return (
                                                    item.team.map((value, key) => {
                                                        return (
                                                            <option selected={basic_info.team_id && Number(basic_info.team_id) == Number(value.id)} value={value.id}  key={key}>{value.name}</option>
                                                        )
                                                    })
                                                )

                                            })}
                                            <option>Add More</option>
                                        </select>
                                        {props.errors.team_id &&
                                        <span style={{color: 'red'}}>{props.errors.team_id}</span>}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className={"col-lg-6 col-12 mobile_space  add_lead_contact_info" + (props.contacts_disabled && ' add_disabled')}>
                        <div className={'add_lead_basic'}>
                            <h3>Contact Info</h3>

                            <div className="row">

                                <div className="col-4">
                                    <p>First Name</p>
                                </div>

                                <div className="col-8">
                                    <input defaultValue={basic_info.first_name && basic_info.first_name}
                                           type={'text'}
                                           disabled={(props.exist_contact_check && props.contacts_disabled)}
                                           className={props.errors.first_name && ' error_input'} name={'first_name'}
                                           onChange={(e) => {props.handleInputValues(e, 1, 'require')}}
                                           required={true}/>
                                    {props.errors.first_name && <span style={{color: 'red'}}>{props.errors.first_name}</span>}
                                </div>

                                <div className="col-4">
                                    <p>Last Name</p>
                                </div>
                                <div className="col-8">
                                    <input defaultValue={basic_info.last_name && basic_info.last_name}
                                           className={props.errors.last_name && ' error_input'} type={'text'}
                                           disabled={(props.exist_contact_check && props.contacts_disabled)}
                                           name={'last_name'} onChange={(e) => {props.handleInputValues(e, 1)}}
                                           required={true}/>
                                    {props.errors.last_name && <span style={{color: 'red'}}>{props.errors.last_name}</span>}
                                </div>

                                <div className="col-4">
                                    <p>Cell Number</p>
                                </div>

                                <div className="col-8">

                                    <NumberFormat 
                                    format="(###) ###-####"
                                    type={'text'}
                                           defaultValue={basic_info.cell_number && basic_info.cell_number}
                                           className={props.errors.cell_number && ' error_input'}
                                           disabled={(props.exist_contact_check && props.contacts_disabled)}
                                           name={'cell_number'} required={!props.number_require && true}
                                           onChange={(e) => {props.handleInputValues(e, 1)}}/>
                                    {props.errors.cell_number && <span style={{color: 'red'}}>{props.errors.cell_number}</span>}
                                </div>


                                <div className="col-4">
                                    <p>Work Number</p>
                                </div>

                                <div className="col-8">
                                <NumberFormat 
                                    format="(###) ###-####"
                                            defaultValue={basic_info.work_number && basic_info.work_number}
                                            className={props.errors.work_number && ' error_input'} type={'text'}
                                            disabled={(props.exist_contact_check && props.contacts_disabled)}
                                            name={'work_number'} required={!props.number_require && true}
                                            onChange={(e) => {props.handleInputValues(e, 1)}}/>
                                    {props.errors.work_number && <span style={{color: 'red'}}>{props.errors.work_number}</span>}
                                </div>


                                <div className="col-4">
                                    <p>Home Number</p>
                                </div>

                                <div className="col-8">

                                <NumberFormat 
                                    format="(###) ###-####"
                                     defaultValue={basic_info.home_number && basic_info.home_number}
                                           className={props.errors.home_number && ' error_input'} type={'text'}
                                           disabled={(props.exist_contact_check && props.contacts_disabled)}
                                           name={'home_number'} required={!props.number_require && true}
                                           onChange={(e) => {props.handleInputValues(e, 1)}}/>
                                    {props.errors.home_number && <span style={{color: 'red'}}>{props.errors.home_number}</span>}
                                </div>

                                <div className="col-4">
                                    <p>Email</p>
                                </div>

                                <div className="col-8">
                                    <input defaultValue={basic_info.email && basic_info.email}
                                           className={props.errors.email && ' error_input'}
                                           type="email"
                                           disabled={(props.exist_contact_check && props.contacts_disabled)}
                                           name={'email'}
                                           onChange={(e) => {props.handleInputValues(e, 1)}}
                                           required={true}
                                    />
                                    {props.errors.email && <span style={{color: 'red'}}>{props.errors.email}</span>}
                                    {props.email_validate &&  <span style={{color: 'red'}}>{props.email_validate}</span>}

                                </div>

                                <div className="col-4">
                                    <p>Contact Method</p>
                                </div>

                                <div className="col-8">
                                    <div className="form-group selectdiv">

                                        <select defaultValue={basic_info.contact_method && basic_info.contact_method}
                                                disabled={(props.exist_contact_check && props.contacts_disabled)}
                                                className={"form-control alterationTypeSelect" + (props.errors.email && ' contact_method')}
                                                name="contact_method" onChange={(e) => {props.handleInputValues(e, 1)}}
                                                required={true}>
                                            <option value={'DEFAULT'}/>
                                            <option value="1">Cell Number</option>
                                            <option value="2">Work Number</option>
                                            <option value="3">Home Number</option>
                                            <option value="4">Email</option>

                                        </select>
                                    </div>
                                    {props.errors.contact_method && <span style={{color: '#ff4040'}}>{props.errors.contact_method}</span>}
                                </div>

                                <div className="col-4">
                                    <p>Zono Account</p>
                                </div>

                                <div className="col-8">
                                    <input defaultChecked={props.zono_account === "1"} type="checkbox"
                                           disabled={(props.exist_contact_check && props.contacts_disabled)}
                                           id="zono_check" name="zono_account" onChange={(e) => {props.handleCheckboxChange(e, 1)}}/>
                                    <label htmlFor="zono_check"/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

function Step2(props) {
    if (props.currentStep !== 2) {
        return null
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: props.slides.length > 2 ? 3 : 2,
        slidesToScroll: props.slides.length,
        initialSlide: 0,
        responsive: [

            {
                breakpoint: 1450,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,

                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,

                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };



    return (

        <div className={'add_lead_slider'}>
            <Slider {...settings} ref={props.slider_ref}>
                {props.sliders.map(function (slide, key) {

                    return (
                        
                        <div key={slide}>
                            <input type="hidden" value={key}/>
                            <div
                                className={"col-12  add_lead_contact_info mobile_space" }>
                                    
                                <div className={'add_lead_basic'}>
                                    <h3>Related Contacts</h3>
                                    <div className="row">

                                        <div className="col-4">
                                            <p>Company Name</p>
                                        </div>

                                        <div className="col-8">
                                            <input
                                                defaultValue={slide.company_name ? slide.company_name : null}
                                                className={(props.errors.company_name ? ' error_input' : '')}
                                                type={'text'} data-id={key}
                                                name={'company_name'}
                                                onChange={(e) => {props.handleInputValues(e, 2,'',key)}}
                                                required={true}/>
                                            {props.errors.company_name ? <span style={{color: 'red'}}>{props.errors.company_name}</span> : ''}

                                        </div>

                                        <div className="col-4">
                                            <p>First Name</p>
                                        </div>
                                        <div className="col-8">
                                            <input
                                                defaultValue={slide.first_name ? slide.first_name : null}
                                                className={(props.errors.first_name ? ' error_input' : '')}
                                                type={'text'} data-id={key}
                                                name={'first_name'}
                                                onChange={(e) => {props.handleInputValues(e, 2,'',key)}}
                                                required={true}/>
                                            {props.errors.first_name? <span style={{color: 'red'}}>{props.errors.first_name}</span> : ''}

                                        </div>

                                        <div className="col-4">
                                            <p>Last Name</p>
                                        </div>

                                        <div className="col-8">
                                            <input
                                                defaultValue={slide.last_name ? slide.last_name : null}
                                                className={(props.errors.last_name ? ' error_input' : '')}
                                                type={'text'} data-id={key}
                                                name={'last_name'}
                                                onChange={(e) => {
                                                props.handleInputValues(e, 2,'',key)
                                            }} required={true}/>
                                            {props.errors.last_name ? <span style={{color: 'red'}}>{props.errors.last_name}</span> : ''}

                                        </div>

                                        <div className="col-4">
                                            <p>Cell Number</p>
                                        </div>

                                        <div className="col-8">
                                        <NumberFormat 
                                    format="(###) ###-####"
                                                defaultValue={slide.cell_number ? slide.cell_number : null}
                                                className={(props.errors.cell_number ? ' error_input' : '')}
                                                type={'text'}
                                                data-id={key}
                                                name={'cell_number'}
                                                onChange={(e) => {props.handleInputValues(e, 2,'',key)}}
                                                required={true}/>
                                            {props.errors.cell_number ? <span style={{color: 'red'}}>{props.errors.cell_number}</span> : ''}
                                        </div>


                                        <div className="col-4">
                                            <p>Work Number</p>
                                        </div>

                                        <div className="col-8">
                                        <NumberFormat 
                                    format="(###) ###-####"
                                                defaultValue={slide.work_number ? slide.work_number : null}
                                                className={(props.errors.work_number ? ' error_input' : '')}
                                                type="text"
                                                data-id={key}
                                                name={'work_number'}
                                                onChange={(e) => {props.handleInputValues(e, 2,'',key)}}
                                                required={true}/>

                                            {props.errors.work_number ? <span style={{color: 'red'}}>{props.errors.work_number}</span> : ''}
                                        </div>

                                        <div className="col-4">
                                            <p>Home Number</p>
                                        </div>

                                        <div className="col-8">
                                        <NumberFormat 
                                    format="(###) ###-####"
                                                defaultValue={slide.home_number ? slide.home_number : null}
                                                className={(props.errors.home_number ? ' error_input' : '')}
                                                type="text"
                                                data-id={key}
                                                name={'home_number'}
                                                onChange={(e) => {props.handleInputValues(e, 2,'',key)}}
                                                required={true}/>
                                            {props.errors.home_number ? <span style={{color: 'red'}}>{props.errors.home_number}</span> : ''}

                                        </div>

                                        <div className="col-4">
                                            <p>Email</p>
                                        </div>

                                        <div className="col-8">
                                            <input
                                                defaultValue={slide.email ? slide.email : null}
                                                className={(props.errors.email ? ' error_input' : '')}
                                                type={'email'} ref={props.related_ref} data-id={key}
                                                name={'email'}
                                                 onChange={(e) => {props.handleInputValues(e, 2,'',key)}}
                                                required={true}/>
                                            {props.errors.email ? <span style={{color: 'red'}}>{props.errors.email}</span> : ''}
                                            {props.email_validate ? <span style={{color: 'red'}}>{props.email_validate}</span> : ''}

                                        </div>

                                        <div className="col-4">
                                            <p>Notes</p>
                                        </div>

                                        <div className="col-8">
{/*test*/}

                                            <input
                                                className={(props.errors.notes && props.slides.length - 1 === key + 1 ? ' error_input' : '')}
                                                type={'text'} data-id={key}
                                                name={'notes'}
                                                readOnly={true}
                                                defaultValue= {slide.notes}
                                                value={slide.notes}
                                                onClick={(e) => {props.handleNoteValue(e, 'step2_notes',key)}}
                                                required={true}
                                                onFocus={(e)=>{
                                                    props.handleNoteValue(e, 'step2_notes', key);
                                                    e.target.blur();
                                                }}
                                                />
                                            {props.errors.notes && props.slides.length - 1 === key + 1 ? <span style={{color: 'red'}}>{props.errors.notes}</span> : ''}
                                        </div>

                                        <div className="col-4">
                                            <p>Primary Contact</p>
                                        </div>

                                        <div className="col-8">
                                            <input
                                                checked={slide.primary_contact && slide.primary_contact === '1' ? true : false }
                                                type="checkbox" data-id={key} id={"primary_contact" + key}
                                                name="primary_contact"
                                                onChange={(e) => {props.handleCheckboxChange(e, 2,key)}}/>
                                            <label htmlFor={"primary_contact" + key}/>
                                        </div>

                                        <div className="col-4">
                                            <p>Policy Holder</p>
                                        </div>

                                        <div className="col-8">
                                            <input
                                                checked={slide.policy_holder && slide.policy_holder === '1' ? true : false }
                                                type="checkbox" data-id={key} id={"contact_check" + key}
                                                name="policy_holder"
                                                onChange={(e) => {props.handleCheckboxChange(e, 2,key)}}/>
                                            <label htmlFor={"contact_check" + key}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    );
                })}
                <div>
                            <input type="hidden"/>
                            <div
                                className={"col-12  add_lead_contact_info mobile_space add_disabled" }>
                                    
                                <div className={'add_lead_basic'}>
                                    <h3>Related Contacts</h3>
                                    <div className="row">

                                        <div className="col-4">
                                            <p>Company Name</p>
                                        </div>

                                        <div className="col-8">
                                            <input
                                                defaultValue={''}
                                                type={'text'}
                                                disabled={true} 
                                                name={'company_name'}
                                                required={true}/>

                                        </div>

                                        <div className="col-4">
                                            <p>First Name</p>
                                        </div>
                                        <div className="col-8">
                                            <input
                                                defaultValue={''}
                                                type={'text'}
                                                disabled={true} 
                                                name={'first_name'}
                                                />

                                        </div>

                                        <div className="col-4">
                                            <p>Last Name</p>
                                        </div>

                                        <div className="col-8">
                                            <input
                                                defaultValue={''}
                                                type={'text'} 
                                                name={'last_name'}
                                                disabled={true}  />
                                           

                                        </div>

                                        <div className="col-4">
                                            <p>Cell Number</p>
                                        </div>

                                        <div className="col-8">
                                            <input
                                                defaultValue={''}
                                                type={'text'}
                                                name={'cell_number'}
                                                disabled={true} 
                                                />
                                        </div>


                                        <div className="col-4">
                                            <p>Work Number</p>
                                        </div>

                                        <div className="col-8">
                                            <input
                                                defaultValue={''}
                                                type="number"
                                                name={'work_number'}
                                                disabled={true} 
                                                />
                                        </div>

                                        <div className="col-4">
                                            <p>Home Number</p>
                                        </div>

                                        <div className="col-8">
                                            <input
                                                defaultValue={''}
                                                type="number"
                                                name={'home_number'}
                                                disabled={true} 
                                                />

                                        </div>

                                        <div className="col-4">
                                            <p>Email</p>
                                        </div>
                                        <div className="col-8">
                                            <input
                                                defaultValue={''}
                                                type={'email'} 
                                                name={'email'}
                                                disabled={true} 
                                                />

                                        </div>

                                        <div className="col-4">
                                            <p>Notes</p>
                                        </div>

                                        <div className="col-8">

                                            <input
                                                type={'text'} 
                                                name={'notes'}
                                                readOnly={true}
                                                defaultValue={''}
                                                disabled={true}
                                                required={false}
                                                />
                                        </div>

                                        <div className="col-4">
                                            <p>Primary Contact</p>
                                        </div>

                                        <div className="col-8">
                                            <input
                                                defaultChecked={false}
                                                type="checkbox"
                                                name="primary_contact"
                                                disabled={true}/>
                                            <label htmlFor={"primary_contact"}/>
                                        </div>

                                        <div className="col-4">
                                            <p>Policy Holder</p>
                                        </div>

                                        <div className="col-8">
                                            <input
                                                defaultChecked={false}
                                                type="checkbox" 
                                                name="policy_holder"
                                                disabled={true} 
                                                />
                                            <label htmlFor={"contact_check"}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'add_lead_slider_add'}>
                                <button type={'button'} className={'lead_add_more'} onClick={props.addSlide}>Add More<span><i className="apa-icon-add"/></span></button>
                            </div>
                        </div>
            </Slider>

        </div>
    );
}

function Step3(props) {

    if (props.currentStep !== 3) {
        return null
    }

    return (

        <div className="row">
            <div className="col-md-4 col-lg-6 col-xl-4 col-12 mobile_space  loss_info ">
                <div className={'add_lead_basic'}>
                    <h3>Loss Info</h3>

                    <div className="row">

                        <div className="col-4">
                            <p>Hot Lead</p>
                        </div>

                        <div className="col-8">
                            <input
                                className={props.errors.loss_reported ? ' error_input' : ''}
                                type="checkbox"
                                id="hot_lead_check"
                                name="hot_lead"
                                onChange={(e) => {props.handleCheckboxChange(e, 3)}}
                                checked={props.hot_lead_checked}
                            />
                            <label htmlFor="hot_lead_check"/>
                        </div>

                        <div className="col-4">
                            <p>Peril</p>
                        </div>
                        <div className="col-8">
                            <div className="form-group selectdiv ">

                                <select
                                    ref={props.peril_select_ref}
                                    defaultValue={loss_info[0].peril && loss_info[0].peril}
                                    className={"form-control alterationTypeSelect" + (props.errors.peril ? ' error_input' : '')}
                                    required={true} name="peril" onChange={(e) => {props.handleInputValues(e, 3)}}>
                                    <option value=''/>
                                    {props.peril_list && props.peril_list.map((item, key) => {

                                        return (
                                            <option key={key} value={item.id}>
                                                {item.name}
                                            </option>
                                        )
                                    })}

                                    <option className={'test_option'}>Add More</option>

                                </select>

                                {props.errors.peril ? <span style={{color: 'red'}}>{props.errors.peril}</span> : ''}

                            </div>
                        </div>

                        <div className="col-4">
                            <p>DOL</p>
                        </div>


                        <div className="col-8">
                            <DatePicker
                                        className={'input_date'}
                                        defaultValue={loss_info[0].DOL && loss_info[0].DOL}
                                        onChange={props.dateSave.bind(this,'DOL')}
                                        selected={props.date}
                                        name={'DOL'}/>

                            {props.errors.DOL ? <span style={{color: 'red'}}>{props.errors.DOL}</span> : ''}
                        </div>


                        <div className="col-4">
                            <p>Loss Reported</p>
                        </div>


                        <div className="col-8">
                            <input
                                className={props.errors.loss_reported ? ' error_input' : ''}
                                type="checkbox"
                                id="loss_reported"
                                name="loss_reported"
                                onChange={(e) => {props.handleCheckboxChange(e, 3)}}
                                defaultChecked={loss_info[0].loss_reported && Number(loss_info[0].loss_reported)}
                            />
                            <label htmlFor="loss_reported"/>
                        </div>

                        <div className="col-4">
                            <p>Date Loss Reported</p>
                        </div>

                        <div className="col-8">
                            <DatePicker
                                        className={'input_date'}
                                        onChange={props.dateSave.bind(this, 'date_loss_reported')}
                                        selected={props.date_loss_reported}
                                        name={'date_loss_reported'}
                                        defaultValue={loss_info[0].date_loss_reported && loss_info[0].date_loss_reported}
                            />
                        </div>

                        <div className="col-4">
                            <p>Denial</p>
                        </div>

                        <div className="col-8">
                            <input
                                type="checkbox"
                                id="denial"
                                name="denial"
                                onChange={(e) => {props.handleCheckboxChange(e, 3)}}
                                defaultChecked={loss_info[0].denial && Number(loss_info[0].denial)}
                            />
                            <label htmlFor="denial"/>
                        </div>


                        <div className="col-4">
                            <p>Denial Letter</p>
                        </div>

                        <div className="col-8">
                            <input
                                type="checkbox" id="denial_letter"
                                name="denial_letter"
                                onChange={(e) => {props.handleCheckboxChange(e, 3)}}
                                defaultChecked={loss_info[0].denial_letter && Number(loss_info[0].denial_letter)}
                            />
                            <label htmlFor="denial_letter"/>
                        </div>

                        <div className="col-4">
                            <p>Loss Reported Note</p>
                        </div>

                        <div className="col-8">
                            <input
                                ref={props.lossReportedRef}
                                readOnly={true}
                                className={props.errors.loss_reported_note ? ' error_input' : ''}
                                type="text"
                                name={'loss_reported_note'}
                                onFocus={(e)=>{
                                    if (!props.redirectedByLossReportedModal) {
                                        props.handleNoteValue(e, 'loss_reported_notes');
                                    } else props.setRedirectedByLossReportedModal(false)
                                }}
                                onClick={(e) => {
                                    if (props.redirectedByLossReportedModal) {
                                        props.handleNoteValue(e, 'loss_reported_notes');
                                    } else props.setRedirectedByLossReportedModal(false)
                                }}
                                defaultValue={loss_info[0].loss_reported_note && loss_info[0].loss_reported_note}
                            />
                            {props.errors.loss_reported_note ?
                                <span style={{color: 'red'}}>{props.errors.loss_reported_note}</span> : ''}
                        </div>

                        <div className="col-4">
                            <p>Previous Payment</p>
                        </div>


                        <div className="col-8">
                            <input
                                className={props.errors.previous_payment ? ' error_input' : ''}
                                type="text"
                                name={'previous_payment'}
                                onChange={(e) => {props.handleInputValues(e, 3)}}
                                defaultValue={loss_info[0].previous_payment && loss_info[0].previous_payment}

                            />
                            {props.errors.previous_payment ?
                                <span style={{color: 'red'}}>{props.errors.loss_reported_note}</span> : ''}
                        </div>

                        <div className="col-4">
                            <p>Discovery Description</p>
                        </div>

                        <div className="col-8">
                            <input
                                ref={props.discoveryRef}
                                readOnly={true}
                                className={props.errors.discovery ? ' error_input' : ''}
                                defaultValue={loss_info[0].discovery_description && loss_info[0].discovery_description}
                                type="text"
                                name={'discovery'} required={true}
                                onFocus={(e) => {
                                    if (!props.redirectedByDiscoveryModal) {
                                        props.handleDiscoverChange()
                                    } else props.setRedirectedByDiscoveryModal(false);
                                }}
                                onClick={(e) => {
                                    if (props.redirectedByDiscoveryModal) {
                                        props.handleDiscoverChange()
                                    } else props.setRedirectedByDiscoveryModal(false);
                                }}
                                onKeyPress={props.handleDiscoverChange}/>

                            {props.errors.discovery_description ? <span style={{color: 'red'}}>{props.errors.discovery_description}</span> : ''}
                        </div>

                        <div className="col-4">
                            <p>Photos</p>
                        </div>


                        <div className="col-8">
                            <input
                                type="checkbox" id="photo"
                                name="photos"
                                onChange={(e) => {props.handleCheckboxChange(e, 3)}}
                                defaultChecked={loss_info[0].photos && Number(loss_info[0].photos)}
                            />
                            <label htmlFor="photo"/>

                        </div>


                    </div>
                </div>
            </div>

            <div className="col-md-4  col-lg-6 col-xl-4 col-12 mobile_space  loss_info ">
                <div className={'add_lead_basic'}>

                    <h3>Extent of Damage</h3>

                    <div className="row">


                        <div className="col-md-8 col-6">
                            <p>How many rooms were affected?</p>
                        </div>

                        <div className="col-md-4 col-6">
                            <input
                                className={props.errors.affected_rooms ? ' error_input' : ''}
                                type={'number'}
                                name={'affected_rooms'}
                                required={true}
                                onChange={props.handleExtentDamageValues}
                                defaultValue={loss_info[1].affected_rooms && loss_info[1].affected_rooms}
                            />
                            {props.errors.affected_rooms ? <span style={{color: 'red'}}>{props.errors.affected_rooms}</span> : ''}
                        </div>

                        <div className="col-md-7 col-6">
                            <p>What kind of floors do you have in the rooms that were damaged?</p>
                        </div>
                        <div className="col-md-8 col-6">
                            <div className="form-group selectdiv">

                                <select
                                    defaultValue={loss_info[1].kind_of_floors && loss_info[1].kind_of_floors}
                                    className={"form-control alterationTypeSelect" + (props.errors.kind_of_floors ? ' error_input' : '')}
                                    required={true} name="kind_of_floors" onChange={props.handleExtentDamageValues}>
                                    <option value={''}/>
                                    {props.kind_floor && props.kind_floor.map((item, key) => {
                                        return <option value={item.id} key={key}>{item.type}</option>
                                    })}
                                </select>

                                {props.errors.kind_of_floors ? <span style={{color: 'red'}}>{props.errors.kind_of_floors}</span> : ''}
                            </div>

                        </div>

                        <div className="col-md-8 col-6">
                            <p>Does that flooring lead into other rooms?</p>
                        </div>


                        <div className="col-md-4 col-6">
                            <input defaultChecked={loss_info[1].floor_lead === '1' && true} type="radio" id="other_room" name="floor_lead" value={1}
                                   onChange={props.handleExtentDamageValues}/>
                            <label htmlFor="other_room">Yes</label>

                            <input defaultChecked={loss_info[1].floor_lead === '0' && true} type="radio" id="room" name="floor_lead" value={0}
                                   onChange={props.handleExtentDamageValues}/>
                            <label htmlFor="room">No</label>
                        </div>


                        <div className="col-md-8 col-6">
                            <p>Was the plumbing repaired?</p>
                        </div>


                        <div className="col-md-4 col-6">
                            <input defaultChecked={loss_info[1].repaired_plumbing === '1' && true} type="radio" id="age1" name="repaired_plumbing" value={1} onChange={props.handleExtentDamageValues}/>
                            <label htmlFor="age1">Yes</label>


                            <input defaultChecked={loss_info[1].repaired_plumbing === '0' && true}  type="radio" id="age2" name="repaired_plumbing" value={0} onChange={props.handleExtentDamageValues}/>
                            <label htmlFor="age2">No</label>
                        </div>

                        <div className="col-md-4 col-6">
                            <p>EMS Vendor</p>
                        </div>

                        <div className="col-md-8 col-6">
                            <input
                                className={props.errors.EMS_vendor ? ' error_input' : ''}
                                type="text"
                                name={'EMS_vendor'}
                                onChange={props.handleExtentDamageValues}
                                defaultValue={loss_info[1].EMS_vendor && loss_info[1].EMS_vendor}
                            />
                            {props.errors.EMS_vendor ? <span style={{color: 'red'}}>{props.errors.EMS_vendor}</span> : ''}
                        </div>


                        <div className="col-md-4 col-6">
                            <p>EMS Notes</p>
                        </div>

                        <div className="col-md-8 col-6">
                            <input
                                ref={props.emsNotesRef}
                                className={props.errors.EMS_notes ? ' error_input' : ''}
                                type="text"
                                readOnly={true}
                                name={'EMS_notes'}
                                onFocus={(e)=>{
                                    if (!props.redirectedByEMSNotesModal) {
                                        props.handleNoteValue(e, 'ems_notes');
                                    } else props.setRedirectedByEMSNotesModal(false)
                                }}
                                onClick={(e) => {
                                    if (!props.redirectedByEMSNotesModal) {
                                        props.handleNoteValue(e, 'ems_notes');
                                    } else props.setRedirectedByEMSNotesModal(false)
                                }}
                                defaultValue={loss_info[1].EMS_notes && loss_info[1].EMS_notes}
                            />
                            {props.errors.EMS_notes ? <span style={{color: 'red'}}>{props.errors.EMS_notes}</span> : ''}
                        </div>

                        <div className="col-md-4 col-6">
                            <p>Repairs Since DOL</p>
                        </div>

                        <div className="col-md-8 col-6">
                            <input
                                type={'text'}
                                readOnly
                                name={'repairs_since_DOL'}
                                onChange={props.handleExtentDamageValues}
                                onFocus={(e) => {
                                    if (!props.redirectedByDOLModal) props.handleNoteValue(e, "dol_notes")
                                    else props.setRedirectedByDOLModal(false)
                                }}
                                onClick={(e) => {
                                    if (!props.redirectedByDOLModal) props.handleNoteValue(e, "dol_notes")
                                    else props.setRedirectedByDOLModal(false)
                                }}
                                className={props.errors.repairs_since_DOL ? ' error_input' : ''}
                                value={props.dolVal}
                            />
                            {props.errors.repairs_since_DOL ? <span style={{color: 'red'}}>{props.errors.repairs_since_DOL}</span> : ''}
                        </div>

                        <div className="col-md-4 col-6">
                            <p>Mitigation Efforts</p>
                        </div>

                        <div className="col-md-8 col-6">
                            <input
                                readOnly
                                ref={props.mitigationEffRef}
                                className={props.errors.mitigation_efforts ? ' error_input' : ''}
                                type="text"
                                name={'mitigation_efforts'}
                                onChange={props.handleExtentDamageValues}
                                defaultValue={props.mitigationEffVal}
                                onFocus={(e) => {
                                    if (!props.redirectedByMitigationNotes) props.handleNoteValue(e, 'mitigation_note')
                                    else props.setRedirectedByMitigationNotes(false)
                                }}
                                onClick={(e) => {
                                    if (!props.redirectedByMitigationNotes) props.handleNoteValue(e, 'mitigation_note')
                                    else props.setRedirectedByMitigationNotes(false)
                                }}
                            />
                            {props.errors.mitigation_efforts ? <span style={{color: 'red'}}>{props.errors.mitigation_efforts}</span> : ''}

                        </div>


                        <div className="col-md-4 col-6">
                            <p>Carrier Vendor</p>
                        </div>


                        <div className="col-md-8 col-6">
                            <input
                                type="checkbox" id="carrier_vendor"
                                name="carrier_vendor"
                                onChange={(e) => {props.handleCheckboxChange(e, 3)}}
                                defaultChecked={loss_info[1].carrier_vendor && Number(loss_info[1].carrier_vendor)}
                            />
                            <label htmlFor="carrier_vendor"/>
                        </div>

                    </div>
                </div>
            </div>
            <div className="col-md-4  col-lg-6 col-xl-4  col-12 mobile_space  loss_info ">
                <div className={'add_lead_basic'}>
                    <h3>Carrier Info</h3>

                    <div className="row">

                        <div className="col-md-4 col-6">
                            <p>Carrier</p>
                        </div>

                        <div className="col-md-8 col-6">
                            <div className="form-group selectdiv">
                                <select
                                    ref={props.select_ref}
                                    defaultValue={loss_info[2].carrier_id && loss_info[2].carrier_id}
                                    className={"form-control alterationTypeSelect" + (props.errors.carrier_id ? ' error_input' : '')}
                                    required={true} name="carrier_id" onChange={props.handleCarrierInfoValues}>
                                    <option value={''}/>
                                    {props.carrier && props.carrier.map((item, id) => {
                                        return <option key={id} value={item.id}>{item.name}</option>
                                    })}
                                    <option className={'test_option'}>Add More</option>
                                </select>

                                {props.errors.carrier_id ? <span style={{color: 'red'}}>{props.errors.carrier_id}</span> : ''}
                            </div>
                        </div>

                        <div className="col-md-4 col-6">
                            <p>Claim Number</p>
                        </div>
                        <div className="col-md-8 col-6">
                            <input className={props.errors.carrier_number ? ' error_input' : ''}
                                   defaultValue={loss_info[2].carrier_number && loss_info[2].carrier_number}
                                   type={'text'}
                                   name={'carrier_number'}
                                   required={true}
                                   onChange={props.handleCarrierInfoValues}/>
                            {props.errors.carrier_number ? <span style={{color: 'red'}}>{props.errors.carrier_number}</span> : ''}

                        </div>

                        <div className="col-md-4 col-6">
                            <p>Carrier Notes</p>
                        </div>

                        <div className="col-md-8 col-6">
                            <input
                                ref={props.carrierNoteRef}
                                readOnly={true}
                                defaultValue={loss_info[2].carrier_notes && loss_info[2].carrier_notes}
                                className={props.errors.carrier_notes ? ' error_input' : ''}
                                type={'text'}
                                name={'carrier_notes'}
                                onFocus={(e) => {
                                    if (!props.redirectedByCarrierNoteModal) {
                                        props.handleNoteValue(e, 'carrier_notes')
                                    } else { 
                                        props.setRedirectedByCarrierNoteModal(false) 
                                    }}}
                                />
                            {props.errors.carrier_notes ? <span style={{color: 'red'}}>{props.errors.carrier_notes}</span> : ''}
                        </div>

                    </div>
                </div>
            </div>
        </div>

    );
}

function Step4(props) {
    if (props.currentStep !== 4) {
        return null
    }
    const {current_basic,current_building} = props

    return (


        <React.Fragment>

            <Alert show={props.success_alert} variant="success">
                <Alert.Heading>Lead Add Success</Alert.Heading>
            </Alert>

            <div className="row">
                <div className={"col-md-4 col-12 mobile_space  loss_address "}>
                    <div className={'add_lead_basic'}>
                        <h3>Loss Address</h3>

                        <div className={"row" + (props.isExistingPropertyChecked ? ' custom_row' : '')}>
                            <div className="col-4" style={{display: props.exist_contact_check == 1  && props.loss_address_property.length > 0 ? 'block' : 'none'}}>
                                <p>Existing Property</p>
                            </div>
                            <div className="col-8" style={{display: props.exist_contact_check == 1  && props.loss_address_property.length > 0 ? 'block' : 'none'}}>
                                <input type="checkbox"
                                       id="existing_property"
                                       name="existing_property"
                                       checked={props.isExistingPropertyChecked}
                                       onChange={props.handleLossAddressValue}/>
                                <label htmlFor="existing_property"/>
                            </div>

                            <div className="col-4" style={{display: props.isExistingPropertyChecked && props.loss_address_property.length > 0  ? 'block' : 'none'}}>
                                <p>Property</p>
                            </div>

                            <div className="col-8" style={{display: props.isExistingPropertyChecked  && props.loss_address_property.length > 0 ? 'block' : 'none'}}>
                                <div className="form-group selectdiv">
                                    <select
                                        value={loss_address[0].property_type && Number(loss_address[0].property_type)}
                                        onChange={(e) => {
                                            props.getBuildingInfoData(e)
                                            // props.setState({ forceUpdate: false });
                                        }}
                                        className={"form-control alterationTypeSelect" + (props.errors.property_type ? ' error_input' : '')}
                                        name="property_type">

                                        <option value={''}/>

                                        {props.loss_address_property && props.loss_address_property.map((item,key) => {
                                            return(<option key={key} value={item.id}>{item.property}</option>)})}
                                    </select>

                                    {props.errors.property_type ? <span style={{color: 'red'}}>{props.errors.property_type}</span> : ''}

                                </div>

                            </div>


                            <div className="col-4">
                                <p>Street Number</p>
                            </div>


                            <div className="col-8">
                                <input className={props.errors.street_number ? ' error_input' : ''}
                                       type={'text'}
                                       defaultValue={null}
                                       value={loss_address[0].street_number && loss_address[0].street_number}
                                       required={true}
                                       name={'street_number'}
                                       disabled={props.isExistingPropertyChecked && true}
                                       onChange={props.handleLossAddressValue}/>
                                {props.errors.street_number ? <span style={{color: 'red'}}>{props.errors.street_number}</span> : ''}
                            </div>


                            <div className="col-4">
                                <p>Street Name</p>
                            </div>

                            <div className="col-8">
                                <input className={props.errors.street_name ? ' error_input' : ''} type="text"
                                       required={true}
                                       name="street_name"
                                       value={loss_address[0] && loss_address[0].street_name}
                                       disabled={props.isExistingPropertyChecked && true}
                                       onChange={props.handleLossAddressValue}/>
                                {props.errors.street_name ? <span style={{color: 'red'}}>{props.errors.street_name}</span> : ''}


                            </div>


                            <div className="col-4">
                                <p>Unit Number</p>
                            </div>

                            <div className={"col-8"}>

                                <input
                                    className={props.errors.unit_number ? ' error_input' : ''}
                                    type={'text'}
                                    value={loss_address[0].unit_number && loss_address[0].unit_number}
                                    required={true} name={'unit_number'}
                                    disabled={props.isExistingPropertyChecked && true}
                                    onChange={props.handleLossAddressValue}/>
                                {props.errors.unit_number ? <span style={{color: 'red'}}>{props.errors.unit_number}</span> : ''}
                            </div>


                            <div className={"col-4" + (props.city ? ' disabled_input' : '')}>
                                <p>City</p>
                            </div>

                            <div className="col-8">

                                <div className={'form-group selectdiv' + (props.city ? ' disabled_input' : '')}>

                                    <select
                                        className={"form-control alterationTypeSelect" + (props.errors && props.errors.city ? ' error_input' : '')}
                                        required={true}
                                        name="city"
                                        disabled={props.isExistingPropertyChecked}
                                        onChange={props.handleLossAddressValue}
                                        defaultValue={loss_address[0].city && Number(loss_address[0].city)}>
                                        {loss_address[0] && loss_address[0].city ?  
                                        <option value={loss_address[0].city}>{loss_address[0].city}</option> : <option defaultValue={'0'}> </option> }

                                        {props.cities_date && props.cities_date.map((item, key) => {

                                            return <option  selected = {props.state_date.length === 1} value={item.city} key={key}>{item.city}</option>
                                        })}

                                    </select>

                                    {props.errors.city ? <span style={{color: 'red'}}>{props.errors.city}</span> : ''}
                                </div>
                            </div>

                            <div className={"col-4" + (props.isExistingPropertyChecked ? ' disabled_input' : '')}>
                                <p>State</p>
                            </div>

                            <div className="col-8">
                                <div className={"form-group selectdiv" + (props.isExistingPropertyChecked ? ' disabled_input' : '')}>
                                      
                                    <select
                                        className={"form-control alterationTypeSelect" + (props.errors && props.errors.state ? ' error_input' : '')}
                                        required={true} name="state" disabled={props.isExistingPropertyChecked}
                                    
                                        onChange={props.handleLossAddressValue}>
                                        {loss_address[0] && loss_address[0].state ?  
                                        <option value={loss_address[0].state}>{loss_address[0].state}</option> :  <option defaultValue={'0'}> </option> }

                                        {props.state_date && props.state_date.map((item, key) => {

                                            return <option selected = {props.state_date.length === 1} value={item.state} key={key}>{item.state}</option>
                                        })}

                                    </select>
                                    {props.errors.state ? <span style={{color: 'red'}}>{props.errors.state}</span> : ''}
                                </div>
                            </div>

                            <div className="col-4">
                                <p>Zip</p>
                            </div>
                            <div className="col-8">
                                <input className={props.errors && props.errors.zip ? ' error_input' : ''}
                                       type="number"
                                       required={true}
                                       value={loss_address[0].zip && Number(loss_address[0].zip)}
                                       name={'zip'} disabled={props.isExistingPropertyChecked && true}
                                       onChange={props.handleLossAddressValue}/>
                                {props.errors.zip ? <span style={{color: 'red'}}>{props.errors.zip}</span> : ''}
                            </div>

                            <div className="col-4">
                                <p>Country</p>
                            </div>


                            <div className="col-8">
                                {/*<div className={"form-group selectdiv" + (props.isChecked ? ' disabled_input' : '')}>*/}

                                {/*    <select*/}
                                {/*        defaultValue={loss_address[0].country && Number(loss_address[0].country)}*/}
                                {/*        className={"form-control alterationTypeSelect" + (props.errors && props.errors.country ? ' error_input' : '')}*/}
                                {/*        name="country" disabled={props.isChecked && true}*/}
                                {/*        onChange={props.handleLossAddressValue}>*/}

                                {/*        {current_basic && current_basic.country ?  <option value={current_basic.country}>{current_basic.country_name}</option> :  <option defaultValue={'0'}> </option> }*/}



                                {/*            return <option value = {props.countrys_date.country}>{props.countrys_date.country}</option>*/}


                                {/*    </select>*/}

                                {/*</div>*/}
                                    <input type={'text'} name={'country'}  value={props.countrys_date.country} className={(props.errors && props.errors.country ? ' error_input' : '')}/>
                                    {props.errors.country ? <span style={{color: 'red'}}>{props.errors.country}</span> : ''}

                            </div>

                        </div>
                    </div>
                </div>
                <div className={"col-md-4 col-12 mobile_space  loss_address" + (props.isExistingPropertyChecked ? ' add_disabled' : '')}>
                    <div className={'add_lead_basic'}>
                        <h3>Building Info</h3>

                        <div className="row">

                            <div className="col-4">
                                <p>Building Type</p>
                            </div>


                            <div className="col-8">
                                <div className="form-group selectdiv">
                                    <select
                                        defaultValue={loss_address[1].building_id && loss_address[1].building_id}
                                        disabled={props.isExistingPropertyChecked && true}
                                        className={"form-control alterationTypeSelect" + (props.errors.building_id ? ' error_input' : '')}
                                        required={true} name="building_id" onChange={props.handleBuildingInfoValues}>
                                        {loss_address[1] && loss_address[1].building_type ?  <option value={loss_address[1] && loss_address[1].building_type && loss_address[1].building_type.id}>{loss_address[1] && loss_address[1].building_type && loss_address[1].building_type.type}</option> :  <option defaultValue={'0'}> </option> }

                                        {props.building_types && props.building_types.map((item, key) => {return <option key={key} value={item.id}>{item.type}</option>})}

                                    </select>
                                    {props.errors.building_id ? <span style={{color: 'red'}}>{props.errors.building_id}</span> : ''}
                                </div>
                            </div>

                            <div className="col-4">
                                <p>Size of Building</p>
                            </div>
                            <div className="col-8">
                                <input className={props.errors.size_of_building ? ' error_input' : ''}

                                       type={'number'}
                                       value={loss_address[1].size_of_building && loss_address[1].size_of_building}
                                       disabled={props.isExistingPropertyChecked && true}
                                       required={true} name={'size_of_building'}
                                       onChange={props.handleBuildingInfoValues}/>
                                 {props.errors.size_of_building ? <span style={{color: 'red'}}>{props.errors.size_of_building}</span> : ''}
                            </div>


                            <div className="col-4">
                                <p>Year Built</p>
                            </div>

                            <div className="col-8">
                                <input className={props.errors.year_build ? ' error_input' : ''}
                                       type={'number'}
                                       value={loss_address[1].year_build && loss_address[1].year_build}
                                       disabled={props.isExistingPropertyChecked && true}
                                       name={'year_build'}
                                       onChange={props.handleBuildingInfoValues}/>{props.errors.year_build ? <span style={{color: 'red'}}>{props.errors.year_build}</span> : ''}
                            </div>


                            <div className="col-4">
                                <p>Beds</p>
                            </div>

                            <div className="col-8">
                                <input className={props.errors.beds ? ' error_input' : ''}
                                       type="number"
                                       value={loss_address[1].beds && loss_address[1].beds}
                                       name="beds"
                                       disabled={props.isExistingPropertyChecked && true}
                                       onChange={props.handleBuildingInfoValues}/>
                                {props.errors.beds ? <span style={{color: 'red'}}>{props.errors.beds}</span> : ''}

                            </div>

                            <div className="col-4">
                                <p>Baths</p>
                            </div>

                            <div className="col-8">
                                <input className={props.errors.baths ? ' error_input' : ''}
                                       type={'number'}
                                       value={loss_address[1].baths && loss_address[1].baths}
                                       disabled={props.isExistingPropertyChecked && true}
                                       name={'baths'}
                                       onChange={props.handleBuildingInfoValues}/>
                                {props.errors.baths ? <span style={{color: 'red'}}>{props.errors.baths}</span> : ''}
                            </div>


                            <div className="col-4">
                                <p>Prior Claims</p>
                            </div>

                            <div className="col-8">
                                <input type="checkbox" id="prior_claims"
                                       value={loss_address[1].prior_claims && Number(loss_address[1].prior_claims)}
                                       name="prior_claims"
                                       disabled={props.isExistingPropertyChecked && true}
                                       onChange={props.handleCheckboxChange}/>
                                <label htmlFor="prior_claims"/>

                            </div>

                            <div className="col-4">
                                <p>Est. Property Value</p>
                            </div>

                            <div className="col-8">
                                <input className={props.errors.est_property_value ? ' error_input' : ''}
                                       value={loss_address[1].est_property_value && loss_address[1].est_property_value}
                                       type={'number'}
                                       disabled={props.isExistingPropertyChecked && true}
                                       required={true} name={'est_property_value'}
                                       onChange={props.handleBuildingInfoValues}/>
                                {props.errors.est_property_value ? <span style={{color: 'red'}}>{props.errors.est_property_value}</span> : ''}
                            </div>

                            <div className="col-4">
                                <p>Building Notes</p>
                            </div>

                            <div className="col-8">
                                <input
                                        readOnly={true}
                                        ref={props.buildingNotesRef}
                                        className={props.errors.building_note ? ' error_input' : ''}
                                        type="text"
                                        value={loss_address[1].building_notes && loss_address[1].building_notes}
                                        disabled={props.isExistingPropertyChecked && true}
                                        name={'building_note'}
                                        onFocus={(e)=>{
                                           if (!props.redirectedByBuildingNotesModal) {
                                            props.handleNoteValue(e, 'building_notes');
                                           } else props.setRedirectedByBuildingNotesModal(false)
                                        }}
                                        onClick={(e) => {
                                            if (!props.redirectedByBuildingNotesModal) {
                                                props.handleNoteValue(e, 'building_notes');
                                               } else props.setRedirectedByBuildingNotesModal(false)
                                        }}
                                       />
                                {props.errors.building_note ? <span style={{color: 'red'}}>{props.errors.building_note}</span> : ''}
                            </div>

                            <div className="col-4">
                                <p>Age of Home</p>
                            </div>

                            <div className="col-8">
                                <input className={props.errors.age_of_home ? ' error_input' : ''}
                                       type="number"
                                       value={loss_address[1].age_of_home && loss_address[1].age_of_home}
                                       disabled={props.isExistingPropertyChecked && true}
                                       name={'age_of_home'} onChange={props.handleBuildingInfoValues}/>
                                {props.errors.age_of_home ? <span style={{color: 'red'}}>{props.errors.age_of_home}</span> : ''}
                            </div>


                            <div className="col-4">
                                <p>Years Occupied</p>
                            </div>


                            <div className="col-8">
                               
                                <input className={props.errors.years_occupied ? ' error_input' : ''}
                                       type="number"
                                       value={loss_address[1].years_occupied && loss_address[1].years_occupied}
                                       disabled={props.isExistingPropertyChecked && true}
                                       name={'years_occupied'}
                                       onChange={props.handleBuildingInfoValues}/>
                                {props.errors.years_occupied ? <span style={{color: 'red'}}>{props.errors.years_occupied}</span> : ''}
                            </div>
                        </div>

                    </div>
                </div>
                <div
                    className={"col-md-4 col-12 mobile_space  loss_address" + (props.isExistingPropertyChecked ? ' add_disabled' : '')}>
                    <div className={'add_lead_basic'}>
                        <h3>Mortgage Company Information</h3>

                        <div className="row">


                            <div className="col-4">
                                <p>Company Name</p>
                            </div>

                            <div className="col-8">
                                <input className={props.errors.company_name ? ' error_input' : ''}
                                       type={'text'}
                                       defaultValue={loss_address[2].company_name && loss_address[2].company_name}
                                       disabled={props.isExistingPropertyChecked && true}
                                       required={true} name={'company_name'}
                                       onChange={props.handleMortgageCompanyInformationValue}/>
                                {props.errors.company_name ? <span style={{color: 'red'}}>{props.errors.company_name}</span> : ''}
                            </div>


                            <div className="col-4">
                                <p>Address</p>
                            </div>
                            <div className="col-8">
                                <input className={props.errors.address ? ' error_input' : ''}
                                       type={'text'}
                                       defaultValue={loss_address[2].address && loss_address[2].address}
                                       disabled={props.isChecked && true}
                                       required={true} name={'address'}
                                       onChange={props.handleMortgageCompanyInformationValue}/>
                                {props.errors.address ? <span style={{color: 'red'}}>{props.errors.address}</span> : ''}
                            </div>


                            <div className="col-4">
                                <p>Phone Number</p>
                            </div>

                            <div className="col-8">
                            <NumberFormat 
                                    format="(###) ###-####"
                                    className={props.errors.phone_number ? ' error_input' : ''}
                                       type={'text'}
                                       defaultValue={loss_address[2].phone_number && loss_address[2].phone_number}
                                       disabled={props.isChecked && true}
                                       required={true}
                                       name={'phone_number'}
                                       onChange={props.handleMortgageCompanyInformationValue}/>
                                {props.errors.phone_number ? <span style={{color: 'red'}}>{props.errors.phone_number}</span> : ''}
                            </div>

                            <div className="col-4">
                                <p>Email</p>
                            </div>

                            <div className="col-8">
                                <input className={props.errors.email ? ' error_input' : ''}
                                       type={'email'}
                                       defaultValue={loss_address[2].email && loss_address[2].email}
                                       disabled={props.isChecked && true}
                                       required={true}
                                       name={'email'}
                                       onChange={props.handleMortgageCompanyInformationValue}/>
                                {props.errors.email ? <span style={{color: 'red'}}>{props.errors.email}</span> : ''}
                                {props.email_validate && <span style={{color: 'red'}}>{props.email_validate}</span>}

                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}



function mapStateToProps(state) {
return{
    show:state.leadsReducer.carrier_modal
}
}

export default connect(mapStateToProps,null)(AddSteps)

