import React from "react";
import SideBar from "./header/SideBar";
import Navbar from "./header/Navbar";
import UsersTable from "./UsersTable";
import CreateUser from "./settings/CreateUser";
import { Modal} from "react-bootstrap";
import {connect} from 'react-redux'
import {checkAuth} from "../redux/actions";



class Users extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            show:false,
            modalTitle:'Add New User',
            saveButtonTitle:'Complete',
            userData:false
        }
    }


    componentDidMount() {
       this.props.dispatch(checkAuth());
    }



    handleAddUser = () => {
        this.setState({
            show:true,
            userData:false
        })
    }


    handleClose = () => {
        this.setState({
            show:false,
            userData:true
        })
    }

    render() {
        const {toggled} = this.props;
        return(
            <>

                <div className={"d-flex" + (toggled ? ' toggled' : '')} id="wrapper">
                    <SideBar isAuth = {this.props.isAuth}/>
                    <div id="page-content-wrapper">
                        <div>
                            <Navbar />
                            <div className={'col-12'}>

                                <div className="row">
                                    <div className="col-lg-6 col-12">
                                        <div className={'page_title users_title'}>
                                            <h2>Users</h2>
                                        </div>

                                    </div>

                                    <div className="col-lg-6 col-12 leads_content">

                                        <div className={'leads_search'}>

                                            <button className={'default_hover'} onClick={this.handleAddUser}> <i className={'apa-icon-user '}></i>Add New User</button>
                                        </div>

                                    </div>
                                </div>

                                {/*------Create User  Modal------*/}

                                     <CreateUser saveButtonTitle = {this.state.saveButtonTitle} show={this.state.show} handleClose={this.handleClose} data={this.state.userData} />
                            </div>
                            <UsersTable/>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}


function mapStateToProps(state) {

    return{
        toggled:state.appReducer.toggled
    }

}


export default connect(mapStateToProps,null)(Users);