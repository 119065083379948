import React from "react";
import axios from "axios";
import {url} from "../../config";
import NumberFormat from 'react-number-format';
import {connect} from "react-redux";
import {getCurrentLead} from "../../redux/actions";

const contact_info = {};

class ContactInfo extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            inputEdit:false,
            contact_method:'DEFAULT'
        }

    }

    handleEdit = () => {
        this.setState({
            inputEdit:!this.state.inputEdit,
        })

        contact_info.contact_id = this.props.lead_body[0]?.client.contacts[0].id

    }


    handleChangeData = (event) => {

        const {name,value} = event.target;

        if(name === 'zono_account'){
            if( this.props.lead_body[0].client.contacts[0].zono_account === "0"){
               return  contact_info.zono_account = "1"
            }else {
              return   contact_info.zono_account = "0"
            }
        }

        contact_info[name] = value;

    }

    closeEdit = () =>{
        this.setState({
            inputEdit: !this.state.inputEdit,
        });
    }



    handleSaveData = () => {

        const data = {};
        data.contact_info = contact_info

        axios.post(url + 'update-lead-basic', data)

            .then(res => {
                if(!this.props.type)
                {
                    this.props.myCallback(true)
                }

                this.setState({
                    inputEdit:!this.state.inputEdit,
                })
            })
            .catch(err => {
                console.log(err)
            })
    }


    render() {
            const {lead_body} = this.props;


        if(!lead_body){
            return null
        }

        return(

            <div className={'add_lead_basic lead_details_basic_info'}>

                <div className={'leads_details_edit '}>
                    <h3>Contact Info</h3>
                    {lead_body.length !==0 && (this.state.inputEdit ?
                        <>
                            <span onClick={this.handleSaveData}>Save</span>
                            <div className={'close_b'} onClick={this.closeEdit}><i className="fas fa-times"/></div>
                        </>

                        : <i onClick={this.handleEdit} className={'apa-pencil'}/>)  }
                </div>

                {lead_body.map((item,key) => {

                    if (item) {


                        const contact_method =  item.client.contacts[0].contact_method === '1' ? 'Cell Number' : item.client.contacts[0].contact_method === '2' ? 'Work Number'
                            : item.client?.contacts[0].contact_method === '3' ? 'Home Number'
                                : item.client?.contacts[0].contact_method === '4' ? 'Email' : ''

                    return(
                <div className="row" key={key}>


                    <div className="col-lg-4 col-4">
                        <p>Client ID</p>
                    </div>

                    <div className="col-lg-8 col-8">
                       <input name={'contact_number'} readOnly={true} disabled={true} defaultValue={item.client.contacts[0].contact_number} />
                    </div>


                    <div className="col-lg-4 col-4">
                        <p>First Name</p>
                    </div>

                    <div className="col-lg-8 col-8">
                        <input name={'first_name'} onChange={this.handleChangeData} defaultValue={item.client.contacts[0].first_name} readOnly={!this.state.inputEdit} className={this.state.inputEdit ? ' edit_inputs' : ''}/>
                    </div>


                    <div className="col-lg-4 col-4">
                        <p>Last Name</p>
                    </div>


                    <div className="col-lg-8 col-8">
                        <input name={'last_name'} onChange={this.handleChangeData} defaultValue={item.client.contacts[0].last_name}  readOnly={!this.state.inputEdit} className={this.state.inputEdit ? ' edit_inputs' : ''} />
                    </div>

                    <div className="col-lg-4 col-4">
                        <p>Cell Number</p>
                    </div>


                    <div className="col-lg-8 col-8">
                    <NumberFormat
                                    format="(###) ###-####" name={'cell_number'} type={'text'} onChange={this.handleChangeData} defaultValue={item.client.contacts[0].cell_number} readOnly={!this.state.inputEdit} className={this.state.inputEdit ? ' edit_inputs' : ''} />
                    </div>


                    <div className="col-lg-4 col-4">
                        <p>Work Number</p>
                    </div>


                    <div className="col-lg-8 col-8">
                    <NumberFormat
                                    format="(###) ###-####" name={'work_number'}  onChange={this.handleChangeData} type={'text'} defaultValue={item.client.contacts[0].work_number} readOnly={!this.state.inputEdit} className={this.state.inputEdit ? 'edit_inputs' : ''}/>
                    </div>


                    <div className="col-lg-4 col-4">
                        <p>Home Number</p>
                    </div>


                    <div className="col-lg-8 col-8">
                    <NumberFormat
                                    format="(###) ###-####" name={'home_number'}  onChange={this.handleChangeData} type = 'text' defaultValue={item.client.contacts[0].home_number} readOnly={!this.state.inputEdit} className={this.state.inputEdit ? 'edit_inputs' : ''}/>
                    </div>


                    <div className="col-lg-4 col-4">
                        <p>Email</p>
                    </div>


                    <div className="col-lg-8 col-8">
                       <input name={'email'} onChange={this.handleChangeData} defaultValue={item.client.contacts[0].email} type = 'text' readOnly={!this.state.inputEdit} className={this.state.inputEdit ? ' edit_inputs' : ''}/>
                    </div>

                    <div className="col-lg-4 col-4">
                        <p>Contact Method</p>
                    </div>


                    <div className="col-lg-8 col-8">
                       <input style={this.state.inputEdit ? {display:"none"} : {display:'block'}} defaultValue={contact_method} className={this.state.inputEdit ? ' edit_inputs' : ''} type={'text'} name={'contact_method'} readOnly={!this.state.inputEdit} />
                        <div className="form-group selectdiv leads_details_selects"  style={!this.state.inputEdit ? {display:"none"} : {display:'block'}} >
                            <select  name={'contact_method'} defaultValue={item.client?.contacts[0]?.contact_method} className={"form-control alterationTypeSelect"} onChange={this.handleChangeData} >
                                    <option value={'DEFAULT'}></option>
                                    <option selected value="1">Cell Number</option>
                                    <option value="2">Work Number</option>
                                    <option value="3">Home Number</option>
                                    <option value="4">Email</option>

                            </select>

                        </div>
                    </div>

                    <div className="col-lg-4 col-4">
                        <p>Zono Account</p>
                    </div>


                    <div className="col-lg-8 col-8">
                        <input  onChange={this.handleChangeData} defaultChecked={Number(item.client.contacts[0].zono_account)} disabled={!this.state.inputEdit} className={this.state.inputEdit ? ' edit_inputs' : ''} type="checkbox" id="zono_account" name="zono_account" />
                        <label htmlFor="zono_account"></label>
                    </div>

                </div>
                    )
                    }})}

            </div>
        )
    }
}
//
// function mapStateToProps(state) {
//
//     return{
//         lead_body:state.leadsReducer.getLead,
//     }
//
// }


export default connect(null,null)(ContactInfo);