import React from "react";
import {Button, Modal} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { ReactMic } from 'react-mic';
import Timer from "../Timer";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import {connect} from 'react-redux'
import axios from "axios";
import {url} from "../../config";

let formData = new FormData();
const lead_id = localStorage.getItem('leadId');
class LogEntryModal extends React.Component{
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            record: false,
            recordedBlob:null,
            record_modal_date:null,
            send_data:null,
            device_error:null,
            lead_body:[],
            log_first_contact_date:null,
            message:''
        }
    }

    handleHideModal = () => {
        this.props.modalCallBack(false)
        this.setState({
            show:false
        })
    }
    componentDidMount() {
        //this.getCurrentLead();
   
    }



    startRecording = (recordedBlob) => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                this.setState({
                    record: true,
                    device_error:null
                });

            }).catch(err => {
               this.setState({
                   device_error:err.toString()
               })
        })

    }

    stopRecording = () => {
        this.setState({ record: false });
    }

    onData = (recordedBlob) =>  {

        // console.log('chunk of real-time data is: ', recordedBlob);

    }



    onStop = (recordedBlob) =>  {

        this.setState({
            recordedBlob:recordedBlob.blobURL,
            send_data:recordedBlob
       })
    }



    handleClose = () => {
        this.props.modalCallBack(true,'fffdf')
        this.setState({
            show:false
        })
    }


    openRecordModal = () => {
        this.props.modalCallBack(false)
        this.setState({
            show:true
        })
    }


    handleRemoveRecord = () => {
        this.setState({
            recordedBlob:''
        })
    }


    saveDate = (date) => {

        if(this.myRef.current){

            if(this.myRef.current.props.name === 'first_contact'){
                return this.setState({
                    log_first_contact_date:date
                })
            }else {
                return   this.setState({
                    record_modal_date:date

                })
            }
        }
        else {
            return   this.setState({
                record_modal_date:date
            })
        }


    }




    sendData = (event) => {
        event.preventDefault();

        const {message} = this.state;


        let data = new FormData();

        //data.append('log_file',this.state.send_data ? this.state.send_data.blob : null)
        data.append('lead_id',localStorage.getItem('leadId'));
        data.append('details',message);
        if(this.state.send_data) {
            var audioFile = new File([this.state.send_data.blob], "voice_memo.wav",{
                type: "audio/wav",
              });
            data.append("file", audioFile);
        }

        axios.post(url + 'add-lead-log', data,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(res => {

                this.props.myCallback(true)
                this.setState({
                    message:'',
                    send_data:'',
                    recordedBlob:''
                })
                return this.handleHideModal()
            })
            .catch(err => {
                console.log(err)
            })
    }


    messageValue = (event) => {
        this.setState({
            message:event.target.value
        })
    }


    handleAddAudio = () => {

    }


    render() {

        const {show} = this.props;
        const {record,recordedBlob,device_error,log_first_contact_date,record_modal_date} = this.state;

        return(
            <>

                <Modal show={show} onHide = {this.handleHideModal} centered className="discover_modal_content log_entry_modal" dialogClassName='discover_modal'>
                    <form onSubmit={this.sendData}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Log Entry</Modal.Title>
                        <span onClick={this.openRecordModal} className={'microphone_icon'}><i className={'apa-icon-microphone'}></i>Record</span>
                    </Modal.Header>
                    
                    <Modal.Body>

                        <div className="row">

                            <div className="col-lg-4 col-5"><p>Select Lead</p></div>
                            <div className="col-lg-8 col-7 select_lead">
                                <div className="form-group selectdiv select_content">

                                    <select className="form-control alterationTypeSelect default_select" name="contact"  >
                                    <option value={''}>{this.props?.lead_body && this.props?.lead_body[0]?.client.contacts[0].first_name} {this.props?.lead_body && this.props?.lead_body[0]?.client.contacts[0].last_name}</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-lg-4 col-12"><p>Log</p></div>
                            <div className="col-lg-8 col-12 textarea_border">
                                <textarea name={'log'} className={'steps_text_area'} onChange={this.messageValue} required={true}/>
                            </div>


                        </div>



                    </Modal.Body>
                    <Modal.Footer>

                        <Button  type = {'submit'}>Save</Button>

                    </Modal.Footer>
                    </form>
                </Modal>




                {/*Record Modal*/}

                <Modal show={this.state.show} onHide = {this.handleHideModal}  centered className="discover_modal_content log_entry_modal" dialogClassName='discover_modal'>
                <form onSubmit={this.sendData}>
                    
                    <Modal.Header closeButton>
                        <Modal.Title>Add Log Entry</Modal.Title>
                        <span onClick={this.handleClose} className={'microphone_icon'}><i className={'apa-back'}></i></span>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="row">

                            <div className="col-4"><p>Select Lead</p></div>
                            <div className="col-8 select_lead">
                                <div className="form-group selectdiv select_content">

                                    <select className="form-control alterationTypeSelect default_select" name="contact"  >
                                    <option value={''}>{this.props?.lead_body && this.props?.lead_body[0]?.client.contacts[0].first_name} {this.props?.lead_body && this.props?.lead_body[0]?.client.contacts[0].last_name}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className={'audio_content'}>
                            <ReactMic
                                record={this.state.record}
                                className="record"
                                mimeType="audio/wav"
                                onStop={this.onStop}
                                onData={this.onData}

                                />
                            {record ?
                                <button onClick={this.stopRecording} type="button">
                                    <i className="fas fa-microphone-slash"></i></button>
                                :
                                <button onClick={this.startRecording} type="button">
                                    <i className={'apa-icon-microphone'}></i></button>  }

                            <div className={'record_time'}>
                            </div>
                            {record ? <Timer/> : <span>00:00</span>}
                        </div>


                        { device_error && <span className={'create_error'}>{device_error}</span>}

                        {recordedBlob &&
                        <>
                        <AudioPlayer
                            className={'audio_player'}
                            defaultDuration="Loading"
                            src={recordedBlob}
                            volume={false}
                            layout="horizontal-reverse"
                            showJumpControls={false}
                            onPlay={e => console.log("onPlay")}
                            customAdditionalControls={[]}
                            customIcons={{
                                play: <i className={'apa-icon-play'}></i>
                            }}
                            />


                        <button className={'link_button remove_record'} onClick={this.handleRemoveRecord}>Remove</button>

                            </>
                            }
                    </Modal.Body>
                    <Modal.Footer>

                        <Button type={'submit'} variant="primary">Save</Button>
                    </Modal.Footer>
                    </form>

                </Modal>

            </>
        )
    }
}


function mapDispatchToProps(dispatch){
    return{
        recordAudio: (audioBlob) => dispatch({type:'audioRecord',payload:audioBlob})
    }
}




export default connect(null,mapDispatchToProps)(LogEntryModal);
