import {combineReducers} from 'redux'
import appReducer from '../redux/reducers/appReducer'
import userInfoReducer from "./reducers/userInfoReducer";
import leadsReducer from './reducers/leadsReducer';
import propertyReducer from './reducers/propertyOptionsReducer';

export default combineReducers({
    appReducer,
    userInfoReducer,
    leadsReducer,
    propertyReducer

})