import _ from "lodash";
import React from "react";
import axios from "axios";
import { url } from "../../config";
import DatePicker from "react-datepicker";
import NoteModal from "./NoteModal";
import { connect } from "react-redux";
import Moment from 'react-moment';
import moment from 'moment'
const basic_info = {};

class
BasicInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reps: [],
      inputEdit: false,
      oldData: {},
      dateValue: "",
      updated: false,
      status_progress: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    };
  }

  getLeadsData = async () => {
    try {
      const { data } = await axios.get(url + "create-lead", {});
      this.setState({
        oldData: data,
      });
    } catch (err) {
      if (err.response && err.response.status === 401) {
        window.location.href = "/login";
      }
    }
    // axios
    //   .get(url + "create-lead", {})
    //   .then((res) => {
    //     if (res) {
    //       this.setState({
    //         oldData: res.data,
    //       });
    //     }
    //   })
    //   .catch((err) => {
    //     if (err.response && err.response.status === 401) {
    //       window.location.href = "/login";
    //     }
    //   });
  };

  componentDidMount() {
    this.getLeadsData();
  }

  handleEdit = () => {
    basic_info.lead_id = this.props.lead_body[0]?.id;
    this.setState({
      inputEdit: !this.state.inputEdit,
    });
  };

  updateBasicInfoValues = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    basic_info[name] = value;
  };

  dateSave = (name, value) => {
    basic_info[name] = value;
    this.setState({
      dateValue: value,
    });
  };

  noteSave = (name,value)=> {
    basic_info[name] = value;
  }

  closeEdit = () =>{
    this.setState({
      inputEdit: !this.state.inputEdit,
    });
  }


  handleSaveData = async () => {
    this.setState({
      inputEdit: !this.state.inputEdit,
    });

    const data = {
      basic_info,
    };

    // data.basic_info = basic_info;

    const objectLength = Object.keys(data.basic_info).length;

    if (objectLength >= 1) {
      try {
        await axios.post(url + "update-lead-basic", data);
        if(data.basic_info.status_id) {
        let status_name = this.state.oldData.status.filter(item => item.id == data.basic_info.status_id)
          await axios.post(url + "add-lead-log", {lead_id:data.basic_info.lead_id,details:'Lead status changed to '+status_name[0].type});
        }
        this.props.myCallback(true);
      } catch (err) {
        console.log(err);
      }
      //   axios
      //     .post(url + "update-lead-basic", data)

      //     .then((res) => {
      //       this.props.myCallback(true);
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //     });
    }
  };

  render() {
    const { lead_body } = this.props;
    const { oldData } = this.state;

    return (
      <div
        className={"add_lead_basic lead_details_basic_info lead_details_infos"}
      >
        <div className={"leads_details_edit"}>
          <h3>Basic Info</h3>
          {lead_body.length !== 0 &&
            (this.state.inputEdit ? (
                <>
                  <span onClick={this.handleSaveData}>Save</span>
                  <div className={'close_b'} onClick={this.closeEdit}><i className="fas fa-times"/></div>
                </>
            ) : (
              <i onClick={this.handleEdit} className={"apa-pencil"}/>
            ))}
        </div>

        {lead_body &&
          lead_body.map((item, key) => {
            return (
              <div className="row" key={key}>
                <div className="col-lg-4 col-4">
                  <p>Category</p>
                </div>

                <div className="col-lg-8 col-8">
                  <input
                    style={
                      this.state.inputEdit
                        ? { display: "none" }
                        : { display: "block" }
                    }
                    name={"category"}
                    readOnly={!this.state.inputEdit}
                    defaultValue={item.category && item.category.type}
                    className={this.state.inputEdit ? " edit_inputs" : ""}
                  />
                  <div
                    className="form-group selectdiv leads_details_selects"
                    style={
                      !this.state.inputEdit
                        ? { display: "none" }
                        : { display: "block" }
                    }
                  >
                    {/* Fixed */}
                    <select
                      name={"category_id"}
                      defaultValue={item.category?.id}
                      onChange={this.updateBasicInfoValues}
                      className={"form-control alterationTypeSelect"}
                    >
                      <option value={null}></option>
                      {oldData.category?.map((option, key) => {
                        return (
                          <option
                            value={option.id}
                            key={key}
                            // selected={item.category?.type === option.type}
                          >
                            {option.type}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>


                <div className="col-lg-4 col-4">
                  <p>LIN</p>
                </div>

                <div className="col-lg-8 col-8">
                  <input
                    name={"LIN"}
                    className={
                      "mobile_strong" +
                      (this.state.inputEdit ? " edit_inputs" : "")
                    }
                    defaultValue={item.LIN}
                    readOnly={true}
                  />
                </div>

                <div className="col-lg-4 col-4">
                  <p>Lead Taker</p>
                </div>

                <div className="col-lg-8 col-8 select_lead">
                  {this.state.inputEdit ? (
                    <div className={"form-group selectdiv select_content"}>
                      <select
                        name={"lead_rep"}
                        disabled={!this.state.inputEdit}
                        onChange={this.updateBasicInfoValues}
                        className={`form-control alterationTypeSelect`}
                        style={{
                          padding: "0px",
                          cursor: this.state.inputEdit ? "pointer" : "default",
                        }}
                      >
                        <option value="{null}"></option>
                        {oldData.rep?.map((rep, index) => {
                          return (
                            <option
                              disabled={
                                rep?.id === item?.rep?.id ? "selected" : ""
                              }
                              selected={
                                rep?.id === item?.rep?.id ? "selected" : ""
                              }
                              value={rep.id}
                              key={rep.id}
                            >
                              {rep.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  ) : (
                    <p>{item.rep?.name}</p>
                  )}
                </div>

                <div className="col-lg-4 col-4">
                  <p>Status</p>
                </div>

                <div className="col-lg-8 col-8">
                  <input
                    style={
                      this.state.inputEdit
                        ? { display: "none" }
                        : { display: "block" }
                    }
                    className={this.state.inputEdit ? " edit_inputs" : ""}
                    defaultValue={item.status && item.status.type}
                    readOnly={!this.state.inputEdit}
                  />
                  <ul className={"insured_progress"}>
                    {this.state.status_progress &&
                      this.state.status_progress.map((k) => {
                        return (
                          <li
                            key={k}
                            className={
                              "red_dot " +
                              (item.status && item.status.id === k
                                ? " green_dot"
                                : "")
                            }
                          />
                        );
                      })}
                  </ul>
                  <div
                    className="form-group selectdiv leads_details_selects"
                    style={
                      !this.state.inputEdit
                        ? { display: "none" }
                        : { display: "block" }
                    }
                  >
                    <select
                      name={"status_id"}
                      className={"form-control alterationTypeSelect"}
                      onChange={this.updateBasicInfoValues}
                    >
                      <option value={null}></option>
                      {oldData.status?.map((statuses, key) => {
                        return (
                          <option selected={item.status && item.status.type == statuses.type} value={statuses.id} key={key}>
                            {statuses && statuses.type}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="col-lg-4 col-4">
                  <p>First Contact</p>
                </div>

                <div className="col-lg-8 col-8">
                  <input
                    style={
                      this.state.inputEdit
                        ? { display: "none" }
                        : { display: "block" }
                    }
                    className={this.state.inputEdit ? " edit_inputs" : ""}

                    defaultValue={
                      item.first_contact ?
                      moment(new Date(item.first_contact)).format("MM/DD/YYYY")
                       : ''}
                    readOnly={!this.state.inputEdit}
                  />
                  <div
                    style={
                      !this.state.inputEdit
                        ? { display: "none" }
                        : { display: "block" }
                    }
                    className={"details_date_input_content"}
                  >
                    <DatePicker
                      className={"details_date_inputs"}
                      onChange={this.dateSave.bind(this, "first_contact")}
                      selected={
                        this.state.dateValue ? this.state.dateValue :
                        item.first_contact && item.first_contact ?
                        new Date(item.first_contact)
                         : new Date()}
                      dateFormat="MM/dd/yyyy"
                    />
                  </div>
                </div>

                <div className="col-lg-4 col-4">
                  <p>Lead Source</p>
                </div>

                <div className="col-lg-8 col-8">
                  <input
                    name={"lead_source"}
                    style={
                      this.state.inputEdit
                        ? { display: "none" }
                        : { display: "block" }
                    }
                    className={this.state.inputEdit ? " edit_inputs" : ""}
                    defaultValue={item.source && item.source.name}
                    readOnly={!this.state.inputEdit}
                  />
                  <div
                    className="form-group selectdiv leads_details_selects"
                    style={
                      !this.state.inputEdit
                        ? { display: "none" }
                        : { display: "block" }
                    }
                  >
                    <select
                      name={"lead_source"}
                      className={"form-control alterationTypeSelect"}
                      onChange={this.updateBasicInfoValues}
                      defaultValue={item.source && item.source.id}
                    >
                      <option></option>
                      {oldData.source &&
                        oldData.source.map((sources, key) => {
                          return (
                            <option value={sources.id} key={key}>
                              {sources.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                <NoteModal title={'Source Notes'} name={'source_notes'} notes={item.notes}
                           id={'basic_notes'} inputEdit={this.state.inputEdit} noteSave = {this.noteSave} />

              </div>
            );
          })}
      </div>
    );
  }
}

function mapStateToProps(state) {

  return{
    note_data:state.leadsReducer.note_data,
    schedule_title:state.leadsReducer.schedule_title

  }

}

export default connect(mapStateToProps)(BasicInfo);

