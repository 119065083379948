import React from "react";
import login_styles from '../assets/css/login_styles.module.css'
import axios from "axios";
import {url} from "../config";
import {NavLink} from "react-router-dom";
import {Spinner} from 'react-bootstrap'


class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            is_remember:0,
            error:'',
            isShow:false
        }
    }


    componentDidMount() {
        if(localStorage.getItem('token')){
            window.location.href = '/'
        }
    }


    sendLoginInfo = () => {
        const body = {
            username: this.state.username,
            password: this.state.password,
            remember_me:this.state.is_remember
        }

        
        axios.post(url + 'login', body)

            .then(res => {
                    console.log(res)

                if(res.data.error){
                    this.setState({
                        error:res.data.error,
                        isShow:false
                    })
                }else {
                    localStorage.setItem('token', '1')
                    window.location.href = '/dashboard'
                }



            })
            .catch(err => {
                this.setState({
                    isShow:false,
                    error:err.response.data
                })

            })
    }

    handleInputValue = (e) => {
        e.preventDefault();
        if(e.target.value.trim()){
            this.setState({
                [e.target.name]: e.target.value
            })
        }

    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isShow:true
        })
        this.sendLoginInfo()

    }

    handleCheck = () => {
        if(this.state.is_remember === 0){
            this.setState({
                is_remember:1
            })
        }else {
            this.setState({
                is_remember:0
            })
        }
    }


    render() {
        return (
            <>
                <div className={`${login_styles.login_header}  ` +   (window.innerWidth < 991 ? ' mobile_vhc' : ' vhc') }>

                    <div className="container">

                        <div className={login_styles.login_content + ' mid_col col-md-6 col-12'}>

                            <form onSubmit={this.handleSubmit}>

                                <div className={login_styles.img}>
                                    <NavLink to = {'/'}>
                                        <img src={require('../assets/img/APA_Logo.png')} alt={'logo'}/>
                                    </NavLink>

                                </div>

                                <p>Welcome to APA-LM. Please login to your account.</p>

                                <div className={login_styles.login_inputs}>

                                    <div>
                                        <input type={'text'} placeholder={'Username'} name={'username'} onChange={this.handleInputValue}/>
                                    </div>


                                    <div>
                                        <input type={'password'} autoComplete={'off'} placeholder={'Password'} name={'password'} onChange={this.handleInputValue}/>
                                    </div>

                                </div>

                                <div className={'row'}>
                                    <div className="col-6">
                                        <div className={login_styles.login_remember}>
                                            <input id="rememberMe" name="rememberMe" type="checkbox" checked={this.state.is_remember} onChange={this.handleCheck}/>
                                            <label htmlFor="rememberMe">Keep Me Signed In</label>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className={login_styles.login_forgot}>
                                            <a href="/pass-forgot">Forgot Password?</a>
                                        </div>

                                    </div>

                                </div>

                                {this.state.error && <p style={{color:'red',textAlign:"center"}}>{this.state.error}</p>}
                                <div className={'login_button'}>
                                    {this.state.isShow ? <Spinner animation="grow" variant="primary"/> :<button className={'link_button'} type={'submit'}>Login</button> }
                                </div>

                            </form>

                        </div>

                        <div className={login_styles.rules}>
                            <ul>
                                <li>
                                    <a href={'/'}>Term of use</a>
                                </li>

                                <li>
                                    <a className={login_styles.last} href={'/'}>Privacy policy</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>


            </>
        );
    }
}

export default Login;