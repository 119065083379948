import React, {useEffect, useState} from "react";
import DataTable from 'react-data-table-component';
import  {ArrowDropDown} from "@material-ui/icons";
import Contacts from "./Tables/Contacts";

const selectProps = { indeterminate: (isIndeterminate: boolean) => isIndeterminate };
const sortIcon = <ArrowDropDown />;



function Basic<T>(props: TableProps<T>): JSX.Element {

   const {columns,data} = props

    return (

        <>
            { data && data.length > 20 ?
                <style dangerouslySetInnerHTML={{__html: `
                  .rdt_Pagination { display: flex }
                `}} />
                :
                <style dangerouslySetInnerHTML={{__html: `
                  .rdt_Pagination { display: none }
                `}} />
            }
            <DataTable
                responsive
                pagination
                paginationPerPage={20}
                paginationRowsPerPageOptions={[20,40,60,80,100]}
                columns={columns}
                data={data}
                sortIcon={sortIcon}
                selectableRowsComponentProps={selectProps}
                dense
                {...props}
            />
        </>

    );
};

export default Basic