import React from "react";
import axios from "axios";
import { url } from "../../config";

const loss_address = {};

class LossAdress extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      inputEdit: false,
      countrys: [],
      states: [],
      citys: [],
      building_types: [],
    };
  }

  componentDidMount() {
    this.getCountyis();
    this.getStates();
    this.getCitys()
  }

  getCountyis = async () => {
    try {
      const { data } = await axios.get(url + "get-countries");
      this.setState({
        countrys: data.country,
      });
    } catch (error) {
      console.error(error);
    }
  };

  getZip = async (zipKey = null) => {
    // const { lead_body: lb } = this.props;

    if (zipKey) {
      try {
        const { data: response } = await axios.post(
          url + "get-location-by-zip",
          {
            parameter: zipKey,
          }
        );

        console.log(response,456)
        if (response?.result?.length === 1) {
          this.props.setState((old) => {
            //   TODO: Fix this bug
            old.lead_body[0].loss_address.city = response.result[0].city;
            old.lead_body[0].loss_address.state = response.result[0].state;  
            // old.lead_body[0] = lb[0];
            loss_address.city =  response.result[0].city;
            loss_address.state =  response.result[0].state;

            return {
              lead_body: old.lead_body,
            };
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  handleEdit = () => {
    loss_address.id = this.props.lead_body[0].loss_address.id;
    this.setState({
      inputEdit: !this.state.inputEdit,
    });
    if (
      this.props.lead_body[0] &&
      this.props.lead_body[0].loss_address &&
      this.props.lead_body[0].loss_address.state
    ) {
      this.getCitys(this.props.lead_body[0].loss_address.state);
    }
  };

    closeEdit = () =>{
    this.setState({
      inputEdit: !this.state.inputEdit,
    });
  }

  getStates = async (country_id = "USA") => {
    try {
      const { data } = await axios.get(url + "get-states", {
        params: {
          country_id: country_id,
        },
      });

      this.setState({
        states: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  getCitys = async (citi_id=null) => {
    try {
      let state_code =''
      if(citi_id) state_code =citi_id;
      const { data } = await axios.get(url + "get-cities?state="+state_code);
      this.setState({
        citys: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  handleChangeData = (event) => {
    const { name, value } = event.target;

    loss_address[name] = value;
    if (name === "zip") {
      const { lead_body: lb } = this.props;

      lb[0].loss_address.zip = value;

      this.getZip(value);
    }
    if (name === "state") this.getCitys(value);
    if (name === "country") this.getStates(value);
  };

  handleSaveData = async () => {

    const data = {
      loss_address,
    };

    try {
      await axios.post(url + "update-lead-loss-address", data).then(async response => {
        
        let log_address = response?.data?.street_number+', '+response?.data?.street_name+', '+response?.data?.unit_number+', '+response?.data?.city+', '+response?.data?.state+', '+response?.data?.zip+', '+response?.data?.country
      await axios.post(url + "add-lead-log", {lead_id:this.props.lead_body[0].id,details:'Loss Address Changed to ' + log_address} );

      });
        if(!this.props.noCallback){
          this.props.myCallback(true)
        }

      this.setState({
        inputEdit: !this.state.inputEdit,
      });

    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const { lead_body } = this.props;
    const { states, citys } = this.state;


    return (
      <div className={"add_lead_basic lead_details_basic_info loss_addresses"}>
        <div className={"leads_details_edit"}>
          <h3>{this.props.type ? 'Address':'Loss Address'}</h3>
          {this.state.inputEdit ? (
              <>
                <span onClick={this.handleSaveData}>Save</span>
                <div className={'close_b'} onClick={this.closeEdit}><i className="fas fa-times"/></div>
              </>
          ) : (
            <i onClick={this.handleEdit} className={"apa-pencil"}></i>
          )}
        </div>

        {lead_body &&
          lead_body.map((item, key) => {
            return (
              <div className="row" key={key}>
                <div className="col-4">
                  <p>Street Number</p>
                </div>

                <div className="col-8">
                  <input
                    onChange={this.handleChangeData}
                    name={"street_number"}
                    type={"text"}
                    className={this.state.inputEdit ? " edit_inputs" : ""}
                    defaultValue={
                      item?.loss_address && item?.loss_address.street_number
                    }
                    readOnly={!this.state.inputEdit}
                  />
                </div>

                <div className="col-4">
                  <p>Street Name</p>
                </div>

                <div className="col-8">
                  <input
                    onChange={this.handleChangeData}
                    name={"street_name"}
                    className={this.state.inputEdit ? " edit_inputs" : ""}
                    defaultValue={
                      item?.loss_address && item?.loss_address.street_name
                    }
                    readOnly={!this.state.inputEdit}
                  />
                </div>

                <div className="col-4">
                  <p>Unit Number</p>
                </div>

                <div className="col-8">
                  <input
                    onChange={this.handleChangeData}
                    name={"unit_number"}
                    type={"number"}
                    className={this.state.inputEdit ? " edit_inputs" : ""}
                    defaultValue={
                      item?.loss_address && item?.loss_address.unit_number
                    }
                    readOnly={!this.state.inputEdit}
                  />
                </div>

                <div className="col-4">
                  <p>City</p>
                </div>

                <div className="col-8">
                  <input
                    defaultValue={item?.loss_address?.city}
                    style={
                      this.state.inputEdit
                        ? { display: "none" }
                        : { display: "block" }
                    }
                    name={"city"}
                    className={this.state.inputEdit ? " edit_inputs" : ""}
                    readOnly={!this.state.inputEdit}
                  />
                  <div
                    className="form-group selectdiv leads_details_selects"
                    style={
                      !this.state.inputEdit
                        ? { display: "none" }
                        : { display: "block" }
                    }
                  >
                    <select

                      name={"city"}
                      className={
                        "form-control alterationTypeSelect"
                      }
                      onChange={this.handleChangeData}
                    >
                      <option value={""}></option>
                      {citys &&
                        citys.map((cities, key) => {
                          return (
                            <option
                              selected={
                                item?.loss_address &&
                                item?.loss_address.city == cities.city
                              }
                              value={cities.city}
                              key={key}
                            >
                              {cities.city}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                <div className="col-4">
                  <p>State</p>
                </div>

                <div className="col-8">
                  <input
                    defaultValue={
                      item?.loss_address && item?.loss_address?.state
                    }
                    style={
                      this.state.inputEdit
                        ? { display: "none" }
                        : { display: "block" }
                    }
                    name={"state"}
                    className={this.state.inputEdit ? " edit_inputs" : ""}
                    readOnly={!this.state.inputEdit}
                  />
                  <div
                    className="form-group selectdiv leads_details_selects"
                    style={
                      !this.state.inputEdit
                        ? { display: "none" }
                        : { display: "block" }
                    }
                  >
                    <select
                      disabled={
                        item?.loss_address && !item?.loss_address.country
                      }
                      name={"state"}
                      className={
                        "form-control alterationTypeSelect" +
                        (item?.loss_address && !item?.loss_address?.country
                          ? " disabled_selects"
                          : "")
                      }
                      onChange={this.handleChangeData}
                    >
                      <option value={""}></option>
                      {states &&
                        states.map((state, key) => {
                          return (
                            <option
                              selected={
                                item?.loss_address &&
                                item?.loss_address.state === state.state
                              }
                              value={state.id}
                              key={key}
                            >
                              {state.state}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                <div className="col-4">
                  <p>Zip</p>
                </div>

                <div className="col-8">
                  <input
                    name={"zip"}
                    onChange={this.handleChangeData}
                    readOnly={!this.state.inputEdit}
                    defaultValue={item?.loss_address?.zip}
                    className={this.state.inputEdit ? " edit_inputs" : ""}
                  />
                </div>

                <div className="col-4">
                  <p>Country</p>
                </div>

                <div className="col-8">
                  <input
                    style={
                      this.state.inputEdit
                        ? { display: "none" }
                        : { display: "block" }
                    }
                    name={"country"}
                    className={this.state.inputEdit ? " edit_inputs" : ""}
                    defaultValue={
                      item?.loss_address && item?.loss_address.country
                    }
                    readOnly={!this.state.inputEdit}
                  />
                  <div
                    className="form-group selectdiv leads_details_selects"
                    style={
                      !this.state.inputEdit
                        ? { display: "none" }
                        : { display: "block" }
                    }
                  >
                    <select
                      name={"country"}
                      className={"form-control alterationTypeSelect"}
                      onChange={this.handleChangeData}
                    >
                      <option value={""}></option>
                      return{" "}
                      <option
                        value={this.state.countrys}
                        selected={
                          item?.loss_address &&
                          item?.loss_address.country &&
                          this.state.countrys == item.loss_address.country
                        }
                        key={key}
                      >
                        {this.state.countrys}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  }
}

export default LossAdress;
