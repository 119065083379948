import React from "react";
import SideBar from "./header/SideBar";
import Navbar from "./header/Navbar";



const not_data = [
    {
        not_title:'Claims:',
        not_progress:'Contract has been signed by lead',
        not_date:'LIN: 2020.01.12',
        not_user:'Jane Doe',
        not_time:'6 hours ago'
    },
    {
        not_title:'Claims:',
        not_progress:'A Claim has been assigned to you',
        not_date:'LIN: 2020.01.12',
        not_user:'Jane Doe',
        not_time:'6 hours ago'
    },
    {
        not_title:'Claims:',
        not_progress:'A Claim has been assigned to you',
        not_date:'LIN: 2020.01.12',
        not_user:'Jane Doe',
        not_time:'6 hours ago'
    },
    {
        not_title:'Claims:',
        not_progress:'A Claim has been assigned to you',
        not_date:'LIN: 2020.01.12',
        not_user:'Jane Doe',
        not_time:'6 hours ago'
    }
]

class MobileNotifications extends React.Component{
    constructor(prop) {
        super(prop);
        this.state = {

        }
    }

    render() {
        return(
            <>

                <div className={"d-flex" + (this.state.toggled ? ' toggled' : '')} id="wrapper">
                    <SideBar isAuth = {this.props.isAuth}/>
                    <div id="page-content-wrapper">
                        <div>
                            <Navbar toggled = {this.myCallback} />
                            <div className={'col-12'}>



                                        <div className={'page_title users_title'}>
                                            <h2>Notifications</h2>
                                        </div>



                            </div>
                        </div>

                        <div className={'mobile_notifications'}>
                            {not_data.map((item,key) =>{

                                return(

                                    <div className={'notification_body header_notify' + ( Number(key + 1) % 2 === 0 ? ' c_not' : '')} key={key}>
                                        <div>
                                            <p><span>{item.not_title}</span> {item.not_progress}</p>
                                        </div>

                                        <div className={'notifications_date'}>
                                            <p>{item.not_date}</p><span>{item.not_user}</span>
                                        </div>

                                        <div className={'notifications_time'}>
                                            <span>{item.not_time}</span>
                                        </div>

                                    </div>
                                )
                            })}
                        </div>

                    </div>
                </div>

            </>
        )
    }
}


export default MobileNotifications;