import React,{useState} from "react";
import {Modal, Table} from "react-bootstrap";
import AudioPlayer from "react-h5-audio-player";
import {connect} from "react-redux";
import axios from "axios";
import Moment from 'react-moment';
import {uploads_url,url} from "../../config";
import {GETLEADID} from "../../redux/types";
import {checkAuth, openBeginModal, sideBarToggle} from "../../redux/actions";
class LogEntry extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            logModal:false,
            audioModal:false,
            logs:[],
            textModal: [{
                by: '',
                first_contact: '',
                time: '',
                details: '',
            }]
        }

    }


    openLogModal = (id) => {

        axios.get(url + 'lead-log?id=' + id)
          .then(res => {
            {console.log(res.data,'123123')}

            this.setState({
                textModal:res.data,
                logModal:true
            })
          })

    }


    handleClose = () => {
        this.setState({
            logModal:false
        })
    }


    closeAudioModal = () => {
        this.setState({
            audioModal:false
        })
    }

    openAudioModal = () => {
        this.setState({
            audioModal:true
        })
    }



    render() {
        const { lead_body } = this.props;
        return(

        <div className={'log_entry_content'}>

            <h3>Log Entries</h3>
            <div className={'lead_table logs_table table-responsive log_entry_table'}>
                <Table striped bordered hover className = "">

                    <thead>
                    <tr>
                        <th>Log Details</th>
                        <th>Type</th>
                        <th>By</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>


                    <React.Fragment>
                        <tbody >

                        { lead_body[0]?.lead_logs.map((log,key) =>
                        <tr key={key+1}>
                            <td >{log.details.replace(/(<([^>]+)>)/gi, "").substring(0, 32)}...</td>
                            <td>{log.type}</td>
                            <td>{log.by}</td>
                            <td>
                            <Moment format="MM/DD/YYYY">
                            {log.first_contact}
                            </Moment>
                            </td>
                            <td>
                            <Moment format="hh:mm">
                            {log.first_contact}
                            </Moment>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            {log.type == 'Audio' ? (
                            <td><i onClick={() => {
                                this.openLogModal(log.id)
                             }} className={'apa-icon-listen table_listen_icon'}></i></td>
                            ) : (
                            <td 
                                 onClick={() => {
                                    this.openLogModal(log.id)
                                 }}
                                 ><i className={'apa-icon-view'}></i></td>
                            )}
                        </tr>
                        )}

                        </tbody>
                    </React.Fragment>

                </Table>

                <Modal show={this.state.logModal} onHide = {this.handleClose}  centered className="discover_modal_content" dialogClassName='discover_modal log_info_modal'>
                    <Modal.Header closeButton>
                        <Modal.Title>Log Entry</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={'log_info'}>
                            <ul>
                                <li key={'1'}>
                                    <h4><span>By: </span>
                                    {this.state.textModal.by}
                                    </h4>
                                </li>

                                <li key={'2'}>
                                    <h4><span>Date: </span>
                                    <Moment format="MM/DD/YYYY">
                                        {this.state.textModal.first_contact}
                                    </Moment>
                                    </h4>
                                </li>

                                <li key={'3'}>
                                    <h4><span>Time </span>
                                    <Moment format="hh:mm">
                                        {this.state.textModal.first_contact}
                                    </Moment>
                                    </h4>
                                </li>

                            </ul>
                        </div>

                       {this.state.textModal.type == 'Text' ? (
                        <div className={'log_text'}>
                            <p dangerouslySetInnerHTML={{__html: this.state.textModal.details}}></p>
                        </div>


                       ): (
                    <div className={'container'}>
                            <AudioPlayer
                                className={'audio_player'}
                                defaultDuration="Loading"
                                volume={true}
                                src={uploads_url +'uploads/'+this.state.textModal.file}
                                layout="horizontal-reverse"
                                showJumpControls={false}
                                onPlay={e => console.log("onPlay")}
                                customAdditionalControls={[]}
                                customIcons={{
                                    play: <i className={'apa-icon-play'}></i>
                                }}
                            />
                        </div>

                       )}
                        
                        

                    </Modal.Body>
                    <Modal.Footer>

                    </Modal.Footer>
                </Modal>

            </div>
        </div>
        )
    }
}

function mapDispatchToProps(dispatch){
    return{
        getLeadId: (leadId) => dispatch({type:GETLEADID,payload:leadId}),
        checkAuth:() => dispatch(checkAuth()),
        showBeginModal: () => dispatch(openBeginModal()),
        sideBarToggle : (condition) => dispatch(sideBarToggle(condition))
    }
}
function mapStateToProps(state) {

    return{
        recordBlob:state.appReducer.recordBlob
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(LogEntry);


