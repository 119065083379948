import React from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { url } from "../../config";
import { connect } from "react-redux";
import { relatedUpdateModalShowHide } from "../../redux/actions";
import NoteModal from "./NoteModal";
import NumberFormat from 'react-number-format';
let related_contact = {};
class RelatedUpdateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closeModal: false,
      inputEdit: false,
    };
  }
  handleEdit = () => {
    related_contact.related_id = this.props.related_data.id;
    this.setState({
      inputEdit: !this.state.inputEdit,
    });
  };
  removeRelatedContact = async () => {
    if (this.props.related_data.id) {
      const data = {
        id: this.props.related_data.id,
      };
      await axios.delete(url + "remove-related-contact-by-id/" + data.id);
      this.props.dispatch(relatedUpdateModalShowHide(false, 1));
    }
  };
  handleChangeData = (event) => {
    const { name, value } = event.target;
    if (name === "primary_contact") {
      if (this.props.related_data.primary_contact === "1") {
        return (related_contact.primary_contact = "0");
      } else return (related_contact.primary_contact = "1");
    }
    if (name === "policy_holder") {
      if (this.props.related_data.policy_holder === "1") {
        return (related_contact.policy_holder = "0");
      } else return (related_contact.policy_holder = "1");
    }
    related_contact[name] = value;
  };
  handleSaveData = async () => {
    await axios.post(url + "update-lead-basic", {
      related_contact,
    });
    this.props.dispatch(relatedUpdateModalShowHide(false, 1));
  };
  noteSave(name, value) {
    related_contact[name] = value;
  }
  render() {
    const { related_update_show, related_data } = this.props;
    return (
      <>
        <Modal
          show={related_update_show}
          onHide={() => {
            this.props.dispatch(relatedUpdateModalShowHide(false));
          }}
          centered
          className="discover_modal_content log_entry_modal"
          dialogClassName="discover_modal related_update_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Related Contacts</Modal.Title>
            {this.state.inputEdit ? (
              <span className={"microphone_icon"} onClick={this.handleSaveData}>
                Save
              </span>
            ) : (
              <span className={"microphone_icon related_edit_icon"}>
                <i
                  onClick={this.handleEdit}
                  className={"apa-pencil hovered_icons"}
                ></i>{" "}
              </span>
            )}
            <span
              onClick={this.removeRelatedContact}
              className={"related_remove_icon"}
            >
              <i className={"apa-icon-delete hovered_icons"}></i>
            </span>
          </Modal.Header>
          <Modal.Body>
            <div className={"lead_details_basic_info related_update  "}>
              <div className="row">
                <div className="col-6">
                  <p>Company Name</p>
                </div>
                <div className="col-6">
                  <input
                    onChange={this.handleChangeData}
                    readOnly={!this.state.inputEdit}
                    defaultValue={related_data.company_name}
                    type={"text"}
                    name={"company_name"}
                    className={this.state.inputEdit ? " edit_inputs" : ""}
                  />
                </div>
                <div className="col-6">
                  <p>First Name</p>
                </div>
                <div className="col-6">
                  <input
                    onChange={this.handleChangeData}
                    readOnly={!this.state.inputEdit}
                    className={this.state.inputEdit ? " edit_inputs" : ""}
                    defaultValue={related_data.first_name}
                    type={"text"}
                    name={"first_name"}
                  />
                </div>
                <div className="col-6">
                  <p>Last Name</p>
                </div>
                <div className="col-6">
                  <input
                    onChange={this.handleChangeData}
                    readOnly={!this.state.inputEdit}
                    className={this.state.inputEdit ? " edit_inputs" : ""}
                    defaultValue={related_data.last_name}
                    type={"text"}
                    name={"last_name"}
                  />
                </div>
                <div className="col-6">
                  <p>Cell Number</p>
                </div>
                <div className="col-6">
                <NumberFormat 
                    format="(###) ###-####"
                    onChange={this.handleChangeData}
                    readOnly={!this.state.inputEdit}
                    className={this.state.inputEdit ? " edit_inputs" : ""}
                    defaultValue={related_data.cell_number}
                    type={"text"}
                    name={"cell_number"}
                  />
                </div>
                <div className="col-6">
                  <p>Work Number</p>
                </div>
                <div className="col-6">
                <NumberFormat 
                    format="(###) ###-####"
                    onChange={this.handleChangeData}
                    readOnly={!this.state.inputEdit}
                    className={this.state.inputEdit ? " edit_inputs" : ""}
                    defaultValue={related_data.work_number}
                    name={"work_number"}
                    type={"text"}
                  />
                </div>
                <div className="col-6">
                  <p>Home Number</p>
                </div>
                <div className="col-6">
                <NumberFormat 
                    format="(###) ###-####"
                    onChange={this.handleChangeData}
                    readOnly={!this.state.inputEdit}
                    className={this.state.inputEdit ? " edit_inputs" : ""}
                    defaultValue={related_data.home_number}
                    type={"text"}
                    name={"home_number"}
                  />
                </div>
                <div className="col-6">
                  <p>Email</p>
                </div>
                <div className="col-6">
                  <input
                    onChange={this.handleChangeData}
                    readOnly={!this.state.inputEdit}
                    className={this.state.inputEdit ? " edit_inputs" : ""}
                    defaultValue={related_data.email}
                    type={"email"}
                    name={"email"}
                  />
                </div>
                <NoteModal
                  col={6}
                  title={"Notes"}
                  name={"notes"}
                  notes={related_data.notes}
                  noteSave={this.noteSave}
                  inputEdit={this.state.inputEdit}
                  id={"notes"}
                />
                <div className="col-6">
                  <p>Primary Contact</p>
                </div>
                <div className="col-6">
                  <input
                    onChange={this.handleChangeData}
                    defaultChecked={Number(related_data.primary_contact)}
                    disabled={!this.state.inputEdit}
                    className={this.state.inputEdit ? " edit_inputs" : ""}
                    type="checkbox"
                    id="primary_contact"
                    name="primary_contact"
                  />
                  <label htmlFor="primary_contact"></label>
                </div>
                <div className="col-6">
                  <p>Policy Holder</p>
                </div>
                <div className="col-6">
                  <input
                    onChange={this.handleChangeData}
                    defaultChecked={Number(related_data.policy_holder)}
                    disabled={!this.state.inputEdit}
                    className={this.state.inputEdit ? " edit_inputs" : ""}
                    type="checkbox"
                    id="policy_holder"
                    name="policy_holder"
                  />
                  <label htmlFor="policy_holder"></label>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* <NoteModal title={"Edit note"} notes={related_data.notes} /> */}
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    related_update_show: state.leadsReducer.related_update_modal,
  };
}
export default connect(mapStateToProps, null)(RelatedUpdateModal);
