

function byTerritory(series,cities) {
    return  {
        series: series?.length>0 ? series:[100],
        options: {
            chart: {
                type: 'donut',
                width:500,
            },
            dataLabels: {
                enabled: true
            },
            labels: cities?.length>0 ? cities :['No Result'],
            responsive: [
                {
                    breakpoint: 1000,
                    options: {
                        plotOptions: {
                            bar: {
                                horizontal: false
                            }
                        },

                    }
                }
            ],
            colors: ['#1B75BE', '#99B7CF', '#A9AEB2', '#CDDAF3','#FFD456','#FFC10E'],

        },
        value:[new Date(),new Date()],
        icon:require('../../assets/img/report1.png')

    }


}
export default (byTerritory)