import React from "react";
import '../assets/css/leads.css'
import {Col, Nav, Row, Tab} from "react-bootstrap";
import LeadsTable from "./LeadsTable";
import {NavLink} from "react-router-dom";
import axios from "axios";
import {url} from "../config";
import {connect} from 'react-redux'
import {SEARCHDATA} from "../redux/types";
import LeadsActiveTable from "./LeadsActiveTable";


class Leads extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search:false,
            windowWidth: window.innerWidth,
            totalCount: ''
        }
    }

componentDidMount() {
    this.countTotalActiveLeads()
}
    handleSearch = () => {
        this.setState({
            search:!this.state.search
        })
    }
    countTotalActiveLeads = () => {
            axios.get(url + 'get-total-active-leads-count')
                .then(res => {
                   this.setState({
                       totalCount:res.data
                   })
                })
                .catch(err => {
                    console.log(err)
                })
    }

    searchByLeadId = (event) => {
        const {value} = event.target;

        let data = {};

        data.parameter = value

        axios.post(url + 'search-by-lead-id', data)
            .then(res => {

                this.props.getSearchData(res.data)
                this.setState({
                    search:true
                })
            })
            .catch(err => {
                console.log(err)
            })

    }


    render() {

        const {leads_data} = this.props;



        return (
            <>

                <div>

                    <div className={'col-12'}>
                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <div className={'page_title'}>
                                    <h2>Home / Overview</h2>
                                </div>

                            </div>

                            <div className="col-lg-6 col-12 leads_content">
                                <div className={'leads_search lead_id_search'}>
                                    {this.state.windowWidth > 991 ?

                                        <div className={'search-box' + (this.state.search ? ' search lead_id_search' : ' lead_id_search')} >
                                            <input  name={'lead_id_search'} onChange={this.searchByLeadId} className=" search-text" type="text" />
                                            {this.state.search ?  <i className="fas fa-times close_icon" onClick={this.handleSearch}/> :  <span onClick={this.handleSearch} className={'lead_id_search default_hover'}><i className="apa-icon-search" />Search by Lead ID</span> }

                                        </div> :
                                        <div className={'mobile-search-box'}>
                                            <NavLink to="/search">
                                                <i className={'apa-icon-search'}/>
                                            </NavLink>
                                        </div>


                                    }
                                </div>


                            </div>
                        </div>

                    </div>


                    <Tab.Container className = {"table_container"} id={'table_container'} defaultActiveKey="first">

                        <Row>
                            <Col lg={12}>
                                <Nav variant="pills" className="">

                                    <Nav.Item className={'col-6  leads_tabs'}>
                                        <Nav.Link eventKey="first">{this.state.totalCount > 0 ? this.state.totalCount + ' Active Leads' : 'Active Leads' }  </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className={'col-6  leads_tabs'}>
                                        <Nav.Link eventKey="second">Signed Claims</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col lg={12}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <LeadsActiveTable checkLeadsLength = {this.checkLeadsLength} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        {/*<LeadsTable/>*/}
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>

                        </Row>
                    </Tab.Container>
                </div>
            </>
        )
    }
}

function mapDispatchToProps(dispatch){
    return{
        getSearchData: (searchData) => dispatch({type:SEARCHDATA,payload:searchData})
    }
}


function mapStateToProps(state) {

    return{
        windowWidth:state.appReducer.windowWidth,
        leads_data:state.leadsReducer.leads_data,
        search:state.leadsReducer.search,
    }
}



export default connect(mapStateToProps,mapDispatchToProps)(Leads);
