import React, { PureComponent } from "react";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { url } from "../../config";
import { connect } from "react-redux";
import { ADJUSTER, STATUS } from "../../redux/types";
import Moment from 'react-moment';
import moment from 'moment'
import NoteModal from "./NoteModal";
import ReportModal from "./ReportModal";
import {
  findAll,
  getLeedInspection,
  scheduleTitleCheck,
} from "../../redux/actions";
import _ from "lodash";
import DatePicker from "react-datepicker";

const inspectionInfo = {};
let intervalId;
const basic_info = {};

const values = {
  source_notes: "",
  notes: "",
};

class InspectionInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inputEdit: false,
      suggestedModal: false,
      inspection_data: null,
      status_progress: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      reportData: {},
      show_report: false
    };
  }

  handleEdit = () => {
    inspectionInfo.lead_id = this.props.leadId;
    this.setState({
      inputEdit: !this.state.inputEdit,
    });
  };
  noteSave = (name, value) => (inspectionInfo[name] = value);
  inputOnFocus = () => {
    if (this.nameInput) {
      return this.nameInput.focus();
    }
  };

  handleNoteValue = () => {
    intervalId = setTimeout(this.inputOnFocus, 600);
    this.setState({
      notes_show: true,
    });
  };

  handleChangeNotes = (event) => {
    clearInterval(intervalId);

    return (values.notes = event.target.value);
  };

  updateInspectionInfoValues = (event) => {
    event.preventDefault();

    const { name, value } = event.target;
    inspectionInfo[name] = value;
  };

  handleSave = () => {
    this.setState({
      inputEdit: !this.state.inputEdit,
    });

    if (inspectionInfo.inspection_date) {
      inspectionInfo["status_id"] = 2;

      //update-lead-current-status-code
    }

    const objectLength = Object.keys(inspectionInfo).length;
    if (objectLength > 1) {
      axios
        .post(url + "schedule-inspection", inspectionInfo)

        .then((res) => {
          this.updateLeadStatusCode(
            inspectionInfo.lead_id,
            inspectionInfo["status_id"]
          );
          this.props.dispatch(getLeedInspection(inspectionInfo.lead_id));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  updateLeadStatusCode = (lead_id, status_code) => {
    axios
      .post(url + "update-lead-current-status-code", {
        lead_id: lead_id,
        status_code: status_code,
      })
      .then((res) => {
        console.log("Status Updated");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  openSuggestedDatesModal = () => {
    this.setState({
      suggestedModal: true,
    });
  };

  componentDidMount() {
    this.props.dispatch(findAll("get-user-roles", ADJUSTER));
    this.props.dispatch(findAll("assignment-statuses", STATUS));
  }

  closeEdit = () =>{
    this.setState({
      inputEdit: !this.state.inputEdit,
    });
  }

  getFormattedDate(date) {
    if (!date) return "";
    // let todayTime = new Date(date);
    // let month = todayTime.getMonth() + 1;
    // let day = todayTime.getDate();
    // if (month < 10) month = "0" + month;
    // if (day < 10) day = "0" + day;
    // let year = todayTime.getFullYear();
    let dateTime =  moment(new Date(date)).format("MM/DD/YYYY hh:mm A")
    return dateTime;
  }

  handleDateChange = (name, value) => {
    if (name === "DOL") {
      this.setState({
        DOL: value,
      });
      inspectionInfo["inspection_date"] = value;
    }
  };

  handleClose = (type = null) => {
    if (type) {
      this.setState({
        notes_show: false,
      });
    } else {
      this.setState({
        suggestedModal: false,
      });
    }
  };

  handleSaveNotes = (name = "", key) => {
    let input = document.getElementById("ins_notes");
    input.value = values.notes;
    inspectionInfo["notes"] = values.notes;
    this.setState({
      notes_show: false,
    });
  };

  checkInspectionDate(data, date) {
    let result = "";
    if (data) date ? (result = date) : (result = "Pending Approval by Client");
    return result;
  }
  showReportModal = (key) => {
    if(this.props.inspections.inspection_forms) {
      this.setState({
        reportData: this.props.inspections.inspection_forms[key],
        show_report: true
      }) 
    }
  }
  toggleReportModal = (status) => {
    this.setState({
      show_report: status
    }) 
  }
  render() {
    const { inspections } = this.props;
    const { statuses } = this.props;
    const dates = inspections && inspections.suggestion_date && JSON.parse(inspections.suggestion_date);
    const insp_date = this.getFormattedDate(
      inspections?.inspection_date
    ).toString();

    return (

    
      <div
        className={
          "add_lead_basic lead_details_basic_info inspection_info " +
          (inspections ? "" : "inactive_box")
        }
      >

        <div className={"leads_details_edit "}>
          <h3>Inspection Info</h3>
          {this.state.inputEdit ? (
              <>
                <span onClick={this.handleSave}>Save</span>
                <div className={'close_b'} onClick={this.closeEdit}><i className="fas fa-times"/></div>
              </>
          ) : inspections ? (
            <i onClick={this.handleEdit} className={"apa-pencil"}></i>
          ) : null}
        </div>
        <div className="row">
          <div className="col-4">
            <p>Inspection Date</p>
          </div>
          <div className="col-8">
            <input
              style={
                this.state.inputEdit
                  ? { display: "block" }
                  : { display: "block" }
              }
              name={"inspection_date"}
              type={"text"}
              value={this.checkInspectionDate(inspections, insp_date)}
              // className={this.state.inputEdit ? " edit_inputs" : ""}
              readOnly={true}
              disabled={true}
            />

            <div
              style={
                !this.state.inputEdit
                  ? { display: "none" }
                  : { display: "none" }
              }
              className={"details_date_input_content loss_info_dates"}
            >
              <DatePicker
                className={"details_date_inputs "}
                // onChange={this.handleDateChange.bind(this, "DOL")}
                selected={this.state.DOL}
              />
            </div>
          </div>
          <div className="col-4">
            <p>Suggested Date(s)</p>
          </div>
          <div className="col-8">
            <i
              onClick={this.openSuggestedDatesModal}
              className={"apa-icon-view c-pointer"}
            />
          </div>
          <div className="col-4">
            <p>Inspector</p>
          </div>
          <div className="col-8">
          
           
            {this.state.inputEdit ? (
              <div className="form-group selectdiv select_content">
                <select
                  name={"inspector_id"}
                  className={"form-control alterationTypeSelect"}
                  onChange={this.updateInspectionInfoValues}
                  disabled={!this.state.inputEdit}
                >
                  <option key={0} disabled selected={"selected"}>
                    Select Inspector
                  </option>
                  {this.props.adjusters &&
                    this.props.adjusters.users.map((item, key) => {
                      return (
                        <option
                          value={item.id}
                          key={key + 1}
                          selected={
                            item.id === inspections?.inspector_id
                              ? "selected"
                              : ""
                          }
                        >
                          {item.username}
                        </option>
                      );
                    })}
                </select>
              </div>
            ) : 
            inspections?.inspector_id ? (
              <p>{inspections?.inspector?.username}</p>
            ) : (
              <p>No Inspector</p>
            )}
          </div>
          <div className="col-4">
            <p>Status</p>
          </div>
          <div className="col-8">
            <div className="form-group">
              <p>{!inspections ? "" : inspections?.ass_status?.type}</p>
            </div>

            <ul className={"insured_progress"}>
              {statuses &&
                statuses.map((v, k) => {
                  if (inspections.suggestion_date) {
                    return (
                      <li
                        key={k + 1}
                        className={
                          "red_dot " +
                          (inspections.status_id === v.id ? " green_dot" : "")
                        }
                      />
                    );
                  }
                })}
            </ul>
          </div>

          <div className="col-4">
            <p>Report</p>
          </div>
          <div className="col-8">

            { inspections && inspections.inspection_forms && inspections.inspection_forms.map((value,key) => {
              return (
                <p onClick={() => this.showReportModal(key)} className={'report_url'}>#{value.report_id} </p>
              );
            })

            }
          </div>

 
          <NoteModal
                  title={"Source Notes"}
                  name={"notes"}
                  notes={inspections && inspections?.notes}
                  id={"ins_notes"}
                  inputEdit={this.state.inputEdit}
                  noteSave={this.noteSave}
                />
          
        </div>
        <Modal
          show={this.state.suggestedModal}
          onHide={this.handleClose}
          centered
          className="discover_modal_content log_entry_modal"
          dialogClassName="suggested_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Suggested Dates for Inspection</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={"suggested_content"}>
              
              <h5>{inspections && inspections.inspection_date ? (<span className="green_text">Inspection Date Approved by Client</span>) : ( "Pending Approval by Client" )}</h5>
              {dates &&
                Object.keys(dates).map((keyName, i, value) => (
                  <div className={"pending_approval"} key={i}>
                    <h4>{`Option ${i + 1} `}</h4>
                    <p>
                     
                        <Moment format="MM/DD/YYYY ">
                          {dates[keyName]}
                          </Moment>
                    </p>
                    <span>
                    <Moment format="hh:mm A">
                    {dates[keyName]}
                      </Moment>
                      
                    </span>
                  </div>
                ))}
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <ReportModal 
        report = {this.state.reportData} 
        inspection={inspections && inspections}  
        lead_body = {this.props.lead_body}
        show = {this.state.show_report} 
        toggleReportModal = {this.toggleReportModal}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    adjusters: state.leadsReducer.adjusters,
    statuses: state.leadsReducer.statuses,
    inspections: state.leadsReducer.inspections,
  };
}

export default connect(mapStateToProps, null)(InspectionInfo);
