import React, {useEffect, useState} from 'react';
import moment from "moment";
import {uploads_url} from "../../../../config";

import Modal from 'react-bootstrap/Modal';
import {saveAs} from "file-saver";

function  Message({message,own,contactName}) {

    const [userName,setUserName] = useState([])
    const [show, setShow] = useState(false);
    const [content, setContent] = useState('');

    const handleClose = () => {
        setContent('path')
        setShow(false)

    };
    const handleShow = (path) => {
        setContent(path)
        setShow(true);

    }

    const handleClick = (url,name)=>{
        saveAs(url,name );
    }

    useEffect(()=>{
       setUserName(message?.message_user?.first_name +' '+message?.message_user?.last_name  )
    })

    const IsJsonString =(str) => {
        try {

            let json = JSON.parse(str);
            return (typeof json === 'object');
        } catch (e) {
            return false;
        }
    }

const fullScreen = (path) => {
    console.log(path);
}

    return (
        <>
            <style>
                {
                `.modal-header {
                       justify-content: end
                }`
                }
            </style>
            <div className={own ?'incoming_msg':'outgoing_msg'}>
                <div className={own ?'received_msg':'sent_msg'}>
                    <div className={"received_withd_msg "+(own ?'':'w-100')} >
                        <p className={'received_message_time'}>{own ? userName :contactName}
                            <span className="time_date"> {moment(message.createdAt).format("hh:mm A")}  |  {moment(message.createdAt).format("MMM D")} </span>
                        </p>
                        {
                            IsJsonString(message?.message) ?
                                (
                                    <div>
                                        {'image' in JSON.parse(message?.message) && JSON.parse(message?.message).image.map(v=>(
                                            <div className={'chat_image'} onClick={()=>handleShow(v)}>
                                                <img src={uploads_url+'/uploads/'+v}/>
                                            </div>
                                            )
                                        )}

                                        {'file' in JSON.parse(message?.message) && JSON.parse(message?.message).file.map(v=>(
                                                <div className={'chat_file'}>
                                                    <i style={{fontSize:'25px'}} className={'apa-icon-claims'}></i>
                                                    <div>
                                                        <a href={uploads_url+'/uploads/'+v}>{v}</a>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                )
                                : (own ?
                                        <p className={'message_text message-blue'}>{message?.message} </p>
                                        :
                                        <div className={'box sb1'}>
                                            <p className={'message sent '}>{message?.message}</p>
                                        </div>
                                )
                        }


                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose}   size="lg">
                <Modal.Header closeButton >
                    <a  onClick={()=>handleClick(uploads_url+'/uploads/'+content,content)} style={{marginTop:'14px',cursor:'pointer',color:'#0056b3'}}>
                        Download</a>
                </Modal.Header>
                <Modal.Body>
                    <img style={{width:'100%',objectFit:"contain"}} src={uploads_url+'/uploads/'+content}/>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Message;