import {
    HIDEBEGINMODAL,
    OPENPROPERTYMODAL,
    SHOWBEGINMODAL,
    TOGGLESIDEBAR,
    GETSEARCHRESULT,
    GETEMAILTEMPLATES, UPDATESTATUS, GETCONVERSATIONBYLEAD
} from "../types";

const initialState = {
    recordBlob:null,
    windowWidth: window.innerWidth,
    lead_id:null,
    add_property_modal:false,
    begin_modal_show:false,
    toggled:false,
    emails:[]
}


export default function rootReducer(state = initialState,action){

    switch (action.type) {
        case "audioRecord":

            return {
                ...state,
                recordBlob:action.payload
            }

        case "resize_screen":

            return {
                ...state,
                windowWidth:action.payload
            }

        case OPENPROPERTYMODAL:

            return {
                ...state,
                add_property_modal:action.payload
            }


        case SHOWBEGINMODAL :   {
            return {
                ...state,
                begin_modal_show:action.payload
            }
        }


        case HIDEBEGINMODAL :   {
            return {
                ...state,
                begin_modal_show:action.payload
            }
        }


        case TOGGLESIDEBAR : {

            return {
                ...state,
                toggled:action.payload
            }
        }
        case GETSEARCHRESULT : {

            return {
                ...state,
                results:action.payload
            }
        }
        case GETEMAILTEMPLATES : {
            return {
                ...state,
                emails:action.payload
            }
        }

        default:{
            return state;
        }
    }

}