import React from "react";
import '../assets/css/add_lead.scss'
import {Button, Modal} from "react-bootstrap";




class DiscoveryModal extends React.Component{

    inputOnFocus = () =>  {
        if(this.nameInput){
            this.nameInput.classList.add('active_changes')
            return this.nameInput.focus()
        }

    }


    componentDidMount() {
       setInterval(() => {
           this.inputOnFocus();
       },1000)
    }

    render() {

        const {show,onHide,handleChange,handleSave,disc_value} = this.props;

        return(

            <Modal show={show} onHide={onHide} centered className="discover_modal_content" dialogClassName='discover_modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Discovery Description</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <textarea ref={(input) => { this.nameInput = input; }}  className={'steps_text_area'} name={'disc_desc'} value={disc_value} onChange={handleChange} /></Modal.Body>

                <Modal.Footer>

                    <Button variant="primary" onClick={handleSave}>Save</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}


export default DiscoveryModal;