import { getDate, checkNull, countDaysPassed, hrefLink,note,Reason} from '../Helper'

import React from "react";


export const columns = [
    {
        name: 'LIN',
        selector: row => row?.LIN,
        sortable: true,
    },
    {
        name: 'Insured Name',
        selector: row => checkNull(row?.contact?.first_name)+' '+checkNull(row?.contact?.last_name),
        sortable: true,
    },
    {
        name: 'Team',
        selector: row => row?.assignment?.team?.name,
        sortable: true,
    },
    {
        name: 'Category',
        selector: row => row?.category?.type,
        sortable: true,
    },
    {
        name: 'Lead PA',
        selector: row => '',
        sortable: true,
    },
    {
        name: 'Location',
        selector: row => checkNull(row?.loss_address?.city)+', '+checkNull(row?.loss_address?.state),
        sortable: true,
    },
    {
        name: 'Lead Source',
        selector: row => row?.source?.name,
        sortable: true,
    },
    {
        name: 'Peril',
        selector: row => row?.loss_info?.peril?.name,
        sortable: true,
    },
    {
        name: 'First Contact',
        selector: row => getDate(row?.first_contact),
        sortable: true,
    },
    {
        name: 'Initiated',
        selector: row => getDate(row.created_at),
        sortable: true,
    },
    {
        name: 'Reason Inactive',
        selector: row => Reason(row.created_at),
        sortable: true,
    },
    {
        name: '',
        selector: row => hrefLink(row),
        sortable: true,
    },
    {
        name: '',
        selector: row => note(row,'apa-icon-notes default_hover'),
        sortable: true,
    },
    {
        name: '',
        selector: row => note(row,'apa-support default_hover'),
        sortable: true,
    },


];

