import React, {useEffect, useState,useRef} from "react";
import { Button, Modal } from "react-bootstrap";



const NoteModal = (props) => {

    const values = {
        source_notes: "",
        notes: props.notes,
    };

    let intervalId;

    const [notesShow,setNotesShow] = useState(false)
    const [suggestedModal,setSuggestedModal] = useState(false)
    const [value,setValue] = useState('')
    const [newValue,setNewValue] = useState('')
    const inputEl = useRef(null);


    useEffect((name) => {

    },)



   const inputOnFocus = () => {
        if (inputEl.current) return  inputEl.current.focus();
    };

   const handleNoteValue = () => {
       console.log(1);
       intervalId = setTimeout(inputOnFocus, 600);
        setNotesShow(true)
        setValue(props.notes)

    };

    const handleChangeNotes = (event) => {
        clearInterval(intervalId);
        return (values.notes = event.target.value);
    };


    const handleSaveNotes = (name = "", key) => {


        let input = document.getElementById(props.id);
        input.value = inputEl.current.value;
        setNewValue(inputEl.current.value)

        props.noteSave(props.name,inputEl.current.value)
        setNotesShow(false)
    };


   const handleClose = (type = null) => {
       type ? setNotesShow(false) : setSuggestedModal(false)
    };


    return (
        <>
            <div className={"col-" + (props.col ? props.col : 4)}>
                <p>{props.title}</p>
            </div>

            <div className={"col-" + (props.col ? 12-props.col : 8)}>
                <input
                    type={"text"}
                    id={props.id}
                    value={newValue ? newValue : props.notes != false ? props.notes : '' }
                    disabled={!props.inputEdit}
                    name={props.name}
                    onFocus={(e) => {
                       handleNoteValue();
                        e.target.blur();
                    }}
                    className={props.inputEdit ? "edit_inputs" : ""}
                />
            </div>

            <Modal
                show={notesShow}
                onHide={handleClose.bind(this, "source_notes")}
                centered
                className="discover_modal_content"
                dialogClassName="discover_modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
              <textarea
                  ref={inputEl}
                  id="noter-text-area"
                  className={"steps_text_area"}
                  name="source_notes"
                  defaultValue={newValue ? newValue : value }
                  onChange={handleChangeNotes}
              />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSaveNotes}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default NoteModal;

