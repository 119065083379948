import React, {useEffect, useState} from "react";
import SideBar from "../header/SideBar";
import Navbar from "../header/Navbar";
import '../../assets/css/reports_styles.css';
import ReportByTerritory from "./ReportByTerritory";
import IncomingLeadsReport from "./IncomingLeadsReport";
import {Col, Nav, Row, Tab} from "react-bootstrap";
import Slider from "react-slick";
import {connect, useDispatch, useSelector} from 'react-redux'
import {
    findAll,
    getAllCities,
    getAllSources, getReportResultByDate,
} from "../../redux/actions";
import QuickOverview from "./QuickOverview";
import GlobalFilters from "./GlobalFilters";
import {ADJUSTER, TEAMS} from "../../redux/types";

import moment from "moment";
import incoming from "../Charts/Incoming";
import axios from "axios";
import {uploads_url, url} from "../../config";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";


const calendar_icon   = <i className={'apa-icon-calendar reports_calendar_icon'}/>

 let filter_result = {
     from :moment().subtract(7,'d').format('YYYY-MM-DD'),
     to:moment().format('YYYY-MM-DD'),
     inspected:{},
     incoming:{},
     claims:{},
     byTerritory: {},
     change_type:'',
     type:'',
 }

 let default_week = [moment().subtract(6,"day"),moment()]

function Reports(props){
    const dispatch = useDispatch()
    const [selectedDays,setSelectedDays] = useState(default_week)
    const [activeTab,setActiveTab] = useState('1')
    const [loading,setLoading] = useState(false)


    const result = useSelector(state =>state.leadsReducer.reportResult)


    useEffect(() => {
        dispatch(findAll('teams',TEAMS))
        dispatch(findAll('get-user-roles',ADJUSTER))
        dispatch(getAllSources())
        dispatch(getAllCities())
        filter_result.type=true
        dispatch(getReportResultByDate(filter_result))
    },[])

    const tabs =['Quick Overview','Incoming Leads','Inc. Leads by Territory','Inspected Leads','Signed Claims']

    const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3
        };


    const handleDateChange = (value) => {
        filter_result.type=false
        filter_result.from=value[0];
        filter_result.to=value[1];
        setSelectedDays([value[0],value[1]])
        dispatch(getReportResultByDate(filter_result))
    }

    const  handleChangeData = (event,type) => {
        const { name, value } = event.target;
        filter_result.change_type=type
        filter_result[type][name] = value;
        dispatch(getReportResultByDate(filter_result))

    };

    const tabSelect = (key) =>{
        setTimeout(function (){
            setActiveTab(key)
       },200)
    }

   const down = ()=> {

        filter_result['timezone'] = (new Date()).getTimezoneOffset()
       setLoading(true)
        axios
            .post(url + "/download-lead-full-report", filter_result)
            .then(res=>{
                window.open(uploads_url+"uploads/leadReportsFull/"+res.data.fileName, '_blank');
               setLoading(false)
            })
    }


    return(
            <>
                <style dangerouslySetInnerHTML={{__html: `
                  .tab-pane { padding: 28px 0px }
                `}} />

                {/*+ (toggled ? ' toggled' : '')*/}
                <div className={"d-flex"} id="wrapper">
                    <SideBar isAuth = {props.isAuth}/>
                    <div id="page-content-wrapper">
                        <div>
                            <Navbar />
                            <div className={'col-12'}>
                                <div className="row">
                                    <div className="col-lg-12 col-12">
                                        <div className={'page_title users_title'}>
                                            <div>
                                                <span className={'page_name'}>Reports</span>
                                                <div className={'leads_details_properties'} style={{float:'right',cursor:'pointer'}} onClick={down}>
                                                    <span className={'export_icon'}><i className={'apa-icon-export'}/>Export Data</span>
                                                    {loading ?  <div className={'spinner-grow text-primary'} style={{width:'1.3rem',height: '1.3rem',marginLeft:'7px'}}/> :''}
                                                </div>
                                                <div className={'general_date'}>
                                                    <DateRangePicker
                                                        onChange={ (e) => handleDateChange(e)}
                                                        value={selectedDays}
                                                        maxDate = {new Date()}
                                                        calendarIcon = {calendar_icon}
                                                        clearIcon = {null}
                                                        className = {'territory_date'}
                                                        format = {"MM/dd/y"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <Tab.Container onSelect={tabSelect} className = {"table_container"} id={'table_container'} activeKey={activeTab} >
                                <Row>
                                    <Col lg={12}>
                                        <Nav variant="pills" className="report_tabs">
                                            {tabs.map((v,k)=>{
                                                return (
                                                    <Nav.Item className={(k===2?'col-3':'col-2') +' messages_tabs desktop_tabs'} key={k}>
                                                        <Nav.Link eventKey={k+1}>{v}</Nav.Link>
                                                    </Nav.Item>
                                                )
                                            })}
                                        </Nav>
                                        <div className={'leads_details_mobile_slider'}>
                                            <Slider {...settings}>
                                                {tabs.map((v,k)=>{
                                                    return (
                                                        <div key={k}>
                                                            <Nav.Item className={'search_tabs new_leads leads_details_tabs'} >
                                                                <Nav.Link eventKey={k+1}>{v}</Nav.Link>
                                                            </Nav.Item>
                                                        </div>
                                                    )
                                                })}
                                            </Slider>
                                        </div>
                                    </Col>
                                    <Col lg={12} className = ''>
                                        <Tab.Content>
                                            {/*-----------------------DefaultActive -----------------*/}
                                            <Tab.Pane eventKey={1}>
                                                <QuickOverview/>
                                                <div className="row">
                                                    <div className="col-md-6 col-12">
                                                        <IncomingLeadsReport text={'Incoming Leads'}
                                                                             count={true}
                                                                             version={'incoming'}
                                                                             data={{
                                                                                     all:result?.incoming,
                                                                                     ins:result?.inspected,
                                                                                     days:result?.days
                                                                                    }}

                                                        />

                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <ReportByTerritory
                                                            handleDateChange={handleDateChange}
                                                            version={'byTerritory'}
                                                            data={result?.byTerritory}
                                                        />
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            {/*-----------------------End -----------------*/}

                                            {/*-----------------------Incoming Leads -----------------*/}
                                            <Tab.Pane eventKey={2}>
                                                <GlobalFilters handleChangeData={handleChangeData} type={'incoming'}/>
                                                <IncomingLeadsReport
                                                    text={'Incoming Leads'}
                                                    handleDateChange={handleDateChange}
                                                    version={'incoming'}
                                                    data={{
                                                        all:result?.incoming,
                                                        days:result?.days
                                                    }}
                                                />
                                            </Tab.Pane>
                                            {/*-----------------------End-----------------*/}

                                            {/*-----------------------Inspected Leads -----------------*/}
                                            <Tab.Pane eventKey={3}>
                                                <GlobalFilters handleChangeData={handleChangeData} type={'byTerritory'} version={'mini'}/>
                                                <ReportByTerritory
                                                    handleDateChange={handleDateChange}
                                                    version={'byTerritory'}
                                                    data={result?.byTerritory}

                                                />

                                            </Tab.Pane>
                                            {/*-----------------------End -----------------*/}

                                            {/*-----------------------Incoming Leads -----------------*/}
                                            <Tab.Pane eventKey={4}>
                                                <GlobalFilters handleChangeData={handleChangeData} type={'inspected'}/>
                                                <IncomingLeadsReport text={'Inspected Leads'}
                                                                     handleDateChange={handleDateChange}
                                                                     version={'inspected'}
                                                                     data={{
                                                                         all:result?.inspected,
                                                                         days:result?.days
                                                                     }}
                                                />
                                            </Tab.Pane>
                                            {/*-----------------------End -----------------*/}

                                            {/*-----------------------Signed Claims -----------------*/}
                                            <Tab.Pane eventKey={5}>
                                                <GlobalFilters handleChangeData={handleChangeData} type={'claims'}/>
                                                <IncomingLeadsReport type={true}
                                                                     text={'Signed Claims'}
                                                                     handleDateChange={handleDateChange}
                                                                     version={'claims'}
                                                                    data={result?.claims}
                                                />
                                            </Tab.Pane>
                                            {/*-----------------------End -----------------*/}
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </>
        )

}



export default connect(null,null)(Reports)