import React, {useEffect, useState } from "react";
import SideBar from "../header/SideBar";
import Navbar from "../header/Navbar";
import {Col, Nav, Row, Tab} from "react-bootstrap";
import {NavLink,useLocation} from "react-router-dom";
import AddLead from "../AddLead";

import PropertiesTable from "./PropertiesTable";
import {connect, useDispatch, useSelector} from 'react-redux'
import LeadsTable from "../LeadsTable";
import AddPropertyModal from "./AddPropertyModal";
import {addPropertyModal, checkAuth, getClientLead, getCurrentClient, getCurrentLead} from "../../redux/actions";
import axios from "axios";
import {url} from "../../config";

import ContactInfo from "../clientOptions/ContactInfo";
import PropertiesInfo from "./PropertiesInfo";
import DocFile from "./DocFiles";
import Documents from "../leadDetails/Documents/Documents";
import LossAddress from "../clientOptions/LossAddress";

import Claims from "../Claims";
import AssignedLeads from "../leads/AssignedLeads";


function ClientInfo(props){
    const location = useLocation()
    const currentClient = useSelector (state => state.leadsReducer.currentClient)
    const dispatch = useDispatch()

    const [show,setShow] = useState(false)

    const [contact,setContact] = useState([])
    const [slider_key,setSlider_key] = useState()


    useEffect(() => {
        dispatch(getCurrentClient(props.match.params.contactId))
    },[])


   const myCallback = (dataFromChild) => {
        setShow(dataFromChild)
    }

    const updateState = () => {
        dispatch(getCurrentClient(props.match.params.contactId))
    }


   const addLead = () => {
       setShow(true)
    }

    const {toggled} = props;


    if(!currentClient) return  null


    return(

        <>
            <div className={"d-flex" + (toggled ? ' toggled' : '')} id="wrapper">
                <SideBar isAuth = {props.isAuth}/>
                <div id="page-content-wrapper">
                    <div>
                        <Navbar toggled = {myCallback} />


                        <div className="row">
                            <div className="col-lg-5 col-6">

                                <div className={'leads_details_title social'}>
                                    <h3>Clients / { currentClient?.insured_name} / #{currentClient?.contacts[0]?.contact_number}</h3>
                                </div>

                            </div>



                            <div className="col-lg-7 col-6 leads_details_properties contact_info_properties">

                                <ul className={'d_none'}>
                                    <li  onClick={() => {props.dispatch(addPropertyModal(true))}}>
                                        <span><i className={'apa-icon-property'}></i>Add Property</span>
                                    </li>

                                    <li onClick={addLead}>
                                        <span><i className={'apa-icon-leads'}></i>Add Lead</span>
                                    </li>



                                    {/*<li id = {'rotate_icon'} >*/}
                                    {/*    <NavLink to = {'/contacts'}>*/}
                                    {/*        <i className={'apa-back'}></i>*/}
                                    {/*    </NavLink>*/}
                                    {/*</li>*/}
                                </ul>


                                <ul className={'mobile_properties'}>
                                    <li>
                                        <span><i className={'apa-icon-messaging'}></i>Begin Inspection</span>
                                    </li>

                                    <li>
                                        <span><i className={'apa-icon-messaging'}></i>Reschedule</span>
                                    </li>
                                </ul>


                            </div>

                        </div>

                        <Tab.Container className = {"table_container"} id={'table_container'} defaultActiveKey="first" onSelect={key => setSlider_key(key)} >
                            <Row >
                                <Col lg={12} >

                                    <Nav variant="pills" className={""}>
                                        <Nav.Item  className={' col-md col-12 search_tabs new_leads leads_details_tabs  '}>
                                            <Nav.Link eventKey="first">Contact Info</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className={' col-md col-12 search_tabs new_leads leads_details_tabs  '}>
                                            <Nav.Link eventKey="second">Properties</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item className={' col-md col-12  search_tabs new_leads leads_details_tabs  '}>
                                            <Nav.Link eventKey="third">Leads</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item className={' col-md col-12 search_tabs new_leads leads_details_tabs  '}>
                                            <Nav.Link eventKey="fourth">Claims</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className={' col-md col-12 search_tabs new_leads leads_details_tabs  '}>
                                            <Nav.Link eventKey="five">Documents</Nav.Link>
                                        </Nav.Item>


                                    </Nav>


                                    <div className={'leads_details_mobile_slider'}>


                                        {/*<Slider {...settings}>*/}

                                        {/*    <Nav.Item className={' search_tabs new_leads leads_details_tabs' + (this.state.slider_key === 'first' ? ' active_slider' : '')}>*/}
                                        {/*        <Nav.Link eventKey="first">Basic Info</Nav.Link>*/}
                                        {/*    </Nav.Item>*/}

                                        {/*    <div>*/}
                                        {/*        <Nav.Item className={' search_tabs new_leads leads_details_tabs' + (this.state.slider_key === 'second' ? ' active_slider' : '')}>*/}
                                        {/*            <Nav.Link eventKey="second">Loss Info</Nav.Link>*/}
                                        {/*        </Nav.Item>*/}
                                        {/*    </div>*/}
                                        {/*    <Nav.Item className={'search_tabs new_leads leads_details_tabs' + (this.state.slider_key === 'third' ? ' active_slider' : '')}>*/}
                                        {/*        <Nav.Link eventKey="third">Loss Address</Nav.Link>*/}
                                        {/*    </Nav.Item>*/}

                                        {/*    <div>*/}
                                        {/*        <Nav.Item className={' search_tabs new_leads leads_details_tabs' + (this.state.slider_key === 'fourth' ? ' active_slider' : '')}>*/}
                                        {/*            <Nav.Link eventKey="fourth">Assignment & Inspection Info</Nav.Link>*/}
                                        {/*        </Nav.Item>*/}
                                        {/*    </div>*/}
                                        {/*    <div>*/}
                                        {/*        <Nav.Item className={' search_tabs new_leads leads_details_tabs' + (this.state.slider_key === 'five' ? ' active_slider' : '')}>*/}
                                        {/*            <Nav.Link eventKey="five">Documents</Nav.Link>*/}
                                        {/*        </Nav.Item>*/}
                                        {/*    </div>*/}
                                        {/*    <div>*/}
                                        {/*        <Nav.Item className={' search_tabs new_leads leads_details_tabs' + (this.state.slider_key === 'six' ? ' active_slider' : '')}>*/}
                                        {/*            <Nav.Link eventKey="six">Log Entries</Nav.Link>*/}
                                        {/*        </Nav.Item>*/}
                                        {/*    </div>*/}


                                        {/*</Slider>*/}
                                    </div>
                                </Col>

                                <Col lg={12}>

                                    <Tab.Content>

                                        <Tab.Pane eventKey="first">

                                            <div className="row">
                                                <div className="col-md-6 col-12 leads_details_content">
                                                    <ContactInfo contact_id ={currentClient?.contacts[0]?.id} />
                                                </div>
                                                <div className="col-md-6 col-12 leads_details_content">
                                                    <LossAddress  address_id={currentClient?.contacts[0]?.loss_addresses[0]?.id} general={true} />
                                                </div>

                                            </div>

                                        </Tab.Pane>


                                        <Tab.Pane eventKey="second">
                                            <div className="row">
                                                <div className=" col-12">


                                                    {props.match.params.id
                                                        ? <PropertiesInfo  />
                                                        :   <PropertiesTable  data = {currentClient?.loss_addresses} type={true}/>
                                                    }
                                                </div>

                                            </div>
                                        </Tab.Pane>


                                        <Tab.Pane eventKey="third">
                                            <div className="row">
                                                <div className=" col-12">
                                                    <LeadsTable leads_new_data = {currentClient?.leads} type={true}/>
                                                </div>
                                            </div>
                                        </Tab.Pane>


                                        <Tab.Pane eventKey="fourth">
                                            <div className="row">
                                                <div className=" col-12">
                                                    <Claims type={true} data = {currentClient?.signed_claims}/>
                                                </div>
                                            </div>


                                        </Tab.Pane>

                                        <Tab.Pane eventKey="five">


                                            {props.match.params.docId
                                                ?  <Documents leadId = {props.match.params.docId} type={true} proper={location.state}/>
                                                : <DocFile data = {currentClient?.leads}/>
                                            }

                                        </Tab.Pane>


                                        <Tab.Pane eventKey="six">
                                            {/*<LogEntry/>*/}
                                        </Tab.Pane>

                                    </Tab.Content>
                                </Col>


                            </Row>
                        </Tab.Container>


                    </div>

                </div>

                {/*<button onClick={handleCallModal} className="call_button button-small">*/}
                {/*    <span className="label-hidden">Initate Call</span>*/}
                {/*    <i className="apa-icon-phone call"></i>*/}

                {/*</button>*/}
            </div>

            {/*------Modals------*/}

            {/*<LogEntryModal show = {this.state.show} modalCallBack = {this.modalCallBack} />*/}
            {/*<SendMessageModal show = {this.state.send_message} modalCallBack = {this.modalCallBack}/>*/}
            {/*<AssignModal show = {this.state.assign_show} modalCallBack = {this.modalCallBack} />*/}
            {/*<ScheduleInspection show = {this.state.schedule_modal}  modalCallBack = {this.modalCallBack} />*/}
            {/*<SignClaim show = {this.state.signClaimModal} modalCallBack = {this.modalCallBack} />*/}
            {/*<Inactive show = {this.state.inactive_modal} modalCallBack = {this.modalCallBack} />*/}
            {/*<CallModal handleClose = {this.handleClose}  call_show = {this.state.call_show} />*/}

            <AddLead show = {show} myCallback = {myCallback} type={props.match.params.contactId} contact={contact}/>

            <AddPropertyModal contact_id={props.match.params.contactId} myCallback = {updateState} />

        </>
    )
}

function mapStateToProps(state) {

    return{
        leads:state.leadsReducer.leads,
    }

}



export default connect(mapStateToProps,null)(ClientInfo);



