import React, {useEffect, useState} from "react";
import SideBar from "./header/SideBar";
import Navbar from "./header/Navbar";
import EmailTable from "./EmailTable";
import {useDispatch, useSelector} from 'react-redux';
import { getEmails} from "../redux/actions";

function Email(props){

    const dispatch = useDispatch()
    const toggled = useSelector(state =>state.appReducer.toggled)
    const emails = useSelector(state =>state.appReducer.emails)

    useEffect(() => {
        dispatch(getEmails())
    },[])



    return(
        <>
            <div className={"d-flex" + (toggled ? ' toggled' : '')} id="wrapper">
                <SideBar isAuth = {props.isAuth}/>
                <div id="page-content-wrapper">
                    <div>
                        <Navbar/>
                        <div className={'col-12'}>
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <div className={'page_title users_title'}>
                                        <h2>Email</h2>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12 leads_content">
                                    <div className={'leads_search'}>
                                        <button className={'default_hover'} onClick={props.handleAddUser}><i className={'apa-icon-search'}></i>Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <EmailTable emails={emails}/>
                    </div>

                </div>
            </div>
        </>
    )
}

export default(Email);