import React from "react";
import {Button, Modal} from "react-bootstrap";





function CallModal ({handleClose,call_show,handleCall,}) {
        return (
            <>
                <Modal show={call_show} onHide={handleClose} centered className="discover_modal_content"
                       dialogClassName='discover_modal call_modal'>
                    <Modal.Body style={{margin: '0 auto'}}>
                        <div>
                            <Button className={'add_lead_next_button video_call call_buttons'}
                                    onClick={()=>handleCall('audio')}>Voice Call</Button>
                            <Button className={'add_lead_next_button video_call call_buttons '}
                                    onClick={()=>handleCall('video')}>Video call
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
}


export default CallModal;