import React from "react";
import '../assets/css/add_lead.scss'
import AddSteps from "./AddSteps";
import {Modal} from 'react-bootstrap'
import {connect} from 'react-redux'



class AddLead extends React.Component {
    constructor(props) {

        super(props);

        this.state = {
            show: '',
            currentStep: 0,
            checked:false,
            slide_length:1,
            window_width:' col-7',

        }


    }


    componentDidMount() {

        if(this.props.page_width < 991){
            this.setState({
                window_width: ' col-12',
            })
        }


    }

    handleClose = () => {
    this.props.myCallback(false);

    }


    myCallback = (dataFromChild,slide_count) => {

        this.setState({
            currentStep: dataFromChild,
            slide_length:slide_count

        })

    }

    render() {
        const {page_width} = this.props;

        return (
            <>
                <div style={{backgroundColor: '#F9F9F9'}}>

                        <Modal show={this.props.show} onHide={this.handleClose} className = "fullScreen_modal">
                            <Modal.Header >
                                <Modal.Title></Modal.Title>
                                <i className={'apa-icon-close default_hover'} onClick={this.handleClose}></i>
                            </Modal.Header>
                            <Modal.Body>
                                    <div className={"middle_content mid_col add_lead_col" + (page_width > 991 ?  
                                        (this.state.currentStep >= 3 &&  this.state.currentStep != 2) || (this.state.currentStep == 2 && this.state.slide_length > 1)  ? ' col-10' : this.state.window_width : ' col-12')}>
                                        <AddSteps callbackFromParent={this.myCallback} type={this.props.type} contact={this.props.contact} />
                                    </div>
                            </Modal.Body>

                        </Modal>
                    </div>
            </>
        )
    }
}



function mapStateToProps(state){
    return{
        page_width:state.appReducer.windowWidth
    }
}


export default connect(mapStateToProps,null)(AddLead);

