import React from "react";
import {Button, Modal} from "react-bootstrap";
import axios from "axios";
import {url} from "../config";

let arr = [];
let perils = [];
let newArr = [];
let unique = []


class PerilOptionsEdit extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            propsArray: [],
            inputValue: '',
            inputKey: [],
            deleted:false

        }
    }

    handleChange(e) {
        arr = this.props.manulAdd;
        arr[e.target.id] = e.target.value
    }


    handleDelete = (id) => {

        let data = {}
        data.id = id

        if(this.props.name === 'Carrier'){
            return  axios.post(url + 'destroy-carrier-type', data)
                .then(res => {
                    //this.props.periListDataFetch();
                    this.props.getAllCarriers()
                })
                .catch(err => {
                    console.log(err)
                })
        }

        if(this.props.name === "Teams"){

            return axios.post(url + 'destroy-team', data)
                .then(res => {
                    //this.props.periListDataFetch();
                    this.props.handleUpdate();
                    this.props.getAllTeams();
                })
                .catch(err => {
                    console.log(err)
                })
        }

        axios.post(url + 'destroy-loss-info', data)
            .then(res => {
              this.props.periListDataFetch();
            })
            .catch(err => {
                console.log(err)
            })
    }


    perilChange = (event) => {

        const {id,value} = event.target;

        function checkExist(peril) {
            return peril === id;
        }

        if (!unique.find(checkExist)) {
            let newData = {
                id: id,
                name: value
            }
            perils.push(newData)
        } else {
            perils.map((v) => {

                if (v.id === id) {
                    v.name = value
                }


                return value;

            })
        }
        if (newArr.length > 0) {
            newArr.map((elem) => {
                if (elem !== id) {
                    newArr.push(id)

                }

                return true
            })
        } else {
            newArr.push(id)
        }

        unique = newArr.filter((v, i, a) => a.indexOf(v) === i);



    }


    handleSaveData = () => {

        // if(arr.length > 0){
        //     this.props.handleDeletePeril(arr);
        //     this.props.onHide();
        // }

        if(this.props.name === "Carrier"){

            return axios.post(url + 'update-carrier-types', {carriers : perils})
                .then(res => {

                     this.props.onHide();
                     return this.props.periListDataFetch()
                })
                .catch(err => {
                    console.log(err)
                })
        }


        if(this.props.name === "Teams"){
            return  axios.post(url + 'update-team', {teams : perils})
                .then(res => {
                    this.props.getBasicInfoData();
                   return  this.props.onHide();
                })
                .catch(err => {
                    console.log(err)
                })
        }

      return   axios.post(url + 'update-loss-info', {perils : perils})
            .then(res => {
                this.props.periListDataFetch();
                this.props.onHide();
            })
            .catch(err => {
                console.log(err)
            })


    }

    render() {

        const {show, onHide, manulAdd, perilList,name} = this.props;

        return (
            <Modal show={show} onHide={onHide} centered className="discover_modal_content peril_modal peril_list "
                   dialogClassName='discover_modal'>
                <Modal.Header closeButton>
                    <span className={'peril_edit_save'} onClick={this.handleSaveData}>Save</span>
                    <Modal.Title>{name} Options</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {
                       perilList &&  perilList.map((i, k) => {

                            return (
                                i.type === 'manual' &&
                                <div className={'remove_peril'} key={k}>
                                    <input data-id = {i.k} id = {i.id} defaultValue={i.name} readOnly={i.type !== "manual"} onChange={this.perilChange}/>
                                    <i className={'apa-icon-delete delete_icon'} onClick={this.handleDelete.bind(this,i.id)}/>
                                </div>

                                )

                        })
                    }


                    {manulAdd && manulAdd.map((item, key) => {

                        return (

                            <input type={'text'} defaultValue={item} id={key} key={key} onChange={this.handleChange}/>


                            )})
                    }

                    <button className={'peril_options_add_more'} onClick={onHide.bind(this,'perilEdit')}><i className={'apa-icon-add '}></i> <span>Add More</span></button>
                </Modal.Body>

                <Modal.Footer>

                    <Button variant="primary" onClick={this.handleSaveData}>Save</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}


export default PerilOptionsEdit;