import React from "react";
import axios from "axios";
import {url} from "../../config";
import {Alert} from "react-bootstrap";


class Account extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            confirm_pass_error:'',
            back_errors:'',
            password_length:'',
            success_show:false
        }
    }


    handlePassValues = (event) => {
        event.preventDefault();

        const {name,value} = event.target;

        this.setState({
            [name]:value,
            confirm_pass_error:''
        })

    }


    changePass = (event) => {
        const data = {};
        event.preventDefault();

        if(this.state.new_pass.trim().length >=  8 ){

        if(this.state.new_pass === this.state.confirm_pass){

            data.old_pass = this.state.current_pass;
            data.new_pass = this.state.new_pass;


            axios.post(url + 'change-password', data)
                .then(res => {
                    console.log(res, 'Change');
                    this.setState({
                        success_show:true
                    })

                    return setInterval(() => {
                        this.setState({
                            success_show:false
                        })
                    },2000)
                })
                .catch(err => {
              this.setState({
                  back_errors:err.response.data.message
              })
            })
        }else {
            this.setState({
                confirm_pass_error:'Passwords do not match'
            })

        }

        }else {
            this.setState({
                password_length:'Password must be 8 characters or longer.'
            })
        }
    }

    render() {
        const {success_show,confirm_pass_error,back_errors,password_length} = this.state;
        return(
            <form onSubmit={this.changePass}>


                <div className="container">
                    <div className={'change_pass_title'}>
                        <h3>Change Password</h3>
                    </div>


                    <Alert show={success_show} variant="success">
                        <Alert.Heading>Password is successfully updated!</Alert.Heading>

                    </Alert>

                    <div className="row">
                        <div className="col-lg-5 col-6  change_pass_content">
                            <p>Current Password</p>
                            <p>New Password</p>
                            <p>Confirm New Password </p>
                        </div>
                        <div className="col-lg-7 col-6 change_profile_inputs change_pass-inputs">

                            <input type={'password'} name={'current_pass'} onChange={this.handlePassValues} autoComplete = {'false'}/>


                            <input className={confirm_pass_error && ' error_input'} type={'password'} required={true} name={'new_pass'} onChange={this.handlePassValues }   autoComplete = {'false'} />
                            <div className={'pass_validate'}>
                                <span>Password must be 8 characters or longer.</span>
                            </div>

                            {confirm_pass_error && <p style={{color:'red',marginTop:"15px"}}>{confirm_pass_error}</p>}

                            <input type={'password'} className={confirm_pass_error && ' error_input'} name={'confirm_pass'} onChange={this.handlePassValues}  autoComplete = {'false'}/>
                        </div>
                    </div>
                    {back_errors && <p style={{color:'red'}}>{back_errors}</p>}
                    {password_length && <p style={{color:'red'}}>{password_length}</p> }

                    <button className={'setting_modal_button default_hover'} type={'submit'}>
                        Save Changes
                    </button>
                </div>
            </form>
        )
    }
}

export default Account;