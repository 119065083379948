import React, {useEffect, useRef, useState} from "react";
import { io } from "socket.io-client";
import axios from "axios";
import {url} from "../config";
import {useDispatch, useSelector} from "react-redux";

export default function Test(props){
    const socket = useRef()
    const [user,setUser] = useState([])




    useEffect(()=>{

        axios.get(url + 'get-auth-user-profile', {
        })
            .then(res => {
                setUser(res.data)
            })

        socket.current= io('ws://localhost:8900')

        socket.current.on('getData',data=>{
            console.log(data);
        })

        if(user.id){
            socket.current.emit('sendData',user.id)
        }

    },[user.id])

    useEffect(()=>{
        axios.get(url+'/twilio',{}).then(res=>{

        })

    },[])
    // function factorialize(num) {
    //     return num > 0 && ( num * factorialize(num - 1)) || 1
    // }
    // console.log(factorialize(4));


    let names = ["enlists", "google", "inlets", "banana"];
    let word = "listen";
    let num = 0
    for (let i = 0; i < names.length; ++i) {
            if(names[i].length !== word.length){

            }else {

            }
    }

    console.log(word.includes(names));



    return (
            <>
            </>
        )
}