import React, {useEffect} from 'react';
import { Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import MobileTable from './MobileTable';
import {connect, useDispatch, useSelector} from 'react-redux';
import Pagination from 'react-js-pagination';
import { Link } from 'react-router-dom';
import { getLeads } from '../redux/actions';
import { url } from '../config';
import axios from 'axios';
import LogEntryModal from "./leadDetails/LogEntryModal";
import Moment from 'react-moment';
import moment from 'moment'
import Basic from "./DataTables/Basic";
import {columns} from "./DataTables/Tables/ActiveLeads";
let lead = []


let new_columns_assign

function LeadsActiveTable  (props)  {


  const [show, setShow] = React.useState(false);
  const [activePage, setActivePage] = React.useState(1);
  const [status_progress, setStatus_progress] = React.useState([1, 2, 3, 4, 5, 6, 7, 8, 9]);
  const [incomingLeads, setIncomingLeads] = React.useState([]);
  const [lead_body, setLead_body] = React.useState([]);
  const [updated, setUpdated] = React.useState(false);
  const [lead_body_new, setLead_body_new] = React.useState(false);

  const windowWidth = useSelector (state => state.appReducer.windowWidth)
  const leads_data = useSelector (state => state.leadsReducer.leads_data)
  const search = useSelector (state => state.leadsReducer.search)
  const lead_count = useSelector (state => state.leadsReducer.lead_count)
  const pagination_count = useSelector (state => state.leadsReducer.pagination_count)

  const dispatch = useDispatch()

  useEffect(() => {
    if(props.leads_new_data){
      setLead_body_new(props.leads_new_data)
    }
    dispatch(getLeads());
    getLeadsInfo();
    new_columns_assign =columns(addLogEntry)
  },[props.leads_new_data])


   const addLogEntry = (item) => {
     setLead_body([item])
     setShow(true)
  }


  const myCallback = (dataFromChild) => {
    if(dataFromChild){
      setLead_body('')
      setUpdated(dataFromChild)
    }
}

  const getLeadsInfo = () => {
    axios
      .get(url + 'leads')
      .then((res) => {
        setIncomingLeads(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const modalCallBack = (parentDate,name = '') => {
    setShow(parentDate)

  }

  const {leads_new_data} = props

  if(lead_body_new){
    lead= lead_body_new
  }
  else{
    lead = leads_data
  }

    return (

      <>
      <div>
        <div className={'lead_table table-responsive new_lead_table showOnDesktop needAssignLead activeNeedLead'}>
          <Basic columns={new_columns_assign} data={lead}/>
          <LogEntryModal lead_body = {lead_body} show = {show} myCallback = {myCallback} modalCallBack = {modalCallBack}/>
        </div>
        {windowWidth <= 991 && <MobileTable leads_data={leads_data} />}
        </div>
      </>
    );

}


export default connect(null, null)(LeadsActiveTable);
