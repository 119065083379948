import React, {useEffect, useState} from "react";
import {Table} from "react-bootstrap";
import Contacts from "./DataTables/Tables/Contacts";
import Basic from "./DataTables/Basic";
import byTerritory from "./Charts/ByTerritory";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import {url} from "../config";




function ContactsTable(props){
    const [clients,setClients] = useState([])


    useEffect(() => {
            getClients()
    },[])

  const getClients = () => {
      axios.get(url + '/get-clients-contact')
          .then(res => {setClients(res.data)})
  }


    return (
        <>
            <div className={'lead_table table-responsive d_none'}>
                <Basic columns={props.type ? Contacts('contact') : Contacts() }
                       data={clients ? clients : [] }/>
            </div>
        </>
    )

}



export default ContactsTable










