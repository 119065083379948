import React, {useEffect, useRef, useState} from "react";
import {Button, Modal, Spinner} from "react-bootstrap";
import {connect, useDispatch, useSelector} from "react-redux";
import {findAll} from "../../redux/actions";
import {ADJUSTER} from "../../redux/types";
import Select from "../Inputs/Select";
import moment from "moment";
import DatePicker from "react-datepicker";
import axios from "axios";
import {url} from "../../config";
import { Editor } from '@tinymce/tinymce-react';
const sign_info = {
    hidden:{},
    options:{}
};

function SignClaim(props){

    const adjusters = useSelector (state => state.leadsReducer.adjusters)

    const dispatch = useDispatch()

    const [dateValue,setDateValue] = useState(false)
    const [error,setError] = useState(false)
    const [isParsed,setIsParsed] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);
    const [disclosure, setDisclosure] = useState('');
    const [Notice_of_Representation, setNotice_of_Representation] = useState('');
    const [Public_Adjuster_Contract, setPublic_Adjuster_Contract] = useState('');
    const [isLoading, setisLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const fee_options =[
        {type:'50%'},
        {type:'45%'},
        {type:'40%'},
        {type:'35%'},
        {type:'30%'},
        {type:'25%'},
        {type:'Other'},
    ]

    useEffect(() => {

        dispatch(findAll('get-user-roles',ADJUSTER))
        if(props && props.lead_body[0] && props.lead_body[0].signed_claim) {
            let ops = props.lead_body[0].signed_claim;
            sign_info.hidden.main_id = ops.id;
            sign_info.options.claim_id= ops.client_id;
            sign_info.options.fee_rate= ops.fee_rate
            sign_info.options.assign_id= ops.assign_id
            sign_info.options.signed_date= ops.signed_date
            sign_info.options.signed_notes= ops.signed_notes
            if(ops.additional_emails && ops.additional_emails != "") {
                sign_info.options.additional_emails= ops.additional_emails.join(',')
            }
            console.log(sign_info);
            let row =  adjusters.users.filter(item => item.id == ops.assign_id)
            sign_info.hidden['email'] = row[0]?.email
            setIsUpdate(true)
        }
      },[props])

   const handleHideModal = () => {
        props.modalCallBack(false,'signClaim')

       setError(false)
    }
    const handleChangeData = (event) => {
        const {name, value} = event.target;
        sign_info.options[name] = value;
        if(name ==='claim_id'){
            let row =  adjusters.users.filter(item => item.id == value)
            sign_info.hidden['email'] = row[0]?.email
        }
        if(name ==='assign_id' && value !== ''){
            let row =  adjusters.users.filter(item => item.id == value)
            sign_info.hidden['assigned_email'] = row[0]?.email
        }

    }

   const dateSave = (name, value) => {
        sign_info.options[name] = value;
        setDateValue(value)
    };
    const next = () => {
        if(currentPage===1 && isParsed == 0) {
            axios.post(url + "contracts", {lead:props.lead_body[0],sign_info:sign_info}).then(res => {
                setDisclosure(res.data.Disclosure)
                setNotice_of_Representation(res.data.NoticeOfRepresentation)
                setPublic_Adjuster_Contract(res.data.adjusterContract)
                setIsParsed(1)
            })
        }
        setCurrentPage((prev) => prev + 1)
    };
    const prev = () => setCurrentPage((prev) => prev - 1);

    const handleSave = () => {
        setisLoading(true)
        sign_info.options['lead_id'] = props.lead_body[0]?.id
        sign_info.options['client_id'] = props.lead_body[0]?.client?.id
        sign_info.hidden['contact_email'] = props.lead_body[0]?.contact?.email
        sign_info.hidden['claim_id'] = props.lead_body[0]?.claims_folder_id
        sign_info.hidden['tmp_folder_id'] = props.lead_body[0]?.tmp_folder_id
        sign_info.hidden['disclosure_html'] = disclosure;
        sign_info.hidden['nor_html'] = Notice_of_Representation;
        sign_info.hidden['PA_contract'] = Public_Adjuster_Contract;
        if(Object.keys(sign_info.options).length >3){
            setError(false)
            let endpoint = "sign-claim"
            if(isUpdate === true) {
                endpoint = "resign-claim";
            }
            axios.post(url + endpoint, sign_info).then(res => {
                props.myCallback(false)
                handleHideModal()
            })
        }
        else{
            setError(true)
        }


    }
    const handleDisclosure = (value, editor) => {
        setDisclosure(value);
    };
    const handleNotice = (value, editor) => {
        setNotice_of_Representation(value);
    };
    const handlePAContract = (value, editor) => {
        setPublic_Adjuster_Contract(value);
    };

    const {show} = props;

    return(

            <Modal  size={currentPage > 1 ? 'xl':'md'} show={show} onHide = {handleHideModal} centered className="discover_modal_content log_entry_modal send_message_modal sign_claim_modal" dialogClassName='discover_modal'>

                <style dangerouslySetInnerHTML={{__html: `
                      .react-datepicker-wrapper:after { top: 0px !important }
                    `}} />

                <Modal.Header closeButton>
                    {currentPage === 1 && (
                        <Modal.Title>Sign Claim</Modal.Title>
                    )}
                    {currentPage === 2 && (
                        <Modal.Title>Disclosure</Modal.Title>
                    )}
                    {currentPage === 3 && (
                        <Modal.Title>Notice of Representation</Modal.Title>
                    )}
                    {currentPage === 4 && (
                        <Modal.Title>Public Adjuster Contract</Modal.Title>
                    )}
                </Modal.Header>
                {currentPage === 1 && (
                <Modal.Body>

                    <h4 className={'details_modal_titles'}>{props?.lead_body && props?.lead_body[0]?.client.contacts[0].first_name} {props?.lead_body && props?.lead_body[0]?.client.contacts[0].last_name}</h4>
                    <div className="row">

                        <div className="col-4"><p>Claim Signer *</p></div>
                        <div className="col-8 ">
                            <div className="form-group selectdiv  select_content">
                            <select
                                name={"claim_id"}
                                onChange={handleChangeData}
                                className={`form-control alterationTypeSelect default_select`}
                            >
                                <option value="{null}"></option>
                                {adjusters && adjusters.users.map((item, index) => {
                                    return (
                                        <option
                                            selected={
                                                props.lead_body[0] && props.lead_body[0].signed_claim && props.lead_body[0].signed_claim.claim_id == item.id? "selected" : ""
                                            }
                                            value={item.id}
                                            key={item.id}
                                        >
                                            {item.first_name} {item.last_name}
                                        </option>
                                    );
                                })}
                            </select>
                            </div>
                        </div>
                        <div className="col-4"><p>Fee Rate *</p></div>
                        <div className="col-8">

                            <div className="form-group selectdiv  select_content">
                                <select
                                    name={"fee_rate"}
                                    onChange={handleChangeData}
                                    className={`form-control alterationTypeSelect default_select`}
                                >
                                    <option value="{null}"></option>
                                    {fee_options && fee_options.map((item, index) => {
                                        return (
                                            <option
                                                selected={
                                                    props.lead_body[0] && props.lead_body[0].signed_claim && props.lead_body[0].signed_claim.fee_rate == item.type? "selected" : ""
                                                }
                                                value={item.type}
                                                key={item.type}
                                            >
                                                {item.type}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="col-4"><p>Assign to</p></div>
                        <div className="col-8">
                            <div className="form-group selectdiv  select_content">
                                <select
                                    name={"assign_id"}
                                    onChange={handleChangeData}
                                    className={`form-control alterationTypeSelect default_select`}
                                >
                                    <option value="{null}"></option>
                                    {adjusters && adjusters.users.map((item, index) => {
                                        return (
                                            <option
                                                selected={
                                                    props.lead_body[0] && props.lead_body[0].signed_claim && props.lead_body[0].signed_claim.assign_id == item.id ? "selected" : ""
                                                }
                                                value={item.id}
                                                key={item.id}
                                            >
                                                {item.username}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="col-lg-4 col-4">
                            <p>Signed date *</p>
                        </div>
                        <div className="col-lg-8 col-8">
                            <div className={"details_date_input_content"}>

                                <DatePicker
                                    className={"details_date_inputs"}
                                    onChange={(e) => dateSave('signed_date', e )}
                                    selected={dateValue ? dateValue : props.lead_body[0] && props.lead_body[0].signed_claim && props.lead_body[0].signed_claim.signed_date ? new Date(props.lead_body[0].signed_claim.signed_date) : ''}
                                />
                            </div>
                        </div>
                        <div className="col-4"><p>Signed Notes *</p></div>
                            <div className="col-8 textarea_border">
                                <textarea onChange={handleChangeData} name={'signed_notes'} defaultValue={props.lead_body[0] && props.lead_body[0].signed_claim && props.lead_body[0].signed_claim.signed_notes} className={'steps_text_area'}></textarea>
                            </div>

                        <div className="col-4 mt-2"><p>Additional Emails <small>comma (,) separated</small></p></div>

                        <div className="col-8 mt-2 textarea_border">
                            <textarea onChange={handleChangeData} name={'additional_emails'} className={'steps_text_area'} defaultValue={props.lead_body[0] && props.lead_body[0].signed_claim && props.lead_body[0].signed_claim.additional_emails && props.lead_body[0].signed_claim.additional_emails != "" ? props.lead_body[0].signed_claim.additional_emails.join(',') : ''  }></textarea>
                        </div>
                    </div>


                    {error ? <span className={'req_error'}>Please add all required inputs</span> : ''}
                </Modal.Body>
                )}

                {currentPage === 2 && (
                    <Modal.Body className={"editor_content"}>
                               <Editor
                                   apiKey='wwzu5v6vk2usoswhk1n05w1p3e1cqy9jqskidd7r6cibzjkt'
                                    value={disclosure}
                                    init={{
                                        plugins: 'link image code',
                                        toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code',
                                        height: 650
                                    }}
                                    onEditorChange={ handleDisclosure}
                                />

                    </Modal.Body>
                )}

                {currentPage === 3 && (
                    <Modal.Body className={"editor_content"}>
                        <Editor
                            apiKey='wwzu5v6vk2usoswhk1n05w1p3e1cqy9jqskidd7r6cibzjkt'
                            value={Notice_of_Representation}
                            init={{
                                plugins: 'link image code',
                                toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code',
                                height: 650
                            }}
                            onEditorChange={ handleNotice}
                        />
                    </Modal.Body>
                )}

                {currentPage === 4 && (
                    <Modal.Body className={"editor_content"}>
                        <Editor
                            apiKey='wwzu5v6vk2usoswhk1n05w1p3e1cqy9jqskidd7r6cibzjkt'
                            value={Public_Adjuster_Contract}
                            init={{
                                plugins: 'link image code',
                                toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code',
                                height: 650
                            }}
                            onEditorChange={ handlePAContract}
                        />
                    </Modal.Body>
                )}
                <Modal.Footer>
                    {currentPage <= 4 && currentPage > 1 && (
                        <button onClick={prev}>Back</button>
                    )}
                    {currentPage < 4 && (
                       <Button onClick={next} variant="primary">Next</Button>
                  )}

                    {currentPage >= 4 && !isLoading && (
                    <Button onClick={handleSave} variant="primary">Send Notice of Signed Claim</Button>
                    )}
                    {currentPage >= 4 && isLoading && (
                    <Spinner style = {{float:'right'}} animation="grow" variant="primary"/>
                    )}
                </Modal.Footer>
            </Modal>
        )
}

export default connect(null,null)(SignClaim);
