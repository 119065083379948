import React from "react";
import {connect} from 'react-redux';
import {Button, Modal} from "react-bootstrap";
import PerilOptionsEdit from "./PerilOptionsEdit";
import {carrierModalCondition, optionTeamsModalCondition} from "../redux/actions";
import axios from "axios";
import {url} from "../config";


class TeamsModal extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            teams_inputs:[1,2],
            optionsLeast:false,
            show:false,
            teams_values:[],
            changed:false,
            default_team:'',
            teams_inputs_counter:0
        }
    }



    handleCarrierValue = (e) => {
        e.preventDefault();

        const teams_values = this.state.teams_values;
        teams_values[e.target.dataset.id] = e.target.value;

    }



    carrierEditModal = () => {
        this.setState({
            show:true,

        })
    }


    onHide = () => {
        this.setState({
            show:false,

        })
        //this.props.dispatch(carrierModalCondition(true))
        this.props.dispatch(optionTeamsModalCondition(true))


    }


    handleOpenOptionList = () => {
        this.setState({
            show: true
        })

        this.props.dispatch(optionTeamsModalCondition(false))

    }


    getAllTeams = () =>  {

        axios.get(url + 'teams')
            .then(res => {

                this.setState({
                    team_list:res.data
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    componentDidMount() {
    this.getAllTeams();
    }


    handleClose = () => {
        this.setState({
            optionsLeast:false
        })
        this.props.handleDefaultValue()
        return  this.props.dispatch(optionTeamsModalCondition(false))
    }


    handleCloseModal = () => {
        this.setState({
            optionsLeast:false
        })

    }


    handleUpdate = () => {
        this.setState({
            team_list:[...this.state.team_list]
        })
    }

    handleSaveCarrier = ()=> {
        const team = this.state.teams_values;
        this.props.handleDefaultValue()
       if(team.length > 0 ){
          return  axios.post(url + 'create-team', {team})
               .then(res => {
                    this.setState({
                        teams_values:[]
                    })

                   this.props.dispatch(optionTeamsModalCondition(false))
                   this.props.getBasicInfoData();
                    this.getAllTeams()

               })
               .catch(err => {

                   console.log(err,'errrrr')
               })
       }else {
           this.props.dispatch(optionTeamsModalCondition(false))
           this.props.getBasicInfoData();
       }


    }


    handleAddMoreCarrier = () => {
        this.setState({
            optionsLeast:false
        })
        this.props.dispatch(carrierModalCondition(true))
    }


    handleDeleteTeam = (team_id) => {
        let data = this.state.teams_values
        data.splice(team_id)
        this.setState({
            carrier_values:data,

        })
    }


    handleSubmit = () => {
        this.setState({
            optionsLeast:false
        })

        this.props.dispatch(optionTeamsModalCondition(true))
    }


    handleAddMoreTeams = () => {
        this.setState({
            teams_inputs:[...this.state.teams_inputs,this.state.teams_inputs.length + 1]
        })
    }

    render() {

        return(
            <>
                <Modal show={this.props.team_modal_show} onHide={this.handleClose} centered
                       className="discover_modal_content peril_modal" dialogClassName='discover_modal options_modal'>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Team Option</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {this.state.teams_inputs.map((item,key) => {
                            return(
                                <input name={'teams' + this.state.teams_inputs_counter + key}
                                       key={this.state.teams_inputs_counter + key}
                                       data-id = {key}
                                       onChange={this.handleCarrierValue}/>
                            )
                        })}


                        <button onClick={this.handleAddMoreTeams}>Add More<span><i className={'apa-icon-add '}/></span></button>

                    </Modal.Body>


                    <Modal.Footer>

                        <button className={'peril_edit'} onClick={this.handleOpenOptionList}><span><i className={'apa-pencil'}/></span>Edit List</button>

                        <Button className={'default_hover'} variant="primary" onClick = {this.handleSaveCarrier}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/*<Modal show={this.state.optionsLeast} onHide = {this.handleCloseModal}   centered className="discover_modal_content peril_list" dialogClassName='discover_modal'>*/}
                {/*    <Modal.Header closeButton>*/}
                {/*        <span className={'peril_list_edit'} onClick={this.carrierEditModal}>*/}
                {/*            <i className={'apa-pencil'}/>*/}
                {/*        </span>*/}

                {/*        <Modal.Title>Teams Options</Modal.Title>*/}
                {/*    </Modal.Header>*/}
                {/*    <Modal.Body>*/}
                {/*        <div className={'panir'}>*/}


                {/*            {this.state.teams_values && this.state.teams_values.length > 0 && this.state.teams_values.map((item, key) => {*/}

                {/*                return (*/}
                {/*                    <React.Fragment key={key}>*/}

                {/*                        <i className={'apa-icon-delete delete_icon'} onClick={this.handleDeleteTeam.bind(this,key)} />*/}
                {/*                        <p>{item}</p>*/}

                {/*                    </React.Fragment>*/}
                {/*                )*/}
                {/*            })}*/}

                {/*        </div>*/}
                {/*        <button className={'add_lead_button'} onClick={this.handleAddMoreCarrier}><i className={'apa-icon-add '}/> <span>Add More</span></button>*/}

                {/*    </Modal.Body>*/}
                {/*    <Modal.Footer>*/}

                {/*        <Button variant="primary" onClick = {this.handleSubmit} >Submit</Button>*/}

                {/*    </Modal.Footer>*/}
                {/*</Modal>*/}

                 <PerilOptionsEdit getBasicInfoData = {this.props.getBasicInfoData}   getAllTeams = {this.getAllTeams} handleUpdate = {this.handleUpdate}   name = {'Teams'} onHide = {this.onHide} show={this.state.show} perilList = {this.state.team_list} manulAdd = {this.state.teams_values}/>
            </>
        )
    }
}


function mapStateToProps(state){
    return{
        show:state.leadsReducer.carrier_modal,
        team_modal_show:state.leadsReducer.teams_modal
    }
}


export default connect(mapStateToProps,null)(TeamsModal);