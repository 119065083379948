import React from "react";
import {NavLink} from "react-router-dom";


class RightPan extends React.Component{
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const {sendMessageModalOpen,scheduleModalOpen,signClaimModalOpen,inactiveModal,assignModalOpen} = this.props
        return(
            <>

                <ul className={'d_none'}>
                    <li  onClick={sendMessageModalOpen}>
                        <span><i className={'apa-icon-messaging'}></i>Send Messageasdasdas</span>
                    </li>

                    <li onClick={assignModalOpen}>
                        <span><i className={'apa-add-user'}></i>Assign</span>
                    </li>

                    <li onClick={scheduleModalOpen} >
                        <span><i className={'apa-icon-calendar'}></i>Schedule an Inspection</span>
                    </li>

                    <li onClick={signClaimModalOpen} >
                        <span><i className={'apa-icon-sign'}></i>Sign Claim</span>
                    </li>

                    <li onClick={inactiveModal} >
                        <span><i className={'apa-icon-deactivate'}></i>Inactive</span>
                    </li>

                    <li id = {'rotate_icon'} >
                        <NavLink to = {'/new-leads'}>
                            <i className={'apa-back'}></i>
                        </NavLink>
                    </li>
                </ul>

                <ul className={'mobile_properties'}>
                    <li>
                        <span><i className={'apa-icon-messaging'}></i>Begin Inspection</span>
                    </li>

                    <li>
                        <span><i className={'apa-icon-messaging'}></i>Reschedule</span>
                    </li>
                </ul>

                </>
        )
    }
}


export default RightPan;