import React, {useEffect, useState} from "react";
import SideBar from "./header/SideBar";
import Navbar from "./header/Navbar";
import {connect, useDispatch, useSelector} from 'react-redux'
import {checkAuth, findAll, getAllSignedClaims} from "../redux/actions";
import Basic from "./DataTables/Basic";
import  claimsColumns from './DataTables/Tables/Clims';
import  {contactColumns} from './DataTables/Tables/ContactClimes';
import LogEntryModal from "./leadDetails/LogEntryModal";


function Claims(props) {

    const signedClaims = useSelector(state => state.leadsReducer.signedClaims)
    const dispatch = useDispatch()
    const [show,setShow] = useState(false)
    const [lead_body,setLead_body] = useState('')
    const [updated,setUpdated] = useState('')

    useEffect(() => {
        dispatch(checkAuth());
        dispatch(getAllSignedClaims())
    }, [])


   const addLogEntry = (item) => {
        setShow(true)
        setLead_body([item])
    }

   const modalCallBack = (parentDate,name = '') => {
        setShow(parentDate)
    }


  const  myCallback = (dataFromChild) => {
        if(dataFromChild){
            setLead_body('')
            setUpdated(dataFromChild)
        }
    }


    return(
            <div className={"d-flex"} id="wrapper">
                {!props.type ?  <SideBar isAuth = {props.isAuth}/> : ''}
                <div id="page-content-wrapper">
                    <div>
                        {!props.type ?   <Navbar toggled = {false} /> : ''}
                        <div className={'col-12'}>
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <div className={'page_title users_title'}>
                                        <h2>Claims</h2>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12 leads_content">
                                    <div className={'leads_search'}>
                                        <button className={'default_hover'} >
                                            <i className={'apa-icon-search'}/>Search by Claim Number
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'lead_table table-responsive needAssignLead' }>
                            {!props.type ?
                                <Basic columns={claimsColumns(addLogEntry)} data={signedClaims}/>
                                : <Basic columns={contactColumns} data={props.data}/>
                            }
                            <LogEntryModal  show = {show} myCallback = {myCallback} modalCallBack = {modalCallBack}/>
                        </div>
                    </div>
                </div>
            </div>
        )
}
export default connect(null,null)(Claims)




