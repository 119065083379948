import {
    ADJUSTER,
    CARRIERMODAL,
    GETALLLEADS,
    GETLEADID,
    GETLEEDCONTACT,
    HISTORY,
    LEEDINSPECTION,
    OPTIONEDITMODAL,
    RELATEDUPDATEMODALSHOWHIDE,
    RESCHEDULE,
    SEARCHDATA,
    STATUS,
    TEAMS,
    TEAMSMODAL,
    LEEDDOCUMETS,
    GETCURENTLEAD,
    LEEDDOCUMETSBYID,
    GETFOLDERINFO,
    GETCLIENTLEED,
    GETLEEDBYID,
    GETALLSIGNEDCLAIMS,
    GETINCOMINGLEADSBYDATE,
    GETALLSOURCES,
    GETALLCOUNTRIES,
    GETALLCITIES,
    GETFILTERRESULT,
    GETCLIENTBYID, GETRESULTBYDATE, MESSAGELEADS, GETMESSAGELEADS
} from "../types";

const initialState = {
    search_data:'',
    leads_data:'',
    search:false,
    pagination_count:1,
    related_update_modal:false,
    carrier_modal:false,
    optionsEdit:false,
    teams_modal:false,
    schedule_title:'Schedule an Inspection',
    teams:false,
    adjusters:false,
    histories:[],
    statuses:false,
    inspections:false,
    contact:'',
    document:null,
    getLead : null,
    getLeadId:null,
    reportResult:{}
}


export default function rootReducer(state = initialState,action){

    switch (action.type) {


        case GETALLLEADS:

            return {
                ...state,
                leads_data: action.payload.data,
                pagination_count:action.payload.count
            }

        case SEARCHDATA:

            return {
                ...state,
                leads_data: action.payload,
                search: true,
            }



        case GETLEADID:

            return {
                ...state,
                lead_id: action.payload
            }

        case GETLEEDCONTACT:

            return {
                ...state,
                contact: action.payload
            }



        case RELATEDUPDATEMODALSHOWHIDE:
            return {
                ...state,
                related_update_modal:action.payload
            }


        case CARRIERMODAL:
            return {
                ...state,
                carrier_modal:action.payload
            }


        case OPTIONEDITMODAL:
            return {
                ...state,
                optionsEdit:action.payload,

            }


        case TEAMSMODAL:
            return {
                ...state,
                teams_modal:action.payload,

            }


        case RESCHEDULE:
            return {
                ...state,
                schedule_title: action.payload

            }

        case TEAMS:
            return {
                ...state,
                teams: action.payload

            }
        case ADJUSTER:
            return {
                ...state,
                adjusters: action.payload

            }

            case HISTORY:
                return {
                    ...state,
                    histories: action.payload
    
                }
                case STATUS:
                    return {
                        ...state,
                        statuses: action.payload
        
                    }

                case LEEDINSPECTION:
                    return {
                        ...state,
                        inspections: action.payload

                    }
                    case LEEDDOCUMETS:
                        return {
                            ...state,
                            documets: action.payload
    
                        }

                    case LEEDDOCUMETSBYID:
                        return {
                            ...state,
                            document: action.payload
                        }

                        case GETCURENTLEAD:
                            return {
                                ...state,
                                getLead: action.payload
        
                            }
                        case GETLEEDBYID:
                            return {
                                ...state,
                                getLeadId: action.payload

                            }

                        case GETFOLDERINFO:
                            return {
                                ...state,
                                folder_info: action.payload

                            }

                        case GETCLIENTLEED:
                            return {
                                ...state,
                                client_leads: action.payload

                            }

                    case GETALLSIGNEDCLAIMS:
                        return {
                            ...state,
                            signedClaims: action.payload

                        }
                    case GETINCOMINGLEADSBYDATE:
                        return {
                            ...state,
                            leadsCount: action.payload

                        }
                    case GETALLSOURCES:
                        return {
                            ...state,
                            sources: action.payload

                        }

                    case GETALLCITIES:
                        return {
                            ...state,
                            cities: action.payload

                        }


                    case GETCLIENTBYID:
                        return {
                            ...state,
                            currentClient: action.payload

                        }
                    case GETRESULTBYDATE:
                        return {
                            ...state,
                            reportResult: action.payload

                        }

                    case GETMESSAGELEADS:
                        return {
                            ...state,
                            message_leads: action.payload

                        }








        default:{
            return state;
        }
    }

}