import React from "react";
import '../../assets/css/side_bar.css'
import {NavLink} from "react-router-dom";
import axios from "axios";
import {url} from "../../config";
import {connect} from 'react-redux'
import {checkAuth, sideBarToggle} from "../../redux/actions";




class SideBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            cookie_value: ''
        }
    }


    componentDidMount() {
       this.props.checkAuth()
    }


    handleLogout = () => {
        axios.post(url + 'logout')
            .then(res => {
               localStorage.removeItem('token')
                window.location.reload();
            })
            .catch(err => {
                console.log(err)
            })

    }


    handleClick = () => {
        this.props.sideBarToggle(false)
    }

    render() {
        const {isAuth} = this.props;

        return (
            <>


                <div className="bg-light border-right" id="sidebar-wrapper">
                    <NavLink to="/dashboard" >
                        <div className="sidebar-heading"><img src={require('../../assets/img/logo_header.png')} alt={'header_logo'}/></div>
                    </NavLink>

                    <div className="list-group list-group-flush">

                        <NavLink onClick={this.handleClick} to="/dashboard" className="list-group-item list-group-item-action" activeClassName="active_link">

                            <i className={'apa-icon-home'}></i>
                            <span>Home</span>
                        </NavLink>

                        <NavLink onClick={this.handleClick} to="/leads" className="list-group-item list-group-item-action" activeClassName="active_link">
                            <i className={'apa-icon-leads'}></i>
                            <span>Leads (LMS)</span>

                        </NavLink>
                        <NavLink onClick={this.handleClick} to="/claims" className="list-group-item list-group-item-action" activeClassName="active_link">
                            <i className={'apa-icon-claims'}></i>

                            <span>Claims (CMS)</span>

                        </NavLink>
                        <NavLink to="/settlements" className="list-group-item list-group-item-action" activeClassName="active_link">
                            <i className={'apa-icon-settlement'}></i>
                            <span>Settlements (SMS)</span>


                        </NavLink>
                        <NavLink onClick={this.handleClick} to="/reports" className="list-group-item list-group-item-action" activeClassName="active_link">

                            <i className={'apa-icon-reports'}></i>

                            <span>Reports</span>


                        </NavLink>
                        <NavLink onClick={this.handleClick} to="/contacts" className="list-group-item list-group-item-action" activeClassName="active_link">
                            <i className={'apa-icon-clients'}></i>

                            <span>Contacts</span>

                        </NavLink>


                        <NavLink onClick={this.handleClick} to="/messages" className="list-group-item list-group-item-action" activeClassName="active_link">
                            <i className={'apa-icon-messaging'}></i>

                            <span>Messages</span>

                        </NavLink>


                        <NavLink onClick={this.handleClick} to="/users" className="list-group-item list-group-item-action" activeClassName="active_link">

                            <i className={'apa-icon-user'}></i>
                            <span>Users</span>

                        </NavLink>


                        <NavLink onClick={this.handleClick} to="/email-templates" className="list-group-item list-group-item-action" activeClassName="active_link">
                            <i className={'apa-icon-email-templates'}></i>

                            <span>Email Templates</span>

                        </NavLink>

                        {isAuth ?  <button className={'list-group-item list-group-item-action'} type="button" onClick={this.handleLogout}>
                            <i className={'apa-icon-logout'}></i>
                            <span>Logout</span>

                        </button> :  <NavLink to="/login" className="list-group-item list-group-item-action" activeClassName="active_link">
                            <i className={'apa-icon-user'}></i>

                            <span>Login</span>

                        </NavLink>  }

                    </div>

                </div>

            </>
        );
    }
}




function mapStateToProps(state){

    return{
        isAuth:state.userInfoReducer.isAuth,

    }
}

function mapDispatchToProps(dispatch){
    return{
        sideBarToggle : (condition) => dispatch(sideBarToggle(condition)),
        checkAuth: () => dispatch(checkAuth())
    }
}



export default connect(mapStateToProps,mapDispatchToProps)(SideBar)