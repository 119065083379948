import React from "react";
import RelatedUpdateModal from "./RelatedUpdateModal";
import {connect} from 'react-redux';
import {relatedUpdateModalShowHide} from "../../redux/actions";
import axios from "axios";
import {url} from "../../config";
import AddRelatedContactModal from "./AddRelatedContactModal";

class RelatedContacts extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            related_data:'',
            show:false,
            primary_contacts:[],
            policy_holders:[],
            moreRelatedContact:false
        }
    }

    componentDidMount() {
        // const lead_id = this.props.leadId;
        // axios.get(url + 'get-primary-contact?lead_id=' + lead_id)
        //   .then(res => {
        //     const primary_contacts = res.data;
        //     this.setState({ primary_contacts:primary_contacts });
        //   })
        //   axios.get(url + 'get-policy-holder?lead_id=' + lead_id)
        //   .then(res => {
        //     const policy_holders = res.data;
        //     this.setState({ policy_holders:policy_holders });
        //   })
      }

    ChangeRelatedContact = (id) => {
        const {lead_body} = this.props;
       // const data = lead_body[0].client.contacts[0].related_contacts[key];
       axios.get(url + 'get-related-contact-by-id?id=' + id)
       .then(res => {
         this.setState({
            related_data:res.data,
        })
        this.props.dispatch(relatedUpdateModalShowHide(true));
       })
    }
    openAddMoreRelatedContactModal = (state=false) => {
        this.setState({
            moreRelatedContact: state
        });
    }

    render() {
        const {lead_body} = this.props;
        return(
            <div className={'add_lead_basic leads_details_related'}>

                <div className={'leads_details_edit'}>
                    <h3>Related Contacts</h3>
                    <i onClick={() => this.openAddMoreRelatedContactModal(true)} className={'apa-icon-add'}></i>
                </div>

                {lead_body.length > 0 && lead_body.map((item,key) => {

                    return(



                <div className="col-12" key={key}>
                   
                    {item && item.client.contacts[0].related_contacts.map((value,key) => {
                        if(value.primary_contact == 1 ){
                                return(


                        <React.Fragment key = {key}>
                            <div className="row">
                                    <div className="col-lg-6 col-6">
                                        <span>Primary Contact</span>
                                    </div>
                                    <div className="col-lg-4 col-6">
                                    <p>{value.company_name}</p>
                                    </div>

                                    <div className="col-lg-2 col-2 details_view_icon">
                                    <i onClick={this.ChangeRelatedContact.bind(this,value.id)} className={'apa-icon-view c-pointer'}></i>
                                    </div>
                                </div>
                        </React.Fragment>
                            )
                        }
                    })}
                        
                    {item && item.client.contacts[0].related_contacts.map((value,key) => {
                        if(value.policy_holder == 1 ){
                                return(


                        <React.Fragment key = {key}>
                            <div className="row">
                                    <div className="col-lg-6 col-6">
                                        <span>Policy Holder</span>
                                    </div>
                                    <div className="col-lg-4 col-6">
                                    <p>{value.company_name}</p>
                                    </div>

                                    <div className="col-lg-2 col-2 details_view_icon">
                                    <i onClick={this.ChangeRelatedContact.bind(this,value.id)} className={'apa-icon-view c-pointer'}></i>
                                    </div>
                                </div>
                        </React.Fragment>
                            )
                        }
                    })}

                    {item && item.client.contacts[0].related_contacts.map((value,key) => {
                        if(value.primary_contact == 0 && value.policy_holder == 0 ){
                                return(


                        <React.Fragment key = {key}>
                            <div className="row">
                                    <div className="col-lg-6 col-6">
                                        <span>Related Contact</span>
                                    </div>
                                    <div className="col-lg-4 col-6">
                                    <p>{value.company_name}</p>
                                    </div>

                                    <div className="col-lg-2 col-2 details_view_icon">
                                    <i onClick={this.ChangeRelatedContact.bind(this,value.id)} className={'apa-icon-view c-pointer'}></i>
                                    </div>
                                </div>
                        </React.Fragment>
                            )
                        }
                    })}
                </div>
                    )
                })}

              <RelatedUpdateModal related_data = {this.state.related_data}/>
              <AddRelatedContactModal
              myCallback = {this.props.myCallback} 
               openAddMoreRelatedContactModal={this.openAddMoreRelatedContactModal} lead_body={lead_body} show={this.state.moreRelatedContact} />
            </div>


        )
    }
}


export default connect(null,null)(RelatedContacts)