import React from "react";


function InputSelect(props){
    const {options,handleChange,inputEdit,data,path , value=null} = props

    return(
        <>
            <div className={props.col ?'col-'+props.col :'col-'+4}>
                <p>{options.label}</p>
            </div>
            <div className={props.col ?'col-'+props.col :'col-'+8}>
                <input
                    defaultValue={options.value}
                    style={
                       inputEdit ? { display: "none" } : { display: "block" }
                    }
                    // name={options.name}
                    className={inputEdit ? " edit_inputs" : ""}
                    readOnly={!inputEdit}
                />
                <div
                    className={"form-group selectdiv "+options.class}
                    style={
                        !inputEdit ? { display: "none" } : { display: "block" }
                    }
                >
                    <select
                        name={options.name}
                        className={"form-control alterationTypeSelect"}
                        onChange={handleChange}
                        style={props.type==='mini'? {width:'160px',float:'left'}: {}}

                    >
                        <option value={""}/>
                        {data && data.map((items, key) => {
                            let checkValue
                            if(!props.check){checkValue = options.value
                            }else{checkValue =props.check}
                            return (
                                    <option
                                        selected={checkValue == (items[path] && !value ? items[path] : items[value])}
                                        value={(items[path] && !value) ? items[path] : items[value]}
                                        key={key}
                                    >
                                        {items[path] ? items[path] : items}
                                    </option>
                                );
                            })}
                    </select>
                </div>
            </div>
        </>
    )
}

export default InputSelect;