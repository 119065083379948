import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import thunk from "redux-thunk";
import {createStore,applyMiddleware} from 'redux'
import rootReducer from "./redux/rootReducer";
import {Provider} from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';



const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))
    );


const app = (

    <Provider store = {store}>
        <App />
    </Provider>
)


ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
