import React from "react";
import SideBar from "../../header/SideBar";
import Navbar from "../../header/Navbar";
import { Col, Nav, Row, Tab} from "react-bootstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import BasicInfo from "../BasicInfo";
import ContactInfo from "../ContactInfo";
import RelatedContacts from "../RelatedContacts";
import LossInfo from "../LossInfo";
import ExtentDamage from "../ExtentDamage";
import CarrierInfo from "../CarrierInfo";
import LossAdress from "../LossAdress";
import BuildingInfo from "../BuildingInfo";
import MortgageInformation from "../MortgageInformation";
import AssignmentInfo from "../AssignmentInfo";
import AssignedHistory from "../AssignedHistory";
import InspectionInfo from "../InspectionInfo";
import {url} from "../../../config";
import LogEntry from "../../leadDetails/LogEntry";
import axios from "axios";
import Slider from "react-slick";
import {connect} from 'react-redux'
import LogEntryModal from "../LogEntryModal";
import SendMessageModal from "../SendMessageModal";
import AssignModal from "../AssignModal";
import ScheduleInspection from "../ScheduleInspection";
import SignClaim from "../SignClaim";
import Inactive from "../Inactive";
import RightPan from "../RightPan";
import {Link} from "react-router-dom";
import CallModal from "../CallModal";
import {
    checkAuth,
    getDocumentById,
    getFolderInfo,
    getLeads, getLeedInspection,
    openBeginModal,
    sideBarToggle
} from "../../../redux/actions";
import { result } from "lodash";



let obj= {}

class DocsClaims extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            show: false,
            send_message: false,
            assign_show: false,
            schedule_modal: false,
            signClaimModal: false,
            inactive_modal: false,
            call_show: false,
            lead_body: [],
            updated: false,
            slider_key: 'first',
            crumbs:[],
        
           
        
        }
    }


   

    componentDidMount() {

        this.getCurrentLead(this.props.match.params.id);
        this.props.dispatch(getDocumentById(this.props.match.params.id))
        this.props.dispatch(getFolderInfo(this.props.match.params.id))

    }

    getLink(id)
    {
        axios.get(url + '/file-link?id=' + id)
            .then(res => {
                window.open(res.data, '_blank');
            })
            .catch(err => {
                console.log(err)
            })
    }





    myCallback = (dataFromChild) => {

        this.setState({
            updated: dataFromChild
        })

        if (dataFromChild) {
            this.setState({
                lead_body: ''
            })
            this.getCurrentLead();
        }
    }

    addLogEntry = () => {
        this.setState({
            show: true
        })
    }


    modalCallBack = (parentDate, name = '') => {

        if (name === 'SendMessage') {
            return this.setState({
                send_message: false
            })
        }


        if (name === 'inactive') {
            return this.setState({
                inactive_modal: false
            })
        }


        if (name === 'signClaim') {
            return this.setState({
                signClaimModal: false
            })
        }


        if (name === 'assign_modal') {
            return this.setState({
                assign_show: false
            })
        }

        if (name === 'schedule_modal') {
            return this.setState({
                schedule_modal: false
            })
        }

        this.setState({
            show: parentDate
        })
    }


    sendMessageModalOpen = () => {
        this.setState({
            send_message:true
        })
    }

    assignModalOpen = () => {
        this.setState({
            assign_show:true
        })
    }

    scheduleModalOpen = () => {
        this.setState({
            schedule_modal:true
        })
    }

    signClaimModalOpen = () => {
        this.setState({
            signClaimModal:true
        })
    }

    inactiveModal = () => {
        this.setState({
            inactive_modal:true
        })
    }


    handleCallModal = () => {
        this.setState({
            call_show:true
        })
    }


    handleClose = () => {
        this.setState({
            call_show:false
        })
    }


    getCurrentLead =  () => {
       
        const lead_id = this.props.match.params.leadId;

        axios.get(url + 'get-lead?id=' + lead_id)
            .then(res => {
            
                this.setState({
                    lead_body:[...this.state.lead_body,res.data]
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    render() {

        const {lead_body} = this.state;
        const {files,file_info} =this.props;
        const path =file_info && file_info.path_collection.entries

    

        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3
        };


        if(!files && !file_info){
            return  null
        }


        return(

           
            <>

                <div className={"d-flex" + (this.state.toggled ? ' toggled' : '')} id="wrapper">
                    <SideBar isAuth = {this.props.isAuth}/>
                    <div id="page-content-wrapper">
                        <div>
                            <Navbar toggled = {this.myCallback} />


                            <div className="row">
                                <div className="col-lg-5 col-6">

                                    <div className={'leads_details_title social'}>
                                        <h3>Leads/Details</h3>

                                        <span onClick={this.addLogEntry}> <i className={'apa-icon-add'}></i>Add Log Entry</span>
                                    </div>

                                </div>



                                <div className="col-lg-7 col-6 leads_details_properties">

                                    <RightPan
                                        sendMessageModalOpen = {this.sendMessageModalOpen}
                                        assignModalOpen = {this.assignModalOpen}
                                        scheduleModalOpen = {this.scheduleModalOpen}
                                        signClaimModalOpen = {this.signClaimModalOpen}
                                        inactiveModal = {this.inactiveModal}
                                    />





                                </div>

                            </div>

                            <Tab.Container className = {"table_container"} id={'table_container'} defaultActiveKey="five" onSelect={key => this.setState({slider_key:key})} >
                                <Row>
                                    <Col lg={12}>

                                        <Nav variant="pills" className="">
                                            <Nav.Item  className={'col-md-2 col-12 search_tabs new_leads leads_details_tabs desktop_tabs '}>
                                                <Nav.Link eventKey="first">Basic Info</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className={'col-md-2 col-12 search_tabs new_leads leads_details_tabs desktop_tabs '}>
                                                <Nav.Link eventKey="second">Loss Info</Nav.Link>
                                            </Nav.Item>

                                            <Nav.Item className={'col-md-2 col-12  search_tabs new_leads leads_details_tabs desktop_tabs '}>
                                                <Nav.Link eventKey="third">Loss Address</Nav.Link>
                                            </Nav.Item>

                                            <Nav.Item className={'col-md-2 col-12 search_tabs new_leads leads_details_tabs desktop_tabs '}>
                                                <Nav.Link eventKey="fourth">Assignment & Inspection Info</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className={'col-md-2 col-12 search_tabs new_leads leads_details_tabs desktop_tabs '}>
                                                <Nav.Link eventKey="five">Documents</Nav.Link>
                                            </Nav.Item>

                                            <Nav.Item className={'col-md-2 col-12 search_tabs new_leads leads_details_tabs desktop_tabs '}>
                                                <Nav.Link eventKey="six">Log Entries</Nav.Link>
                                            </Nav.Item>
                                        </Nav>


                                        <div className={'leads_details_mobile_slider'}>


                                            <Slider {...settings}>

                                                <Nav.Item className={' search_tabs new_leads leads_details_tabs' + (this.state.slider_key === 'first' ? ' active_slider' : '')}>
                                                    <Nav.Link eventKey="first">Basic Info</Nav.Link>
                                                </Nav.Item>

                                                <div>
                                                    <Nav.Item className={' search_tabs new_leads leads_details_tabs' + (this.state.slider_key === 'second' ? ' active_slider' : '')}>
                                                        <Nav.Link eventKey="second">Loss Info</Nav.Link>
                                                    </Nav.Item>
                                                </div>
                                                <Nav.Item className={'search_tabs new_leads leads_details_tabs' + (this.state.slider_key === 'third' ? ' active_slider' : '')}>
                                                    <Nav.Link eventKey="third">Loss Address</Nav.Link>
                                                </Nav.Item>

                                                <div>
                                                    <Nav.Item className={' search_tabs new_leads leads_details_tabs' + (this.state.slider_key === 'fourth' ? ' active_slider' : '')}>
                                                        <Nav.Link eventKey="fourth">Assignment & Inspection Info</Nav.Link>
                                                    </Nav.Item>
                                                </div>
                                                <div>
                                                    <Nav.Item className={' search_tabs new_leads leads_details_tabs' + (this.state.slider_key === 'five' ? ' active_slider' : '')}>
                                                        <Nav.Link eventKey="five">Documents</Nav.Link>
                                                    </Nav.Item>
                                                </div>
                                                <div>
                                                    <Nav.Item className={' search_tabs new_leads leads_details_tabs' + (this.state.slider_key === 'six' ? ' active_slider' : '')}>
                                                        <Nav.Link eventKey="six">Log Entries</Nav.Link>
                                                    </Nav.Item>
                                                </div>


                                            </Slider>
                                        </div>
                                    </Col>

                                    <Col lg={12}>

                                        <Tab.Content>

                                            <Tab.Pane eventKey="first">

                                                <div className="row">
                                                    <div className="col-md-4 col-12 leads_details_content">
                                                        <BasicInfo lead_body = {lead_body} myCallback = {this.myCallback} />
                                                    </div>
                                                    <div className="col-md-4 col-12 leads_details_content">
                                                        <ContactInfo lead_body = {lead_body} myCallback = {this.myCallback}/>
                                                    </div>
                                                    <div className="col-md-4 col-12 leads_details_content">
                                                        <RelatedContacts lead_body = {lead_body && lead_body} myCallback = {this.myCallback}/>
                                                    </div>
                                                </div>

                                            </Tab.Pane>


                                            <Tab.Pane eventKey="second">
                                                <div className="row">
                                                    <div className="col-md-4 col-12">
                                                        <LossInfo lead_body = {lead_body} myCallback = {this.myCallback} />
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <ExtentDamage lead_body = {lead_body} myCallback = {this.myCallback}/>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <CarrierInfo lead_body = {lead_body} myCallback = {this.myCallback} />
                                                    </div>
                                                </div>
                                            </Tab.Pane>


                                            <Tab.Pane eventKey="third">
                                                <div className="row">
                                                    <div className="col-md-4 col-12">
                                                        <LossAdress lead_body = {lead_body} myCallback = {this.myCallback}/>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <BuildingInfo lead_body = {lead_body} myCallback = {this.myCallback}/>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <MortgageInformation lead_body = {lead_body} myCallback = {this.myCallback}/>
                                                    </div>
                                                </div>
                                            </Tab.Pane>


                                            <Tab.Pane eventKey="fourth">
                                                <div className="row">
                                                    <div className="col-md-4 col-12">
                                                        <AssignmentInfo lead_body = {lead_body} myCallback = {this.myCallback} />
                                                    </div>
                                                    <div className="col-lg-8 col-12">
                                                        <AssignedHistory lead_body = {lead_body} leadId={lead_body.id}/>
                                                    </div>


                                                    <div className="col-md-4 col-12 mt-4">
                                                        <InspectionInfo lead_body = {lead_body} rescheduleInfo = {this.rescheduleInfo} leadId = {this.props.location.state}  myCallback = {this.myCallback} />
                                                    </div>

                                                </div>


                                            </Tab.Pane>

                                            <Tab.Pane eventKey="five">
                                                <div className={'documents_folder'}>
                                                    <Breadcrumb >
                                                        {path && path.map((pat,key)=>{
                                                            if(key > 0) {
                                                                return (
                                                                    <Breadcrumb.Item href={`/lead/${this.props.match.params.leadId}/document/${pat.id}`} key={key}>
                                                                        {(key === 1) ? 'Document Folders' : pat.name}
                                                                    </Breadcrumb.Item>
                                                                )
                                                            }

                                                        })}
                                                        <Breadcrumb.Item active>{file_info && file_info.path_collection.entries.length>1? file_info.name:'Document Folders'}</Breadcrumb.Item>
                                                    </Breadcrumb>
                                                </div>

                                                <div className="row">
                                                    {files && files.map((v,k)=>{

                                                        switch(v.type) {
                                                            case 'folder':

                                                                return(
                                                                    <>

                                                                        <div className="col-lg-3 col-12" key={k} onClick={() => {
                                                                            window.location.href = `/lead/${this.props.match.params.leadId}/document/${v.id}`
                                                                        }}>

                                                                            <div className={'folders_content'}>
                                                                                <p>{v.name}</p>
                                                                                <span>Items</span>
                                                                            </div>

                                                                        </div>
                                                                    </>

                                                                )
                                                            case 'file':
                                                                return(

                                                                    <div className={'col-lg-3 col-6'} key={k}>

                                                                        <div className={'document_cin'}>
                                                                            <img onClick={()=>{this.getLink(v.id)}} className={'file_type_img'} src={require('../../../assets/img/file_icons/'+v.name.split('.').pop()+'.png')} alt = {v.name.split('.').pop()}/>
                                                                            <img src={require('../../../assets/img/cin.png')} alt = {'cin_img'}/>
                                                                            <p><span className={'icon_name'}>{v.name.split('.').pop()}</span>: #{v.name}<span> | Date: 10/30/2019</span></p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            default:
                                                                return ('');
                                                        }
                                                    })}

                                                </div>
                                            </Tab.Pane>


                                            <Tab.Pane eventKey="six">
                                                <LogEntry lead_body = {lead_body}/>
                                            </Tab.Pane>

                                        </Tab.Content>
                                    </Col>


                                </Row>
                            </Tab.Container>


                        </div>

                    </div>

                    <button onClick={this.handleCallModal} className="call_button button-small">
                        <span className="label-hidden">Initate Call</span>
                        <i className="apa-icon-phone call"></i>

                    </button>
                </div>


                {/*------Modals------*/}

                 <LogEntryModal lead_body = {this.state.lead_body} show = {this.state.show} modalCallBack = {this.modalCallBack} />
                <SendMessageModal show = {this.state.send_message} modalCallBack = {this.modalCallBack}/>
                <AssignModal lead_body = {this.state.lead_body} show = {this.state.assign_show} modalCallBack = {this.modalCallBack} />
                <ScheduleInspection lead_body = {this.state.lead_body} show = {this.state.schedule_modal}  modalCallBack = {this.modalCallBack} />
                <SignClaim lead_body = {this.state.lead_body} show = {this.state.signClaimModal} modalCallBack = {this.modalCallBack} />
                <Inactive show = {this.state.inactive_modal} modalCallBack = {this.modalCallBack} />
                <CallModal handleClose = {this.handleClose}  call_show = {this.state.call_show} />

            </>
        )
    }
}




function mapStateToProps(state) {

    return{
    //     lead_body:state.leadsReducer.getLead
        files: state.leadsReducer.document,
        file_info: state.leadsReducer.folder_info,
    }

}


export default connect(mapStateToProps,null)(DocsClaims);

