import axios from "axios";
import {url} from "../config";
import {
    CARRIERMODAL,
    CHECKAUTH,
    GETALLLEADS,
    HIDEBEGINMODAL,
    OPENPROPERTYMODAL,
    OPTIONEDITMODAL,
    RELATEDUPDATEMODALSHOWHIDE,
    RESCHEDULE,
    SHOWBEGINMODAL,
    TEAMSMODAL,
    TOGGLESIDEBAR,
    HISTORY,
    LEEDINSPECTION,
    LEEDDOCUMETS,
    GETCURENTLEAD,
    LEEDDOCUMETSBYID,
    GETFOLDERINFO,
    GETSEARCHRESULT,
    GETCLIENTLEED,
    GETLEEDBYID,
    GETUSERNOTIFY,
    GETALLSIGNEDCLAIMS,
    GETADDRESSBYID,
    GETMORTGAGEBYID,
    GETBUILDINGBYID,
    GETCONTACTBYID,
    GETALLSOURCES,
    GETALLCITIES,
    GETCLIENTBYID,
    GETRESULTBYDATE,
    GETADDRESSBYIDGENERAL,
    GETEMAILTEMPLATES,
    MESSAGELEADS,
    GETMESSAGELEADS,
    GETCONVERSATIONBYLEAD
} from "./types";


export function getLeads(pageNumber = 1) {

    return dispatch => {
        axios.get(url + 'leads?page=' + pageNumber)
            .then(res => {
                dispatch({
                    type:GETALLLEADS,
                    payload:res.data
                })

            })
            .catch(err => {
                console.log(err)
            })
    }
}

export function messageLeads(pageNumber = 1) {

    return dispatch => {
        axios.get(url + 'message-leads?page=' + pageNumber)
            .then(res => {
                dispatch({
                    type:GETMESSAGELEADS,
                    payload:res.data
                })

            })
            .catch(err => {
                console.log(err)
            })
    }
}



export function checkAuth() {

   return dispatch => {
       axios.post(url + 'check-auth', {})
           .then(() => {
               dispatch({
                   type:CHECKAUTH,
                   payload:true
               })
           })
           .catch(err => {
               if (err.response && err.response.status === 401) {

                   localStorage.removeItem('token')
                   dispatch({
                       type:CHECKAUTH,
                       payload:false
                   })
                   window.location.href = '/login'
               }
           })
   }
}


export function addPropertyModal(condition) {
    return dispatch => {
      dispatch({
          type:OPENPROPERTYMODAL,
          payload:condition
      })
    }
}

export function openBeginModal(){
    return dispatch => {
        dispatch({
            type:SHOWBEGINMODAL,
            payload:true
        })
    }
}


export function hideBeginModal(){
    return dispatch => {
        dispatch({
            type:HIDEBEGINMODAL,
            payload:false
        })
    window.location.reload();

    }
}


export function sideBarToggle(condition) {

return dispatch => {
    dispatch({
        type:TOGGLESIDEBAR,
        payload:condition
    })
}
}


export function relatedUpdateModalShowHide(condition,page_id) {
    if(page_id){
        return dispatch => {
            dispatch({
                type:RELATEDUPDATEMODALSHOWHIDE,
                payload:condition
            })

            window.location.reload();
        }

    }
    return dispatch => {
        dispatch({
            type:RELATEDUPDATEMODALSHOWHIDE,
            payload:condition
        })
    }

}

export function carrierModalCondition(condition) {
    return dispatch => {
        dispatch({
            type:CARRIERMODAL,
            payload:condition
        })
    }
}

export function optionEditModalCondition(condition) {
    return dispatch => {
        dispatch({
            type:OPTIONEDITMODAL,
            payload:condition
        })
    }
}



export function optionTeamsModalCondition(condition) {
    return dispatch => {
        dispatch({
            type:TEAMSMODAL,
            payload:condition
        })
    }
}


export function scheduleTitleCheck(title = 'Schedule an Inspection') {

    return dispatch => {
        dispatch({
            type:RESCHEDULE,
            payload:title
        })
    }

}


export function findAll(path,type) {

    return dispatch => {
        axios.get(url + path, {})
            .then((res) => {
                dispatch({
                    type:type,
                    payload:res.data
                })
            })
            .catch(err => {
                    console.log(err)
            })
    }

}


export function getAllHistory(lead_id) {

    return dispatch => {
            axios.get(url + 'get-assignment-history?lead_id='+lead_id, {}).then((res) => {
              if (res){
                dispatch({
                    type:HISTORY,
                    payload:res.data
                })
              }
            });
     
    }

}

export function getLeedInspection(lead_id) {
    return dispatch => {
        axios.get(url + 'get-lead-inspection?lead_id='+lead_id, {}).then((res) => {
            if (res){
                dispatch({
                    type:LEEDINSPECTION,
                    payload:res.data
                })
            }
        });

    }

}

export function getDocument(lead_id) {

    return dispatch => {
        axios.get(url + 'documents?lead_id='+lead_id, {}).then((res) => {
        
                    if(res){
                    dispatch({
                        type:LEEDDOCUMETS,
                        payload:res.data
                    })
                }
            
            
        });

    }
}


export function  getDocumentById(id) {

        return  dispatch => {
        axios.get(url + 'document_files?document_id='+id, {}).then( (res) => {
                  dispatch({
                type:LEEDDOCUMETSBYID,
                payload:res.data
            })

    })
}
}

export function getCurrentLead(id,column='id',callback=null) {


    return dispatch => {
        axios.get(url + 'get-lead?'+column+'='+id)
        .then(res => {
            if(column!=='id'){

              return   dispatch({
                    type:GETCURENTLEAD,
                    payload:res.data
                })
            }else{

                if(callback){
                    callback()
                }

               return  dispatch({
                    type:GETLEEDBYID,
                    payload:res.data
                })
            }

        })

    }
}

export function getFolderInfo(id) {

    return dispatch => {
        axios.get(url + 'folder-info?document_id='+id)
            .then(res => {
                dispatch({
                    type:GETFOLDERINFO,
                    payload:res.data
                })

            })

    }
}
export function getSearchResult(term) {

    return dispatch => {
        axios.get(url + 'global-search?term='+term)
            .then(res => {
                dispatch({
                    type:GETSEARCHRESULT,
                    payload:res.data
                })

            })

    }
}


export function getClientLead(id,pageNumber = 1) {

    return dispatch => {
        axios.get(url + 'get-client-leads?id='+id+'&page=' + pageNumber)
            .then(res => {
                dispatch({
                    type:GETCLIENTLEED,
                    payload:res.data
                })

            })

    }
}


export function getUserNotify(user_id) {

    return dispatch => {
        axios.get(url +'/get-notify?user_id='+user_id)
            .then(res => {
                dispatch({
                    type:GETUSERNOTIFY,
                    payload:res.data
                })

            })

    }
}

export function getAllSignedClaims() {

    return dispatch => {
        axios.get(url +'/all-claim')
            .then(res => {
                dispatch({
                    type:GETALLSIGNEDCLAIMS,
                    payload:res.data
                })

            })

    }
}

export function getAddress(id,type) {

    return dispatch => {
        axios.get(url +'/get-loss-address?id='+id)
            .then(res => {
                if(type){
                    dispatch({
                        type:GETADDRESSBYIDGENERAL,
                        payload:res.data
                    })
                }else{
                    dispatch({
                        type:GETADDRESSBYID,
                        payload:res.data
                    })
                }


            })

    }
}


export function getMortgage(id) {

    return dispatch => {
        axios.get(url +'/get-mortgage-company?id='+id)
            .then(res => {
                dispatch({
                    type:GETMORTGAGEBYID,
                    payload:res.data
                })

            })

    }
}

export function getBuilding(id) {
    return dispatch => {
        axios.get(url +'/get-building?id='+id)
            .then(res => {
                dispatch({
                    type:GETBUILDINGBYID,
                    payload:res.data
                })

            })

    }
}


export function getContactById(id) {

    return dispatch => {
        axios.get(url +'/show-contact?id='+id)
            .then(res => {
                dispatch({
                    type:GETCONTACTBYID,
                    payload:res.data
                })

            })

    }
}


export function getAllSources() {

    return dispatch => {
        axios.get(url +'/sources')
            .then(res => {
                dispatch({
                    type:GETALLSOURCES,
                    payload:res.data
                })

            })

    }
}

export function getAllCities() {

    return dispatch => {
        axios.get(url +'/get-cities')
            .then(res => {
                dispatch({
                    type:GETALLCITIES,
                    payload:res.data
                })

            })

    }
}






export function getCurrentClient(id) {
    return dispatch =>
        axios.get(url + 'get-client?id='+id)
            .then(res =>{
                dispatch({
                    type:GETCLIENTBYID,
                    payload:res.data
                })
            }).catch(err => {
            console.log(err)
        })

}


export function getReportResultByDate(data) {
    return dispatch =>
        axios.post(url + '/get-all-result-by-date',data)
            .then(res =>{
                dispatch({
                    type:GETRESULTBYDATE,
                    payload:res.data
                })
            }).catch(err => {
            console.log(err)
        })

}


export function getEmails() {
    return dispatch =>
        axios.post(url + "/get-emails",{}).then(res=>{
            dispatch({
                type:GETEMAILTEMPLATES,
                payload:res.data
            })
        }).catch(err => {
            console.log(err)
        })
}







