import React from "react";
import { Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import LogEntryModal from "../leadDetails/LogEntryModal";
import MobileTable from '../MobileTable';
import { connect } from 'react-redux';
import Basic from "../DataTables/Basic";
import {columns} from "../DataTables/Tables/NeedAssignLeads";

let new_columns
class NeedAssignLeads extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            show:false,
            lead_body:''
        }
    }


    componentDidMount() {
        new_columns =columns(() => this.addLogEntry())
    }

    addLogEntry = (item) => {
        this.setState({
            lead_body:[item],
            show:true
        })
    }

      myCallback = (dataFromChild) => {
        if(dataFromChild){
            this.setState({
                lead_body:'',
                updated:dataFromChild
            })
            //this.getCurrentLead();
        }
    }

    modalCallBack = (parentDate,name = '') => {
        this.setState({
            show:parentDate
        })
    }


    render() {
        const {needAssignedLeads,activePage,totalCount,windowWidth} = this.props

        return(
          <div>
            <div className={'lead_table table-responsive new_lead_table showOnDesktop needAssignLead' }>
                <Basic columns={new_columns} data={needAssignedLeads}/>
          <LogEntryModal lead_body = {needAssignedLeads} show = {this.state.show} myCallback = {this.myCallback} modalCallBack = {this.modalCallBack}/>
            </div>
            {windowWidth <= 991 && <MobileTable leads_data={this.props.needAssignedLeads} />}
        </div>

        )
    }
}

function mapStateToProps(state) {
  return {
    windowWidth: state.appReducer.windowWidth,
  };
}
export default connect(mapStateToProps, null)(NeedAssignLeads);



// <div className={'table_pagination'}>
//     {totalCount > 10 ? (
//         <Pagination
//             itemClass='page-item'
//             linkClass='page-link'
//             activePage={this.props.ActiveNeedToAssign}
//             itemsCountPerPage={10}
//             totalItemsCount={totalCount !== null && totalCount}
//             pageRangeDisplayed={5}
//             onChange={this.props.handlePageChange.bind(this)}
//         />
//     ) : (<span></span>)}
//
// </div>