import React from "react";
import {Button, Modal} from "react-bootstrap";
import {connect} from 'react-redux'
import {findAll, getLeedInspection, hideBeginModal} from "../../redux/actions";
import { format} from 'date-fns'
import axios from "axios";
import {url} from "../../config";
import moment from 'moment'

const inspectionForm ={};
class BeginInspection extends React.Component{
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    handleHideModal = () => {
        this.props.myCallback(true);
        this.props.dispatch(hideBeginModal());
    }

    updateInspectionForm = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        inspectionForm[name] = value;
    };

    updateLeadStatusCode = (lead_id, status_code) => {
        axios
            .post(url + "update-lead-current-status-code", {
                lead_id: lead_id,
                status_code: status_code,
            })
            .then((res) => {
               // axios.post(url + "add-lead-log", {lead_id:lead_id,details:'Inspection Completed.'});
            })
            .catch((err) => {
                console.log(err);
            });
    };

    handleSave = async (e)  => {
        const objectLength = Object.keys(inspectionForm).length;
        if (objectLength > 0) {
            inspectionForm['inspection_id']= this.props.inspections.id
            inspectionForm['contact_id']= this.props.contact.id
          await  axios
                .post(url + "inspection-form", inspectionForm)
                .then(async (res)  => {
                    console.log(res);
                    this.updateLeadStatusCode(
                        this.props.inspections.lead_id,
                        3
                    );
                    await axios.post(url + "add-lead-log", {lead_id:this.props.inspections.lead_id,details:'Inspection Completed.'});
                    this.props.dispatch(getLeedInspection(this.props.inspections.lead_id));
        this.handleHideModal();
                    
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };


    render() {

        const {begin_modal_show,inspections,contact} = this.props;
        return(
            <Modal show={begin_modal_show} onHide = {this.handleHideModal} centered className="discover_modal_content mobile_begin_inspection" dialogClassName='discover_modal schedule_modale'>
                <Modal.Header closeButton>
                    <Modal.Title>Inspection Form</Modal.Title>

                </Modal.Header>
                <Modal.Body>

                    <div className="row">

                        <div className="col-4 col-lg-4">
                            <p>Inspector</p>
                        </div>
                        <div className="col-8 col-lg-8  ">
                          <span className="opt-12">{inspections?.inspector?.first_name +' '+ inspections?.inspector?.last_name}</span>
                        </div>



                        <div className="col-4 col-lg-4">
                            <p>Insured</p>
                        </div>
                        <div className="col-8 col-lg-8  ">
                            <span className="opt-12">{contact?.first_name +' '+ contact?.last_name}</span>
                        </div>


                        <div className="col-4 col-lg-4">
                            <p>Date</p>
                        </div>
                        <div className="col-8 col-lg-8  ">
                            <span className="opt-12">{inspections?.inspection_date && moment(new Date(inspections.inspection_date)).format("MM/DD/YYYY hh:mm A")}</span>
                        </div>


                    </div>


                    <div className={'mobile_begin_form profile_content'}>
                        <h5>Lead Status After Inspection</h5>
                        <div className="form-group selectdiv profile_select">

                            <select
                                className="form-control alterationTypeSelect"
                                name="lead_status"
                                onChange={this.updateInspectionForm}
                            >
                                <option value={'Signed'} > Signed </option>
                                <option value={'Inactive'} > Inactive </option>
                                <option value={'Follow_Up_Required'}> Follow Up Required </option>
                            </select>
                        </div>
                    </div>


                    <div className={'mobile_begin_form'}>
                        <h5>Fee Details</h5>
                        <input type={'text'}
                               name={'details'}
                         onChange={this.updateInspectionForm}
                        />
                    </div>


                    <div className={'mobile_begin_form'}>
                        <h5>Claim Assignment Recommendation</h5>
                        <textarea name={'recommendation'}
                                  onChange={this.updateInspectionForm} />
                    </div>


                    <div className={'mobile_begin_form'}>
                        <h5>Tentative Plan of Action/Strategy</h5>
                        <textarea name={'strategy'}
                                  onChange={this.updateInspectionForm}/>
                    </div>



                    <div className={'mobile_begin_form'}>
                        <h5>Additional Comments</h5>
                        <textarea  name={'comments'}
                                   onChange={this.updateInspectionForm}/>
                    </div>

                </Modal.Body>
                <Modal.Footer>

                    <Button variant="primary" onClick={this.handleSave}>Complete Inspection</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}


function mapStateToProps(state) {

    return{
        begin_modal_show:state.appReducer.begin_modal_show,
        inspections: state.leadsReducer.inspections,
        contact:state.leadsReducer.contact
    }

}


export default connect(mapStateToProps,null)(BeginInspection)