import React from "react";
import {Button, Modal, Table} from "react-bootstrap";
import '../assets/css/users_styles.css';
import axios from "axios";
import {url} from "../config";
import {connect} from 'react-redux';
import MobileTable from "./MobileTable";





let data = {};



class UsersTable extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            user_list:'',
            show:false,
            current_user:'',
            roles:'',
            default_office: 'DEFAULT',
            itemId:'',
            delete_confirm:false,
            user_id:null,
            userName:null
        }
    }


    getUsers = () => {
        axios.get(url + 'get-users')
            .then(res => {

                this.setState({
                    user_list:res.data,

                })

            })
            .catch(err => {
                console.log(err)
            })
    }

    componentDidMount() {

       // get-users
        this.getUsers();

        // get-roles
        this.getRoles();
    }


    deleteUser = (user_id) =>{

        let data = {};

        this.setState({
            delete_confirm:true
        })

            data.id = user_id
            axios.post(url + 'user/disable', data)
                .then(res => {

                    this.getUsers();
                    this.setState({
                        delete_confirm:false,
                        user_id:null,
                        userName:null
                    })


                })
                .catch(err => {
                    console.log(err)
                })

    }

    getRoles = () => {
        axios.get(url + 'get-roles')
            .then(res => {

                this.setState({
                    roles:res.data
                })

            })
            .catch(err => {
                console.log(err)
            })
    }


    handleSave = () => {
        data.id = this.state.current_user.id

        axios.post(url + 'update-user', data)
            .then(res => {
                console.log(res);
                window.location.reload();
            })
            .catch(err => {
                console.log(err)
            })
    }


    handleClose = (modalName) => {
        if(modalName === 'deleteConfirmModal'){
            return this.setState({
                delete_confirm:false,
                user_id:null,

            })
        }

        this.setState({
            show:false
        })
    }


    editModal = (user_id,user_disabled) => {

        axios.get(url + 'edit-user?id=' + user_id)
            .then(res => {

                data ={
                    username:res.data.username,
                    first_name:res.data.first_name,
                    last_name:res.data.last_name,
                    role_id:res.data.role_id,
                    email:res.data.email,
                    active:res.data.active,
                    disabled_at:user_disabled
                };

            this.setState({
                current_user:res.data,
                show:true,
                default_office:res.data.role_id
            })

            })
            .catch(err => {
                console.log(err)
            })

    }


    handleChange = (event) => {
        const {name,value} = event.target;
        data[name] = value

    }


    handleConfirmDelete = (userId,userName) => {

        this.setState({
            delete_confirm:true,
            userId:userId,
            userName:userName
        })
    }


   handleCheckboxChange = (event) => {
        const {name} = event.target;

       if(name === 'active'){
           if(data.active === 'inactive'){
               data.active = 'active'
           }else {
               data.active = 'inactive'
           }
       }
    }


    render() {
        const {user_list,itemId,show,current_user, default_office,roles,delete_confirm,userName,userId} = this.state;
        const {windowWidth} = this.props;

        return(

            <>
            <div className={'users_table_container'}>


            <div className={'lead_table table-responsive d_none' }>
                <Table striped bordered hover >

                    <thead>
                    <tr>
                        <th>Username</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email Address</th>
                        <th>Role</th>
                        <th>Date User Created</th>
                        <th>Status</th>
                        <th></th>
                        <th></th>

                    </tr>
                    </thead>

                    {user_list ? user_list.map((item,key) => {

                        return(
                            <React.Fragment key = {key}>

                                <tbody className={key % 2 !== 0 ? 'table_hovered' : null}>
                                <tr className={itemId === item.id ? ' removed' : null}>
                                    <td>{item.username}</td>
                                    <td>{item.first_name}</td>
                                    <td>{item.last_name}</td>
                                    <td>{item.email}</td>
                                    <td>{item && item.role && item.role.name}</td>
                                    <td>{new Date(item.created_at).toJSON().slice(0,10).split('-').reverse().join('/')}</td>
                                    <td style={{color: item.disabled_at || item.active === 'inactive' ? '#FF4040' : '#5AF1C1'}}>{item.disabled_at ? 'disabled' :item.active}</td>
                                    <td onClick={this.editModal.bind(this,item.id,item.disabled_at)}>

                                            <i className={'apa-pencil users_table_icons'}/>
                                    </td>

                                    <td>
                                        <i onClick={item.active === "active" ? this.handleConfirmDelete.bind(this,item.id,item.username) : null} className={'fas fa-ban default_hover'}/>

                                    </td>

                                </tr>

                                </tbody>

                            </React.Fragment>
                            )}) : null}


                </Table>

            </div>

                {/*----Edit User Modal----*/}

                <Modal show={show} onHide={this.handleClose} centered
                       className=" peril_modal user_update_modal " dialogClassName='discover_modal'>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                            <div className={''}>

                                <div className="row">
                                    <div className="col-4">
                                        <p>Username</p>
                                    </div>


                                    <div className="col-8">
                                        <input type={'text'} name={'username'} onChange={this.handleChange} required={true} defaultValue={current_user && current_user.username}/>
                                    </div>

                                    <div className="col-4">
                                        <p>First Name</p>
                                    </div>


                                    <div className="col-8">
                                        <input type={'text'} name={'first_name'} onChange={this.handleChange} required={true} defaultValue={current_user && current_user.username}/>
                                    </div>


                                    <div className="col-4">
                                        <p>Last Name</p>
                                    </div>


                                    <div className="col-8">
                                        <input type={'text'} name={'last_name'} required={true} onChange={this.handleChange} defaultValue={current_user && current_user.last_name}/>
                                    </div>



                                    <div className="col-4">
                                        <p>Email</p>
                                    </div>


                                    <div className="col-8">
                                        <input type={'email'} name={'email'} required={true} onChange={this.handleChange} defaultValue={current_user && current_user.email}/>
                                    </div>



                                    <div className="col-4">
                                        <p>Assign Role</p>
                                    </div>


                                    <div className="col-8 select_role">
                                        <div className="form-group selectdiv assign_role">

                                            <select className="form-control alterationTypeSelect" name="role" defaultValue={default_office} onChange={this.handleChange}>
                                                <option value={'DEFAULT'} disabled> </option>
                                                {roles && roles.map((item,key) => {

                                                    return(
                                                        <option key={key} value={item.id}>{item.name}</option>

                                                    )
                                                })}

                                            </select>
                                        </div>
                                    </div>



                                    <div style={{display: data.disabled_at === null ? 'block' : 'none' }} className="col-4">
                                        <p>Active</p>
                                    </div>


                                    <div className="col-8 " style={{display: data.disabled_at === null ? 'block' : 'none' }}>
                                        <input type="checkbox" id="active" defaultChecked={current_user.active === 'active' && true} name="active" onChange={this.handleCheckboxChange} />
                                        <label htmlFor="active"></label>
                                    </div>


                                </div>
                            </div>


                    </Modal.Body>


                    <Modal.Footer>

                        <Button variant="primary" onClick={this.handleSave}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>



                {/*----delete confirm modal---*/}

                <Modal show={delete_confirm} onHide={this.handleClose.bind(this,'deleteConfirmModal')}  className="peril_modal deleteConfirmModal user_update_modal" dialogClassName='discover_modal'>
                    <Modal.Header closeButton>
                        <Modal.Title>Are you sure?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>You are about to delete <span>{userName + '?'}</span></p>
                    </Modal.Body>


                    <Modal.Footer>

                        <Button variant="primary" className = "default_hover" onClick = {this.deleteUser.bind(this,userId)} >Delete</Button>
                        <Button className = "add_lead_prev_button" variant="secondary " onClick = {this.handleClose.bind(this,'deleteConfirmModal')}>Close</Button>

                    </Modal.Footer>
                </Modal>

            </div>

                {windowWidth <= 991 && <MobileTable leads_data = {user_list}/>}
                </>
        )
    }
}


function mapStateToProps(state) {

    return{
        windowWidth:state.appReducer.windowWidth,

    }
}

export default connect(mapStateToProps,null)(UsersTable)