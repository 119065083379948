import React, {useEffect, useState} from "react";
import axios from "axios";
import {url} from "../../config";


function QuickOverview(props){

    const [ytd,setYtd] = useState(false)
    const [mtd,setMtd] = useState(false)
    const [s_claims,setS_claims] = useState(false)
    const [thr_days,setThr_days] = useState(false)
    const [average,setAverage] = useState(false)
    const [inspected,setInspected] = useState(false)

    useEffect(() => {
        getIncomingYTD()
        getIncomingMTD()
        getIncoming30days()
        getSignedClimes()
        getAverage()
        getInspected30days()
    },[])

    const getIncomingYTD = () => {
        axios.post(url + '/incoming-filter', {type:'YTD'})
            .then(res => {
                setYtd(res.data)
            })
    }


    const getIncomingMTD = () => {
        axios.post(url + '/incoming-filter', {type:'MTD'})
            .then(res => {
                setMtd(res.data)
            })
    }

    const getIncoming30days = () => {
        axios.post(url + '/incoming-filter', {type:'30days'})
            .then(res => {
                setThr_days(res.data)
            })
    }

    const getInspected30days = () => {
        axios.get(url + '/inspected-count', {})
            .then(res => {
                setInspected(res.data)
            })
    }

    const getSignedClimes = () => {
        axios.post(url + '/signed-filter', {})
            .then(res => {
                setS_claims(res.data)
            })
    }


    const getAverage =() => {
        axios.get(url + '/average-leads', {})
            .then(res => {
                setAverage(res.data)
            })
    }


    const checkPercent = (value) => {
        if(value?.percent > 0) {
            return (
                <>
                    <span className={'up_static'}>{value?.count}</span>
                    <img src={require('../../assets/img/report1.png')} alt={'report1'}/>
                    <span className={'up_static'}><i className="fas fa-arrow-up"/>{value?.percent}%</span>
                </>
            )
        }
        else if(value?.percent <0) {
            return (
                <>
                    <span className={'static_down'}>{value?.count}</span>
                    <img src={require('../../assets/img/report2.png')} alt={'report2'}/>
                    <span className={'static_down'}><i className="fas fa-arrow-down"/>{value?.percent}%</span>
                </>
            )
        }else {
            return (
                <>
                    <span className={'static_normal'}>{value?.count}</span>
                    <img src={require('../../assets/img/report3.png')} alt={'report3'}/>
                    <span className={'static_normal'}><i className="fas fa-arrow-right"/>{value?.percent}%</span>
                </>
            )
        }
    }

    return (
        <>
            <div className={'report_title'}>
                <h2>
                    Quick Overview
                </h2>
            </div>

            <div className="row">
                <div className="col-lg-2 col-12 ">
                    <div className={'quick_overview'}>
                        <h4>Incoming Leads YTD</h4>
                        {checkPercent(ytd)}
                    </div>
                </div>
                <div className="col-lg-2 col-12 ">
                    <div className={'quick_overview'}>
                        <h4>Incoming Leads MTD</h4>
                        {checkPercent(mtd)}
                    </div>
                </div>
                <div className="col-lg-2 col-12 ">
                    <div className={'quick_overview'}>
                        <h4>Incoming Leads Last 30 Days</h4>
                        {checkPercent(thr_days)}
                    </div>
                </div>
                <div className="col-lg-2 col-12">
                    <div className={'quick_overview'}>
                        <h4>Inspected Leads Last 30 Days</h4>
                        {checkPercent(inspected)}

                    </div>
                </div>
                <div className="col-lg-2 col-12">
                    <div className={'quick_overview'}>
                        <h4>Average Leads/Weekly Last 30 Days</h4>
                        {checkPercent(average)}
                    </div>
                </div>
                <div className="col-lg-2 col-12">
                    <div className={'quick_overview'}>
                        <h4>Signed Claims Last 30 Days</h4>
                        {checkPercent(s_claims)}
                    </div>
                </div>
            </div>
        </>
    )
}

export default QuickOverview;