import React, { Component } from "react";

class Timer extends Component {
    constructor(props) {
        super(props);
        this.countDownId = null;
        this.state = {
            days: 0,
            hours: 0,
            minutes: '00',
            seconds: '00',
            expired: false,
            currentCount:0,
            intervalId:''
        };
    }

    componentDidMount = () =>  {
        var intervalId = setInterval(this.timer, 1000);
        this.setState({
            intervalId:intervalId
        })

    }


    componentWillUnmount  = () =>  {

        clearInterval(this.state.intervalId);
    }

    timer =  () => {

        this.setState({
            currentCount: this.state.currentCount + 1
        });
           this.secondsToHms(this.state.currentCount)
    }

    secondsToHms = (d) =>  {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 9 ? h : '0' + h;
        var mDisplay = m > 9 ? m : '0' + m;
        var sDisplay = s > 9 ? s : '0' + s;

        this.setState({
            hours:hDisplay,
            minutes:mDisplay,
            seconds:sDisplay
        })

    }



    render() {

        return (
            <span className="timer">
                {this.state.minutes}
                :
                {this.state.seconds}


            </span>
        );
    }
}
export default Timer;