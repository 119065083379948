import React from "react";



class LeadAdded extends React.Component{
    constructor(props) {
        super(props);
        this.state = {

        }
    }




    render() {
        return(

            <>
            <div className="col-12 add_lead_basic add_lead_contact_info">
                <h3>Related Contacts</h3>

                <div className="row">


                    <div className="col-4">
                        <p>Company Name</p>
                    </div>


                    <div className="col-8">
                        <input type={'text'}  />
                    </div>



                    <div className="col-4">
                        <p>First Name</p>
                    </div>
                    <div className="col-8">
                        <input type={'text'}  />
                    </div>

                    <div className="col-4">
                        <p>Last Name</p>
                    </div>


                    <div className="col-8">
                        <input type={'number'} name={'cell_number'}  />
                    </div>


                    <div className="col-4">
                        <p>Cell Number</p>
                    </div>


                    <div className="col-8">
                        <input type={'number'} name={'work_number'}  />
                    </div>


                    <div className="col-4">
                        <p>Work Number</p>
                    </div>


                    <div className="col-8">
                        <input type="number" name={'home_number'}  />
                    </div>


                    <div className="col-4">
                        <p>Home Number</p>
                    </div>


                    <div className="col-8">
                        <input type="email" name={'email'}  />
                    </div>


                    <div className="col-4">
                        <p>Email</p>
                    </div>


                    <div className="col-8">
                        <input type={'email'} />
                    </div>

                    <div className="col-4">
                        <p>Notes</p>
                    </div>


                    <div className="col-8">
                        <input type={'text'} />
                    </div>

                    <div className="col-4">
                        <p>Primary Contact</p>
                    </div>


                    <div className="col-8">
                        <input type="checkbox" id="contact_check" name="contact_check"  />
                        <label htmlFor="contact_check"></label>
                    </div>


                    <div className="col-4">
                        <p>Policy Holder</p>
                    </div>


                    <div className="col-8">
                        <input type="checkbox" id="contact_check" name="contact_check" />
                        <label htmlFor="contact_check"></label>
                    </div>

                </div>

            </div>
                </>
        )
    }
}

export default LeadAdded;