import React, {useCallback, useEffect, useState} from 'react';
import SideBar from "../header/SideBar";
import Navbar from "../header/Navbar";
import {useDispatch, useSelector} from "react-redux";
import Users from "./components/Users";
import Chat from "./components/Chat/Chat";
import UserInfo from "./components/UserInfo";
import {getLeads, messageLeads} from "../../redux/actions";
import axios from "axios";
import {url} from "../../config";
import CallModal from "../leadDetails/CallModal";




function Messenger(props) {
    const dispatch =useDispatch()
    const leads = useSelector((state)=>state.leadsReducer.message_leads)
    const toggled = useSelector(state=>state.appReducer.toggled)
    const [currentLead,setCurrentLead] = useState()
    const [convId,setConvId] = useState('')
    const [callModal,setCallModal] = useState(false)
    const [nonReadMessage,setNonReadMessage] = useState([])


    useEffect((props)=>{

        dispatch(messageLeads('*'))
        getUnreadMessage()
    },[])



    useEffect((props)=>{
        setCurrentLead( leads && leads[0])
    },[leads])

    const search = () =>{
        console.log(1);
    }
    const getCurrentLead = (id,conv_id,contact_id)=>{
       setCurrentLead(leads.filter(item=> item.id ===id)[0]);
        axios.post(url + '/update-message-status', {contactId:contact_id}).then(r=>{
            setNonReadMessage(nonReadMessage=>nonReadMessage.filter(item=>item != conv_id))
        })

    }

    const getUnreadMessage =async () =>{
      const conv_Ids = await axios.get(url + '/get-unread-message', {})
        setNonReadMessage(conv_Ids.data.map(v=>v.id));
    }


    const nonReadMess =(id)=>{
        setNonReadMessage(nonReadMessage=>[...nonReadMessage,id])
    }

    const getConvId = (id) =>{
        setConvId(id)
    }



    const handleCall = useCallback(async (type=false)=>{

        axios.get(url + 'get-auth-user-profile', {})
            .then( async res => {
                let data = {
                    roomName:currentLead?.LIN,
                    number:currentLead?.contact?.cell_number,
                    type:type,
                    folder_id:currentLead?.folder_id,
                    auth_name:res.data.first_name +' '+ res.data.last_name

                }
                const response = await axios.post(url + '/room_create',data);
                window.location.href = response.data.localPath
            })



    },[currentLead])



    return (
       <>
           <div className={"d-flex" + (toggled ? ' toggled' : '')} id="wrapper">
               <SideBar isAuth = {props.isAuth}/>
               <div id="page-content-wrapper">
                   <div>
                       <Navbar  />
                       <div className={'col-12'}>
                           <div className="row">
                               <div className="col-lg-6 col-12">
                                   <div className={'page_title users_title'}>
                                       <h2>Messages</h2>
                                   </div>
                               </div>
                               {/*<div className="col-lg-6 col-12 leads_content">*/}
                               {/*    <div className={'leads_search '}>*/}
                               {/*        <button className={'default_hover'} onClick={search} > <i className={'apa-icon-search'}></i>Search</button>*/}
                               {/*    </div>*/}
                               {/*</div>*/}
                           </div>
                       </div>
                   </div>
                   <div className={'container-fluid'}>
                        <div className="row">
                            <div className="col-lg-3">
                                <Users leads={leads}
                                       getCurrentLead={getCurrentLead}
                                       currentLead={currentLead}
                                       nonReadMessage={new Set(nonReadMessage.filter(item=>Number(item != convId)))}


                                />
                            </div>
                            <div className="col-lg-5">
                                <Chat currentLead={currentLead}
                                      nonReadMess={nonReadMess}
                                      getConvId ={getConvId}
                                />
                            </div>
                            <div className="col-lg-4">
                                <UserInfo currentLead={currentLead}/>
                            </div>
                            <button onClick={()=>setCallModal(true)} className="call_button button-small">
                                <span className="label-hidden">Initate Call</span>
                                <i className="apa-icon-phone call"></i>

                            </button>
                            <CallModal handleClose = {()=>setCallModal(false)}
                                       call_show = {callModal}
                                       handleCall={handleCall}
                            />
                        </div>
                   </div>
               </div>
           </div>
       </>
    );
}

export default Messenger;