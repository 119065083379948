import {checkNull, hrefLinkWithState} from '../Helper'

function Property(option=null) {


        if(option === 'property') {
            return   [
                {
                    name: 'Property ID',
                    selector: row =>'#P-'+row?.lead_building?.id,
                    sortable: true,
                },
                {
                    name: 'Address',
                    selector: row => checkNull(row?.street_number)+' '+checkNull(row?.street_name)+', '+checkNull(row?.city)+' '+checkNull(row?.state)+' '+checkNull(row?.zip)+',',
                    sortable: true,
                },
                {
                    name: 'Property Type',
                    selector: row => row?.lead_building?.building_type?.type,
                    sortable: true,
                },
                {
                    name: 'Location',
                    selector: row => checkNull(row?.city)+' '+checkNull(row?.state),
                    sortable: true,
                },
                {
                    name: 'Leads',
                    selector: row => row?.loss_address_lead.length,
                    sortable: true,
                },
                {
                    name: 'Claims',
                    selector: row => '',
                    sortable: true,
                },
                {
                    name: '',
                    selector: row => hrefLinkWithState(`/property/${row?.lead_building?.id}/client-info/${row?.client_id}`,
                        {address:row?.id,
                            mortgage:row?.mortgage_company?.id,
                            building:row?.lead_building?.id,
                            client_id:row?.client_id
                        }),
                    sortable: true,
                },
            ]
        }
    return  [
        {
            name: 'Property ID',
            selector: row =>'#P-'+row.id,
            sortable: true,
        },
        {
            name: 'Address',
            selector: row =>  checkNull(row?.building?.street_number)+' '+checkNull(row?.building?.street_name)+', '+checkNull(row?.building?.city)+' '+checkNull(row?.building?.state)+' '+checkNull(row?.building?.zip)+',',
            sortable: true,
        },
        {
            name: 'Property Type',
            selector: row => row?.building_type?.type,
            sortable: true,
        },
        {
            name: 'Location',
            selector: row => checkNull(row?.building?.city)+' '+checkNull(row?.building?.state),
            sortable: true,
        },
    ]
    
}

export default (Property)
