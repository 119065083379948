import {getDate, hrefLink} from '../Helper'

function Contacts(option=null) {

    let general = [
        {
            name: 'Client ID',
            selector: row =>'#-'+row.id,
            sortable: true,
        },
        {
            name: 'Insured Name',
            selector: row => row?.insured_name,
            sortable: true,
        },
        {
            name: 'Location',
            selector: row => row?.location,
            sortable: true,
        },
        {
            name: 'Client Since',
            selector: row => getDate(row?.client_since),
            sortable: true,
        },
    ]

    if (option === 'contact'){
        return general.concat([
            {
                name: 'Leads',
                selector: row => row?.leads?.length,
                sortable: true,
            },
            {
                name: 'Claims',
                selector: row => row?.signed_claims?.length,
                sortable: true,
            },
            {
                name: '',
                selector: row => hrefLink(row,'/client-info/'),
                sortable: true,
            },
        ])
    }
    return  general

}

export default (Contacts)
