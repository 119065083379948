import React from "react";
import axios from "axios";
import {url} from "../../config";
import NumberFormat from 'react-number-format';

const mortgage_info = {};

class MortgageInformation extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            inputEdit:false
        }
    }


    handleEdit = () => {
        mortgage_info.lead_id = this.props.lead_body[0].id;
        this.setState({
            inputEdit:!this.state.inputEdit
        })
    }

    handleChangeData = (event) => {
        const {name,value} = event.target;
        mortgage_info[name] = value;
    }

    closeEdit = () =>{
          this.setState({
            inputEdit: !this.state.inputEdit,
          });
        }

    handleSaveData = () => {
        const data = {};
        data.mortgage_info = mortgage_info;


        axios.post(url + 'update-lead-loss-address', data)

            .then(res => {

                    this.props.myCallback(true)

                this.setState({
                    inputEdit:!this.state.inputEdit
                })
            })
            .catch(err => {
                console.log(err)
            })

    }


    render() {
        const {lead_body} = this.props;

        return(
            <div className={'add_lead_basic lead_details_basic_info'}>

                <div className={'leads_details_edit mortgage_title'}>
                    <h3>Mortgage Company Info</h3>
                    {this.state.inputEdit ?
                        <>
                          <span onClick={this.handleSaveData}>Save</span>
                          <div className={'close_b'} onClick={this.closeEdit}><i className="fas fa-times"/></div>
                        </>
                        :
                        <i onClick={this.handleEdit} className={'apa-pencil'}></i>  }
                </div>

                <div className="row">


                    <div className="col-4">
                        <p>Company Name</p>
                    </div>

                    <div className="col-8">
                        <input defaultValue={lead_body[0] && lead_body[0].mortgage_company && lead_body[0].mortgage_company.company_name} name={'company_name'} onChange={this.handleChangeData} className={this.state.inputEdit ? ' edit_inputs' : ''}  readOnly={!this.state.inputEdit}/>
                    </div>


                    <div className="col-4">
                        <p>Address</p>
                    </div>

                    <div className="col-8">
                        <input defaultValue={lead_body[0] && lead_body[0].mortgage_company && lead_body[0].mortgage_company.address} name={'address'} onChange={this.handleChangeData} type={'text'} className={this.state.inputEdit ? ' edit_inputs' : ''}   readOnly={!this.state.inputEdit} />
                    </div>


                    <div className="col-4">
                        <p>Phone Number</p>
                    </div>


                    <div className="col-8">
                        {this.state.inputEdit ? (
                            <NumberFormat defaultValue={lead_body[0] && lead_body[0].mortgage_company && lead_body[0].mortgage_company?.phone_number} format="(###) ###-####" name={'phone_number'} onChange={this.handleChangeData} type={'text'} className={'edit_inputs'}   />

                        ) : (
                            <p style={{fontFamily:'openSansRegular'}}>{lead_body[0] && lead_body[0].mortgage_company && lead_body[0].mortgage_company?.phone_number}</p>

                        )}
                    </div>


                    <div className="col-4">
                        <p>Email</p>
                    </div>


                    <div className="col-8">
                        <input defaultValue={lead_body[0] && lead_body[0].mortgage_company && lead_body[0].mortgage_company.email} name={'email'} onChange={this.handleChangeData} type={'text'} className={this.state.inputEdit ? ' edit_inputs' : ''} readOnly={!this.state.inputEdit} />
                    </div>

                </div>
            </div>
        )
    }
}


export default MortgageInformation;