import React, {useEffect, useState} from "react";
import axios from "axios";
import { url } from "../../config";
import {connect, useDispatch, useSelector} from "react-redux";
import Input from "../Inputs/Input";
import InputSelect from "../Inputs/InputSelect";
import {getMortgage} from "../../redux/actions";
import NumberFormat from "react-number-format";

const mortgage_info = {};


function MortgageCompanies(props){

  const [inputEdit,setInputEdit] = useState(false)

  const mortgage = useSelector(state => state.propertyReducer.mortgage)
  const dispatch = useDispatch()

  useEffect(() => {
      dispatch(getMortgage(props.mortage_id))
  },[])

 const handleEdit = () => {
     mortgage_info.lead_id = mortgage.lead_id;
     setInputEdit(!inputEdit)
  };

 const  handleChangeData = (event) => {
    const { name, value } = event.target;
     mortgage_info[name] = value;
  };

    const closeEdit = () =>{
        setInputEdit(!inputEdit)
    }

 const handleSaveData =  () => {
    const data = {mortgage_info};
     axios.post(url + "update-lead-loss-address", data).then( response => {
         console.log(response)
          dispatch(getMortgage(mortgage.id))
           setInputEdit(!inputEdit)
      });
  };

    return (

      <div className={"add_lead_basic lead_details_basic_info"}>
        <div className={"leads_details_edit mortgage_title"}>
          <h3>Mortgage Company Info</h3>
          {inputEdit ?
              <>
                  <span onClick={handleSaveData}>Save</span>
                  <div className={'close_b'} onClick={closeEdit}><i className="fas fa-times"/></div>
              </>
              :
            <i onClick={handleEdit} className={"apa-pencil"}/>
          }
        </div>
              <div className="row">
                  <Input options={{label:'Company Name',name:'company_name',type:'text',value:mortgage?.company_name}}
                         inputEdit={inputEdit}
                         handleChange={handleChangeData}
                  />

                  <Input options={{label:'Address',name:'address',type:'text',value:mortgage?.address}}
                         inputEdit={inputEdit}
                         handleChange={handleChangeData}
                  />

                  <div className="col-4">
                      <p>Phone Number</p>
                  </div>
                  <div className="col-8">
                      {inputEdit ? (
                          <NumberFormat defaultValue={mortgage?.phone_number} format="(###) ###-####" name={'phone_number'} onChange={handleChangeData} type={'text'} className={'edit_inputs'} />
                      ) : (
                          <p style={{fontFamily:'openSansRegular'}}>{mortgage?.phone_number}</p>
                      )}
                  </div>
                  <Input options={{label:'Email',name:'email',type:'text',value:mortgage?.email}}
                         inputEdit={inputEdit}
                         handleChange={handleChangeData}
                  />
              </div>
      </div>
    );
}
export default connect(null,null)(MortgageCompanies);

