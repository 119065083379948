import React, {useEffect, useState} from "react";
import {Link,
    useHistory, useLocation} from "react-router-dom";
import {connect} from "react-redux";




function DocFile(props) {
    const location = useLocation()
    const history = useHistory()

    const {data} = props
    return(
        <>

            <div className="row">
                {data && data.map((v,k)=> {
                        if (v.folder_id) {
                            return (
                                <div className="col-lg-4 col-12" key={k}>
                                    <Link to={{
                                        pathname: `/document/${v.id}/client-info/${v.contact_id}`,
                                        state: v?.loss_address?.street_number + ' ' + v?.loss_address?.street_name + ', ' + v?.loss_address?.city + ' ' + v?.loss_address?.state + ' ' + v?.loss_address?.zip

                                    }}>
                                        <div className={'folders_content'} style={{height: '138px'}}>
                                            <span>Property</span>
                                            <p>
                                                {v?.loss_address?.street_number} {v?.loss_address?.street_name}, {v?.loss_address?.city} {v?.loss_address?.state} {v?.loss_address?.zip}
                                            </p>
                                        </div>
                                    </Link>
                                </div>

                            )
                        }
                    }
                )}


            </div>
        </>
    )

}

export default connect(null,null)(DocFile);