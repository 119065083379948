import React from 'react';
import Login from "./components/Login";
import ForgotPass from "./components/ForgotPass";
import ResetPassConfirm from "./components/ResetPassConfirm";
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import Dashboard from "./components/Dashboard";
import AddLead from "./components/AddLead";
import axios from "axios";
import Notifications from "./components/Notifications";
import Search from "./components/Search";
import NewLeads from "./components/NewLeads";
import Users from "./components/Users";
import {url} from "./config";
import LeadDetails from "./components/LeadDetails";
import Contacts from "./components/Contacts";
import Claims from "./components/Claims";
import Reports from "./components/Reports/Reports";
import Messages from "./components/Messages";
import Settlements from "./components/Settlements";
import Email from "./components/Email";
import { useIdleTimer } from 'react-idle-timer'
import ScrollToTop from "react-scroll-to-top";
import MobileNotifications from "./components/MobileNotifications";
import ContactsPage from "./components/ContactsPage";
import DocsClaims from "./components/leadDetails/Documents/DocsClaims";
import ClientInfo from "./components/contacts/ClientInfo";
import Basic from "./components/DataTables/Basic";
//styles
import './assets/css/fontello.css';
import './assets/css/side_bar.css';
import './assets/css/responsive_styles.css';
import '../src/assets/css/style.css';
import Test from "./components/Test";
import Messenger from "./components/Messenger/Messenger";
import TwilioVideo from "./components/TwilioVideo";
import Room from "./video/Room";
import Disconnected from "./video/Disconnected";





axios.defaults.withCredentials = true;


function App() {

    // const [toggled, setToggled] = useState(false);




    // const myCallback = (dataFromChild) => {
    //
    //     setToggled(dataFromChild)
    //
    // }


    // const  checkFunction = () => {
    //     axios.post(url + 'check-auth', {
    //
    //     })
    //         .catch(err => {
    //             if (err.response && err.response.status === 401) {
    //                 localStorage.removeItem('token')
    //                 window.location.href = '/login'
    //             }
    //         })
    // }



  const   handleLogout = () => {
        axios.post(url + 'logout')
            .then(res => {
                localStorage.removeItem('token')
                window.location.reload();
            })
            .catch(err => {
                console.log(err)
            })


    }


    const    PrivateRoute =  ({component: Component, authed, data , ...rest}) => {
        return (
            <Route
                {...rest}
                render={(props) => authed
                    ? <Component {...props}  isAuth = {localStorage.getItem('token')} />
                    : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
            />
        )
    }

   const  handleOnAction =  () => {

    }

   const  handleOnActive =  () =>  {

    }

   const  handleOnIdle =  () =>  {
        const user = localStorage.getItem('token');



       if(user){
           handleLogout();
       }

    }


    useIdleTimer({
        timeout: 900000,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
    })

    return (
        <>

            <ScrollToTop  color="black" />


            <BrowserRouter>

                <Switch>


                    <Route path="/login" render={props => <Login {...props}  />}/>
                    <Route path="/pass-forgot" render={props => <ForgotPass {...props}  />}/>
                    <Route path="/reset-confirm" render={props => <ResetPassConfirm {...props}  />}/>
                    <Route path="/clients" render={props => <ContactsPage {...props}  />}/>
                    <Route path="/client-info/:contactId" render={props => <ClientInfo {...props}  />}/>

                    <Route path="/property/:id/client-info/:contactId/" render={props => <ClientInfo {...props}  />}/>
                    <Route path="/document/:docId/client-info/:contactId/" render={props => <ClientInfo {...props}  />}/>

                    {/* <Route path="/document/:cin" render={props => <DocsCin {...props}  />}/> */}
                    <Route path="/lead/:leadId/document/:id" component={props => <DocsClaims {...props}  />}/>
                    <Route path="/add-lead" render={props => <AddLead {...props}  />}/>
                    <Route path="/search" render={props => <Search {...props}  />}/>
                    <Route path="/mobile-notifications" render={props => <MobileNotifications {...props}  />}/>
                    <Route path="/claims" render={props => <Claims {...props}  />}/>
                    <Route path="/contacts" render={props => <Contacts {...props} isAuth = {localStorage.getItem('token')}  />}/>
                    <Route path="/settlements" render={props => <Settlements {...props} isAuth = {localStorage.getItem('token')} />}  />}/>
                    <Route path="/email-templates" render={props => <Email {...props} isAuth = {localStorage.getItem('token')} />}  />}/>
                    {/*<Route path="/messages" render={props => <Messages {...props} isAuth = {localStorage.getItem('token')} />}   />}/>*/}
                    <Route path="/messages" render={props => <Messenger {...props} isAuth = {localStorage.getItem('token')} />}   />}/>
                    <Route path="/reports" render={props => <Reports {...props}  isAuth = {localStorage.getItem('token')} />} />
                    <Route path="/leads/:leadId" render={props => <LeadDetails {...props}  />}/>
                    <Route path="/users" render={props => <Users {...props} isAuth = {localStorage.getItem('token')}   />}/>
                    <Route path="/leads" render={props => <NewLeads {...props} isAuth = {localStorage.getItem('token')}  />}/>
                    <Route path="/notifications" render={props => <Notifications {...props} />}/>

                    <Route path="/table" render={props => <Basic {...props} />}/>
                    <Route path="/test" render={props => <Test {...props} />}/>
                    {/*<Route path="/video" render={props => <TwilioVideo {...props} />}/>*/}
                    <Route path="/video/:__token" render={props => <Room {...props} />}/>
                    <Route path="/disconnected" render={props => <Disconnected {...props} />}/>
                    <PrivateRoute authed={localStorage.getItem('token') === '1' } path='/dashboard' component={Dashboard} />
                    <Redirect to="/dashboard" />

                </Switch>

            </BrowserRouter>
        </>
    );
}

export default App;
