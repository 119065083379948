import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import InputSelect from "../Inputs/InputSelect";


function GlobalFilters(props) {

    const teams = useSelector(state =>state.leadsReducer.teams)
    const adjusters = useSelector(state =>state.leadsReducer.adjusters)
    const sources = useSelector(state =>state.leadsReducer.sources)
    const cities = useSelector(state =>state.leadsReducer.cities)

    useEffect(() => {

    }, [])
    const {handleChangeData,type} = props

    return (

        <>
            <div className={'row add_lead_basic mx-0 px-1'}>
                {type==='inspected' || type==='claims'?
                    <>
                        <div className={'col-md-2 col-6'}>
                            <InputSelect options={{label:'Team',name:'team_id',class:'leads_details_selects'}}
                                         inputEdit={true}
                                         handleChange={(e) => handleChangeData(e,props.type)}
                                         col={12}
                                         data={teams}
                                         path={'name'}
                                         value={'id'}
                            />
                        </div>
                        <div className={'col-md-2 col-6'}>
                            <InputSelect options={{label:'Lead PA',name:'pa_id',class:'leads_details_selects'}}
                                         inputEdit={true}
                                         handleChange={(e) => handleChangeData(e,props.type)}
                                         col={12}
                                         data={adjusters?.users}
                                         path={'username'}
                                         value={'id'}
                            />
                        </div>
                        <div className={'col-md-2 col-6'}>
                            <InputSelect options={{label:'Inspector',name:'inspector_id',class:'leads_details_selects'}}
                                         inputEdit={true}
                                         handleChange={(e) => handleChangeData(e,props.type)}
                                         col={12}
                                         data={adjusters?.users}
                                         path={'username'}
                                         value={'id'}
                            />
                        </div>
                        <div className={'col-md-2 col-6'}>
                            <InputSelect options={{label:'Claim Signer',name:'signer_id',class:'leads_details_selects'}}
                                         inputEdit={true}
                                         handleChange={(e) => handleChangeData(e,props.type)}
                                         col={12}
                                         data={adjusters?.users}
                                         path={'username'}
                                         value={'id'}
                            />
                        </div>
                    </>
                    :""}
                <div className={'col-md-2 col-6'}>
                    <InputSelect options={{label:'Lead Source',name:'source_id',class:'leads_details_selects'}}
                                 inputEdit={true}
                                 handleChange={(e) => handleChangeData(e,props.type)}
                                 col={12}
                                 data={sources}
                                 path={'name'}
                                 value={'id'}
                    />
                </div>
                {props.version !=='mini'?
                    <div className={'col-md-2 col-6'}>
                        <InputSelect options={{label:'County',name:'city',class:'leads_details_selects'}}
                                     inputEdit={true}
                                     handleChange={(e) => handleChangeData(e,props.type)}
                                     col={12}
                                     data={cities}
                                     path={'city'}
                        />
                    </div>
                    :''}

            </div>
        </>
    )
}

export default connect(null,null)(GlobalFilters)