import React, {useEffect, useState} from 'react';
import ImageUploading from "react-images-uploading";
import axios from "axios";
import {url} from "../../../../config";
import '../../../../assets/css/messanger.css'




function Write({currentLead,user,loadingCheck,loading}) {

    const [images,setImages] = useState([])
    const [send_message,setSend_message] = useState(true)
    const [message, setMessage] = useState('');
    const [files, setFiles] = useState([]);


    const shortFilename =(name) =>{
        let split = name.split('.');
        let filename = split[0];
        let extension = split[1];

        if (filename.length > 8) filename = filename.substring(0, 8);

        return filename+'..' + '.' + extension;
    }

    const handleSave =   () => {
        let formData = new FormData();
        if(images.length > 0){
            images.map((v,k)=>{
                formData.append("files",v.file);
            })
        }
        if(files.length >0){
            files.map((v,k)=>{
                formData.append("text",v);
            })
        }

         let data ={
                sendMessage:message,
                contactId:currentLead.contact_id,
                userId:user.id,
                cellNumber:currentLead?.contact?.cell_number,
        }


        formData.append('data',JSON.stringify(data))
        loadingCheck(true)
        axios.post(url+'/send-message',formData).then(res =>{
            loadingCheck(false)
            setImages([])
            setFiles([])
            setSend_message(true)
        })
        setMessage('')


    };

    const handleFile = (e) => {
        setFiles(files.concat(Array.from(e.target.files)))

    };

    const openMedia = () => {
        setFiles([])
        setImages([])
        setSend_message(!send_message)
    }



    return (
        <>
            <div className="type_msg">
                <div className="input_msg_write">
                    <i onClick={openMedia} className={'apa-icon-add message_add default_hover'}></i>
                    <textarea
                        rows={2} className="write_msg" placeholder="Type a message"
                        onChange={event => setMessage(event.target.value)}
                        value={message}
                    >
                    </textarea>
                    {loading ?
                        (
                            <div className={'spinner-grow text-primary'} style={{width:'1.3rem',height: '1.3rem',position:'absolute',top:'22px',right:'22px'}}/>
                        ) :(
                            <button  onClick={handleSave} className="msg_send_btn" type="button"><i className="apa-send" aria-hidden="true"></i></button>
                        )}


                </div>

                <div className={send_message ? 'd-none' : 'add_data'}>
                    <div>
                        <label onChange={(event)=>handleFile(event)} htmlFor="formId">
                            <input name="" type="file" id="formId" hidden multiple />
                            <span ><i className="apa-icon-notes" aria-hidden="true"></i></span>
                        </label>
                    </div>

                    <div className={'messenger_upload_img'}>


                        <ImageUploading
                            value={images}
                            multiple
                            onChange={(imageList, addUpdateIndex)=> setImages(imageList)}
                            dataURLKey="data_url"
                        >


                            {({
                                  imageList,
                                  onImageUpload,
                                  onImageRemoveAll,
                                  onImageUpdate,
                                  onImageRemove,
                                  isDragging,
                                  dragProps,
                              }) => (
                                // write your building UI

                                <div className="upload__image-wrapper">

                                    <button
                                        style={isDragging ? { color: 'red' } : undefined}
                                        onClick={onImageUpload}
                                        {...dragProps}
                                    >

                                        <span><i className="apa-gallery" aria-hidden="true"></i></span>
                                    </button>
                                    &nbsp;

                                    {/*{images.length > 0 && <button onClick={ onImageRemoveAll}>Remove Photo</button>}*/}
                                    {imageList.length>0 ||Array.from(files).length>0  ?
                                        (
                                            <div className={'image_box_all'}>

                                                {imageList.map((image, index) => (

                                                    <div key={index} className="image-item">
                                                        <div>

                                                            <img src={image['data_url']} alt=""  />
                                                            <div className={'remove_btn_img'} onClick={()=>onImageRemove(index)}>
                                                                <div className={'rem_b'}>x</div>
                                                            </div>

                                                        </div>
                                                        <div className="image-item__btn-wrapper">
                                                            {/*<button onClick={() => onImageUpdate(index)}>Update</button>*/}
                                                            {/*<button onClick={() => onImageRemove(index)}>Remove</button>*/}

                                                        </div>
                                                    </div>
                                                ))}
                                                { files.map((v,k)=>(
                                                    <div key={k} className="image-item" style={{marginTop:'-1px'}}>
                                                        <div className={'file_box_all'}>
                                                            <div style={{alignItems: 'center',display:'flex'}}>
                                                                <i style={{fontSize:'25px'}} className={'apa-icon-claims'}></i>
                                                                <div>
                                                                    {shortFilename(v.name)}
                                                                </div>
                                                                <div style={{top:'-29px',right:'149px'}} className={'remove_btn_img'} onClick={()=>setFiles(files=>files.filter((item, index) => k !== index))} >
                                                                    <div className={'rem_b'}>x</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                ))
                                                }
                                            </div>
                                        )
                                        :''}


                                </div>
                            )}
                        </ImageUploading>
                    </div>

                </div>
            </div>
        </>
    );
}

export default Write;