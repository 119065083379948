import React from "react";
import SideBar from "./header/SideBar";
import Navbar from "./header/Navbar";
import "../assets/css/contacts_style.css";
import {Link} from "react-router-dom";
import {connect} from 'react-redux'
import axios from 'axios'
import {url} from "../config";


class Contacts extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            contacts_count:0
        }
    }


    getAllContacts = () => {
        axios.get(url + '/get-clients-contact-count')
            .then(res => {
              this.setState({
                  contacts_count:res.data.count
              })
            })
            .catch(err => {
                console.log(err)
            })
    }


    componentDidMount() {
        this.getAllContacts();
    }


    render() {
        const {toggled} = this.props;
        const {contacts_count} = this.state;
        return(
            <>

                <div className={"d-flex" + (toggled ? ' toggled' : '')} id="wrapper">
                    <SideBar/>
                    <div id="page-content-wrapper">
                        <div>
                            <Navbar/>
                            <div className={'col-12'}>

                                <div className="row">
                                    <div className="col-lg-6 col-12">
                                        <div className={'page_title users_title'}>
                                            <h2>Contacts</h2>
                                        </div>

                                    </div>

                                    <div className="col-lg-6 col-12 leads_content">

                                        <div className={'leads_search'}>

                                            <button className={'default_hover'} onClick={this.handleAddUser}><i className={'apa-icon-search'}></i>Search by Client Name or Client ID</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'contact_folder_content'}>
                        <div className={'container-fluid'}>
                        <div className="row">
                            <div className="col-lg-4 col-12">
                                <Link to ={{
                                    pathname: "/clients"
                                }}>

                                <div className={'folders_content contact_folders'}>

                                    <p>Clients</p>
                                    <span>{contacts_count > 0  && contacts_count} Contacts</span>
                                </div>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-12">
                                <div className={'folders_content contact_folders'} >
                                    <p>Insurance Companies</p>
                                    <span>265 Contacts</span>
                                </div>
                            </div>
                            <div className="col-lg-4 col-12">
                                <div className={'folders_content contact_folders'} >
                                    <p>Vendors</p>
                                    <span>90 Contacts</span>
                                </div>
                            </div>
                        </div>
                        </div>

                    </div>

                    </div>
                </div>

            </>
        )
    }
}


function mapStateToProps(state) {

    return{
        toggled:state.appReducer.toggled
    }

}




export default connect(mapStateToProps,null)(Contacts)
