import React from 'react';
import '../assets/css/room.css'

const Disconnected = () => {
    return (
       <>
           <div className={'you_dis'}>
               <div className={'center_box'}>
                   <i className="far fa-phone-slash"></i>
                   <p style={{fontSize:'30px'}}>Your call ended...</p>
               </div>
           </div>
       </>
    );
};

export default Disconnected;