import React from "react";
import { Link } from "react-router-dom";
import LogEntryModal from "./leadDetails/LogEntryModal";
class MobileTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: null,
      lead_body: [],
      show: false,
    };
  }

  componentDidMount() {
    if (window.location.pathname.replace("/", "") === "users") {
      this.setState({
        users: this.props.leads_data,
      });
    }
  }
  addLogEntry = (item) => {
    this.setState({
        lead_body:[item],
        show:true
    })
}
myCallback = (dataFromChild) => {
    if(dataFromChild){
        this.setState({
            lead_body:'',
            updated:dataFromChild
        })
        //this.getCurrentLead();
    }
}
modalCallBack = (parentDate,name = '') => {
    this.setState({
        show:parentDate
    })
}
  render() {
    const { leads_data } = this.props;

    return (
      <>
        {leads_data &&
          leads_data.map((item, key) => {
            return (
              <React.Fragment key={key}>
                <div
                  className={
                    key % 2 === 0
                      ? "table_active_background"
                      : " table_inactive_background"
                  }
                >
                  <div className={"container-fluid"}>
                    <div className={"mobile_table_content"}>
                      <div className="row">
                        <div className="col-5">
                          <div>
                            <h3>Insured Name</h3>
                            <h3>Location</h3>
                            <h3>Lead Source</h3>
                            <h3>Peril</h3>
                          </div>
                        </div>

                        <div className="col-5">
                          <p> {item.client?.contacts[0]?.first_name} {item.client?.contacts[0]?.last_name}</p>
                          <p>{item?.loss_address?.city}, {item?.loss_address?.state}</p>
                          <p>{item.source ? item.source.name : "\u00A0"}</p>
                          <p>{item?.loss_info && item?.loss_info?.peril?.name}</p>
                        </div>
                        <div className="col-2 mobile_table_icons">
                          <div>
                            <Link
                              to={{
                                pathname: "/leads/" + item.id,
                                state: item.id,
                              }}
                            >
                              {" "}
                              <i className={"apa-icon-view"}></i>
                            </Link>
                          </div>

                          <div>
                            <i onClick={() => this.addLogEntry(item)}  className={"apa-icon-add"}></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <LogEntryModal lead_body = {this.state.lead_body} show = {this.state.show} myCallback = {this.myCallback} modalCallBack = {this.modalCallBack}/>
              </React.Fragment>
            );
          })}
      </>
    );
  }
}

export default MobileTable;
