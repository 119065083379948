import React, {useEffect, useState} from "react";
import {connect,} from 'react-redux'


const property = {};

function Select(props){

   const {handleChangeData ,data , name,path , value=null} = props

    return(
        <>
            <div
                className={"form-group selectdiv "+(props.style ? ' select_content' : 'leads_details_selects')}>
                <select
                    name={name}
                    style={props.style ? {} : {width:'160px',float:'left'}}
                    className={
                        "form-control alterationTypeSelect"+(props.style ? ' default_select' : '')
                    }
                    onChange={(event)=>handleChangeData(event)}
                >
                    <option value={""} disabled selected></option>
                    {data && data.map((item, key) => {
                        return (
                            <option
                                value={(item[path] && !value) ? item[path] : item[value]}
                                key={key}
                            >
                                {item[path] ? item[path] : item}
                            </option>
                        );
                    })}
                </select>
            </div>
        </>
    )
}

export default connect(null,null)(Select);