import React from "react";
import ImageUploading from "react-images-uploading";
import axios from "axios";
import {uploads_url, url} from "../../config";
import {connect} from 'react-redux'
import {Alert} from "react-bootstrap";
import {GETUSERINFO} from "../../redux/types";

let formData = new FormData();
let data = {};
let profile_img = ''



class Profile extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            images:[],
            user_avatar: null,
            offices:'',
            default_office: 'DEFAULT',
            deleteSuccess:false,
            success_show:false
        }
    }


    handleUploadImage = (imageList, addUpdateIndex) => {

        this.setState({
            images:imageList
        })
    };


    getOffices = () => {
        axios.get(url + 'get-offices')
            .then(res => {
                this.setState({
                    offices:res.data
                })

            })
            .catch(err => {
                console.log(err)
            })
    }

    componentDidMount() {
        profile_img = this.props.user_avatar;
        this.getUserProfile();
        this.getOffices();
    }

    getUserProfile = () => {
        axios.get(url + 'get-auth-user-profile', {
        })
            .then(res => {

                if(res){

                    this.props.getUserInfo(res.data)

                    data.first_name =  res.data.first_name
                    data.last_name = res.data.last_name
                    data.office= res.data.office_id
                    data.work_number= res.data.work_number
                    data.cell_number= res.data.cell_number

                    this.setState({
                        default_office: res.data.office_id
                    })
                }

            })
            .catch(err => {
                console.log(err)
                if (err.response && err.response.status === 401) {
                    window.location.href = '/login'
                }
            })
    }


    saveDeletedImg = () => {
       this.setState({
           deleteSuccess:true,
       })

       return   profile_img = uploads_url +'static/default_avatar/avatar.png'
    }


    handleDeleteAvatar = () => {
        axios.post(url + 'remove-profile-photo')
            .then(res => {

            })
            .catch(err => {
                console.log(err)
            })
    }

    handleChangeProfile = (event) => {

        const {name,value} = event.target;

        if(name === 'office'){
            this.setState({default_office: event.target.value});
        }

        if(value.trim()){
            data[name] = value
        }
    }



    handleSaveData = () => {

        formData = new FormData();
        formData.append('first_name',data.first_name ? data.first_name : null )
        formData.append('last_name',data.last_name ? data.last_name : null )
        formData.append('office',data.office && data.office.length > 0 ? data.office : this.props.user_data.office_id)
        formData.append('work_number',data.work_number ? data.work_number : null)
        formData.append('cell_number',data.cell_number ? data.cell_number : null)


        if(this.state.images && this.state.images.length > 0 ){

            formData.append("avatar",this.state.images[0].file);
        } else {
            formData.append("avatar",null);
        }

        if(this.state.deleteSuccess){
            this.handleDeleteAvatar();
        }

        axios.post(url + 'change-profile', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(res => {
            this.setState({
                success_show:true
            })

                return setInterval(() => {
                    this.setState({
                        success_show:false
                    })
                },2000)
            })

    }
    render() {

        const {user_data} = this.props;

        return(

            <div className="container">
                <div className="row">
                    <div className="col-3 user_avatar">

                        <img src={this.state.images.length ? this.state.images[0].data_url : profile_img} width="100" alt={'user_upload_img'}/>
                    </div>

                    <Alert show={this.state.success_show} variant="success">
                        <Alert.Heading>Updated</Alert.Heading>

                    </Alert>

                    <div className="col-9 settings_upload">

                        <ImageUploading
                            value={this.state.images}
                            onChange={this.handleUploadImage}
                            dataURLKey="data_url"
                        >
                            {({
                                  imageList,
                                  onImageUpload,
                                  onImageRemoveAll,
                                  onImageUpdate,
                                  onImageRemove,
                                  isDragging,
                                  dragProps,
                              }) => (
                                // write your building UI

                                <div className="upload__image-wrapper">
                                    <p>Your Photo</p>
                                    <button
                                        style={isDragging ? { color: 'red' } : undefined}
                                        onClick={onImageUpload}
                                        {...dragProps}
                                    >
                                        Upload New Photo
                                    </button>
                                    &nbsp;

                                        <button onClick={this.state.images[0] && this.state.images[0].data_url ? onImageRemoveAll : this.saveDeletedImg.bind(this,imageList)}>Remove Photo</button>



                                    {imageList.map((image, index) => (

                                        <div key={index} className="image-item">
                                            {/*<img src={image['data_url']} alt="" width="100" />*/}
                                            <div className="image-item__btn-wrapper">
                                                {/*<button onClick={() => onImageUpdate(index)}>Update</button>*/}
                                                {/*<button onClick={() => onImageRemove(index)}>Remove</button>*/}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </ImageUploading>


                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-5 change_profile_info">
                        <p>First Name</p>
                        <p>Last Name</p>
                        <p>Office</p>
                        <p>Work  Number</p>
                        <p>Cell  Number</p>
                    </div>


                    <div className="col-lg-8 col-7 change_profile_inputs  profile_content">

                        <input type={'text'} name={'first_name'} onChange={this.handleChangeProfile} defaultValue={ user_data.first_name && user_data.first_name !== 'null' ? user_data.first_name : '' }/>
                        <input type={'text'} name={'last_name'} onChange={this.handleChangeProfile} defaultValue={ user_data.last_name   && user_data.last_name   !== 'null' ? user_data.last_name : '' }/>

                        <div className="form-group selectdiv profile_select">

                            <select className="form-control alterationTypeSelect" name="office" value={this.state.default_office ? this.state.default_office : undefined } onChange={this.handleChangeProfile}>
                                <option value={'DEFAULT'} disabled> </option>
                                {this.state.offices && this.state.offices.map((item,key) => {
                                    return(
                                        <option key={key} value={item.id} >{item.name}</option>

                                    )
                                })}

                            </select>
                        </div>
                        <input type={'number'} name={'work_number'} onChange={this.handleChangeProfile} defaultValue={ user_data.work_number && user_data.work_number !== 'null'  ? user_data.work_number : '' }/>
                        <input type={'number'} name={'cell_number'} onChange={this.handleChangeProfile} defaultValue={ user_data.cell_number && user_data.cell_number !== 'null'  ?  user_data.cell_number : '' }/>
                    </div>

                </div>

                <button className={'setting_modal_button default_hover'} onClick={this.handleSaveData}>
                    Save Changes
                </button>

            </div>
        )
    }
}



function mapDispatchToProps(dispatch){
    return{
        getUserInfo: (getUserInfo) => dispatch({type:GETUSERINFO,payload:getUserInfo})
    }
}


function mapStateToProps(state){

    return{
        user_data:state.userInfoReducer.user_data
    }
}



export default connect(mapStateToProps,mapDispatchToProps)(Profile);

