import React, {useState} from "react";
import Helmet from 'react-helmet';
import login_styles from "../assets/css/login_styles.module.css";
import axios from "axios";
import {url} from "../config";
import {NavLink} from "react-router-dom";
import {Spinner} from 'react-bootstrap'


function ForgotPass(){


    const [error,setError] = useState('');
    const [isShow,setIsShow] = useState(false);
    const [email,setEmail] = useState('')

    const handleEmailvalue = (event) => {
        event.preventDefault();
        const {value} = event.target;

        if(value && value.trim()){
           setEmail(value)
        }

    }


    const handleSubmit = (event) => {
        event.preventDefault();

        const data = {} ;

        data.email = email;

        if(email){

            setIsShow(true)

            axios.post(url + 'reset-password', data)
                .then(res => {
                  setIsShow(false)
                    window.location.href = 'reset-confirm'
                })
                .catch(err => {
                 setError('Email not found')
                 setIsShow(false)
                    console.log(err.message)
                })
        }
    }

    return(
        <>
            <Helmet>
                <title>APA-MS</title>
            </Helmet>
            <div className={`${login_styles.login_header} ` +   (window.innerWidth < 991 ? ' mobile_vhc' : ' vhc')}>

                <div className="container">

                    <div className={'mid_col col-md-6 col-12'}>

                        <form onSubmit={handleSubmit}>

                            <div className={login_styles.img}>
                                <img src={require('../assets/img/APA_Logo.png')} alt={'logo'}/>
                            </div>

                            <p className={login_styles.remember_email}>
                                Enter your email and we send you a password reset link.
                            </p>
                            {error && <h4 style={{color:'red',textAlign:'center'}}>{error}</h4>}
                            <div className={login_styles.login_inputs + ' ' +  login_styles.remember_email_content + ' col-11'}>

                                <div>
                                    <input type={'email'} placeholder={'Email'} name={'email'} onChange={handleEmailvalue} required={true} />
                                </div>

                            </div>

                            <div className={'text-center container'}>

                                <div className="row">

                                    <div className="col-lg-6 col-12 mt-2 mb-3">
                                        <NavLink to = {'/login'} className = 'cancel_button'>
                                            Cancel
                                        </NavLink>
                                    </div>

                                    <div className="col-lg-6 col-12 send_request">
                                        {isShow ? <Spinner animation="grow" variant="primary"/> :   <button className={login_styles.button} type={'submit'} >
                                            Send Request
                                        </button>}
                                    </div>


                                </div>

                            </div>

                        </form>

                    </div>

                    <div className={login_styles.rules}>
                        <ul>
                            <li>
                                <a href={'/'}>
                                    Term of use
                                </a>
                            </li>

                            <li>
                                <a className={login_styles.last} href={'/'}>
                                    Privacy policy
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ForgotPass;