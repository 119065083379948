import React, {useEffect, useState,useRef} from "react";
import { Button, Modal } from "react-bootstrap";
import Moment from 'react-moment';



const ReportModal = (props) => {


    const {report,show,inspection,lead_body} = props;

    
    useEffect(() => {
   
    },)

    const handleClose = () => {
        props.toggleReportModal(false);
     };

    return (
        
        <>
            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                centered
                className="discover_modal_content"
                dialogClassName="discover_modal large_modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Report #{report?.report_id}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="report_modal pb-4">
                        <div className="row">
                            <div class="col-12">
                                <div className="head-info"><span>Inspector: </span> { inspection?.inspector?.first_name+' '+inspection?.inspector?.last_name }</div>
                                <div className="head-info"><span>Insured: </span> { lead_body[0]?.contact?.first_name+' '+lead_body[0]?.contact?.last_name }</div>
                                <div className="head-info"><span>Date: </span>  
                                    <Moment format="MM/DD/YYYY ">
                                        {inspection?.inspection_date}
                                    </Moment>
                                </div>
                                <div className="head-info"><span>Date: </span>  
                                    <Moment format="hh:mm A">
                                        {inspection?.inspection_date}
                                    </Moment>
                                </div>
                                <div className="head-info"><span>Lead Status After Inspection: </span>  
                                        {report?.lead_status}
                                </div>
                          </div>
                        </div>


                        <div className="row mt-2">
                            <div class="col-12 col-lg-5"><span>Fee Details</span></div>
                            <div class="col-12 col-lg-7">{report?.details}</div>
                        </div>

                        <div className="row mt-2">
                            <div class="col-12 col-lg-5"><span>Claim Assignment Recommendation</span></div>
                            <div class="col-12 col-lg-7">{report?.recommendation}</div>
                        </div>

                        <div className="row mt-2">
                            <div class="col-12 col-lg-5"><span>Tentative Plan of Action/Strategy</span></div>
                            <div class="col-12 col-lg-7">{report?.strategy}</div>
                        </div>

                        <div className="row mt-2">
                            <div class="col-12 col-lg-5"><span>Additional Comments</span></div>
                            <div class="col-12 col-lg-7">{report?.comments}</div>
                        </div>

                    </div>
        
                </Modal.Body>
               
            </Modal>
        </>
    )
}

export default ReportModal;

