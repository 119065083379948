import React, {useEffect, useState} from "react";
import ReactApexChart from "react-apexcharts";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import byTerritory from "../Charts/ByTerritory";


const calendar_icon   = <i className={'apa-icon-calendar reports_calendar_icon'}></i>
const clear_icon   = <i className={'apa-icon-close reports_calendar_icon'}></i>


function ReportByTerritory(props){
    const [percent,setPercent] = useState([])
    const [places,setPlaces] = useState([])

    const obj = byTerritory(percent,places)

    useEffect(() => {
        setPercent(props?.data?.percent)
        setPlaces(props?.data?.places)
    },[props.data])



    return (
        <>
            <div id="chart">
                <div className={'report_content'}>
                    <div className={"col-12"}>
                    <div className={'report_date'}>
                        <div className={"row"}>
                            <div className={"col-6"}>
                                <h4 style={{paddingTop: "6px"}}>Incoming Leads by Territory</h4>
                            </div>
                        </div>

                    </div>
                    </div>
                    <div style={{width:"500px"}}>
                    <ReactApexChart className = 'territory_reports_body' options={obj.options} series={obj.series ? obj.series:[]} type="donut"  />
                </div>
                </div>

            </div>
        </>
    )
}

export default ReportByTerritory;