import React from "react";
import SideBar from "./header/SideBar";
import Navbar from "./header/Navbar";

class Settlements extends React.Component{
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
       return(
           <>

               <div className={"d-flex" + (this.state.toggled ? ' toggled' : '')} id="wrapper">
                   <SideBar isAuth = {this.props.isAuth}/>
                   <div id="page-content-wrapper">
                       <div>
                           <Navbar toggled = {this.myCallback} />
                           <div className={'col-12'}>

                               <div className="row">
                                   <div className="col-lg-6 col-12">
                                       <div className={'page_title users_title'}>
                                           <h2>Settlements</h2>
                                       </div>
                                   </div>

                                   <div className="col-lg-6 col-12 leads_content">

                                       <div className={'leads_search'}>

                                           <button className={'default_hover'} onClick={this.handleAddUser}><i className={'apa-icon-search'}></i>Search </button>
                                       </div>

                                   </div>
                               </div>
                           </div>
                       </div>

                   </div>
               </div>

           </>
       )
    }
}


export default Settlements;