import React from "react";
import {OverlayTrigger, Table, Tooltip} from "react-bootstrap";
import {Link} from "react-router-dom";
import MobileTable from '../MobileTable';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import moment from 'moment'
import Basic from "../DataTables/Basic";
import {columns} from "../DataTables/Tables/InactiveLeads";


class InactiveLeads extends React.Component{
    constructor(props) {
        super(props);
        this.state = {}

    }
    insuredNameHandler = (item) => {
        const filterObj = this.state.incomingLeads.find(
          (i) => i.client_id === item.client_id
        );
        return filterObj?.first_name+' '+filterObj?.last_name;
      };


    render() {
        const {inactiveLeads,windowWidth}  = this.props;


        return(
            <div>
            <div className={'lead_table table-responsive new_lead_table showOnDesktop newLead initiated'}>
                <Basic columns={columns} data={inactiveLeads}/>
            </div>
            {windowWidth <= 991 && <MobileTable leads_data={this.props.inactiveLeads} />}
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
      windowWidth: state.appReducer.windowWidth,
    };
  }
  export default connect(mapStateToProps, null)(InactiveLeads);







//
// <Table striped bordered hover className ="desktop_table">
//     <thead>
//     <tr>
//         <th>LIN</th>
//         <th>Insured Name</th>
//         <th>Team</th>
//         <th>Category</th>
//         <th>Lead Pa</th>
//         <th>Location</th>
//         <th>Lead Source</th>
//         <th>Peril</th>
//         <th>First Contact</th>
//         <th>Date Initiated</th>
//         <th>Reason Inactive</th>
//         <th></th>
//         <th></th>
//     </tr>
//     </thead>
//     {inactiveLeads && inactiveLeads.length > 0 && inactiveLeads.map((item,key) => {
//         return(
//
//             <React.Fragment key = {key}>
//
//                 <tbody>
//                 <tr>
//                     <td>{item.LIN}</td>
//                     <td>{item.client?.contacts[0]?.first_name} {item.client?.contacts[0]?.last_name}</td>
//                     <td>{item && item.assignment && item.assignment.team && item.assignment.team.name}</td>
//                     <td>{item.category && item.category.type}</td>
//                     <td>{item && item.rep && item.rep.name }</td>
//                     <td>{item.rep && item.rep.name}</td>
//                     <td>{item.source && item.source.name}</td>
//                     <td>{item.loss_info && item.loss_info.peril && item.loss_info.peril.name}</td>
//                     <td>
//                         {item.first_contact ? (
//                             <Moment format="MM/DD/YYYY">
//                                 {item.first_contact}
//                             </Moment>
//                         ) :(
//                             <span></span>
//                         ) }
//                     </td>
//                     <td>
//                         {item.created_at ? (
//                             <Moment format="MM/DD/YYYY">
//                                 {item.created_at}
//                             </Moment>
//                         ) :(
//                             <span></span>
//                         ) }
//                     </td>
//
//                     <td>
//                         {item?.inactivate_reason?.reason && Object.values(item?.inactivate_reason?.reason).map((reason,key,arr) => {
//                             return (
//                                 <span key={key}>{reason}{key === arr.length - 1 ? '' : ', '}</span>
//                             )
//                         })}
//
//                     </td>
//                     <td></td>
//
//
//                     <td>
//
//                         <Link  to={{
//                             pathname:"/leads/" + item.id,
//                             state:item.id
//
//                         }}><i className={'apa-icon-view default_hover'}></i></Link>
//
//                     </td>
//                     <td>
//                         <OverlayTrigger
//                             placement="bottom"
//                             overlay={<Tooltip id="button-tooltip-2">
//
//                                 <h3>Lead Source Notes</h3>
//                                 <p>{item.notes}</p>
//
//                             </Tooltip>}
//                         >
//                             {({ ref, ...triggerHandler }) => (
//
//                                 <i ref={ref} {...triggerHandler} className={'apa-icon-notes default_hover'}></i>
//                             )}
//                         </OverlayTrigger>
//
//                     </td>
//                     <td>
//
//
//                         <OverlayTrigger
//                             placement="bottom"
//                             overlay={<Tooltip id="button-tooltip-2">
//
//                                 <h3>Deactivated By</h3>
//                                 <p>{item?.inactivate_reason?.by}</p>
//
//                             </Tooltip>}
//                         >
//                             {({ ref, ...triggerHandler }) => (
//                                 <i className={'apa-support default_hover'} ref={ref} {...triggerHandler}></i>
//
//                             )}
//                         </OverlayTrigger>
//
//                     </td>
//                 </tr>
//                 </tbody>
//             </React.Fragment>
//         )
//     })}
// </Table>