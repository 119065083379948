import React, {useEffect, useState} from "react";
import axios from "axios";
import { url } from "../../config";
import {connect, useDispatch, useSelector} from "react-redux";
import {getAddress, getBuilding, getMortgage} from "../../redux/actions";
import Input from "../Inputs/Input";
import InputSelect from "../Inputs/InputSelect";
import NoteModal from "../leadDetails/NoteModal";

const building_info = {};


function BuildingInfo(props){

  const [inputEdit,setInputEdit] = useState(false)
  const [buildingTypes,setBuildingTypes] = useState([])
    const [priorChecked,setPriorChecked] = useState()
  const building = useSelector(state => state.propertyReducer.building)
  const dispatch = useDispatch()

  useEffect( () => {
       dispatch(getBuilding(props.building_id))
      getBuildingTypes()
  },[])



  const  getBuildingTypes = () => {

      axios.get(url + '/get-building-types')
          .then(res => {
              setBuildingTypes(res.data)
          })
  }


  const handleEdit = () => {
      building_info.id = building.id;
      setInputEdit(!inputEdit)
  };

    const closeEdit = () =>{
        setInputEdit(!inputEdit)
    }


  const handleChangeData = (event) => {
      const {name, value} = event.target;
      building_info[name] = value;

      if(name === 'prior_claims'){
          if(building?.prior_claims === "0"){
              setPriorChecked(1)
              return building_info.prior_claims = "1"
          }else{
              setPriorChecked(0)
              return building_info.prior_claims = "0"
          }
      }
  };

   const noteSave = (name,value)=> {
        building_info[name] = value;
    }


    const handleSaveData = async () => {
      const data = {building_info};
      setInputEdit(!inputEdit)
      axios.post(url + 'update-lead-loss-address', data).then(res => {
          dispatch(getBuilding(building.id))
          setInputEdit(!inputEdit)
      })

  };


    return (

      <div className={"add_lead_basic lead_details_basic_info"}>
        <div className={"leads_details_edit"}>
          <h3>Building Info</h3>
          {inputEdit ?
              <>
                  <span onClick={handleSaveData}>Save</span>
                  <div className={'close_b'} onClick={closeEdit}><i className="fas fa-times"/></div>
              </>
              :
            <i onClick={handleEdit} className={"apa-pencil"}/>
          }
        </div>
              <div className="row">
                  <InputSelect options={{label:'Building Type',name:'building_type', value:building?.building_type?.type ,class:'leads_details_selects'}}
                               inputEdit={inputEdit}
                               handleChange={handleChangeData}
                               data={buildingTypes}
                               path={'type'}
                               value={'id'}
                               check={building?.building_id}
                  />
                  <Input options={{label:'Size of Building',name:'size_of_building',type:'text',value:building?.size_of_building}}
                         inputEdit={inputEdit}
                         handleChange={handleChangeData}
                  />
                  <Input options={{label:'Year Built',name:'year_build',type:'number',value:building?.year_build}}
                         inputEdit={inputEdit}
                         handleChange={handleChangeData}
                  />
                  <Input options={{label:'Beds',name:'beds',type:'number',value:building?.beds}}
                         inputEdit={inputEdit}
                         handleChange={handleChangeData}
                  />
                  <Input options={{label:'Baths',name:'baths',type:'number',value:building?.baths}}
                         inputEdit={inputEdit}
                         handleChange={handleChangeData}
                  />
                  <div className="col-4">
                      <p>Prior Claims</p>
                  </div>
                  <div className="col-8" >
                      <input onChange={handleChangeData}
                             //value={Number(building?.prior_claims).toString()}
                             disabled={!inputEdit} className={inputEdit ? ' edit_inputs' : ''}
                             type="checkbox" id="prior_claims" name="prior_claims"
                             checked={priorChecked == 1 || (priorChecked === undefined && building?.prior_claims == '1') ? true : false} />
                      <label htmlFor="prior_claims"/>
                  </div>

                  <Input options={{label:'Est. Property Value',name:'est_property_value',type:'text',value:building?.est_property_value}}
                         inputEdit={inputEdit}
                         handleChange={handleChangeData}
                  />


                  <NoteModal title={'Building Notes'} name={'building_note'}
                             notes={building?.building_note}
                             id={'building_note'}
                             inputEdit={inputEdit} noteSave={noteSave}/>


                  <Input options={{label:'Age of Home',name:'age_of_home',type:'number',value:building?.age_of_home}}
                         inputEdit={inputEdit}
                         handleChange={handleChangeData}
                  />

                  <Input options={{label:'Years Occupied',name:'years_occupied',type:'number',value:building?.years_occupied}}
                         inputEdit={inputEdit}
                         handleChange={handleChangeData}
                  />
              </div>
      </div>
    );
}
export default connect(null,null)(BuildingInfo);

