import React, {useEffect, useState} from "react";
import axios from "axios";
import {Button, Modal} from "react-bootstrap";
import {Editor} from "@tinymce/tinymce-react";
import {url} from "../../config";
import PropTypes from "prop-types";

let data={}
function EmailModal ({isShowEmail,handleCloseEmail,slug,loading,handleSave,}) {

    const [template,setTemplate] = useState('')

    useEffect(() => {
        getEmail()
    },[])

     const getEmail = ()=>{
         axios.post(url + 'get-email-by-slug',{slug:slug}).then(res => {
             setTemplate(res.data)
             data['subject']=res.data.subject
             data['description'] = res.data.description

         }).catch(err => {console.log(err)})
     }

    const handleChang = (e) => {
        e.preventDefault();
        const {name,value} = e.target;

        if(value && value.trim()){
            data[name] = value
        }

    }

    const changeEditor =(value,editor) =>{
        data['description'] = value
    }


    return (
        <>

            <Modal show={isShowEmail} onHide = {handleCloseEmail}  centered className="discover_modal_content modal_width" dialogClassName='discover_modal '>
                <Modal.Header closeButton>
                    <Modal.Title>{template.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={'container-fluid'}>
                        <div className="row email_template">
                            <div className="col-2">
                                <h5>Subject</h5>
                            </div>
                            <div className="col-10">
                                <input type={'text'} name={'subject'}  onChange={handleChang}  defaultValue={template.subject}/>
                            </div>
                            <div className="col-2">
                                <h5>Body</h5>
                            </div>
                            <div className="col-10 email_template_editor">
                                <Editor
                                    apiKey='wwzu5v6vk2usoswhk1n05w1p3e1cqy9jqskidd7r6cibzjkt'
                                    initialValue={data.description}
                                    init={{
                                        plugins: 'link image code',
                                        toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code',
                                        height: 400
                                    }}
                                    onEditorChange={changeEditor}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={loading ? 'disabled' :''} variant="primary" onClick = {()=>handleSave(data)}>Send</Button>
                    {loading ?  <div className={'spinner-grow text-primary'} style={{width:'1.3rem',height: '1.3rem',marginLeft:'7px'}}/> :''}
                </Modal.Footer>
            </Modal>
        </>
    )
}

    EmailModal.propTypes = {
        slug: PropTypes.string,
        isShowEmail:PropTypes.bool,

    }

    export default EmailModal