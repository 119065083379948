import React from "react";
import {Button, Modal} from "react-bootstrap";
import DatePicker from "react-datepicker";
import axios from "axios";
import {url} from "../../config";
import {getAllHistory, getLeedInspection} from "../../redux/actions";
import {connect} from "react-redux";
import moment from 'moment'
import EmailModal from "../settings/EmailModal";

const date_values = {};
const inspection = {}


class ScheduleInspection extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            viewDate:false,
            scheduleDate:'',
            dates:[0],
            inspectors_list:'',
            isShowEmail:false,
            loading:false,

        }
    }


    handleHideModal = () => {

        this.props.modalCallBack(false,'schedule_modal')
    }


    handleScheduleDate = (key,date) => {
        let x = date
        date_values['option_' + Number(key + 1)] = x

        this.setState({
           scheduleDate:date_values
       })
    }


    handleAddDate = () => {
        this.setState({
            dates:[...this.state.dates,this.state.dates.length + 1]
        })
    }


    handleAddScheduleInspection = () => {

        this.setState({
            isShowEmail:true
        })
        inspection.contact_name = this.props.lead_body[0].contact.first_name
        inspection.email = this.props.lead_body[0].contact.email
        inspection.lead_id = this.props.leadId
        inspection.suggestion_date = JSON.stringify(this.state.scheduleDate)
        inspection.status_id = 1;
        inspection.inspection_date = null;
        inspection.type= true
        this.handleSave()
    }

    handleSave = () =>{
        this.setState({loading:true})
        let message = 'Inspection Reschedule Options Sent';


        if(!this.props?.lead_body[0]?.lead_inspection?.suggestion_date) {
            message = 'Inspection Scheduled Options Sent';
        }
        axios.post(url + 'schedule-inspection', inspection)
            .then(async res => {
                this.updateLeadStatusCode(inspection.lead_id,inspection.status_id);

                if(inspection.suggestion_date) {
                    let dates = '';
                    Object.values(JSON.parse(inspection.suggestion_date)).map((value,key) => {
                        dates = dates+', '+ moment(new Date(value)).format("MM/DD/YYYY hh:mm A")
                    })
                    message = message +' '+dates
                }
                await axios.post(url + "add-lead-log", {lead_id:inspection.lead_id,details:message});
                await this.props.dispatch(getLeedInspection(inspection.lead_id))
                this.props.myCallback(true);
                this.setState({loading:false})
                this.handleHideModal()
                this.setState({isShowEmail:false})


            })
            .catch(err => {
                console.log(err)
            })

    }

    updateLeadStatusCode = (lead_id,status_code) => {
        axios
        .post(url + 'update-lead-current-status-code', {lead_id:lead_id,status_code:status_code})
        .then( (res) => {
           // await this.props.dispatch(getLeedInspection(inspection.lead_id))
        })
        .catch((err) => {
          console.log(err);
        });
      }

    componentDidMount() {
        this.handleGetInspectors();
    }


    handleGetInspectors = () => {
        axios.get(url + 'get-user-roles')
            .then(res => {
               this.setState({
                   inspectors_list:res.data.users
               })
            })
            .catch(err => {
                console.log(err)
            })
    }

     handleCloseEmail = () =>{
        this.setState({
            isShowEmail:false
        })
    }


    selectInspector = (e) => {
        e.preventDefault();
        const {value} = e.target;

        inspection.inspector_id = value;

    }

    render() {
        const {show} = this.props
        const {scheduleDate,dates,inspectors_list} = this.state;

        return(
            <>
                <Modal show={show} onHide = {this.handleHideModal} centered className="discover_modal_content  send_message_modal " dialogClassName='discover_modal schedule_modale'>
                    <Modal.Header closeButton>
                        <Modal.Title>Schedule an Inspection</Modal.Title>

                    </Modal.Header>
                    <Modal.Body>
                        <h4 className={'details_modal_titles'}>{this.props?.lead_body && this.props?.lead_body[0]?.client?.contacts[0].first_name} {this.props?.lead_body && this.props?.lead_body[0]?.client?.contacts[0].last_name}</h4>


                        <div className="row">

                            <div className="col-4 col-lg-4">
                                <p>Inspector</p>
                            </div>
                            <div className="col-8 col-lg-8 select_inspector ">
                                <div className="form-group selectdiv select_content change_profile_inputs  ">
                                    <select className="form-control alterationTypeSelect default_select" defaultValue={this.props?.lead_body[0]?.lead_inspection?.inspector_id} name="contact" onChange={this.selectInspector}>
                                        <option value={''}/>
                                        {inspectors_list && inspectors_list.map((item,key) => {

                                            return(
                                                <option key={key} value={item.id}>{item.first_name + " " +  item.last_name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            {dates.map((item,key) => {

                                return(
                                    <React.Fragment key = {key}>



                                        <div className=" col-4 col-lg-4 schedule_date_content">
                                            <p>Date</p>
                                        </div>


                                        <div className=" col-8 col-lg-8 change_profile_inputs schedule_date_content">

                                            <DatePicker className={'input_date schedule_date'}
                                                        onChange={this.handleScheduleDate.bind(this,key)}
                                                        name={'scheduleDate'}
                                                        selected={scheduleDate['option_' + Number(key + 1)]}
                                                        isClearable
                                                        dateFormat="MM/dd/yyyy : hh:mm"
                                                        showTimeInput

                                            />
                                        </div>

                                    </React.Fragment>
                                )
                            })}
                        </div>


                        <div className={'schedule_add_icon'}>
                            <span onClick={this.handleAddDate}>Add Date<i className={'apa-icon-add'}></i></span>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="primary" onClick = {this.handleAddScheduleInspection}>Schedule & Send Notification</Button>
                    </Modal.Footer>
                </Modal>
            </>


        )
    }
}


export default connect(null, null)(ScheduleInspection);