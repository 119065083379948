import React from "react";
import {Button, Modal} from "react-bootstrap";
import axios from "axios";
import { url } from "../../config";

let inactiveData = {
    id: '',
    reason: {}
};

class Inactive extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
        reason_count:[0]
        }
    }

    handleHideModal = () => {
        this.props.modalCallBack(false,'inactive')
    }

    handleAddReason = () => {
        this.setState({
            reason_count:[...this.state.reason_count,this.state.reason_count.length + 1]
        })
    }
    handleChangeData = (event) => {
        const { name, value } = event.target;
    
        if(name === 'reason') {
            inactiveData['reason'][event.target.getAttribute("data-key")] = value
        }else {
            inactiveData[name] = value;
        }

      };
      handleSaveData = async () => {
            if(this.props.lead_body[0].id) {
            inactiveData['id'] = this.props.lead_body[0].id;
            console.log(inactiveData,'inactiveDatainactiveDatainactiveData');

            await axios
            .post(url + "inactive-lead", inactiveData)
            .then((res) => {
                this.handleHideModal()
                this.props.myCallback(true);
            })
            .catch((err) => {
            console.log(err);
            });
        }
      }
    render() {

        const {show} = this.props;
        const {reason_count} = this.state;
        return(
            <Modal show={show} onHide = {this.handleHideModal} centered className=" inactive_modal discover_modal_content log_entry_modal send_message_modal" dialogClassName='discover_modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Inactive</Modal.Title>

                </Modal.Header>
                <Modal.Body>

                <h4 className={'details_modal_titles'}>{this.props?.lead_body && this.props?.lead_body[0]?.client?.contacts[0].first_name} {this.props?.lead_body && this.props?.lead_body[0]?.client?.contacts[0].last_name}</h4>


                    <div className="row">


                        {reason_count && reason_count.map((key) => {

                            return(
                               <React.Fragment key = {key}>

                        <div className="col-4">
                            <p>{'Reason ' + (key > 0 ? key : '')}</p>
                        </div>

                        <div className="col-8 select_lead">
                            <div className="form-group selectdiv select_content">

                                <select onChange={this.handleChangeData} className="form-control alterationTypeSelect default_select" data-key={key} name={"reason"}>
                                    <option value={''}></option>
                                    <option value={'Went with another firm'}>Went with another firm</option>
                                    <option value={'Not good fit'}>Not good fit</option>
                                    <option value={'Lead is unresponsive'}>Lead is unresponsive</option>
                                    <option value={'Fee is too high'}>Fee is too high</option>
                                    <option value={'We were unresponsive'}>We were unresponsive</option>
                                </select>
                            </div>
                        </div>

                                </React.Fragment>
                            )
                        })}
                        <div className="col-4"><p>Additional Notes</p></div>
                        <div className="col-8">
                            <textarea 
                            name="notes"
                            onChange={this.handleChangeData}
                            className={'steps_text_area'}></textarea>
                        </div>


                    </div>


                    <div className={'schedule_add_icon'}>
                        <span onClick={this.handleAddReason}>Add Reason<i className={'apa-icon-add'}></i></span>
                    </div>

                </Modal.Body>
                <Modal.Footer>

                    <Button onClick={ () => this.handleSaveData()} variant="primary">Save</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}


export default Inactive;