import React from "react";
import {connect} from "react-redux";
import Basic from "../DataTables/Basic";
import Property from "../DataTables/Tables/Property";


class PropertiesTable extends React.Component{
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const {data,type} = this.props

        return(
            <div className={'lead_table table-responsive ' }>
                <Basic columns={type ? Property('property') : Property() }
                       data={data ? data : [] }/>
            </div>
        )
    }
}


export default connect(null,null)(PropertiesTable)


// <td>
// <i className={'apa-icon-documents'}></i>
// </td>