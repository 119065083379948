import React, {useEffect, useState} from "react";
import axios from "axios";
import { url } from "../../config";
import {connect, useDispatch, useSelector} from "react-redux";
import Input from "../Inputs/Input";
import InputSelect from "../Inputs/InputSelect";
import {getContactById} from "../../redux/actions";
import NumberFormat from "react-number-format";

const contact_info = {};


function ContactInfo(props){

  const [inputEdit,setInputEdit] = useState(false)
    const [zonoChecked,setZonoChecked] = useState()
  const contact = useSelector(state => state.propertyReducer.contact)
  const dispatch = useDispatch()

  useEffect(() => {
      dispatch(getContactById(props.contact_id))
  },[])


    const contact_methods = [
        {id:0,name:'Default'},
        {id:1,name:'Cell Number'},
        {id:2,name:'Work Number'},
        {id:3,name:'Home Number'},
        {id:4,name:'Email'},
    ]

 const handleEdit = () => {
     contact_info.contact_id = contact.id
     setInputEdit(!inputEdit)
  };

    const closeEdit = () =>{
       setInputEdit(!inputEdit)
    }

 const  handleChangeData = (event) => {
    const { name, value } = event.target;

     if(name === 'zono_account'){
         if(contact.zono_account === "0"){
             setZonoChecked(1)
             return  contact_info.zono_account = "1"
         }else {
             setZonoChecked(0)
             return   contact_info.zono_account = "0"
         }
     }
     contact_info[name] = value;
  };

 const handleSaveData =  () => {
    const data = {contact_info};
     axios.post(url + "update-lead-basic", data).then( response => {
          dispatch(getContactById(contact.id))
           setInputEdit(!inputEdit)
      });
  };


    const contact_method = contact?.contact_method === '1' ? 'Cell Number' :contact?.contact_method === '2' ? 'Work Number'
        : contact?.contact_method === '3' ? 'Home Number'
            :contact?.contact_method === '4' ? 'Email' : ''
    return (

      <div className={"add_lead_basic lead_details_basic_info"}>
        <div className={"leads_details_edit mortgage_title"}>
          <h3>Contact Info</h3>
          {inputEdit ?
               <>
                   <span onClick={handleSaveData}>Save</span>
                   <div className={'close_b'} onClick={closeEdit}><i className="fas fa-times"/></div>
                 </>
              :
             <i onClick={handleEdit} className={"apa-pencil"}/>
          }
        </div>
              <div className="row">

                  <div className="col-lg-4 col-4">
                      <p>Contact ID</p>
                  </div>
                  <div className="col-lg-8 col-8">
                      <input name={'contact_number'} readOnly={true} disabled={true} defaultValue={contact.contact_number} />
                  </div>
                  <Input options={{label:'First Name',name:'first_name',type:'text',value:contact?.first_name}}
                         inputEdit={inputEdit}
                         handleChange={handleChangeData}
                  />
                  <Input options={{label:'Last Name',name:'last_name',type:'text',value:contact?.last_name}}
                         inputEdit={inputEdit}
                         handleChange={handleChangeData}
                  />
                  <div className="col-lg-4 col-4">
                      <p>Cell Number</p>
                  </div>

                  <div className="col-lg-8 col-8">
                      {inputEdit ? (
                          <NumberFormat defaultValue={contact?.cell_number} format="(###) ###-####" name={'cell_number'} onChange={handleChangeData} type={'text'} className={'edit_inputs'} />
                      ) : (
                          <p style={{fontFamily:'openSansRegular'}}>{contact?.cell_number}</p>
                      )}
                  </div>

                  <div className="col-lg-4 col-4">
                      <p>Work Number</p>
                  </div>
                  <div className="col-lg-8 col-8">
                      {inputEdit ? (
                          <NumberFormat defaultValue={contact?.work_number} format="(###) ###-####" name={'work_number'} onChange={handleChangeData} type={'text'} className={'edit_inputs'} />
                      ) : (
                          <p style={{fontFamily:'openSansRegular'}}>{contact?.work_number}</p>
                      )}
                  </div>
                  <div className="col-lg-4 col-4">
                      <p>Home Number</p>
                  </div>
                  <div className="col-lg-8 col-8">
                      {inputEdit ? (
                          <NumberFormat defaultValue={contact?.home_number} format="(###) ###-####" name={'home_number'} onChange={handleChangeData} type={'text'} className={'edit_inputs'} />
                      ) : (
                          <p style={{fontFamily:'openSansRegular'}}>{contact?.home_number}</p>
                      )}
                  </div>
                  <Input options={{label:'Email',name:'email',type:'text',value:contact?.email}}
                         inputEdit={inputEdit}
                         handleChange={handleChangeData}
                  />

                  <InputSelect options={{label:'Contact Method',name:'contact_method', value:contact_method ,class:'leads_details_selects'}}
                               inputEdit={inputEdit}
                               handleChange={handleChangeData}
                               data={contact_methods}
                               path={'name'}
                               value={'id'}
                               check={contact?.contact_method}
                               te={true}
                  />
                  <div className="col-lg-4 col-4">
                      <p>Zono Account</p>
                  </div>
                  <div className="col-lg-8 col-8">
                      <input  onChange={handleChangeData}
                              checked={zonoChecked == 1 || (zonoChecked === undefined && contact?.zono_account == 1) ? true : false}
                              disabled={!inputEdit} className={inputEdit ? ' edit_inputs' : ''}
                              type="checkbox" id="zono_account" name="zono_account" />
                      <label htmlFor="zono_account"/>
                  </div>
              </div>
      </div>
    );
}
export default connect(null,null)(ContactInfo);

