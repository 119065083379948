import React, {useEffect} from "react";
import SideBar from "./header/SideBar";
import Navbar from "./header/Navbar";
import Leads from "./Leads";
import {connect} from 'react-redux'
import {checkAuth} from "../redux/actions";




function Dashboard(props){
    const {toggled} = props;

    useEffect(() => {
        const {dispatch} = props;
        dispatch(checkAuth())
    })

    return(
        <>
            <div className={"d-flex" + (toggled ? ' toggled' : '')} id="wrapper">
                <SideBar />
                <div id="page-content-wrapper">
                    <div>
                        <Navbar/>
                        <Leads/>
                    </div>
                </div>
            </div>

        </>
    )
}

function mapStateToProps(state) {

    return{
        toggled:state.appReducer.toggled
    }

}



export default connect(mapStateToProps,null)(Dashboard);

