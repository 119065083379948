import React from "react";
import {Button, Modal} from "react-bootstrap";
import {connect} from 'react-redux';
import {findAll, getAllHistory, getUserNotify, sideBarToggle} from "../../redux/actions";
import {ADJUSTER, TEAMS} from "../../redux/types";
import axios from "axios";
import {url} from "../../config";
import userInfoReducer from "../../redux/reducers/userInfoReducer";
import EmailModal from "../settings/EmailModal";
import {Editor} from "@tinymce/tinymce-react";
import {checkNull} from "../DataTables/Helper";

const assignmentInfo = {};
const data = {};
const dataEditor={}
class AssignModal extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            isShowEmail:false,
            loading:false,
            newData:{},
            description:'',
            assigner_name:''
        }

    }


    componentDidMount() {
        this.props.dispatch(findAll('teams',TEAMS))
        this.props.dispatch(findAll('get-user-roles',ADJUSTER))

        axios.post(url + 'get-email-by-slug',{slug:'assignment'}).then(res => {

            dataEditor['subject']=res.data.subject
            dataEditor['description'] = res.data.description
            this.setState({
                newData:res.data,
                description:res.data.description
            })

        }).catch(err => {console.log(err)})


        this.getUserProfile()
    }

    updateInspectionInfoValues = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        assignmentInfo[name] = value;
    };

    handleCloseEmail=() =>{
        this.setState({
            isShowEmail:false
        })
    }

    getUserProfile = () => {
        axios.get(url + 'get-auth-user-profile', {})
            .then(res => {
                this.setState({
                    assigner_name:res.data.first_name
                })
            })
    }

    handleHideModal  = () => {
        this.props.modalCallBack(false,'assign_modal')
    };

     handleChangEmail = (e) => {
        e.preventDefault();
        const {name,value} = e.target;

        if(value && value.trim()){
            dataEditor[name] = value
        }

    }

     changeEditor =(value,editor) =>{
         dataEditor['description'] = value
    }

    checkNull = (val) => {
        if(val) { return val
        }else {return ''}
    }


    handleSave = (event) => {

        this.setState({
            inputEdit: !this.state.inputEdit,

        });

        assignmentInfo.lead_id = this.props.lead_body[0].id;
        assignmentInfo.type = true

        data.assignmentInfo = assignmentInfo;
        const objectLength = Object.keys(data.assignmentInfo).length;

        let first_name =this.props.adjusters.users.filter(item => item.id ==assignmentInfo.pa_id);
        if(first_name.length > 0){
            this.setState({
                description:this.state.description.replace('{{first_name}}',first_name[0].first_name).replace('{{assigner_name}}',this.state.assigner_name)
                    .replace('{{LIN}}',"LIN: "+this.props?.lead_body[0].LIN)
                    .replace('{{lead_first_name}}',this.props?.lead_body[0]?.client.contacts[0].first_name)
                    .replace('{{lead_last_name}}',this.props?.lead_body[0]?.client.contacts[0].last_name)
                    .replace('{{status}}',this.props?.lead_body[0]?.status?.type)
                    .replace('{{location}}',checkNull(this.props?.lead_body[0]?.loss_address?.city)+', '+checkNull(this.props?.lead_body[0]?.loss_address?.state))
                    .replace('{{number}}',this.props?.lead_body[0].id)
            })
        }


        if (objectLength > 1) {

            this.setState({
                isShowEmail:true
            })
        }
        else {
            event.preventDefault()
        }
    };

    handleSaveData=(dataEditor)=>{
        this.setState({loading:true})
        data['description']= this.state.description
        data['subject']= this.state.newData.subject

        axios
            .post(url + 'update-lead-assignment', data)
            .then(async (res) => {

                let team_name = '';
                let pa_name = '';
                let message = 'Lead Assigned to '

                if(assignmentInfo.team_id && this.props.teams) {
                    let filteredTeams = this.props.teams.filter(item => item.id == assignmentInfo.team_id)
                    team_name = filteredTeams[0].name
                    message = message + 'Team: '+team_name;
                }

                if(assignmentInfo.pa_id && this.props.adjusters.users) {
                    let filteredUsers = this.props.adjusters.users.filter(item => item.id == assignmentInfo.pa_id)
                    pa_name = filteredUsers[0].username
                    message = message + ' PA: '+pa_name;
                }

                await axios.post(url + "add-lead-log", {lead_id:assignmentInfo.lead_id,details:message});

                this.props.myCallback(true);
                this.setState({loading:false})
                this.setState({isShowEmail:false})
                return this.props.dispatch(getAllHistory(assignmentInfo.lead_id))

            })
            .catch((err) => {
                console.log(err);
            });


        this.handleHideModal()
    }

    render() {
        const {show,notifies} = this.props;

        return(
            <>
                <Modal show={show} onHide = {this.handleHideModal} centered className="discover_modal_content log_entry_modal assign_modal" dialogClassName='discover_modal'>
                    <Modal.Header closeButton>
                        <Modal.Title>Assign</Modal.Title>

                    </Modal.Header>
                    <Modal.Body>
                        <h4 className={'details_modal_titles'}> {this.props?.lead_body && this.props?.lead_body[0]?.client.contacts[0].first_name} {this.props?.lead_body && this.props?.lead_body[0]?.client.contacts[0].last_name}</h4>
                        <div className="row">
                            <div className="col-4"><p>Select Team*</p></div>
                            <div className="col-8 select_lead">
                                <div className="form-group selectdiv select_content">

                                    <select name={'team_id'}
                                            className={"form-control alterationTypeSelect"}
                                            onChange={this.updateInspectionInfoValues}
                                    >
                                        <option >Select Team</option>
                                        {this.props.teams && this.props.teams.map((item,key) => {
                                            return  <option value={item.id}
                                                            key={key}>{item.name}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-4"><p>Select Lead PA*</p></div>
                            <div className="col-8 ">
                                <div className="form-group selectdiv select_content">

                                    <select name={'pa_id'}
                                            className={"form-control alterationTypeSelect"}
                                            onChange={this.updateInspectionInfoValues}
                                    >
                                        <option >Select PA Adjuster</option>
                                        {this.props.adjusters && this.props.adjusters.users.map((item,key) => {
                                            return  <option value={item.id} key={key}>{item.username}</option>
                                        })}
                                    </select>
                                </div>
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleSave} variant="primary">Assign & Notify PA</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.isShowEmail} onHide = {this.handleCloseEmail}  centered className="discover_modal_content modal_width" dialogClassName='discover_modal '>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.newData.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={'container-fluid'}>
                            <div className="row email_template">
                                <div className="col-2">
                                    <h5>Subject</h5>
                                </div>
                                <div className="col-10">
                                    <input type={'text'} name={'subject'}  onChange={this.handleChangEmail}  defaultValue={this.state.newData.subject}/>
                                </div>
                                <div className="col-2">
                                    <h5>Body</h5>
                                </div>
                                <div className="col-10 email_template_editor">
                                    <Editor
                                        apiKey='wwzu5v6vk2usoswhk1n05w1p3e1cqy9jqskidd7r6cibzjkt'
                                        initialValue={this.state.description}
                                        init={{
                                            plugins: 'link image code',
                                            toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code',
                                            height: 400
                                        }}
                                        onEditorChange={this.changeEditor}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button disabled={this.state.loading ? 'disabled' :''} variant="primary" onClick = {this.handleSaveData}>Send</Button>
                        {this.state.loading ?  <div className={'spinner-grow text-primary'} style={{width:'1.3rem',height: '1.3rem',marginLeft:'7px'}}/> :''}
                    </Modal.Footer>
                </Modal>
            </>

        )
    }
}



function mapStateToProps(state) {

    return{
        teams:state.leadsReducer.teams,
        adjusters:state.leadsReducer.adjusters,
    }
}


export default connect(mapStateToProps,null)(AssignModal);

