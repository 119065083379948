import React from "react";
import axios from "axios";
import _ from "lodash";
import { url } from "../../config";
import NoteModal from "./NoteModal";
import { connect } from "react-redux";

const damage_info = {};

class ExtentDamage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputEdit: false,
      kind_floor: [],
    };
  }

  getKindOfFloors = () => {
    axios
      .get(url + "create-lead-third")
      .then((res) => {
        const kind_floor = [];

        res.data.kind_of_floor.map((val, key) => {
          return (kind_floor[key] = val);
        });

        this.setState({
          kind_floor: kind_floor,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    this.getKindOfFloors();
  }

  closeEdit = () =>{
    this.setState({
      inputEdit: !this.state.inputEdit,
    });
  }

  handleEdit = () => {
    damage_info.lead_id = this.props.lead_body[0].id;
    this.setState({
      inputEdit: !this.state.inputEdit,
    });
  };

  noteSave = (name, value) => {
    damage_info[name] = value;
  };

  handleChangeData = (event) => {
    const { name, value } = event.target;

    if (name === "carrier_vendor") {
      if (this.props.lead_body[0].damage.carrier_vendor === 0) {
        return (damage_info.carrier_vendor = "1");
      } else {
        return (damage_info.carrier_vendor = "0");
      }
    }

    damage_info[name] = value;
  };

  handleSaveData = () => {
    const data = {};
    data.damage_info = damage_info;

    console.log(damage_info);
    axios
      .post(url + "update-lead-loss", data)

      .then((res) => {
        this.props.myCallback(true);
        this.setState({
          inputEdit: !this.state.inputEdit,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { lead_body } = this.props;
    const { kind_floor } = this.state;

    return (
      <div className={"add_lead_basic lead_details_basic_info"}>
        <div className={"leads_details_edit"}>
          <div className={"container"}>
            {lead_body &&
              lead_body.map((item, key) => {
                return (
                  <div className="row" key={key}>
                    <h3>Extent of Damage</h3>
                    {this.state.inputEdit ? (
                        <>
                          <span onClick={this.handleSaveData}>Save</span>
                          <div className={'close_b'} onClick={this.closeEdit}><i className="fas fa-times"/></div>
                        </>
                    ) : (
                      <i onClick={this.handleEdit} className={"apa-pencil"}/>
                    )}

                    <div className="col-8">
                      <p>How many rooms were affected?</p>
                    </div>

                    <div className="col-4">
                      <input
                        type={"number"}
                        onChange={this.handleChangeData}
                        name={"affected_rooms"}
                        className={this.state.inputEdit ? " edit_inputs" : ""}
                        defaultValue={item.damage && item.damage.affected_rooms}
                        readOnly={!this.state.inputEdit}
                      />
                    </div>

                    <div className="col-8">
                      <p>
                        What kind of floors do you have in the rooms that were
                        damaged?
                      </p>
                    </div>

                    <div className="col-6">
                      <input
                        style={
                          this.state.inputEdit
                            ? { display: "none" }
                            : { display: "block" }
                        }
                        name={"kind_of_floors"}
                        className={this.state.inputEdit ? " edit_inputs" : ""}
                        defaultValue={
                          item.damage &&
                          item.damage.kind_of_floor &&
                          item.damage.kind_of_floor.type
                        }
                        readOnly={!this.state.inputEdit}
                      />
                      <div
                        className="form-group selectdiv leads_details_selects"
                        style={
                          !this.state.inputEdit
                            ? { display: "none" }
                            : { display: "block" }
                        }
                      >
                        <select
                          name={"kind_of_floors"}
                          className={"form-control alterationTypeSelect"}
                          onChange={this.handleChangeData}
                          defaultValue={item.damage &&
                            item.damage.kind_of_floor &&
                            item.damage.kind_of_floor.id}
                        >
                          <option value={null}></option>
                          {kind_floor &&
                            kind_floor.map((types, key) => {
                              return (
                                <option
                                  value={types.id}
                                  key={key}
                                >
                                  {types.type}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>

                    <div className={!this.state.inputEdit ? "col-9" : "col-8"}>
                      <p>Does that flooring lead into other rooms?</p>
                    </div>

                    <div
                      className={
                        this.state.inputEdit ? "col-4" : "col-3 damage_radios"
                      }
                    >
                      <input
                        style={
                          this.state.inputEdit
                            ? { display: "none" }
                            : { display: "block" }
                        }
                        name={"floor_lead"}
                        className={this.state.inputEdit ? " edit_inputs" : ""}
                        defaultValue={
                          Number(item.damage && item.damage.floor_lead) === 1
                            ? "Yes"
                            : "No"
                        }
                        readOnly={!this.state.inputEdit}
                      />

                      <div
                        style={
                          !this.state.inputEdit
                            ? { display: "none" }
                            : { display: "block" }
                        }
                      >
                        <input
                          type="radio"
                          id="other_room"
                          name="floor_lead"
                          value={1}
                          onChange={this.handleChangeData}
                        />
                        <label htmlFor="other_room">Yes</label>

                        <input
                          type="radio"
                          id="room"
                          name="floor_lead"
                          value={0}
                          defaultChecked={true}
                          onChange={this.handleChangeData}
                        />
                        <label htmlFor="room">No</label>
                      </div>
                    </div>

                    <div className="col-8">
                      <p>Was the plumbing repaired?</p>
                    </div>

                    <div className="col-4 damage_radios">
                      <input
                        style={
                          this.state.inputEdit
                            ? { display: "none" }
                            : { display: "block" }
                        }
                        name={"repaired_plumbing"}
                        className={this.state.inputEdit ? " edit_inputs" : ""}
                        defaultValue={
                          Number(
                            item.damage && item.damage.repaired_plumbing
                          ) === 1
                            ? "Yes"
                            : "No"
                        }
                        readOnly={!this.state.inputEdit}
                      />

                      <div
                        style={
                          !this.state.inputEdit
                            ? { display: "none" }
                            : { display: "block" }
                        }
                      >
                        <input
                          type="radio"
                          id="plumbing_yes"
                          name="repaired_plumbing"
                          value={1}
                          onChange={this.handleChangeData}
                        />
                        <label htmlFor="plumbing_yes">Yes</label>

                        <input
                          type="radio"
                          id="plumbing_no"
                          name="repaired_plumbing"
                          value={0}
                          defaultChecked={true}
                          onChange={this.handleChangeData}
                        />
                        <label htmlFor="plumbing_no">No</label>
                      </div>
                    </div>

                    <div className="col-5">
                      <p>EMS Vendor</p>
                    </div>

                    <div className="col-7">
                      <input
                        onChange={this.handleChangeData}
                        name={"EMS_vendor"}
                        type={"text"}
                        defaultValue={item.damage && item.damage.EMS_vendor}
                        className={this.state.inputEdit ? " edit_inputs" : ""}
                        readOnly={!this.state.inputEdit}
                      />
                    </div>

                    <NoteModal
                      col={5}
                      title={"EMS Notes"}
                      name={"EMS_notes"}
                      id={"EMS_notes"}
                      notes={item.damage && item.damage.EMS_notes}
                      inputEdit={this.state.inputEdit}
                      noteSave={this.noteSave}
                    />

                    <NoteModal
                      col={5}
                      title={"Repairs Since DOL"}
                      name={"repairs_since_DOL"}
                      id={"repairs_since_DOL"}
                      notes={item.damage && item.damage.repairs_since_DOL}
                      inputEdit={this.state.inputEdit}
                      noteSave={this.noteSave}
                    />

                    <NoteModal
                      col={5}
                      title={"Mitigation Efforts"}
                      name={"mitigation_efforts"}
                      id={"mitigation_efforts"}
                      notes={item.damage && item.damage.mitigation_efforts}
                      inputEdit={this.state.inputEdit}
                      noteSave={this.noteSave}
                    />

                    <div className="col-4">
                      <p>Carrier Vendor</p>
                    </div>

                    <div className="col-8">
                      <input
                        onChange={this.handleChangeData}
                        defaultChecked={
                          item.damage && item.damage.carrier_vendor
                        }
                        disabled={!this.state.inputEdit}
                        type="checkbox"
                        id="carrier_vendor"
                        name="carrier_vendor"
                      />
                      <label htmlFor="carrier_vendor"></label>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    note_data: state.leadsReducer.note_data,
  };
}

export default connect(mapStateToProps)(ExtentDamage);




