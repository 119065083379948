
import React from 'react';
import login_styles from "../assets/css/login_styles.module.css";

function ResetPassConfirm() {

    return(
        <>

            <div className={`${login_styles.login_header} ` +   (window.innerWidth < 991 ? ' mobile_vhc' : ' vhc')}>

                <div className="container">

                    <div className={`${login_styles.login_content} "mid_col" + "col-md-6" + " col-12"` }>

                        <div>

                            <div className={login_styles.img}>

                                <img src={require('../assets/img/APA_Logo.png')} alt={'logo'}/>

                                <div className={'spacing-80'}></div>
                            </div>

                            <div className={login_styles.succes_message}>
                                <p>
                                    The request has been sent.
                                </p>
                            </div>





                            <div className={'text-center login_button'}>

                              <a href={'/#'} className={'link_button'}>
                                  Login
                              </a>


                            </div>


                        </div>

                    </div>
                    <div className={login_styles.rules}>
                        <ul>
                            <li>
                                <a href={'/'}>
                                    Term of use
                                </a>
                            </li>

                            <li>
                                <a className={login_styles.last} href={'/'}>
                                    Privacy policy
                                </a>
                            </li>
                        </ul>
                    </div>

                </div>

            </div>


        </>
    )
}

export default ResetPassConfirm;
