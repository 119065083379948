import React from "react";



class Help extends React.Component{
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return(
            <div className="container">
                <div className={'change_pass_title'}>
                    <h3>Submit a Ticket</h3>
                </div>


                <div className="row">
                    <div className="col-5 change_profile_info">
                        <p>How Can We Help?</p>
                        <p>Leave a Note</p>
                    </div>

                    <div className="col-7 change_profile_inputs help_select">
                        <div className="form-group selectdiv ">

                            <select className="form-control alterationTypeSelect" name="how_can_help" onChange={this.handleChange}>
                                <option value="" defaultValue=""></option>
                                <option value="button">Button</option>
                                <option value="stitching">Stitching</option>
                                <option value="cloth">Cloth</option>
                                <option value="fabrics">Fabrics</option>
                                <option value="otherClick">Other</option>
                            </select>
                        </div>


                        <input type={'text'} name={'leave_note'} onChange={this.handleChange}/>


                    </div>
                </div>


                <button className={'setting_modal_button default_hover'}>
                    Save Changes
                </button>

            </div>
        )
    }
}

export default Help;