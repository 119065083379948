import React from "react";
import {NavLink, useLocation} from "react-router-dom";
import AddLead from "../AddLead";
import {connect} from 'react-redux'
import Settings from "../Settings";
import {Dropdown} from 'react-bootstrap'
import axios from "axios";
import {uploads_url, url} from "../../config";
import {getUserNotify, sideBarToggle} from "../../redux/actions";
import userInfoReducer from "../../redux/reducers/userInfoReducer";




class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle:false,
            show:false,
            search:false,
            settings_modal_show:false,
            toggleNav :true,
            user_avatar:uploads_url +'static/default_avatar/avatar.png',
            windowWidth: window.innerWidth,
            toSearch:false,
            searchVal:'',
            user:'',

        }
        this.topSearch = React.createRef();
    }

     handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
         this.props.resize_screen(window.innerWidth);
    };

    resize = () => {
        window.addEventListener("resize", this.handleResize);
    }

    getType = (type) =>{
        let value
        switch (type) {
            case 'assign':
                value = 'ASSIGN'
                break;
            case 'signedClaims':
                value = 'Signed Claims'
                break;
            default:
                //Здесь находятся инструкции, которые выполняются при отсутствии соответствующего значения
                //statements_def
                break;
        }
        return value
    }

    getDays = (date) => {
        let now = new Date().getTime()
        let day = new Date(date)
        let res =now-day
        return  Math.floor(res/(1000 * 3600 * 24))
    }
    componentWillUnmount  () {
       this.resize();
       window.removeEventListener('resize',this.handleResize)
    }


    handleOpenModal = () =>{
                this.setState({
                    show:true
                })
        window.addEventListener("resize", this.handleResize);
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        this.getUserAvatar();
        this.getUserProfile();
    }



    getUserProfile =   () => {
        axios.get(url + 'get-auth-user-profile', {})
            .then(res => {
                this.setState({user:res.data})
                setInterval(()=>{
                    this.props.dispatch(getUserNotify(this.state.user.id));
                },10000)
                this.props.dispatch(getUserNotify(res.data.id));
            })
    }

    myCallback = (dataFromChild) => {
        this.setState({
            show: dataFromChild,
            settings_modal_show:dataFromChild
        })

    }




    handleToggleNav = () => {
        this.setState({
            toggleNav:!this.state.toggleNav
        })
    }



    handleSearch = () => {
        this.setState({
            search:!this.state.search
        })
        if(this.state.search === false) {
            this.topSearch.current.focus();
        }
    }


    openSettingsModal = () => {
        this.setState({
            settings_modal_show:!this.state.settings_modal_show
        })
    }


    getUserAvatar = () => {
        axios.get(url + 'get-image')
            .then(response => {
                if (response.data.avatar) {
                    this.setState({ user_avatar: uploads_url +'uploads/'+  response.data.avatar});
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    sideBarToggle = () => {
        this.props.sideBarToggle(!this.state.toggle)

        this.setState({
            toggle:!this.state.toggle
        })

    }
    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            window.location = window.location.origin+'/search' + '?search='+e.target.value
        }
      }

    readNotify = (id,read,lead_id) => {
        if(read==null){
            axios.get(url + 'update_notify?id='+id)
                .catch(err => {
                    console.log(err)
                })
        }
        window.location.href = `/leads/${lead_id}`
    }

    render() {

        const {notifies} = this.props

        return (

            <>


                <div>


                    <nav className="navbar navbar-expand-lg navbar-light  border-bottom">

                        <button className="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon" onClick={this.sideBarToggle}/>

                        </button>


                        <div className={'mobile_logo'}>
                            <img onClick={() => window.location.href = '/'} src={require('../../assets/img/mobile_logo.png')} alt={'mobile_logo'}/>
                        </div>

                        <div className={'add_lead'}>

                            <button  type="button" className={'default_hover'} onClick={this.handleOpenModal}>Add Lead</button>
                        </div>


                        <div className={'create_claim'}>
                            <button>Create Claim</button>
                        </div>


                        <div className="collapse navbar-collapse dashboard_nav" id="navbarSupportedContent">
                            <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                                <li className="nav-item ">

                                    {this.state.windowWidth > 991 ?

                                    <div className={'search-box' + (this.state.search ? ' search' : '')} >
                                        <input ref={this.topSearch} className="search-text" type="text" onKeyDown={this._handleKeyDown} placeholder="Search..."/>
                                        {this.state.search ?  <i className="fas fa-times close_icon" onClick={this.handleSearch}/> :  <i className="apa-icon-search" onClick={this.handleSearch}/> }

                                    </div> :
                                        <div className={'mobile-search-box'}>
                                            <NavLink to="/search">
                                                <i className={'apa-icon-search'}/>
                                            </NavLink>
                                        </div>


                                        }
                                </li>
                                {this.state.windowWidth > 991 ?
                                    <li className="nav-item notification_count">

                                        <Dropdown className = 'notify_drobdown' onClick = {this.handleToggleNav}>
                                            {notifies.count > 0 ? <span className={'notify_count'}>{notifies.count}</span> :""}

                                            <Dropdown.Toggle  id="dropdown-basic">
                                                <i className={'apa-icon-notification'}/>
                                            </Dropdown.Toggle>


                                            <div className={'notify_dd' + (this.state.toggleNav ? ' d-none' : '')}>
                                                <div className={'notify_content'}>
                                                    <div className={'notify_title'}>
                                                        <h5>Notifications</h5>
                                                    </div>

                                                    <div className={'notify_view_all'}>
                                                        <NavLink to = '/notifications'>
                                                            View All
                                                        </NavLink>
                                                    </div>
                                                </div>

                                                <div>
                                                    {notifies.notifies && notifies?.notifies.map((item,key) =>{
                                                            if(key < 6){
                                                                return(

                                                                        <div onClick={() => this.readNotify(item.id,item.read_at,item.lead_id)}
                                                                             className={'notification_body header_notify' + ( Number(key + 1) % 2 === 0 ? ' c_not' : '') + ( item.read_at ? ' notify_read' : '') } key={key}>
                                                                        <div>
                                                                            <p><span>{this.getType(item.type)}:</span> {item.message?.text}</p>
                                                                        </div>


                                                                        <div className={'notifications_date'}>
                                                                            <p>LIN: {item.lead.LIN}</p><span>{item.user.first_name + ' '+ item.user.last_name}</span>
                                                                        </div>


                                                                        <div className={'notifications_time'}>
                                                                            <span>{this.getDays(item.createdAt) == 0 ? 'Today' :this.getDays(item.createdAt)+' days ago' }</span>
                                                                        </div>


                                                                    </div>

                                                                        )

                                                            }

                                                    })}
                                                </div>

                                            </div>

                                        </Dropdown>
                                        <NavLink className="nav-link" to="/mobile-notifications">

                                    </NavLink>
                                    </li> :
                                    <li className="nav-item">

                                        <Dropdown className = 'notify_drobdown' onClick = {this.handleToggleNav}>
                                            <Dropdown.Toggle  id="dropdown-basic">
                                               <NavLink to = '/mobile-notifications'>
                                                   <i className={'apa-icon-notification'}/>
                                               </NavLink>
                                            </Dropdown.Toggle>
                                        </Dropdown>
                                    </li>
                                }
                                <li className="nav-item user_img">
                                    <div className={'user_avatar'} onClick={this.openSettingsModal}>

                                        <NavLink className="nav-link" to="#">

                                            <img src={this.state.user_avatar} alt={'avatar'}/>

                                        </NavLink>

                                    </div>
                                </li>
                            </ul>
                        </div>
                    </nav>

                    <AddLead show = {this.state.show} myCallback = {this.myCallback}/>
                    <Settings show = {this.state.settings_modal_show} myCallback = {this.myCallback} user_avatar = {this.state.user_avatar}/>
                </div>
            </>
        )
    }
}

function mapDispatchToProps(dispatch){
    return{
        resize_screen: (windowWidth) => dispatch({type:'resize_screen',payload:windowWidth}),
        sideBarToggle : (condition) => dispatch(sideBarToggle(condition)),
        dispatch,
    }
}



function mapStateToProps(state){

    return{
        notify_length:state.appReducer.count,
        notifies:state.userInfoReducer.notifies

    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Navbar);
