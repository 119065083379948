import {CHECKAUTH, GETUSERINFO, GETUSERNOTIFY} from "../types";

const initialState = {
    user_data:'',
    isAuth:false,
    notifies:[]
}


export default function rootReducer(state = initialState,action){

    switch (action.type) {

        case GETUSERINFO:

            return {
                ...state,
                user_data:action.payload
            }


        case CHECKAUTH :

            return {
                ...state,
                isAuth:action.payload
            }

        case GETUSERNOTIFY :

            return {
                ...state,
                notifies:action.payload
            }

        default:{
            return state;
        }
    }

}