import React, { PureComponent } from 'react';
import { Table } from 'react-bootstrap';
import {connect} from 'react-redux';
import { getAllHistory } from '../../redux/actions';




class AssignedHistory extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      assignedHistoryData: null,
    };
  }


  componentDidMount() {
    this.props.dispatch(getAllHistory(this.props.leadId)) 
  }
// componentDidUpdate() {
//   this.props.dispatch(getAllHistory(this.props.leadId)) 
// }
componentDidUpdate(prevProps) {
  if(this.props.lead_body[0]?.assignment?.assignment_histories && this.props.lead_body[0]?.assignment?.assignment_histories.length !== prevProps.lead_body[0]?.assignment?.assignment_histories.length) {
    this.props.dispatch(getAllHistory(this.props.leadId)) 
  }
} 

  render() {
    const {histories,lead_body} = this.props;
    return (


      <div className={'add_lead_basic lead_details_basic_info'}>
        <div className={'leads_details_edit'}>
          <h3>History of Assigned Lead PA/Team</h3>

          <div className={'users_table_container'}>
            <div
              className={'lead_table assigned_history_table table-responsive '}
            >
              <Table striped bordered hover className=''>
                <thead>
                  <tr>
                    <th>Lead PA</th>
                    <th >Team</th>
                    <th >By</th>
                    <th >Date</th>
                    <th >Time</th>
                  </tr>
                </thead>

                <React.Fragment>
                  <tbody>
                    {histories && histories?.map((i,k) => {
                      let date = i.date?.slice(0, 10);
                      let time = i.date?.slice(11, 19);
                      return (
                        <tr key={k+1}>
                          <td>{i?.user?.first_name && i?.user?.first_name +' '+i?.user?.last_name }</td>
                          <td>{i?.team?.name}</td>
                          <td>{i.created_by}</td>
                          <td>{date}</td>
                          <td>{time}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </React.Fragment>
              </Table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state)
{
 return{
  histories:state.leadsReducer.histories,
 }
}

export default connect(mapStateToProps,null)(AssignedHistory) ;
