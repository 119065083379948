import React from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import { url } from "../../config";
import { connect } from "react-redux";
import { relatedUpdateModalShowHide } from "../../redux/actions";
import NoteModal from "./NoteModal";
import NumberFormat from 'react-number-format';
let related_contact = {};

class AddRelatedContactModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closeModal: false,
      inputEdit: false,
    };
  }
  handleChangeData = (event) => {
    const { name, value } = event.target;

    related_contact[name] = value;

    if (name === "primary_contact") {
      if (value === "on") {
        related_contact["primary_contact"] = "1";
      } else {
        related_contact["primary_contact"] = "0";
      }
    }

    if (name === "policy_holder") {
      if (value === "on") {
        related_contact["policy_holder"] = "1";
      } else {
        related_contact["policy_holder"] = "0";
      }
    }
  };

  sendData = (event) => {
    event.preventDefault();
    related_contact["contact_id"] = this.props?.lead_body[0]?.contact_id;

    axios
      .post(url + "add-single-related-contact", related_contact)
      .then((res) => {
        related_contact = {};
        this.props.openAddMoreRelatedContactModal(false);
        return this.props.myCallback(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  noteSave(name, value) {
    related_contact[name] = value;
  }

  render() {
    const { show, related_data } = this.props;

    return (
      <Modal
        show={show}
        onHide={() => this.props.openAddMoreRelatedContactModal(false)}
        centered
        className="discover_modal_content log_entry_modal"
        dialogClassName="discover_modal related_update_modal"
      >
        <form onSubmit={this.sendData}>
          <Modal.Header closeButton>
            <Modal.Title>New Related Contact</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className={"lead_details_basic_info related_update  "}>
              <div className="row">
                <div className="col-6">
                  <p>Company Name</p>
                </div>

                <div className="col-6">
                  <input
                    onChange={this.handleChangeData}
                    type={"text"}
                    name={"company_name"}
                    defaultValue={related_contact['company_name'] ? related_contact['company_name'] : ''}
                    className={"edit_inputs"}
                  />
                </div>

                <div className="col-6">
                  <p>First Name</p>
                </div>

                <div className="col-6">
                  <input
                    onChange={this.handleChangeData}
                    className={"edit_inputs"}
                    type={"text"}
                    defaultValue={related_contact['first_name'] ? related_contact['first_name'] : ''}
                    name={"first_name"}
                  />
                </div>

                <div className="col-6">
                  <p>Last Name</p>
                </div>

                <div className="col-6">
                  <input
                    onChange={this.handleChangeData}
                    className={"edit_inputs"}
                    type={"text"}
                    defaultValue={related_contact['last_name'] ? related_contact['last_name'] : ''}
                    name={"last_name"}
                  />
                </div>

                <div className="col-6">
                  <p>Cell Number</p>
                </div>

                <div className="col-6">
                <NumberFormat 
                    format="(###) ###-####"
                    onChange={this.handleChangeData}
                    className={"edit_inputs"}
                    type={"text"}
                    defaultValue={related_contact['cell_number'] ? related_contact['cell_number'] : ''}
                    name={"cell_number"}
                  />
                </div>

                <div className="col-6">
                  <p>Work Number</p>
                </div>

                <div className="col-6">
                <NumberFormat 
                    format="(###) ###-####"
                    onChange={this.handleChangeData}
                    className={"edit_inputs"}
                    name={"work_number"}
                    defaultValue={related_contact['work_number'] ? related_contact['work_number'] : ''}
                    type={"text"}
                  />
                </div>

                <div className="col-6">
                  <p>Home Number</p>
                </div>

                <div className="col-6">
                <NumberFormat 
                    format="(###) ###-####"
                    onChange={this.handleChangeData}
                    className={"edit_inputs"}
                    type={"text"}
                    name={"home_number"}
                    defaultValue={related_contact['home_number'] ? related_contact['home_number'] : ''}
                  />
                </div>

                <div className="col-6">
                  <p>Email</p>
                </div>

                <div className="col-6">
                  <input
                    onChange={this.handleChangeData}
                    className={"edit_inputs"}
                    type={"email"}
                    defaultValue={related_contact['email'] ? related_contact['email'] : ''}
                    name={"email"}
                  />
                </div>

                <NoteModal
                  col={6}
                  title={"Notes"}
                  name={"notes"}
                  id={"add_notes_modal"}
                  noteSave={this.noteSave}
                  inputEdit={true}
                  notes={related_contact["notes"] ?  related_contact["notes"] : ''}
                />

                <div className="col-6">
                  <p>Primary Contact</p>
                </div>

                <div className="col-6">
                  <input
                    onChange={this.handleChangeData}
                    className={"edit_inputs"}
                    type="checkbox"
                    id="primary_contact"
                    defaultChecked={related_contact['primary_contact'] == 1}
                    name="primary_contact"
                  />
                  <label htmlFor="primary_contact"></label>
                </div>

                <div className="col-6">
                  <p>Policy Holder</p>
                </div>

                <div className="col-6">
                  <input
                    onChange={this.handleChangeData}
                    className={"edit_inputs"}
                    type="checkbox"
                    id="policy_holder"
                    defaultChecked={related_contact['policy_holder'] == 1}
                    name="policy_holder"
                  />
                  <label htmlFor="policy_holder"></label>
                </div>
              </div>
            </div>
            <Modal.Footer>
              <Button type={"submit"} variant="primary">
                Save
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    related_update_show: state.leadsReducer.related_update_modal,
  };
}

export default connect(mapStateToProps, null)(AddRelatedContactModal);
