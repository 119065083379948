import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Message from "./Message";
import Write from "./Write";
import {Link} from "react-router-dom";
import axios from "axios";
import {socket_host, url} from "../../../../config";
import { io } from "socket.io-client";

import {OverlayTrigger} from "react-bootstrap";
import LogEntryModal from "../../../leadDetails/LogEntryModal";


function Chat({currentLead,nonReadMess,getConvId}) {


    const [user, setUser] = useState('');
    const [messages,setMessages] = useState({})
    const [reRender,setReRender] = useState(false)
    const [conversationId,setConversationId] = useState()
    const [show,setShow] = useState(false)
    const [lead_body,setLead_body] = useState('')
    const [loading,setLoading] = useState(false)


    const socket = useRef()

    const scrollRef = useRef()



    useEffect( ()=>{
        if (!currentLead) return;
        axios.post(url + '/get-conversation-by-lead',{contact_id:currentLead.contact_id}).then(resp=>{

            if(resp.data.conversationId) {
                getConvId(resp.data.conversationId)
                setMessages(messages => ({
                    ...messages,
                    [resp.data.conversationId]: resp.data.messages,
                }))
            }
            setConversationId(resp.data.conversationId)
        })
    },[currentLead?.id])


    useEffect(()=>{
        getUserProfile()
        // getNew()

    },[messages])

    useEffect(()=>{
        console.log(scrollRef?.current?.scrollIntoView({behavior: "smooth", block: "end"}));
    },[reRender])



    const getUserProfile =   () => {
        axios.get(url + 'get-auth-user-profile', {})
            .then(res => {
                setUser(res.data)
            })
    }


    useEffect(()=>{
        socket.current= io(socket_host)
        const handleNewConversation =(data)=>{
            nonReadMess(data.conversation_id)
            if(data.lead_id === currentLead?.contact_id){
                setConversationId(data.conversation_id)
            }

        }
        socket.current.on('newConversation',handleNewConversation)
        return () => socket.current.off('newConversation',handleNewConversation)
    },[currentLead?.id])


    useEffect(() => {
        socket.current= io(socket_host)
        const handleNewMessage = (data) => {
            if(data.conversationId) {
                if(data.status ==0){
                    nonReadMess(data.conversationId)
                }
                    setMessages(messages => ({
                        ...messages,
                        [data.conversationId]: [...(messages[data.conversationId] || []), data]
                    }))
                setLoading(false)
            }
        }
        socket.current.on('newMessage', handleNewMessage)

        return () => socket.current.off('newMessage', handleNewMessage)
    }, [])


    const myCallback = (dataFromChild) => {
        if(dataFromChild){
            setLead_body('')
        }
    }

   const  modalCallBack = (parentDate,name = '') => {
        setShow(parentDate)
    }

    const addLogEntry = (item) => {
        setLead_body([item])
        setShow(true)

    }

    const changeLoading=(value)=>{
        setLoading(value)
        setReRender( rerender=>!rerender)
    }

    const noMessage = useMemo(()=>{
        if(conversationId in messages) {
            return messages[conversationId].length === 0
        }
        return true
    },[conversationId,messages])


    return (
        <>
            <div className={'chat_title'}>
                <div className={'message_title'}>
                    <h4>LIN: {currentLead?.LIN}</h4>
                </div>
                <div className={'message_icons'}>
                    <ul>
                        <li>
                            <Link to={{
                                pathname:'/leads/' + currentLead?.id,
                                state:currentLead?.id
                            }}>
                                <span><i className={'apa-icon-view icon-view'}></i></span>
                            </Link>
                            <Link to={{
                                pathname:'/leads/' + currentLead?.id,
                                state:currentLead?.id
                            }}>
                                <span><i className={'apa-icon-documents'}></i></span>
                            </Link>


                            <OverlayTrigger
                                placement='bottom'
                                overlay={
                                    <div className={"overflow-box"}>
                                        <h3>Lead Source Notes</h3>
                                        <p>
                                            {currentLead?.notes}
                                        </p>
                                    </div>
                                }>
                                {({ ref, ...triggerHandler }) => (
                                    <i ref={ref}{...triggerHandler} className={'apa-icon-notes'}/>
                                )}
                            </OverlayTrigger>
                            <span onClick={()=>addLogEntry(currentLead)}><i className={'apa-icon-add'}></i></span>
                            <LogEntryModal lead_body = {[currentLead]} show = {show} myCallback = {myCallback} modalCallBack = {modalCallBack}/>
                        </li>
                    </ul>
                </div>

            </div>
            <div className="messaging">
                <div className="inbox_msg">
                    <div className="mesgs">
                        {noMessage ? (<div style={{marginTop:'35px'}}>
                            <div style={{display:'flex',justifyContent:'center',fontSize:'18px',color:'#1B75BE',fontFamily:'OpenSansBold'}}>No messages</div>
                            <div style={{display:'flex',justifyContent:'center',fontSize:'12px',color:'#00559A',fontFamily:'openSansRegular'}}>New messages will appear here</div>
                            </div>)
                            :(

                                <div className="msg_history" ref={scrollRef}>
                                    {conversationId && messages[conversationId] && messages[conversationId].map((v,k)=>
                                        (<Message own={v?.from === 'agent'}
                                                  message={v}
                                                  contactName={currentLead?.client?.insured_name}
                                                  key={k}/>)

                                    )}

                                </div>
                            )
                        }

                    </div>
                    <Write currentLead={currentLead}
                           user={user}
                           loadingCheck={changeLoading}
                           loading={loading}
                    />

                </div>
            </div>
        </>
    );
}

export default Chat;