import React, {useEffect, useRef, useState} from "react";
import {Alert, Modal} from "react-bootstrap";
import {connect, useDispatch, useSelector} from 'react-redux'
import {addPropertyModal, getAddress} from "../../redux/actions";
import axios from "axios";
import {url} from "../../config";
import Select from "../Inputs/Select";
import NumberFormat from "react-number-format";
import NoteModal from "../leadDetails/NoteModal";
import InputSelect from "../Inputs/InputSelect";


const property = {
    mortgage:{},
    address:{},
    building:{}
};

function AddPropertyModal(props){

    const add_property_modal = useSelector (state => state.appReducer.add_property_modal)
    const dispatch = useDispatch()


    const [cities,setCities] = useState([])
    const [states,setStates] = useState([])
    const [countries,setCountries] = useState([])
    const [buildingTypes,setBuildingTypes] = useState([])
    const [successShow,setSuccessShow] = useState(false)
    const [refresh,setRefresh]= useState(false)
    const [loading,setLoading]= useState(false)

    const buttonEl = useRef(null);

    useEffect(() => {
        getCities()
        getStates()
        getCountries()
        getBuildingTypes()
        property.address.contact_id =props.contact_id
        property.address.client_id =props.contact_id
    },[])

   const handleChangeData = (event) => {
       const {name,value} = event.target;

       let box = name.split("|")
       switch (box[1]) {
           case 'mortgage':
                property.mortgage[box[0]] =value
               break;
           case 'address':
               if(box[0] === 'zip') getZip(value)
               property.address[box[0]] =value

               break;
           case 'building':
               property.building[box[0]] =value
               break;
           default:
               break;
       }
    }

    const noteSave = (name,value)=> {
        property.building[name] = value;
    }


    const getZip = async (zip)=>{
        setRefresh(false)
        if(zip.length === 5){
            const { data: response } = await axios.post(url + "get-location-by-zip",
                {parameter: zip,}
            );
            console.log(response.result);
            if(response?.result?.length === 1){
                property.address.state=response.result[0].state
                property.address.city=response.result[0].city
                setRefresh(true)
            }

        }
        else{
            property.address.state=''
            property.address.city=''
        }
    }


    const getCities =  () => {
            axios.get(url + "get-cities", {}).then(res =>  {
                setCities(res.data)
            });
    };

   const getStates =  () => {
       axios.get(url + "get-states", {}).then(res =>  {
                setStates(res.data)
            });
    };

  const  getCountries =  () => {
      axios.get(url + "get-countries").then(res => {
          setCountries(res.data)
      });

    };

  const  getBuildingTypes = () => {
      axios.get(url + '/get-building-types')
          .then(res => {
              setBuildingTypes(res.data)
          })
  }


    const handleSubmit = () => {
        buttonEl.current.setAttribute("disabled", "disabled");
        axios.post(url + 'add-property',property)
        .then(res => {
            setLoading(true)
            dispatch(getAddress(null))
            props.myCallback()
            setTimeout(function (){
                setSuccessShow(true)
                setLoading(false)

            },4000)
            setTimeout(function (){
                property.address.state=''
                property.address.city=''
                dispatch(addPropertyModal(false))
                setSuccessShow(false)
            },7000)

        })



    }


    return(
        <>
        <Modal show = {add_property_modal} onHide = {() => {dispatch(addPropertyModal(false))}}   centered className = "fullScreen_modal add_property_modal" >
            <Modal.Header closeButton >
                <div className="container" style={{paddingLeft:'31px'}}>
                    <Modal.Title>
                        <Alert show={successShow} variant="success">
                            <Alert.Heading>Property Add Success</Alert.Heading>
                        </Alert>

                    </Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>

                <div className="container leads_details_edit ">

                    <div className="row">

                        <div className="col-6">

                            <div className={'property_content'}>
                                <h3>Property Address</h3>
                                <div className="row">
                                    <div className="col-4">
                                        <p>Street Number</p>
                                    </div>

                                    <div className={'col-8'}>
                                        <input  name={"street_number|address"}
                                                type={"text"}
                                                onChange={handleChangeData}  />
                                    </div>

                                    <div className="col-4">
                                        <p>Street Name</p>
                                    </div>

                                    <div className={'col-8'}>
                                        <input type={'text'}
                                               name={"street_name|address"}
                                               onChange={handleChangeData}  />
                                    </div>

                                    <div className="col-4">
                                        <p>Unit Number</p>
                                    </div>

                                    <div className={'col-8'}>
                                        <input  name={"unit_number|address"}
                                                type={"text"}
                                                onChange={handleChangeData}  />
                                    </div>


                                    <InputSelect options={{label:'City',name:'city',value:property?.address?.city,class:'leads_details_selects'}}
                                                 inputEdit={true}
                                                 handleChange={handleChangeData}
                                                 data={cities}
                                                 path={'city'}
                                                 type={'mini'}
                                    />

                                    <InputSelect options={{label:'State',name:'state|address',value:property?.address?.state,class:'leads_details_selects'}}
                                                 inputEdit={true}
                                                 handleChange={handleChangeData}
                                                 data={states}
                                                 path={'state'}
                                                 type={'mini'}
                                    />

                                    <div className="col-4">
                                        <p>Zipcode</p>
                                    </div>

                                    <div className={'col-8'}>
                                        <input type={'text'}  name={'zip|address'} onChange={handleChangeData}  />
                                    </div>

                                    <div className="col-4">
                                        <p>Country</p>
                                    </div>

                                    <div className={'col-8'}>
                                        <Select handleChangeData={handleChangeData} name={'country|address'} path={'country'} data={[countries]}/>
                                    </div>


                                </div>
                            </div>

                            <div className={'property_content'}>
                                <h3>Mortgage Company Information</h3>
                                <div className="row">
                                    <div className="col-4">
                                        <p>Company Name</p>
                                    </div>

                                    <div className={'col-8'}>
                                        <input type={'text'} name={'company_name|mortgage'} onChange={handleChangeData} />
                                    </div>


                                    <div className="col-4">
                                        <p>Address</p>
                                    </div>

                                    <div className={'col-8'}>
                                        <input type={'text'} name={'address|mortgage'} onChange={handleChangeData} />
                                    </div>


                                    <div className="col-4">
                                        <p>Phone Number</p>
                                    </div>

                                    <div className={'col-8'}>
                                        <NumberFormat format="(###) ###-####" name={'phone_number|mortgage'} onChange={handleChangeData} type={'text'}   />
                                    </div>

                                    <div className="col-4">
                                        <p>Email</p>
                                    </div>

                                    <div className={'col-8'}>
                                        <input name={'email|mortgage'} onChange={handleChangeData} type={'text'}  />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className={'property_content building_info_content'}>
                                <h3>Building Info</h3>
                                <div className="row">
                                    <div className="col-4">
                                        <p>Building Type</p>
                                    </div>

                                    <div className={'col-8'}>

                                        <Select handleChangeData={handleChangeData} name={'building_id|building'} path={'type'} data={buildingTypes} value={'id'}/>
                                    </div>

                                    <div className="col-4">
                                        <p>Size of Building</p>
                                    </div>

                                    <div className={'col-8'}>
                                        <input onChange={handleChangeData} name={'size_of_building|building'}  />
                                    </div>

                                    <div className="col-4">
                                        <p>Year Built</p>
                                    </div>

                                    <div className={'col-8'}>
                                        <input onChange={handleChangeData} name={'year_build|building'} type={'number'}  />
                                    </div>

                                    <div className="col-4">
                                        <p>Beds</p>
                                    </div>

                                    <div className={'col-8'}>
                                        <input onChange={handleChangeData} name={'beds|building'} type={'number'}  />
                                    </div>


                                    <div className="col-4">
                                        <p>Baths</p>
                                    </div>

                                    <div className={'col-8'}>
                                        <input name={'baths|building'} type={'number'}  />
                                    </div>

                                    <div className="col-4">
                                        <p>Prior Claims</p>
                                    </div>

                                    <div className={'col-8'}>
                                        <input type="checkbox"
                                               id="prior_claims"
                                               defaultChecked={false}
                                               name="prior_claims|building"
                                               value={1}
                                               onChange={handleChangeData}
                                        />
                                        <label htmlFor="prior_claims"/>
                                    </div>

                                    <div className="col-4">
                                        <p>Est. Property Value</p>
                                    </div>

                                    <div className={'col-8'}>
                                        <input type={'number'}
                                               onChange={handleChangeData}
                                               name={'est_property_value|building'}  />
                                    </div>

                                        <NoteModal title={'Building Notes'} name={'building_note'}
                                                   id={'building_note'}
                                                   inputEdit={true}
                                                   noteSave={noteSave}/>

                                    <div className="col-4">
                                        <p>Age of Home</p>
                                    </div>

                                    <div className={'col-8'}>
                                        <input onChange={handleChangeData} name={'age_of_home|building'} type={'number'}  />
                                    </div>


                                    <div className="col-4">
                                        <p>Years Occupied</p>
                                    </div>

                                    <div className={'col-8'}>
                                        <input onChange={handleChangeData} name={'years_occupied|building'} type={'number'}  />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading ?  <div className={'spinner-grow text-primary'}
                                     style={{width:'1.7rem',height: '1.7rem',marginLeft:'7px',float:'right',marginTop:'6px'}}/> :''}
                    <button
                        ref={buttonEl}
                        className="btn float-right add_lead_next_button default_hover"
                        type="button"
                        onClick={handleSubmit}
                    >
                        Save
                    </button>

                </div>

            </Modal.Body>

        </Modal>
        </>
    )
}


export default connect(null,null)(AddPropertyModal);
