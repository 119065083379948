import React from "react";
import {Modal,Tabs,Tab} from 'react-bootstrap';
import '../assets/css/settings_styles.css';
import CreateUser from "../components/settings/CreateUser";
import Account from "./settings/Account";
import Help from "./settings/Help";
import Profile from "./settings/Profile";



class Settings extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            show:false,


        }
    }

    handleChange = (e) => {

        //const {name,value} = e.target;

    }

    handleClose = () => {
       return this.props.myCallback(false);
    }

   render() {
        const {user_avatar} = this.props;
       return(
           <>
           <Modal show={this.props.show} onHide={this.handleClose} className = {'settings_modal'}>
               <Modal.Header>
                   <i className={'apa-icon-close'} onClick={this.handleClose}></i>
                   <Modal.Title>Settings</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                   <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className = 'settings_tabs'>
                       <Tab eventKey="profile" title="Profile">
                        <Profile user_avatar = {user_avatar} handleClose = {this.handleClose}/>
                       </Tab>


                       <Tab eventKey="account" title="Account">
                        <Account handleClose = {this.handleClose}/>
                       </Tab>

                       <Tab eventKey="notifications" title="Notifications" >
                        <div className="container">
                            <div className="row">

                                <div className="col-lg-6 col-8 notify_info">
                                    <p>Receive Email Notificaitons</p>
                                    <p>Receive SMS Notificaitons</p>
                                </div>

                                <div className="col-lg-6 col-4 receive_notifications">
                                    <input type="checkbox" id="email_notify" name="email_notify" onChange={this.handleChange} /><label htmlFor="email_notify"></label>
                                        <input type="checkbox" id="sms_notify" name="sms_notify"  onChange={this.handleChange} /><label htmlFor="sms_notify"></label>
                                </div>

                            </div>


                            <button className={'setting_modal_button default_hover'}>
                                Save Changes
                            </button>

                        </div>

                       </Tab>

                       <Tab eventKey="help" title="Help" >
                        <Help/>
                       </Tab>


                       <Tab eventKey="create_user" title="Create A User" tabClassName={'create_user_tab'} >
                        <CreateUser/>
                       </Tab>
                   </Tabs>
               </Modal.Body>
               <Modal.Footer>
               </Modal.Footer>
           </Modal>

               </>
       )
   }
}


export default Settings;