export const  SEARCHDATA        = "SEARCHDATA";
export const  GETALLLEADS       = "GETALLLEADS";
export const  GETUSERINFO       = "GETUSERINFO"
export const  GETLEADID         =  "GETLEADID"
export const  GETCURENTLEAD         =  "GETCURENTLEAD"
export const  OPENPROPERTYMODAL = "OPENPROPERTYMODAL"
export const  CHECKAUTH         = "CHECKAUTH"
export const  SHOWBEGINMODAL    = "SHOWBEGINMODAL"
export const  HIDEBEGINMODAL    = "HIDEBEGINMODAL"
export const  TOGGLESIDEBAR     = "TOGGLESIDEBAR"
export const  RELATEDUPDATEMODALSHOWHIDE = "RELATEDUPDATEMODALSHOWHIDE"
export const  CARRIERMODAL = "CARRIERMODAL"
export const OPTIONEDITMODAL = "OPTIONEDITMODAL"
export const TEAMSMODAL = "TEAMSMODAL"
export const RESCHEDULE = "RESCHEDULE"
export const TEAMS = "TEMAS"
export const ADJUSTER = "ADJUSTER"
export const HISTORY = "HISTORY"
export const STATUS = "STATUS"
export const LEEDINSPECTION = "LEEDINSPECTION"
export const GETLEEDCONTACT = "GETLEEDCONTACT"
export const LEEDDOCUMETS = "LEEDDOCUMETS"
export const LEEDDOCUMETSBYID = "LEEDDOCUMETSBYID"
export const GETFOLDERINFO = "GETFOLDERINFO"
export const GETSEARCHRESULT = "GETSEARCHRESULT"
export const GETCLIENTLEED = "GETCLIENTLEED"
export const GETLEEDBYID = "GETLEEDBYID"
export const GETUSERNOTIFY = "GETUSERNOTIFY"
export const GETALLSIGNEDCLAIMS = "GETALLSIGNEDCLAIMS"
export const GETADDRESSBYID = "GETADDRESSBYID"
export const GETMORTGAGEBYID = "GETMORTGAGEBYID"
export const GETBUILDINGBYID = "GETBUILDINGBYID"
export const GETCONTACTBYID = "GETCONTACTBYID"
export const GETINCOMINGLEADSBYDATE = "GETINCOMINGLEADSBYDATE"
export const GETALLSOURCES = "GETALLSOURCES"
export const GETALLCITIES = "GETALLCITIES"
export const GETCLIENTBYID = "GETCLIENTBYID"
export const GETRESULTBYDATE = "GETRESULTBYDATE"
export const GETADDRESSBYIDGENERAL = "GETADDRESSBYIDGENERAL"
export const GETEMAILTEMPLATES = "GETEMAILTEMPLATES"
export const GETMESSAGELEADS = "GETMESSAGELEADS"



