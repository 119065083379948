import React from 'react';


function LeadOptions({title,value}) {
    return (
        <>
            <div className="col-5">
                <p>{title}</p>
            </div>
            <div className="col-7">
                <span>{value}</span>
            </div>
        </>
    );
}

export default LeadOptions;