import React from "react";
import SideBar from "./header/SideBar";
import Navbar from "./header/Navbar";
import {connect} from 'react-redux'

//styles
import '../assets/css/notification_styles.css';
import axios from "axios";
import {url} from "../config";
import Pagination from "react-js-pagination";
import {getLeads} from "../redux/actions";





const not_data = [
    {
        not_title:'Claims:',
        not_progress:'Contract has been signed by lead',
        not_date:'LIN: 2020.01.12',
        not_user:'Jane Doe',
        not_time:'6 hours ago'
    },
    {
        not_title:'Claims:',
        not_progress:'A Claim has been assigned to you',
        not_date:'LIN: 2020.01.12',
        not_user:'Jane Doe',
        not_time:'6 hours ago'
    },
    {
        not_title:'Claims:',
        not_progress:'A Claim has been assigned to you',
        not_date:'LIN: 2020.01.12',
        not_user:'Jane Doe',
        not_time:'6 hours ago'
    },
    {
        not_title:'Claims:',
        not_progress:'A Claim has been assigned to you',
        not_date:'LIN: 2020.01.12',
        not_user:'Jane Doe',
        not_time:'6 hours ago'
    }
]

class Notifications extends React.Component{
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    getDays = (date) => {
        let now = new Date().getTime()
        let day = new Date(date)
        let res =now-day
        return  Math.floor(res/(1000 * 3600 * 24))
    }

    getType = (type) =>{
        let value
        switch (type) {
            case 'assign':
                value = 'ASSIGN'
                break;
            case 'signedClaims':
                value = 'Signed Claims'
                break;
            default:
                //Здесь находятся инструкции, которые выполняются при отсутствии соответствующего значения
                //statements_def
                break;
        }
        return value
    }

    readNotify = (id,read,lead_id) => {
        if(read==null){
            axios.get(url + 'update_notify?id='+id)
                .catch(err => {
                    console.log(err)
                })
        }
        window.location.href = `/leads/${lead_id}`
    }




    componentDidMount() {

    }


    render() {

    const {notifies} = this.props

        return(
            <>
                <div className={"d-flex" + (this.props.toggled ? ' toggled' : '')} id="wrapper">
                    <SideBar/>
                    <div id="page-content-wrapper">

                            <Navbar toggled = {this.props.myCallback} notifi_count = {not_data.length} />
                           <div className={'page_title'}>
                              <h2>Notifications</h2>
                           </div>

                        <div className={'notifications_content'}>

                            {notifies.notifies && notifies?.notifies.map((item,key) =>{

                                return(

                            <div  onClick={() => this.readNotify(item.id,item.read_at,item.lead_id)} className={'notification_body' + ( item.read_at ? ' notify_read' : '')} key={key}>
                                <div>
                                    <p><span>{this.getType(item.type)}:</span> {item.message?.text}</p>
                                </div>


                                <div className={'notifications_date'}>
                                    <p>LIN: {item.lead.LIN}</p><span>{item.user.first_name + ' '+ item.user.last_name}</span>
                                </div>


                                <div className={'notifications_time'}>
                                    <span>{this.getDays(item.createdAt) == 0 ? 'Today' :this.getDays(item.createdAt)+' days ago' }</span>
                                </div>


                            </div>

                                )
                            })}

                        </div>

                    </div>

                </div>

                </>
        )
    }
}


 function mapStateToProps(state) {

    return{
        counter:state.appReducer.count,
        notifies:state.userInfoReducer.notifies

    }
}




export default connect(mapStateToProps,null)(Notifications);