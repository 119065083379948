import React, { useState, useEffect } from 'react';
import VideoChat from "../video/VideoChat";
const { connect } = require('twilio-video');

 const TwilioVideo = () => {

    const [identity, setIdentity] = useState('');
    const [room,setRoom] = useState(null)


    const  joinRoom = async  () => {
        try {
            const response = await fetch(`http://localhost:3005/api/test_vid?roomName=cool-room`);
            const data = await response.json();
            const room = await connect(data.token, {
                name: 'cool-room',
                audio: true,
                video: true
            });

            setRoom(room);
        } catch(err) {
            console.log(err);
        }
    }

    const returnToLobby =() => {
        setRoom(null)
    }
     return (

         <div className="app">
             <header>
                 <h1>Video Chat with Hooks</h1>
             </header>
             <main>
                 <VideoChat />
             </main>
             <footer>
                 <p>
                     Made with{' '}
                     <span role="img" aria-label="React">
            ⚛️
          </span>{' '}
                     by <a href="https://twitter.com/philnash">philnash</a>
                 </p>
             </footer>
         </div>
     );
}

export default TwilioVideo;