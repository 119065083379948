import React from "react";
import Pagination from 'react-js-pagination';
import {connect} from 'react-redux'
import MobileTable from './MobileTable';
import Basic from "./DataTables/Basic";
import  {columns} from './DataTables/Tables/NewLead';

class LeadsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {activePage: 1}
    }

    render() {
        const {title,newIncomingLeads,activePage,totalCount,windowWidth} = this.props;
        return (
            <div>
            <div className={'lead_table table-responsive new_lead_table showOnDesktop newLead' }>
                <Basic columns={columns} data={newIncomingLeads}/>
                </div>
            <div>
                {windowWidth <= 991 && <MobileTable leads_data={this.props.newIncomingLeads} />}
            </div>
         </div>
        );
        
    }
}

function mapStateToProps(state) {
    return {
      windowWidth: state.appReducer.windowWidth,
    };
  }
  export default connect(mapStateToProps, null)(LeadsTable);




{/*<div className={'table_pagination'}>*/}
{/*{totalCount > 10 ? (*/}
{/*  <Pagination*/}
{/*  itemClass='page-item'*/}
{/*  linkClass='page-link'*/}
{/*  activePage={this.props.incomingActiveLeads}*/}
{/*  itemsCountPerPage={10}*/}
{/*  totalItemsCount={totalCount !== null && totalCount}*/}
{/*  pageRangeDisplayed={5}*/}
{/*  onChange={this.props.handlePageChange.bind(this)}*/}
{/*/>*/}
{/*) : (<span></span>)}*/}

{/*</div>*/}