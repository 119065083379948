import {GETADDRESSBYID, GETADDRESSBYIDGENERAL, GETBUILDINGBYID, GETCONTACTBYID, GETMORTGAGEBYID} from "../types";

const initialState={
    contact:{},
    address:[],
    mortgage:[],
    building:[],
    address_general:[],
}

export default function rootReducer(state = initialState,action) {

    switch (action.type) {
        case GETADDRESSBYID:

            return {
                ...state,
                address: action.payload
            }
        case GETADDRESSBYIDGENERAL:

            return {
                ...state,
                address_general: action.payload
            }
        case GETMORTGAGEBYID:
            return {
                ...state,
                mortgage: action.payload
            }
        case GETBUILDINGBYID:
            return {
                ...state,
                building: action.payload
            }
        case GETCONTACTBYID:
            return {
                ...state,
                contact: action.payload
            }
        default: {
            return state;
        }
    }
}