import React, {useState} from "react";
import {Button, Modal, Table} from "react-bootstrap";
import '../assets/css/email_template_styles.css';
import email from "./Email";
import moment from "moment";
import {Editor} from "@tinymce/tinymce-react";
import axios from "axios";
import {url} from "../config";
import {getEmails} from "../redux/actions";
import {useDispatch} from "react-redux";



const result ={
    id:'',
    options:{}
}

 export  default function EmailTable (props) {
     const dispatch = useDispatch()
    const [modalShow,setModalShow] = useState(false)
    const [body,setBody] = useState({})

    const handleClose = () => {
            setModalShow(false)
    }
    const  openEmailModal = (v) => {
        setBody({})
        setBody(v)
        result.options={}
        result['id']=v.id
        setModalShow(true)
    }

    const handleChange = (event) => {
        const {name,value} = event.target;
           result.options[name]=value
    }


    const handleSave = () => {
        axios.post(url + "/update-emails",result).then(res=>{
            if(res.data){
                dispatch(getEmails())
                setModalShow(false)
            }
        })
    }

     const handleDisclosure = (value, editor) => {

        result.options['description'] = value
     };
    return(
        <>
            <div className={'lead_table email_table table-responsive ' }>
                <Table striped bordered hover className >
                    <thead>
                    <tr>
                        <th>Template</th>
                        <th>Subject</th>
                        <th>Date Created</th>
                        <th>Last Modified</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.emails.map((v, k) => {
                        return (
                                <tr key={k}>
                                    <td>{v.title}</td>
                                    <td>{v.subject}</td>
                                    <td>{moment(v.created_at).format('MM/DD/YYYY')}</td>
                                    <td>{moment(v.updated_at).format('MM/DD/YYYY')}</td>
                                    <td onClick={()=>openEmailModal(v)}><i className={'apa-pencil'}></i></td>
                                </tr>
                        )
                    })
                    }
                    </tbody>
                </Table>
            </div>

            <Modal show={modalShow} onHide = {handleClose} centered className="discover_modal_content email_template_modal modal_width" dialogClassName='discover_modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Email Template</Modal.Title>
                    <span onClick={handleClose} className={'microphone_icon'}></span>
                </Modal.Header>
                <Modal.Body>
                    <div className={'container-fluid'}>
                        <div className="row email_template">
                            <div className="col-2">
                                <h5>Template</h5>
                            </div>
                            <div className="col-10">
                                <input type={'text'} name={'title'} onChange={handleChange} defaultValue={body.title} />
                            </div>
                            <div className="col-2">
                                <h5>Subject</h5>
                            </div>
                            <div className="col-10">
                                <input type={'text'} name={'subject'} onChange={handleChange}  defaultValue={body.subject}/>
                            </div>
                            <div className="col-2">
                                <h5>Body</h5>
                            </div>
                            <div className="col-10 email_template_editor">
                                <Editor
                                    apiKey='wwzu5v6vk2usoswhk1n05w1p3e1cqy9jqskidd7r6cibzjkt'
                                    initialValue={body.description}
                                    init={{
                                        plugins: 'link image code',
                                        toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code',
                                        height: 400
                                    }}
                                    onEditorChange={handleDisclosure}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick = {handleSave}>Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}
























