import React, {useState, useEffect, createRef, useCallback} from 'react';
import Video from 'twilio-video';
import Participant from "./Participant";
import '../assets/css/room.css'
import jwt_decode from "jwt-decode";
import { useScreenshot } from 'use-react-screenshot'
import axios from "axios";
import {url} from "../config";
import moment from "moment";
import DatePicker from "react-datepicker";


const Room = (props) => {
    const [room, setRoom] = useState(null);
    const [participants, setParticipants] = useState([]);
    const [token, setToken] = useState('');
    const [roomName, setRoomName] = useState('');
    const [muteLogo, setMuteLogo] = useState(false);
    const [noVideos, setNoVideos] = useState(false);
    const [recording, setRecording] = useState(true);
    const [disconnected, setDisconnected] = useState(false);
    const [type, setType] = useState('');
    const [invalidToken, setInvalidToken] = useState(false);
    const [note,setNote] =useState('')
    const [InspectorNote,setInspectorNote] =useState('')
    const [InspectionDate,setInspectionDate] =useState()
    const [inspect,setInspect] =useState('')
    const [showNote,setShowNote] =useState(false)
    const [inspectors,setInspectors] =useState([])

    const { __token } = props.match.params;

    const ref = createRef()
    const [image, takeScreenshot] = useScreenshot()


    useEffect(()=>{

        setToken(__token)
        setRoomName(new URLSearchParams(props.location.search).get("roomName"))
        setType(new URLSearchParams(props.location.search).get("type"))

        axios.get(url + 'get-user-roles')
            .then(res => {
              setInspectors(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    },[])


    const mute = () =>{
        setMuteLogo(muteLogo => !muteLogo)
        room.localParticipant.tracks.forEach(function(trackPublication) {
            if(trackPublication.kind == 'audio') {
                if(!muteLogo){
                    trackPublication.track.disable()
                }else {
                    trackPublication.track.enable()
                }
            }
        });

    }

    const dataURLtoFile = (dataurl, filename) => {
        const arr = dataurl.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n) {
            u8arr[n - 1] = bstr.charCodeAt(n - 1)
            n -= 1 // to make eslint happy
        }
        return new File([u8arr], filename, { type: mime })
    }

    const screenCapture =  () =>{
      takeScreenshot(ref.current).then(r =>{
          const file = dataURLtoFile(r,'screen.png')
          const data = new FormData()
            let uncoded = jwt_decode(token).grants.identity
          data.append('image', file, file.name)
          data.append('folder_id',uncoded.slice(uncoded.indexOf('___')+3))
          axios.post(url +'/get-screen',data)
      });
    }


    const addNote = () =>{
        setShowNote(false)
        let uncoded = jwt_decode(token).grants.identity
        let user =uncoded.slice(uncoded.indexOf('!!')+2,uncoded.lastIndexOf("!!"))

        let notes ={
                message:note,
                user:user,
                ins_date:moment(InspectionDate).format('MM/DD/YY, h:mm A'),
                ins_note:InspectorNote,
                ins:inspect,
                date:moment().format('MM/DD/YY, h:mm A'),
                folder_id:uncoded.slice(uncoded.indexOf('___')+3)
            }

        axios.post(url +'/get-note',notes)
    }







    // const playRecording = () => {
    //     setRecording(recording=>!recording)
    // }

    const noVideo = () =>{
        setNoVideos(noVideos => !noVideos)
        room.localParticipant.tracks.forEach(function(trackPublication) {
            if(trackPublication.kind == 'video') {
                if(!noVideos){
                    trackPublication.track.disable()
                }else{
                    trackPublication.track.enable()
                }
            }
        });

    }

    const handleLogout = () => {
        room.localParticipant.tracks.forEach(function(trackPublication) {
            trackPublication.track.disable()
            trackPublication.track.stop()
            room.localParticipant.unpublishTrack(trackPublication.track)
        });
        room.disconnect()
        window.location.href = '/disconnected'

    }



    useEffect(() => {

        const participantConnected = participant => {
            setParticipants(prevParticipants => [...prevParticipants, participant]);
        };
        const participantDisconnected =  participant => {


            setParticipants(prevParticipants =>
                prevParticipants.filter(p => p.sid !== participant.sid)
            );
            // room.participants.forEach(participantConnected);
        };

        if(token && roomName && !invalidToken) {

            Video.connect(token, {
                    name: roomName,
                    audio:true,
                    video:true,
                }).then(room => {
                    room.on('participantDisconnected', participantDisconnected);
                    setRoom(room);
                    room.on('participantConnected', participantConnected);
                    room.participants.forEach(participantConnected);


                    if(type =='audio') {
                        setNoVideos(noVideos => !noVideos)
                        room.localParticipant.tracks.forEach(function (trackPublication) {
                            if (trackPublication.kind == 'video') {
                                trackPublication.track.disable()
                            }
                        })
                    }

                }).catch(e=>{
                    setInvalidToken(true)
                });
        }



        return () => {
            setRoom(currentRoom => {
                if (currentRoom && currentRoom.localParticipant.state === 'connected') {
                    currentRoom.localParticipant.tracks.forEach(function(trackPublication) {
                        trackPublication.track.stop();
                    });
                    currentRoom.disconnect();

                    return null;
                } else {

                    return currentRoom;
                }
            });
        };
    }, [roomName,token,type]);



    const remoteParticipants = participants.map(participant => (
        <Participant key={participant.sid} participant={participant} type={true} disconnect={disconnected} roomName={roomName} />
    ));


    if(invalidToken){
        return  (
            <div style={{fontSize:'25px',marginLeft:'25px'}}>
                Invalid access token...
            </div>
        )
    }
    if(participants.length == 0) {
        return (
            <div className={'you_dis'}>
                <div className={'center_box'}>
                    <div className={'spinner-grow text-primary'} style={{width:'3rem',height: '3rem'}}/>
                    <p style={{fontSize:'30px'}}> Waiting for opponent to join...</p>
                </div>
            </div>
        )
    }
    return (
       <div className="room" ref={ref} >
           {
               showNote ? (
                   <div className={'noteBox'}>
                       <p className={'video_title'}>Video Inspection</p>
                       <div className="form-group">
                           <label>Video inspection date/time</label>
                           <DatePicker
                               onChange={(date:Date)=>setInspectionDate(date)}
                               selected={InspectionDate}
                               className={'input_date'}
                               name={'first_contact'}
                               isClearable
                               dateFormat="MM/dd/yyyy : hh:mm"
                               showTimeInput
                           />
                       </div>
                       <div className="form-group selectdiv">
                           <label>Inspector</label>
                           <select
                               // defaultValue={basic_info.lead_source && basic_info.lead_source}
                               className={"form-control alterationTypeSelect"}
                               name="lead_source"
                               onChange={(e) => {setInspect(e.target.value)}}
                           >
                               >
                               { inspectors?.users.map((item,key) => {
                                   return (
                                       <option key={key} value={item.first_name + ' ' +item.last_name} >{item.first_name + ' ' +item.last_name }</option>
                                   )
                               })}
                           </select>
                       </div>
                       <div>
                           <label>Inspector notes</label>
                           <textarea onChange={(event) =>setInspectorNote(event.target.value)} name="log" className="steps_text_area" required=""></textarea>
                       </div>
                       <div>
                           <label>Notes</label>
                           <textarea onChange={(event) =>setNote(event.target.value)} name="log" className="steps_text_area" required=""></textarea>
                       </div>

                       <button  onClick={addNote} className="save_btn">Save</button>
                   </div>
               ) : ""
           }
           <div className="local-participant">
               {room ? (
                   <Participant
                       key={room.localParticipant.sid}
                       participant={room.localParticipant}
                       roomName={roomName}
                       type={false}
                       disconnect={disconnected}
                   />
               ) : (
                   ''
               )}
           </div>
               <div className={'buttons_box'}>
                   <div style={{display:"flex"}}>
                       {jwt_decode(token).grants.identity.includes('admin')
                           ? (
                           <>

                               <div className={'option_btn'} onClick={()=>setShowNote(showNote=>!showNote)} title={'Add Note'}>
                                   <i className="apa-icon-notes"></i>
                               </div>
                               <div className={'option_btn'} onClick={screenCapture} title={'Screen capture'}>
                                   <i className="fal fa-camera" style={{marginLeft:'14px'}}></i>
                               </div>
                               {/*<div className={'option_btn'} onClick={playRecording} title={'Recording'}>*/}
                               {/*    {recording ?*/}
                               {/*        <i className="fas fa-compact-disc" style={{marginLeft:'14px'}}></i>*/}
                               {/*        :*/}
                               {/*        <i className="fas fa-compact-disc" style={{marginLeft:'14px',color:'red'}}></i>*/}
                               {/*    }*/}
                               {/*</div>*/}

                           </>


                           ):''}
                       <div className={'option_btn'} onClick={mute} title={'Voice'}>
                           {muteLogo ?
                               <i className="fas fa-microphone-alt-slash"></i>
                               :
                               <i className="fas fa-microphone-alt" style={{marginLeft:'17px'}}></i>
                           }
                       </div>
                       <div className={'option_btn'} onClick={noVideo} title={'Video'}>
                           {noVideos ?
                               <i className="fas fa-video-slash"></i>
                               :
                               <i className="fas fa-video"></i>
                           }


                       </div>
                       <div className={'option_btn call_stop'} onClick={handleLogout}  title={'Disconnect'}>
                           <i className="fas fa-phone"></i>
                       </div>
                   </div>

               </div>
               <div className="remote-participants">{remoteParticipants}</div>
           </div>
    );
}


export default Room;