import React, {useEffect, useState} from "react";
import axios from "axios";
import { url } from "../../config";
import {connect, useDispatch, useSelector} from "react-redux";
import {getAddress} from "../../redux/actions";
import Input from "../Inputs/Input";
import InputSelect from "../Inputs/InputSelect";

const loss_address = {};


function LossAddress(props){

  const [inputEdit,setInputEdit] = useState(false)
  const [cities,setCities] = useState([])
  const [states,setStates] = useState([])
  const [countries,setCountries] = useState([])

    const address_general = useSelector(state => state.propertyReducer.address_general)
    const  address_all = useSelector(state => state.propertyReducer.address)

    let address

    if(props.general){
      address = address_general
    }else {
        address = address_all
    }



  const dispatch = useDispatch()

  useEffect(() => {
      if(props.general){
          dispatch(getAddress(props.address_id,true))
      }else{
          dispatch(getAddress(props.address_id))
      }
      getCities().then(res=>{})
      getStates().then(res=>{})
      getCountries().then(res=>{})

  },[])

    const closeEdit = () =>{
        setInputEdit(!inputEdit)
    }

 const getCountries = async () => {
    try {
      const { data } = await axios.get(url + "get-countries");
      setCountries(data.country)
    } catch (error) {
      console.error(error);
    }
  };

   const  getCities = async (citi_id=null) => {
       try {
           let state_code =''
           if(citi_id) state_code =citi_id;
           const { data } = await axios.get(url + "get-cities?state="+state_code);
         setCities(data)
       } catch (error) {console.error(error);}
     };

 const getZip = async (zipKey = null) => {
    if (zipKey) {
      try {
        const { data: response } = await axios.post(url + "get-location-by-zip",
          {parameter: zipKey,}
        );
          if (response?.result?.length === 1 && zipKey.length ==5) {
              loss_address.state  = response.result[0].state
              loss_address.city  = response.result[0].city
              dispatch(getAddress(address.id))
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

 const handleEdit = () => {
    loss_address.id = address.id;
     setInputEdit(!inputEdit)
  };


  const getStates = async (country_id = "USA") => {
    try {
      const { data } = await axios.get(url + "get-states", {
        params: {country_id: country_id,},
      });
        setStates(data)
    } catch (error) {console.error(error);}
  };


 const  handleChangeData = (event) => {
    const { name, value } = event.target;
    loss_address[name] = value;
    if (name === "zip") getZip(value);
     if (name === "state") getCities(value);
  };


 const handleSaveData = async () => {
    const data = {loss_address};
     setInputEdit(!inputEdit)

     try {
      await axios.post(url + "update-lead-loss-address", data).then(async response => {
          dispatch(getAddress(address.id))
          if(address?.loss_address_lead.length > 0){
              let log_address = response?.data?.street_number+', '+response?.data?.street_name+', '+response?.data?.unit_number+', '+response?.data?.city+', '+response?.data?.state+', '+response?.data?.zip+', '+response?.data?.country
              await axios.post(url + "add-lead-log", {lead_id:this.props.lead_body[0].id,details:'Loss Address Changed to ' + log_address} );
          }
      });
      setInputEdit(!inputEdit)
    } catch (error) {console.error(error);}
  };


if(props.type && address){
    props.changeLoading(false)
}

    return (

      <div className={"add_lead_basic lead_details_basic_info loss_addresses"}>
        <div className={"leads_details_edit"}>
          <h3>{props.type ? 'Address':'Loss Address'}</h3>
          {inputEdit ?
              <>
                  <span onClick={handleSaveData}>Save</span>
                  <div className={'close_b'} onClick={closeEdit}><i className="fas fa-times"/></div>
              </>
              :
            <i onClick={handleEdit} className={"apa-pencil"}/>
          }
        </div>
              <div className="row">
                  <Input options={{label:'Street Number',name:'street_number',type:'text',value:address?.street_number}}
                         inputEdit={inputEdit}
                         handleChange={handleChangeData}
                  />
                  <Input options={{label:'Street Name',name:'street_name',type:'text',value:address?.street_name}}
                         inputEdit={inputEdit}
                         handleChange={handleChangeData}
                  />
                  <Input options={{label:'Unit Number',name:'unit_number',type:'text',value:address?.unit_number}}
                         inputEdit={inputEdit}
                         handleChange={handleChangeData}
                  />
                  <InputSelect options={{label:'City',name:'city',value:loss_address.state ? loss_address.city :address?.city,class:'leads_details_selects'}}
                               inputEdit={inputEdit}
                               handleChange={handleChangeData}
                               data={cities}
                               path={'city'}
                  />
                  <InputSelect options={{label:'State',name:'state',value:loss_address.state ? loss_address.state :address?.state,class:'leads_details_selects'}}
                               inputEdit={inputEdit}
                               handleChange={handleChangeData}
                               data={states}
                               path={'state'}
                  />
                  <Input options={{label:'Zip',name:'zip',type:'text',value:address?.zip}}
                         inputEdit={inputEdit}
                         handleChange={handleChangeData}
                  />
                  <InputSelect options={{label:'Country',name:'country',value:address?.country,class:'leads_details_selects'}}
                               inputEdit={inputEdit}
                               handleChange={handleChangeData}
                               data={[{country:countries}]}
                               path={'country'}
                  />
              </div>
      </div>
    );
}
export default connect(null,null)(LossAddress);

