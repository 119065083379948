import React from "react";
import axios from "axios";
import {url} from "../../config";
import NoteModal from "./NoteModal";

const building_info = {};

class BuildingInfo extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            inputEdit:false,
            building_types:[]
        }
    }


    getBuildingTypes = () => {
        const building_date = [];
        axios.get(url + 'create-lead-fourth?id='+this.props.lead_body[0].contact_id)
            .then(res => {

                res.data.building_types.map((val,key) => {
                    return   building_date[key] = val
                })
                this.setState({
                    building_types:building_date
                })
            })
            .catch(err => {
                console.log(err)
            })
    }


    componentDidMount() {
        
    }

    closeEdit = () =>{
          this.setState({
            inputEdit: !this.state.inputEdit,
          });
        }


    handleEdit = () => {
        building_info.id =  this.props.lead_body[0].loss_address.lead_building.id;
        this.getBuildingTypes();
        this.setState({
            inputEdit:!this.state.inputEdit
        })
    }


    handleChangeDate = (event) => {
        const {name,value} = event.target;

        if(name === 'prior_claims'){
          if(this.props.lead_body[0]?.loss_address?.lead_building?.prior_claims === "0"){
              return building_info.prior_claims = "1"
          }else {
              return building_info.prior_claims = "0"
          }
        }

        building_info[name] = value;
    }


    noteSave = (name,value)=> {
        building_info[name] = value;
    }


    handleSaveData = () => {
        const data = {};
        data.building_info = building_info

        axios.post(url + 'update-lead-loss-address', data)

            .then(res => {
                    this.props.myCallback(true)
                this.setState({
                    inputEdit:!this.state.inputEdit
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    render() {
        const {lead_body} = this.props;
        const {building_types} = this.state;

        return(
            <div className={'add_lead_basic lead_details_basic_info'}>

                <div className={'leads_details_edit'}>
                    <h3>Building Info</h3>
                    {this.state.inputEdit ?
                        <>
                          <span onClick={this.handleSaveData}>Save</span>
                          <div className={'close_b'} onClick={this.closeEdit}><i className="fas fa-times"/></div>
                        </>

                        : <i onClick={this.handleEdit} className={'apa-pencil'}></i>  }
                </div>

                {lead_body && lead_body.map((item,key) => {

                    return(

                <div className="row" key={key}>


                    <div className="col-4">
                        <p>Building Type</p>
                    </div>

                    <div className="col-8">
                        <input style={this.state.inputEdit ? {display:"none"} : {display:'block'}} className={this.state.inputEdit ? ' edit_inputs' : ''} defaultValue={item?.loss_address?.lead_building && item?.loss_address?.lead_building.building_type && item?.loss_address?.lead_building.building_type.type} readOnly={!this.state.inputEdit}/>
                        <div  className="form-group selectdiv leads_details_selects"  style={!this.state.inputEdit ? {display:"none"} : {display:'block'}} >
                            <select name={'building_type'} className={"form-control alterationTypeSelect"} onChange={this.handleChangeDate}>
                            <option value={null}></option>
                                {building_types && building_types.map((buildingTypeEach,key) => {

                                 return <option key={key} selected={item.loss_address.lead_building.building_type && item?.loss_address?.lead_building?.building_type?.id == buildingTypeEach.id} value={buildingTypeEach.id}>{buildingTypeEach.type}</option>
                                })}
                            </select>

                        </div>
                    </div>


                    <div className="col-4">
                        <p>Size of Building</p>
                    </div>

                    <div className="col-8">
                        <input onChange={this.handleChangeDate} name={'size_of_building'} className={this.state.inputEdit ? ' edit_inputs' : ''}  defaultValue={item?.loss_address?.lead_building && item?.loss_address?.lead_building.size_of_building} readOnly={!this.state.inputEdit} />
                    </div>


                    <div className="col-4">
                        <p>Year Built</p>
                    </div>


                    <div className="col-8">
                        <input onChange={this.handleChangeDate} name={'year_build'} type={'number'} className={this.state.inputEdit ? ' edit_inputs' : ''} defaultValue={item?.loss_address?.lead_building && item?.loss_address?.lead_building.year_build} readOnly={!this.state.inputEdit} />
                    </div>

                    <div className="col-4">
                        <p>Beds</p>
                    </div>


                    <div className="col-8">
                        <input onChange={this.handleChangeDate} name={'beds'} type={'number'} className={this.state.inputEdit ? ' edit_inputs' : ''} defaultValue={item?.loss_address?.lead_building && item?.loss_address?.lead_building.beds} readOnly={!this.state.inputEdit}  />
                    </div>


                    <div className="col-4">
                        <p>Baths</p>
                    </div>


                    <div className="col-8">
                        <input onChange={this.handleChangeDate} name={'baths'} type={'number'} className={this.state.inputEdit ? ' edit_inputs' : ''} defaultValue={item?.loss_address?.lead_building && item?.loss_address?.lead_building.baths} readOnly={!this.state.inputEdit} />
                    </div>


                    <div className="col-4">
                        <p>Prior Claims</p>
                    </div>


                    <div className="col-8" >
                        <input onChange={this.handleChangeDate}  defaultValue={Number(item?.loss_address?.lead_building && item?.loss_address?.lead_building.prior_claims).toString()}
                               disabled={!this.state.inputEdit} className={this.state.inputEdit ? ' edit_inputs' : ''}
                               type="checkbox" id="prior_claims" name="prior_claims" defaultChecked={item?.loss_address?.lead_building?.prior_claims == 1 ? true : false} />
                        <label htmlFor="prior_claims"></label>
                    </div>


                    <div className="col-4">
                        <p>Est. Property Value</p>
                    </div>


                    <div className="col-8">
                        <input type={'number'} onChange={this.handleChangeDate} name={'est_property_value'} className={this.state.inputEdit ? ' edit_inputs' : ''} defaultValue={item?.loss_address?.lead_building && item?.loss_address?.lead_building.est_property_value} readOnly={!this.state.inputEdit}  />
                    </div>


                    <NoteModal title={'Building Notes'} name={'building_note'}
                               notes={item?.loss_address?.lead_building && item?.loss_address?.lead_building.building_note}
                               id={'building_note'}
                               inputEdit={this.state.inputEdit} noteSave={this.noteSave}/>



                    <div className="col-4">
                        <p>Age of Home</p>
                    </div>


                    <div className="col-8">
                        <input onChange={this.handleChangeDate} name={'age_of_home'} type={'number'} className={this.state.inputEdit ? ' edit_inputs' : ''} defaultValue={item?.loss_address?.lead_building && item?.loss_address?.lead_building.age_of_home} readOnly={!this.state.inputEdit}  />
                    </div>


                    <div className="col-4">
                        <p>Years Occupied</p>
                    </div>


                    <div className="col-8">
                        <input onChange={this.handleChangeDate} name={'years_occupied'} type={'number'} className={this.state.inputEdit ? ' edit_inputs' : ''} defaultValue={item?.loss_address?.lead_building && item?.loss_address?.lead_building.years_occupied} readOnly={!this.state.inputEdit}  />
                    </div>


                </div>

                    )
                })}
            </div>
        )
    }
}


export default BuildingInfo;