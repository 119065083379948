import React from 'react';
import { Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import MobileTable from './MobileTable';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import { Link } from 'react-router-dom';

import LogEntryModal from "./leadDetails/LogEntryModal";
import Moment from 'react-moment';
import moment from 'moment'
class LeadsPropTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      leads_data: '',
      activePage: 1,
      status_progress: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      incomingLeads: [],
      lead_body: []
    };
  }

  componentDidMount() {

  }

  addLogEntry = (item) => {
    this.setState({
        lead_body:[item],
        show:true
    })
}

countDaysPassed (date)  {
  var now = moment(new Date()); //todays date
  var end = moment(date); // another date
  var days = now.diff(end, 'days');
  return days;
}


  myCallback = (dataFromChild) => {
    if(dataFromChild){
        this.setState({
            lead_body:'',
            updated:dataFromChild
        })
        //this.getCurrentLead();
    }
}

  modalCallBack = (parentDate,name = '') => {
    this.setState({
        show:parentDate
    })
}

  render() {
    const { windowWidth, leads_data, search, pagination_count } = this.props;

    return (
      <>
      <div>
        <div className={'lead_table table-responsive d_none'}>
          <Table striped bordered hover className='desktop_table'>
            <thead>
              <tr>
                <th>LIN</th>
                <th>Insured Name</th>
                <th>Team</th>
                <th>Category</th>
                <th>Status</th>
                <th>Lead Rep</th>
                <th>Lead PA</th>
                <th>Location</th>
                <th>Lead Source</th>
                <th>Peril</th>
                <th>First Contact</th>
                <th>Days Open</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>

            {leads_data
              ? leads_data.length > 0 &&
                leads_data?.map((item, key) => {
                  return (
                    <React.Fragment key={key}>
                      <tbody className={key % 2 !== 0 ? 'table_hovered' : '1'}>
                        <tr>
                          <td>{item.LIN}</td>
                          <td>
                          {item.client?.contacts[0]?.first_name} {item.client?.contacts[0]?.last_name}
                          </td>
                          <td>
                            {item &&
                              item.assignment &&
                              item.assignment.team &&
                              item.assignment.team.name}
                          </td>
                          <td>{item.category && item.category.type}</td>
                          <td>
                            {item.status && item.status.type}

                            <ul className={'insured_progress'}>
                              {this.state.status_progress &&
                                this.state.status_progress.map((k) => {
                                  return (
                                    <li
                                      key={k}
                                      className={
                                        'red_dot ' +
                                        (item.status && item.status.id === k
                                          ? ' green_dot'
                                          : '')
                                      }
                                    />
                                  );
                                })}
                            </ul>
                          </td>
                          <td>{item.rep && item.rep.name}</td>
                          <td> {/*lead public ajuster to be added*/}</td>
                          <td> {item?.loss_address?.city}, {item?.loss_address?.state}</td>
                          <td>{item.source && item.source.name}</td>
                          <td>
                            {item.loss_info && item.loss_info.peril
                              ? item.loss_info.peril.name
                              : ''}
                          </td>
                          <td>
                                {item.first_contact ? (
                                    <Moment format="MM/DD/YYYY">
                                    {item.first_contact}
                                    </Moment>
                                ) :(
                                    <span></span>
                                ) }
                          </td>
                          <td>
                            <span className={'static_down'}>{this.countDaysPassed(item.created_at)}</span>
                          </td>
                          <td>
                            <Link
                              to={{
                                pathname: '/leads/' + item.id,
                                state: item.id,
                              }}
                            >
                              <i className={'apa-icon-view'}></i>
                            </Link>
                          </td>
                          <td>
                            <OverlayTrigger
                              placement='bottom'
                              overlay={
                                <Tooltip id='button-tooltip-2'>
                                  <h3>Lead Source Notes</h3>
                                  <p>
                                    {item.notes}
                                  </p>
                                </Tooltip>
                              }
                            >
                              {({ ref, ...triggerHandler }) => (
                                <i
                                  ref={ref}
                                  {...triggerHandler}
                                  className={'apa-icon-notes'}
                                ></i>
                              )}
                            </OverlayTrigger>
                          </td>
                          <td>
                            <i onClick={() => this.addLogEntry(item)} className={'apa-icon-add'}></i>
                          </td>
                        </tr>
                      </tbody>
                    </React.Fragment>
                  );
                })
              : null}
          </Table>

          {leads_data
            ? leads_data.length === 0 &&
              search && <h4 className={'search_no_result'}>No Result</h4>
            : null}

          <LogEntryModal lead_body = {this.state.lead_body} show = {this.state.show} myCallback = {this.myCallback} modalCallBack = {this.modalCallBack}/>
        </div>
        {windowWidth <= 991 && <MobileTable leads_data={leads_data} />}


        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    windowWidth: state.appReducer.windowWidth,
  };
}

export default connect(mapStateToProps, null)(LeadsPropTable);
