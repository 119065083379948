import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import BuildingInfo from "../clientOptions/BuildingInfo";
import {useDispatch, useSelector} from "react-redux";
import LossAddress from "../clientOptions/LossAddress";
import MortgageCompanies from "../clientOptions/MortgageCompanies";
import {getAddress, getBuilding, getCurrentClient, getMortgage} from "../../redux/actions";



function PropertiesInfo(props){
    const history = useHistory();
    const location = useLocation();
    let options =  location.state
    const dispatch = useDispatch()
    const [loading,setLoading]= useState(true)

    useEffect(() => {

    },[])


    const back = () => {
        dispatch(getBuilding(null))
        dispatch(getAddress(null))
        dispatch(getMortgage(null))
        dispatch(getCurrentClient(options.client_id))
        history.goBack()

    }

    const changeLoading = (type) => {
        setLoading(type)
    }

    return(

        <>
            {loading ? <div className={'spinner-grow text-primary'}
                                       style={{width:'1.7rem',height: '1.7rem',position: 'absolute',left:'50%',top:'30%',zIndex:1500}}/> :''}

            <div className={'documents_folder'}>
                <h3>Document Folders <span style={{cursor:"pointer"}} onClick={back} className={'float-right'}><i className="apa-back"></i> BACK</span></h3>
            </div>

            <div className="row">
                <div className="col-lg-4 col-12 leads_details_content">
                    <LossAddress address_id={options?.address}  changeLoading={changeLoading} type={true}/>
                </div>
                <div className="col-lg-4 col-12 leads_details_content">
                    <BuildingInfo building_id={options?.building} />
                </div>
                <div className="col-lg-4 col-12 leads_details_content">
                    <MortgageCompanies mortage_id={options?.mortgage}  />
                </div>
            </div>
        </>
    )
}




export default PropertiesInfo;



