import React from 'react';


function All({leads,getCurrentLead,currentLead,nonReadMessage}) {
    return (

        <div>
            {leads && leads.map((item,key) =>{
                    return(
                            <div className={'col-12 pl-0'} onClick={()=>getCurrentLead(item.id,item?.conversation?.id,item.contact_id)}>
                                <div className={'messages_content '+(currentLead?.id===item.id ? 'bg_default_blue':'')} key={key}>
                                    {nonReadMessage.has(item?.conversation?.id)  ? <div className={'non_read'}></div> : '' }
                                    <h4 className={currentLead?.id===item.id ? 'text-white':''} >LIN: {item.LIN}</h4>
                                    <p className={'mb-0 '+(currentLead?.id===item.id ? 'text-white':'')}>{item.client.insured_name}</p>
                                    <span>{item.time}</span>
                                </div>
                            </div>
                    )
                }

                // (
                //     <div className={'col-12 pl-0'} onClick={()=>getCurrentLead(item.id)}>
                //         <div className={'messages_content '+(currentLead?.id===item.id ? 'bg_default_blue':'')} key={key}>
                //             {haveNonRead || nonReadMessage.has(item.conversation.id)  ? <div className={'non_read'}></div> : '' }
                //             <h4 className={currentLead?.id===item.id ? 'text-white':''} >LIN: {item.LIN}</h4>
                //             <p className={'mb-0 '+(currentLead?.id===item.id ? 'text-white':'')}>{item.client.insured_name}</p>
                //             <span>{item.time}</span>
                //         </div>
                //     </div>
                // )
            )}
        </div>
    );
}

export default All;