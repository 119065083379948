import React, { useState, useEffect, useRef } from 'react';

const Participant = ({ participant,roomName ,type,disconnect}) => {
    const [videoTracks, setVideoTracks] = useState([]);
    const [audioTracks, setAudioTracks] = useState([]);

    const videoRef = useRef();
    const audioRef = useRef();

    const trackpubsToTracks = trackMap => Array.from(trackMap.values())
        .map(publication => publication.track)
        .filter(track => track !== null);

    useEffect(() => {
        const trackSubscribed = track => {

            if (track.kind === 'video') {
                setVideoTracks(videoTracks => [...videoTracks, track]);
            } else {
                setAudioTracks(audioTracks => [...audioTracks, track]);
            }
        };

        const trackUnsubscribed = track => {
            if (track.kind === 'video') {
                setVideoTracks(videoTracks => videoTracks.filter(v => v !== track));
            } else {
                setAudioTracks(audioTracks => audioTracks.filter(a => a !== track));
            }
        };
        setVideoTracks(trackpubsToTracks(participant.videoTracks));
        setAudioTracks(trackpubsToTracks(participant.audioTracks));
        participant.on('trackSubscribed', trackSubscribed);
        participant.on('trackUnsubscribed', trackUnsubscribed);
        return () => {
            setVideoTracks([]);
            setAudioTracks([]);
            participant.removeAllListeners();
        };
    },[participant]);

    useEffect(() => {
        const videoTrack = videoTracks[0];
        const audioTrack = audioTracks[0];
        if(audioTrack){
            audioTrack.attach(videoRef.current);
        }
        if (videoTrack) {
            videoTrack.attach(videoRef.current);
        }



    }, [videoTracks,audioTracks]);

    return (
        <div className="participant">
                {type ?(
                    <div className={'room_name'}>LIN: {roomName} </div>
                ) : ''}
            <video ref={videoRef} autoPlay={true} />
            <audio ref={audioRef} autoPlay={true}  />
        </div>
    );
}

export default Participant;