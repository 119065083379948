import React, {useEffect} from 'react';
import { Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import MobileTable from './MobileTable';
import {connect, useDispatch, useSelector} from 'react-redux';
import Pagination from 'react-js-pagination';
import { Link } from 'react-router-dom';
import { getLeads } from '../redux/actions';
import { url } from '../config';
import axios from 'axios';
import LogEntryModal from "./leadDetails/LogEntryModal";
import Moment from 'react-moment';
import moment from 'moment'
let lead = []


function LeadsTable  (props)  {


  const [show, setShow] = React.useState(false);
  const [activePage, setActivePage] = React.useState(1);
  const [status_progress, setStatus_progress] = React.useState([1, 2, 3, 4, 5, 6, 7, 8, 9]);
  const [incomingLeads, setIncomingLeads] = React.useState([]);
  const [lead_body, setLead_body] = React.useState([]);
  const [updated, setUpdated] = React.useState(false);
  const [lead_body_new, setLead_body_new] = React.useState(false);

  const windowWidth = useSelector (state => state.appReducer.windowWidth)
  const leads_data = useSelector (state => state.leadsReducer.leads_data)
  const search = useSelector (state => state.leadsReducer.search)
  const lead_count = useSelector (state => state.leadsReducer.lead_count)
  const pagination_count = useSelector (state => state.leadsReducer.pagination_count)

  const dispatch = useDispatch()

  useEffect(() => {
    if(props.leads_new_data){
      setLead_body_new(props.leads_new_data)
    }
    dispatch(getLeads());
    getLeadsInfo();
  },[props.leads_new_data])


   const addLogEntry = (item) => {
      setLead_body([item])
     setShow(true)
  }

   const countDaysPassed = (date) => {
      var now = moment(new Date()); //todays date
      var end = moment(date); // another date
      var days = now.diff(end, 'days');
      return days;
    }

  const handlePageChange = (pageNumber) => {
    dispatch(getLeads(pageNumber));
    setActivePage(pageNumber)
  };

  const myCallback = (dataFromChild) => {
    if(dataFromChild){
      setLead_body('')
      setUpdated(dataFromChild)
    }
}

  const getLeadsInfo = () => {
    axios
      .get(url + 'leads')
      .then((res) => {
        setIncomingLeads(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const modalCallBack = (parentDate,name = '') => {
    setShow(parentDate)

  }

  const {leads_new_data} = props

  if(lead_body_new){
    lead= lead_body_new
  }
  else{
    lead = leads_data
  }

    return (

      <>
      <div>
        <div className={'lead_table table-responsive d_none'}>
          <Table striped bordered hover className='desktop_table'>
            <thead>
              <tr>
                <th >LIN</th>
                <th >Insured Name</th>
                <th >Team</th>
                <th >Category</th>
                <th >Status</th>
                <th >Lead Rep</th>
                <th >Lead PA</th>
                <th >Location</th>
                <th >Lead Source</th>
                <th >Peril</th>
                <th >First Contact</th>
                <th >Days Open</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>

            {lead
              ?
                lead.map((item, key) => {

                  return (
                    <React.Fragment key={key}>
                      <tbody className={key % 2 !== 0 ? 'table_hovered' : '1'}>
                        <tr>
                          <td>{item.LIN}</td>
                          <td>
                          {item?.contact?.first_name} {item?.contact?.last_name}
                          </td>
                          <td>
                            {item &&
                              item.assignment &&
                              item.assignment.team &&
                              item.assignment.team.name}
                          </td>
                          <td>{item.category && item.category.type}</td>
                          <td>
                            {item.status && item.status.type}

                            <ul className={'insured_progress'}>
                              {status_progress &&
                                status_progress.map((k) => {
                                  return (
                                    <li
                                      key={k}
                                      className={
                                        'red_dot ' +
                                        (item.status && item.status.id === k
                                          ? ' green_dot'
                                          : '')
                                      }
                                    />
                                  );
                                })}
                            </ul>
                          </td>
                          <td>{item.rep && item.rep.name}</td>
                          <td> {/*lead public ajuster to be added*/}</td>
                          <td> {item?.loss_address?.city}, {item?.loss_address?.state}</td>
                          <td>{item.source && item.source.name}</td>
                          <td>
                            {item.loss_info && item.loss_info.peril
                              ? item.loss_info.peril.name
                              : ''}
                          </td>
                          <td>
                                {item.first_contact ? (
                                    <Moment format="MM/DD/YYYY">
                                    {item.first_contact}
                                    </Moment>
                                ) :(
                                    <span></span>
                                ) }
                          </td>
                          <td>
                            <span className={'static_down'}>{countDaysPassed(item.created_at)}</span>
                          </td>
                          <td>
                            <Link
                              to={{
                                pathname: '/leads/' + item.id,
                                state: item.id,
                              }}
                            >
                              <i className={'apa-icon-view'}></i>
                            </Link>
                          </td>
                          <td>
                            <OverlayTrigger
                              placement='bottom'
                              overlay={
                                <Tooltip id='button-tooltip-2'>
                                  <h3>Lead Source Notes</h3>
                                  <p>
                                    {item.notes}
                                  </p>
                                </Tooltip>
                              }
                            >
                              {({ ref, ...triggerHandler }) => (
                                <i
                                  ref={ref}
                                  {...triggerHandler}
                                  className={'apa-icon-notes'}
                                ></i>
                              )}
                            </OverlayTrigger>
                          </td>
                          <td>
                            <i onClick={() => addLogEntry(item)} className={'apa-icon-add'}></i>
                          </td>
                        </tr>
                      </tbody>
                    </React.Fragment>
                  );
                })
              : null}
          </Table>

          {leads_data
            ? leads_data.length === 0 &&
              search && <h4 className={'search_no_result'}>No Result</h4>
            : null}

          <LogEntryModal lead_body = {lead_body} show = {show} myCallback = {myCallback} modalCallBack = {modalCallBack}/>
        </div>
        {windowWidth <= 991 && <MobileTable leads_data={leads_data} />}

        <div className={'table_pagination'}>
            {pagination_count > 10 ? (
              <Pagination
              itemClass='page-item'
              linkClass='page-link'
              activePage={activePage}
              itemsCountPerPage={10}
              totalItemsCount={pagination_count !== null && pagination_count}
              pageRangeDisplayed={5}
              onChange={() => handlePageChange}
            />
            ) : (<span></span>)}

          </div>
        </div>
      </>
    );

}


export default connect(null, null)(LeadsTable);
