import React from "react";
import {Button, Modal} from "react-bootstrap";
import ImageUploading from "react-images-uploading";


const messageData = {};

class SendMessageModal extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            images:[],
            imageSize:''
        }
    }

    handleHideModal = () => {
       this.props.modalCallBack(false,'SendMessage')
    }


    handleUploadImage = (imageList, addUpdateIndex) => {

        this.setState({
            images:imageList
        })
    };


    handleMessageValue =  (event) => {
        const {name,value} = event.target;

        messageData[name] = value

}

     formatBytes = (bytes, decimals = 2) =>  {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        const value = parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];

        return `(${value})`;

    }

    sendMessage = (event) => {
        event.preventDefault();

    }

    render() {
        const {show} = this.props;
        return(
            <Modal show={show} onHide = {this.handleHideModal} centered className="discover_modal_content log_entry_modal send_message_modal" dialogClassName='discover_modal'>
                <form onSubmit={this.sendMessage}>


                <Modal.Header closeButton>
                    <Modal.Title>Send Message</Modal.Title>

                </Modal.Header>
                <Modal.Body>



                    <div className="row">

                        <div className="col-4"><p>Select Lead</p></div>
                        <div className="col-8 select_lead">
                            <div className="form-group selectdiv select_content">

                                <select required={true} className="form-control alterationTypeSelect default_select" name="contact"  >
                                    <option value={'Contact'}>Contact</option>


                                </select>
                            </div>
                        </div>
                        <div className="col-4"><p>Message</p></div>
                        <div className="col-8 textarea_border">
                            <textarea required={true} className={'steps_text_area'} name={'message'} onChange={this.handleMessageValue}/>
                            <div className={'send_message_icons'}>
                                <i className={'apa-article hovered_icons'}></i>

                                <ImageUploading
                                    value={this.state.images}
                                    onChange={this.handleUploadImage}
                                    dataURLKey="data_url"

                                >
                                    {({
                                          imageList,
                                          onImageUpload,
                                          onImageRemoveAll,
                                          onImageUpdate,
                                          onImageRemove,
                                          isDragging,
                                          dragProps,
                                      }) => (
                                        // write your building UI

                                        <div className="upload__image-wrapper message_image_uploader">
                                            {imageList.map((image, index) => (

                                                <div key={index} className="image-item">
                                                    <div className={'upload_data_info'}>
                                                        <button className={'hovered_icons'} onClick={onImageRemoveAll}><i className={'apa-icon-close'}></i></button>
                                                        <span>{image.file.name + this.formatBytes(image.file.size)}</span>

                                                    </div>


                                                    <div className="image-item__btn-wrapper">
                                                        {/*<button onClick={() => onImageUpdate(index)}>Update</button>*/}
                                                        {/*<button onClick={() => onImageRemove(index)}>Remove</button>*/}
                                                    </div>
                                                </div>
                                            ))}
                                            {/*<p>Your Photo</p>*/}
                                            <button
                                                style={isDragging ? { color: 'red' } : undefined}
                                                onClick={onImageUpload}
                                                {...dragProps}
                                            >
                                                <i className={'apa-gallery hovered_icons'}></i>
                                            </button>
                                            &nbsp;

                                        </div>
                                    )}
                                </ImageUploading>

                            </div>

                        </div>

                    </div>

                </Modal.Body>
                <Modal.Footer>

                    <Button type = {'submit'} variant="primary">Send</Button>
                </Modal.Footer>

                </form>
            </Modal>
        )
    }
}


export default SendMessageModal;