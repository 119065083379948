import React from "react";
import SideBar from "./header/SideBar";
import Navbar from "./header/Navbar";
import {Col, Nav, Row, Tab} from "react-bootstrap";
import '../assets/css/message_styles.css';
import ImageUploading from "react-images-uploading";



const data = [{
    name:'LIN',
    userName:'Jane Doe',
    time:"2:40 pm"
},
    {
        name:'LIN',
        userName:'Test',
        time:"3:40 pm"
    },
    {
        name:'Test2',
        userName:'Jane Doe',
        time:"4:40 pm"
    },

    {
        name:'Test2',
        userName:'Jane Doe',
        time:"4:40 pm"
    },


    {
        name:'Test2',
        userName:'Jane Doe',
        time:"4:40 pm"
    }

]




class Messages extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            send_message:true,
            images:[]
        }
    }



    handleUploadImage = (imageList, addUpdateIndex) => {

        this.setState({
            images:imageList
        })
    };


    handleAddData = () => {

        this.setState({
            send_message:!this.state.send_message
        })
    }

    render() {
        return(
            <>

                <div className={"d-flex" + (this.state.toggled ? ' toggled' : '')} id="wrapper">
                    <SideBar isAuth = {this.props.isAuth}/>
                    <div id="page-content-wrapper">
                        <div>
                            <Navbar toggled = {this.myCallback} />
                            <div className={'col-12'}>

                                <div className="row">
                                    <div className="col-lg-6 col-12">
                                        <div className={'page_title users_title'}>
                                            <h2>Messages</h2>
                                        </div>

                                    </div>

                                    <div className="col-lg-6 col-12 leads_content">

                                        <div className={'leads_search '}>

                                            <button className={'default_hover'} onClick={this.handleAddUser} > <i className={'apa-icon-search'}></i>Search</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">


                            <div className="row">
                                <div className="col-lg-3 col-12">

                                    <div className="form-group selectdiv messages_select">

                                        <select>
                                            <option value='hhh'>Most Recent</option>
                                            <option value='hhh'>Unread</option>
                                            <option value='hhh'>Newest</option>
                                            <option value='hhh'>Oldest</option>
                                            <option value='hhh'>Archived</option>

                                        </select>
                                    </div>


                                    <Tab.Container className = {"table_container"} id={'table_container'} defaultActiveKey="first">
                                        <Row className={'w-100'}>
                                            <Col lg={12}>
                                                <Nav variant="pills" className="">
                                                    <Nav.Item className={'col-4  messages_tabs'}>
                                                        <Nav.Link eventKey="first">All</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item className={'col-4 messages_tabs   '}>
                                                        <Nav.Link eventKey="second">Leads</Nav.Link>
                                                    </Nav.Item>

                                                    <Nav.Item className={'col-4  messages_tabs  '}>
                                                        <Nav.Link eventKey="third">Claims</Nav.Link>
                                                    </Nav.Item>


                                                </Nav>
                                            </Col>

                                            <Col lg={12} className = 'messages_chat_history'>

                                                <Tab.Content>

                                                    <Tab.Pane eventKey="first">

                                                        {data && data.map((item,key) => {
                                                            return(

                                                                <div className={'messages_content'} key={key}>
                                                                    <h4>{item.name}</h4>
                                                                    <p>{item.userName}</p>
                                                                    <span>{item.time}</span>
                                                                </div>
                                                            )
                                                        })}
                                                    </Tab.Pane>


                                                    <Tab.Pane eventKey="second">

                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="third">

                                                    </Tab.Pane>

                                                    <Tab.Pane eventKey="fourth">

                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tab.Container>

                                </div>

                                <div className="col-lg-5 col-12">
                                    <div className={'chat_title'}>
                                        <div className={'message_title'}>
                                            <h4>LIN: 2020.01.10</h4>
                                        </div>

                                        <div className={'message_icons'}>


                                            <ul>
                                                <li>
                                                    <span><i className={'apa-icon-view icon-view'}></i></span>
                                                    <span><i className={'apa-icon-documents'}></i></span>
                                                    <span><i className={'apa-icon-notes'}></i></span>
                                                    <span><i className={'apa-icon-add'}></i></span>

                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                    <div className="messaging">
                                        <div className="inbox_msg">

                                            <div className="mesgs">
                                                <div className="msg_history">
                                                    <div className="incoming_msg">

                                                        <div className="received_msg">
                                                            <div className="received_withd_msg">

                                                                <p className={'received_message_time'}>Sussanna<span className="time_date"> 11:01 AM    |    June 9</span></p>
                                                                <p className={'message_text message-blue'}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc tempus risus turpis. In hac habitasse platea dictumst. Maecenas sem massa, dictum vel dolor quis, tempus sodales lectus. </p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="outgoing_msg">
                                                        <div className="sent_msg">
                                                            <p className={'received_message_time'}>Sussanna<span className="time_date"> 11:01 AM    |    June 9</span></p>
                                                            <div className={'box sb1'}>
                                                                <p className={'message sent '}>Test which is a new approach to have all solutions</p>
                                                            </div>



                                                        </div>
                                                    </div>
                                                    <div className="incoming_msg">

                                                        <div className="received_msg">
                                                            <div className="received_withd_msg">
                                                                <p className={'received_message_time'}>Sussanna<span className="time_date"> 11:01 AM    |    June 9</span></p>
                                                                <p className={'message_text message-blue'}>Test which is a new approach to have all solutions</p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="outgoing_msg">
                                                        <div className="sent_msg">
                                                            <p className={'received_message_time'}>Sussanna<span className="time_date"> 11:01 AM    |    June 9</span></p>
                                                            <div className={'box sb1'}>
                                                                <p className={'message sent '}>Test which is a new approach to have all solutions</p>
                                                            </div>
                                                        </div>

                                                    </div>


                                                    <div className="incoming_msg">

                                                        <div className="received_msg">
                                                            <div className="received_withd_msg">
                                                                <p className={'received_message_time'}>Sussanna<span className="time_date"> 11:01 AM    |    June 9</span></p>
                                                                <p className={'message_text message-blue'}>Test which is a new approach to have all solutions</p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="sent_msg">
                                                    <p className={'received_message_time'}>Sussanna<span className="time_date"> 11:01 AM    |    June 9</span></p>
                                                    <div className={'box sb1'}>
                                                        <p className={'message sent '}>Test which is a new approach to have all solutions</p>
                                                    </div>

                                                </div>

                                            </div>



                                            <div className="type_msg">

                                                <div className="input_msg_write">
                                                    <i onClick={this.handleAddData} className={'apa-icon-add message_add default_hover'}></i>
                                                    <input type="text" className="write_msg" placeholder="Type a message"/>
                                                    <button  className="msg_send_btn" type="button"><i className="apa-send" aria-hidden="true"></i></button>

                                                </div>

                                                {/*{*/}
                                                {/*    this.state.images.length > 0 && <img src={this.state.images.length && this.state.images[0].data_url} width="100" alt={'user_upload_img'}/>*/}

                                                {/*}*/}
                                                <div className={this.state.send_message ? 'd-none' : 'add_data'}>

                                                    <span><i className="apa-icon-notes" aria-hidden="true"></i></span>
                                                    <div className={'messenger_upload_img'}>


                                                        <ImageUploading
                                                            value={this.state.images}
                                                            multiple
                                                            onChange={this.handleUploadImage}
                                                            dataURLKey="data_url"
                                                        >


                                                            {({
                                                                  imageList,
                                                                  onImageUpload,
                                                                  onImageRemoveAll,
                                                                  onImageUpdate,
                                                                  onImageRemove,
                                                                  isDragging,
                                                                  dragProps,
                                                              }) => (
                                                                // write your building UI

                                                                <div className="upload__image-wrapper">

                                                                    <button
                                                                        style={isDragging ? { color: 'red' } : undefined}
                                                                        onClick={onImageUpload}
                                                                        {...dragProps}
                                                                    >

                                                                        <span><i className="apa-gallery" aria-hidden="true"></i></span>
                                                                    </button>
                                                                    &nbsp;

                                                                    {this.state.images.length > 0 && <button onClick={ onImageRemoveAll}>Remove Photo</button>}


                                                                    {imageList.map((image, index) => (

                                                                        <div key={index} className="image-item">
                                                                            <img src={image['data_url']} alt="" width="100" />
                                                                            <div className="image-item__btn-wrapper">
                                                                                {/*<button onClick={() => onImageUpdate(index)}>Update</button>*/}
                                                                                {/*<button onClick={() => onImageRemove(index)}>Remove</button>*/}

                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </ImageUploading>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>

                                <div className="col-lg-4 col-12">
                                    <div className={'apa_boxShadow messages_info mb-3'}>
                                        <div className="container">
                                            <div className="row">

                                                <div className="col-6">
                                                    <p>Insured Name</p>
                                                </div>
                                                <div className="col-6">
                                                    <span>Susanna Harutyunyan</span>
                                                </div>


                                                <div className="col-6">
                                                    <p>Cell Number</p>
                                                </div>
                                                <div className="col-6">
                                                    <span>(619) 315-1202</span>
                                                </div>



                                                <div className="col-6">
                                                    <p>Email</p>
                                                </div>
                                                <div className="col-6">
                                                    <span>naomidoire30@gmail.com</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className={'apa_boxShadow messages_info'}>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-6">
                                                    <p>Property</p>
                                                </div>
                                                <div className="col-6">
                                                    <span>3400 San Marino St,Los Angeles, CA 90006</span>
                                                </div>


                                                <div className="col-6">
                                                    <p>Team</p>
                                                </div>
                                                <div className="col-6">
                                                    <span>Team 2</span>
                                                </div>



                                                <div className="col-6">
                                                    <p>Category</p>
                                                </div>
                                                <div className="col-6">
                                                    <span>2-Reopen/NoReport</span>
                                                </div>


                                                <div className="col-6">
                                                    <p>Status</p>
                                                </div>
                                                <div className="col-6">
                                                    <span>Waiting on Insured</span>
                                                </div>



                                                <div className="col-6">
                                                    <p>Peril</p>
                                                </div>
                                                <div className="col-6">
                                                    <span>Water</span>
                                                </div>



                                                <div className="col-6">
                                                    <p>Lead Rep</p>
                                                </div>
                                                <div className="col-6">
                                                    <span>SK</span>
                                                </div>


                                                <div className="col-6">
                                                    <p>Lead PA</p>
                                                </div>
                                                <div className="col-6">
                                                    <span>JL</span>
                                                </div>



                                                <div className="col-6">
                                                    <p>Location</p>
                                                </div>
                                                <div className="col-6">
                                                    <span>Los Angeles, CA</span>
                                                </div>



                                                <div className="col-6">
                                                    <p>First Contact</p>
                                                </div>
                                                <div className="col-6">
                                                    <span>10/30/2019</span>
                                                </div>


                                                <div className="col-6">
                                                    <p>Days Open</p>
                                                </div>
                                                <div className="col-6">
                                                    <span className={'static_down'}>27.2</span>
                                                </div>

                                                <div className="col-6">
                                                    <p>Hot Lead</p>
                                                </div>
                                                <div className="col-6 messages_hot_lead">
                                                    <input type={'checkbox'} id={'message_hotLead'} checked={true} readOnly={true} />
                                                    <label htmlFor={'message_hotLead'}></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}


export default Messages;
