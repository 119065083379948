import React from 'react';
import {Col, Nav, Row, Tab} from "react-bootstrap";
import All from "./All";
import Leads from "./Leads";
import Claims from "./Claims";

const options = ['Most Recent','Unread','Newest','Oldest','Archived']

function Users({leads,getCurrentLead,currentLead,nonReadMessage}) {

    const snakeCase = (str)=>{
        return str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
            .map(x => x.toLowerCase())
            .join('_');
    }

    return (
        <>
            <div className="form-group selectdiv messages_select mb-0">
                <select>
                    {options.map((v,k)=>(
                        <option key={k} value={snakeCase(v)}>{v}</option>
                    ))}
                </select>
            </div>
            <Tab.Container className = {"table_container"} id={'table_container'} defaultActiveKey="first">
                <Row>
                    <Col lg={12}>
                        <Nav variant="pills" className={'mess_nav'}>
                            <Nav.Item className={'messages_tabs d-inline-block'}>
                                <Nav.Link eventKey="first">All</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className={'messages_tabs d-inline-block'}>
                                <Nav.Link eventKey="second">Leads</Nav.Link>
                            </Nav.Item>

                            <Nav.Item className={'messages_tabs d-inline-block last'}>
                                <Nav.Link eventKey="third">Claims</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>

                    <Col lg={12} className = 'messages_chat_history'>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <All leads={leads}
                                     getCurrentLead={getCurrentLead}
                                     currentLead={currentLead}
                                     nonReadMessage={nonReadMessage}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <Leads leads={leads}
                                       getCurrentLead={getCurrentLead}
                                       currentLead={currentLead}
                                       nonReadMessage={nonReadMessage}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                <Claims/>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </>
    );
}

export default Users;