

function Incoming(series,days,text,type=false) {
    return    {
        series: series ,
        options: {
            chart: {
                height: 420,
                type: 'line',
                zoom: {
                    enabled: false
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: [2, 2, 2],
                curve: 'smooth',
                dashArray: [0, 0, 0]
            },
            title: {
                text: text,
                align: 'left',
                style: {
                    fontSize: '15px',
                    fontFamily: 'OpenSansBold',
                    fontWeight: 400,
                    color:  '#00345D'
                },
            },
            legend: {
                tooltipHoverFormatter: function(val, opts) {
                    return val
                    // + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
                },
                position: 'top',
                horizontalAlign: 'left',
                floating: true,
                offsetY: 20,
                offsetX: -30,
                height:50,
                fontSize: '14px',
                fontFamily: 'OpenSansBold',
                fontWeight: 400,
                labels: {
                    colors: '#00345D',
                },
                markers: {
                    width: 35,
                    height: 3,
                    strokeWidth: 0,
                    strokeColor: '#fff',
                    radius: 6,

                },
                itemMargin: {
                    horizontal: 10,
                    vertical: 0
                },
            },
            markers: {
                size: 0,
                hover: {
                    sizeOffset: 6
                }
            },
            xaxis: {
                categories: days,
                offsetY: 10,
                labels: {
                    show:days && days.length <= 10,
                    style: {
                        colors: '#1B75BE',
                        fontSize: '12px',
                        cssClass: 'apexcharts-xaxis-label',
                    },
                },
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
            },
            yaxis: {
                tickAmount:3,
                min: 0,
                max: 60,
            },
            tooltip: {
                y: [
                    {
                        title: {
                            formatter: function (val) {
                                return val
                            }
                        }
                    },
                    {
                        title: {
                            formatter: function (val) {
                                return val
                            }
                        }
                    },
                    {
                        title: {
                            formatter: function (val) {
                                return val;
                            }
                        }
                    }
                ]
            },
            grid: {
                borderColor: '#f1f1f1',
                padding: {
                    top: 70,
                    right: 0,
                    bottom: 0,
                },
            }
        },
        
    }


}
export default (Incoming)