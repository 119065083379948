import React from "react";
import Moment from "react-moment";
import moment from "moment";
import Link from "react-router-dom/es/Link";
import {OverlayTrigger, Tooltip} from "react-bootstrap";


export  const getDate = (val) =>  {
        if(val) {
            return moment(val).format('MM/DD/YYYY')
        }
        return ('')
    }
  export const  checkNull = (val) => {
        if(val) { return val
        }else {return ''}
    }


 export const getStatus = (val,name) => {
    let status_progress = [1,2,3,4,5,6,7,8,9]
    if(val) {
        return (
            <>
                {name}
                <ul className={'insured_progress'}>
                    {status_progress.map((k) => {
                        return(
                            <li key={k} className={'red_dot ' + (val  === k ? ' green_dot' : '')}/>
                        )
                    })}
                </ul>
            </>

        )
    }
}

export const countDaysPassed = (date)  => {
    let now = moment(new Date());
    let end = moment(date);
    return now.diff(end, 'days');
}

export const hrefLink = (val,path = '/leads/') => {
    return (
        <Link  to={{
            pathname:path + val.id,
            state:val.id
        }}><i className={'apa-icon-view'}/></Link>
    )
}

export const hrefLinkWithState = (path,state) => {
    return (
        <Link to={{
            pathname:path,
            state:state
        }} >
            <i   className={'apa-icon-view'}/>
        </Link>
    )
}



export const checkbox = (val=null) => {
    return (
        <>
            <input type="checkbox" id="exist_contact_check" name="exist_contact_check" checked={true} disabled={true}  />
            <label htmlFor="exist_contact_check"></label>
        </>
    )
}


export const checkboxCheck = (val=null) => {
    return (
        <>
            <input type={'checkbox'} id={'message_hotLead'} checked={!!val} readOnly={true} />
            <label htmlFor={'message_hotLead'}></label>
        </>
    )
}

export  const note = (val,className ='apa-icon-notes') => {
    return (
        <OverlayTrigger
            placement='bottom'
            overlay={
                <div className={"overflow-box"}>
                    <h3>Lead Source Notes</h3>
                    <p>
                        {val.notes}
                    </p>
                </div>
            }>
            {({ ref, ...triggerHandler }) => (
                <i ref={ref}{...triggerHandler} className={className }/>
            )}
        </OverlayTrigger>
        )

}


export  const Reason = (val) => {
    return (
        <>
            {val?.inactivate_reason?.reason && Object.values(val?.inactivate_reason?.reason).map((reason,key,arr) => {
                return (
                    <span key={key}>{reason}{key === arr.length - 1 ? '' : ', '}</span>
                )
            })}
        </>

    )

}


