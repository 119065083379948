import React from 'react';
import LeadOptions from "./LeadOptions";
import {checkbox, checkboxCheck, checkNull, countDaysPassed, getDate} from '../../DataTables/Helper'

function UserInfo({currentLead}) {

    return (
        <>
            <div className={'apa_boxShadow messages_info mb-3'}>
                <div className="container">
                    <div className="row">
                        <LeadOptions title={'Insured Name'} value={currentLead?.client?.insured_name}/>
                        <LeadOptions title={'Cell Number'} value={currentLead?.contact?.cell_number}/>
                        <LeadOptions title={'Email'} value={currentLead?.contact?.email}/>
                    </div>
                </div>
            </div>
            <div className={'apa_boxShadow messages_info'}>
                <div className="container">
                    <div className="row">
                        <LeadOptions title={'Property'}
                                     value={checkNull(currentLead?.loss_address.street_number)+' '+
                                            checkNull(currentLead?.loss_address.street_name)+','+
                                            checkNull(currentLead?.loss_address.city)+', '+
                                            checkNull(currentLead?.loss_address.state)+' '+
                                            checkNull(currentLead?.loss_address.zip)
                        }/>
                        <LeadOptions title={'Team'} value={checkNull(currentLead?.assignment?.team.name)}/>
                        <LeadOptions title={'Category'} value={checkNull(currentLead?.category?.type)}/>
                        <LeadOptions title={'Status'} value={checkNull(currentLead?.status?.type)}/>
                        <LeadOptions title={'Peril'} value={checkNull(currentLead?.loss_info?.peril?.name)}/>
                        <LeadOptions title={'Lead Rep'} value={checkNull(currentLead?.rep?.name)}/>
                        <LeadOptions title={'Lead PA'} value={''}/>
                        <LeadOptions title={'Location'} value={checkNull(currentLead?.client?.location)}/>
                        <LeadOptions title={'First Contact'} value={getDate(currentLead?.first_contact)}/>
                        <LeadOptions title={'Days Open'} value={countDaysPassed(currentLead?.created_at)}/>
                        <LeadOptions title={'Hot Lead'} value={checkboxCheck(currentLead?.loss_info.hot_lead)}/>
                    </div>
                </div>
            </div>
        </>

    );
}

export default UserInfo;