import React from "react";
import SideBar from "./header/SideBar";
import Navbar from "./header/Navbar";
import ContactsTable from "./ContactsTable";
import {connect} from 'react-redux'
import axios from "axios";
import {url} from "../config";



class ContactsPage extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            clients:null,
        }
    }



    componentDidMount() {
        axios.get(url + 'clients')
            .then(res => {
                this.setState({
                    clients:res.data
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    render() {
        const {clients} = this.state;

        return(
            <>

                <div className={"d-flex" + (this.state.toggled ? ' toggled' : '')} id="wrapper">
                    <SideBar isAuth = {this.props.isAuth}/>
                    <div id="page-content-wrapper">
                        <div>
                            <Navbar toggled = {this.myCallback} />
                            <div className={'col-12'}>

                                <div className="row">
                                    <div className="col-lg-6 col-12">
                                        <div className={'page_title users_title'}>
                                            <h2>Clients</h2>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-12 leads_content">

                                        <div className={'leads_search'}>
                                            <i className={'apa-icon-search'}></i>
                                            <button onClick={this.handleAddUser} >Search by Client Name or Client ID</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <ContactsTable clients = {clients}  type={true}/>

                    </div>
                </div>

            </>
        )
    }
}


function mapStateToProps(state) {

    return{
        windowWidth:state.appReducer.windowWidth,
        leads_data:state.leadsReducer.leads_data
    }
}


export default connect(mapStateToProps,null)(ContactsPage);
