import React, {useEffect, useState} from "react";
import axios from "axios";
import {url} from "../../config";
import {Alert, Button, Modal, Spinner} from 'react-bootstrap'
import {useSelector} from "react-redux";
import {getEmails} from "../../redux/actions";
import {Editor} from "@tinymce/tinymce-react";
import EmailModal from "./EmailModal";


let create_user_data = {}

let errors={}

export default function CreateUser (props,) {
    const [roles,setRoles] = useState([])
    const [isShow,setIsShow] = useState(false)
    const [createError,setCreateError] = useState({first_name:'',last_name:'',mail:''})
    const [isShowEmail,setIsShowEmail] = useState(false)
    const [success,setSuccess] = useState(false)
    const [loading,setLoading] = useState(false)


    useEffect(() => {
        getRoles()
        if(props.data){
            create_user_data ={}
            errors={}
        }
    },[props.data])


    const getRoles = () => {
        axios.get(url + 'get-roles').then(res => {setRoles(res.data)})
            .catch(err => {console.log(err)})
    }


    const handleChange = (e) => {
        e.preventDefault();
        const {name,value} = e.target;
        if(value && value.trim()) create_user_data[name] = value
    }

     const handleCloseEmail = () =>{
         setIsShowEmail(false)
    }


    const checkRequired= (column,) =>{
        return !!(create_user_data[column] && create_user_data[column].length > 0);
    }


    const handleSendData = (event) => {
        errors={}
        if(checkRequired('first_name') && checkRequired('last_name') && checkRequired('email') ){
            errors={}
            setIsShowEmail(true)
        }
        if(!checkRequired('first_name')) errors['first_name'] = 'First name is required'

        if(!checkRequired('last_name')) errors['last_name'] ='Last name is required'

        if(!checkRequired('email'))  errors['mail']='Email is required'

        if(!checkRequired('role'))  errors['role_id']='Role is required'

        setCreateError(errors)

    }


    const handleSave = (data=null) =>{
        setLoading(true)
        create_user_data['description'] = data.description
        create_user_data['subject'] =data.subject


        axios.post(url + 'add-user', create_user_data).then(res => {
            setIsShow(false)
            if(res.data && res.data.errors){
                console.log(res.data.errors.errors);
                setCreateError(res.data.errors.errors)
                setIsShowEmail(false)
            }
            else {
                setLoading(false)
                setIsShowEmail(false)
                setSuccess(true)
                setTimeout(function (){
                    setSuccess(false)
                    props.handleClose()
                },3000)
            }
            })
    }

    return (

        <>
            <Modal show={props.show} onHide ={props.handleClose}  centered className="discover_modal_content " dialogClassName='discover_modal '>
                <Modal.Header closeButton>

                    <Modal.Title>Add New User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert show={success} variant="success">
                        <Alert.Heading>User is added successfully</Alert.Heading>
                    </Alert>
                    <form>
                        <div className="container">
                            <div className="row">
                                <div className="col-4 change_profile_info user_add_box">
                                    <p>Username</p>
                                </div>
                                <div className="col-8">
                                    <input type={'text'} name={'username'} onChange={handleChange}/>
                                    <span className={'error_box'}></span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4 change_profile_info user_add_box">
                                    <p>First Name</p>
                                </div>
                                <div className="col-8">
                                    <input type={'text'} name={'first_name'} onChange={handleChange}/>
                                    <span className={'error_box'}>{createError.first_name}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4 change_profile_info user_add_box">
                                    <p>Last Name</p>
                                </div>
                                <div className="col-8">
                                    <input type={'text'} name={'last_name'} onChange={handleChange}/>
                                    <span className={'error_box'}>{createError.last_name}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4 change_profile_info user_add_box">
                                    <p>Email</p>
                                </div>
                                <div className="col-8">
                                    <input type={'email'} name={'email'} onChange={handleChange}/>
                                    <span className={'error_box'}>{createError.mail}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4 change_profile_info user_add_box">
                                    <p>Assign Role</p>
                                </div>
                                <div className="col-8 change_profile_inputs help_select create_user">
                                    <div className="form-group selectdiv create_user">

                                        <select className="form-control " name="role" onChange={handleChange}>
                                            <option value={''}/>

                                            {roles && roles.map((item,key) => {
                                                return(
                                                    <option value={item.id} key={key}>{item.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <span className={'error_box'}>{createError.role_id}</span>
                                </div>
                            </div>
                            {isShow ? <Spinner style = {{float:'right'}} animation="grow" variant="primary"/> :
                                <button onClick={handleSendData} className={'setting_modal_button create_user_save default_hover'} type={'button'}>
                                    {props.saveButtonTitle ? props.saveButtonTitle : 'Save'}
                                </button> }


                        </div>

                    </form>

                </Modal.Body>

                <Modal.Footer>


                </Modal.Footer>
            </Modal>

            <EmailModal slug={'welcome'}
                        handleCloseEmail={handleCloseEmail}
                        isShowEmail={isShowEmail}
                        handleSave={handleSave}
                        loading={loading}
                />

            </>
    )

}
