import React, {useEffect, useState} from "react";
import ReactApexChart from "react-apexcharts";
import incoming from "../Charts/Incoming";




function IncomingLeadsReport(props){

    const [days,setDays] = useState(false)

    const [series,setSeries] = useState([])

    const obj = incoming(series,days,props.text)


    useEffect(() => {
        setDays(props.data?.days)
        getDefaultData()
    },[props.data])


    const getDefaultData = () => {

        if(props.count){
            setSeries([
                       {
                        name: "Signed Claims",
                        data:props.data?.all?.claims
                    },
                        {
                            name: "Incoming Leads",
                            data:props.data?.all?.lead
                        },
                         {
                        name: "Inspected Leads",
                        data:props.data?.ins?.lead
                    },
                    ])
        }else{
            let data = props.data?.all?.lead
                if(props.type) data =props.data?.claims
                setSeries([{
                    name: props.text,
                    data: data
                },
            ])
        }


    //     }else {
    //         setSeries([
    //            {
    //             name: "Signed Claims",
    //             data:props.data?.result?.claims
    //         },
    //             {
    //                 name: "Incoming Leads",
    //                 data:props.data?.result?.lead
    //             },
    //         ])
    //     }
    }



        return(
            <div id="chart">
                <div className={'report_content'}>
                    <ReactApexChart options={obj.options} series={obj.series} type="line" height={420} />
                </div>
            </div>
        )

}

export default IncomingLeadsReport