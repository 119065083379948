import moment from "moment";
import {getDate, checkNull, hrefLink, note, hrefLinkWithState} from '../Helper'
import React from "react";


function claimsColumns(option=null) {
    let general = [
        {
            name: 'CIN',
            selector: row => '#CIN-' + row.id,
            sortable: true,
        },
        {
            name: 'Insured Name',
            selector: row => checkNull(row?.claims?.contact.first_name) + ' ' + checkNull(row?.claims?.contact.last_name),
            sortable: true,
        },
        {
            name: 'Team',
            selector: row => row?.claims?.assignment?.team?.name,
            sortable: true,
        },
        {
            name: 'Lead Rep',
            selector: row => row?.claims?.rep?.name,
            sortable: true,
        },
        {
            name: 'Claim Signer',
            selector: row => row?.user_claim?.first_name + ' ' + row?.user_claim?.last_name,
            sortable: true,
        },
        {
            name: 'Location',
            selector: row => checkNull(row?.claims?.loss_address?.city) + ', ' + checkNull(row?.claims?.loss_address?.state),
            sortable: true,
        },
        {
            name: 'Lead Source',
            selector: row => row?.claims?.source?.name,
            sortable: true,
        },
        {
            name: 'First Contact',
            selector: row => getDate(row?.claims.first_contact),
            sortable: true,
        },
        {
            name: 'Signed Date',
            selector: row => getDate(row?.signed_date),
            sortable: true,
        },
        {
            name: 'Days to Sign',
            selector: row => moment(row?.signed_date).diff(row?.createdAt, 'days'),
            sortable: true,
        },

        {
            name: '',
            selector: row => hrefLink(row?.claims,'/client-info/'),
            sortable: true,
        },
        {
            name: '',
            selector: row => note(row),
            sortable: true,
        },
    ];

    if(option){
        return general.concat([
            {
                name: '',
                selector: row => <i onClick={() => option(row)} className={'apa-icon-add'}/>,
                sortable: true,
            },
        ])
    }


    return general
}
export default (claimsColumns)