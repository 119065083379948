import React from "react";
import SideBar from "./header/SideBar";
import Navbar from "./header/Navbar";
import {Col, Nav, Row, Tab} from "react-bootstrap";
import LeadsTable from "./LeadsTable";
import LeadsPropTable from "./LeadsPropTable";
import ContactsTable from "./ContactsTable";
import '../assets/css/search_styles.css';
import {connect} from 'react-redux'
import {
    getSearchResult
} from "../redux/actions";
import PropertiesTable from "./contacts/PropertiesTable";

let searchVal;
class Search extends React.Component{
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount() {
         searchVal = new URLSearchParams(this.props.location.search).get("search")
        if(searchVal) this.props.dispatch(getSearchResult(searchVal))
    }

  
    render() {

     const {result}=this.props
        return (
            <>
                <div className={"d-flex" + (this.props.toggled ? ' toggled' : '')} id="wrapper">
                    <SideBar/>
                    <div id="page-content-wrapper">
                        <Navbar toggled = {this.props.myCallback} loc={this.props.location} />

                        <div className={'page_title'}>
                            <h2>Search results for: {searchVal? searchVal :''} </h2>
                        </div>


                        <Tab.Container className = {"table_container"} id={'table_container'} defaultActiveKey="first">
                            <Row>
                                <Col lg={12}>
                                    <Nav variant="pills" className="">
                                        <Nav.Item className={'col-3 search_tabs '}>
                                            <Nav.Link eventKey="first">Leads</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className={'col-3 search_tabs  '}>
                                            <Nav.Link eventKey="second">Claims</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item className={'col-3  search_tabs '}>
                                            <Nav.Link eventKey="third">Contacts</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item className={'col-3  search_tabs '}>
                                            <Nav.Link eventKey="fourth">Properties</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>

                                <Col lg={12}>

                                    <Tab.Content>

                                        <Tab.Pane eventKey="first">
                                        <LeadsPropTable leads_data = {result?.leads && result.leads}/>
                                        
                                        </Tab.Pane>


                                        <Tab.Pane eventKey="second">
                                            <LeadsTable/>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third">
                                        <ContactsTable clients = {result?.clients && result.clients}/>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="fourth">
                                            <PropertiesTable data={result?.properties && result.properties}/>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>

                    </div>

                </div>
            </>
        )
    }
}

function mapStateToProps(state) {

    return{
        result: state.appReducer.results,
    }

}
export default connect(mapStateToProps,null)(Search);