import React from "react";
import SideBar from "./header/SideBar";
import Navbar from "./header/Navbar";
import {Col, Nav, Row, Tab} from "react-bootstrap";
import '../assets/css/new_leads_styles.css';
import NewLeadsTable from "./NewLeadsTable";
import axios from "axios";
import {url} from "../config";
import NeedAssignLeads from "./leads/NeedAssignLeads";
import AssignedLeads from "./leads/AssignedLeads";
import InactiveLeads from "./leads/InactiveLeads";
import {connect} from 'react-redux'
import {checkAuth} from "../redux/actions";
import Pagination from 'react-js-pagination';


class NewLeads extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            needAssignedLeads: [],
            assignedLeads:[],
            inactiveLeads:[],
            newIncomingLeads:[],
            activePage: 1,
            totalCount: '',
            incomingCount: '',
            incomingActiveLeads:1,
            needToAssignCount: '',
            needToAssignActive:1,
            assignedLeadsCount:'',
            assignedActive:1,
        }
    }

    componentDidMount() {
        this.props.dispatch(checkAuth());
        this.getNeedAssignedLeads();
        this.getAssignedLeads();
        this.getInactiveLeads();
        this.getNewIncomingLeads();
        this.countTotalNewIncomingLeads()
        this.countTotalNeedToassignLeads()
        this.countAssignedLeads()

    }
    countTotalNewIncomingLeads = () => {
        axios.get(url + 'get-total-new-incoming-leads-count')
            .then(res => {
               this.setState({
                incomingCount:res.data
               })
            })
            .catch(err => {
                console.log(err)
            })
    }


    countTotalNeedToassignLeads = () => {
            axios.get(url + 'get-total-need-to-assign-count')
                .then(res => {
                this.setState({
                    needToAssignCount:res.data
                })
                })
                .catch(err => {
                    console.log(err)
                })
    }

    countAssignedLeads = () => {
        axios.get(url + 'get-total-assigned-count')
            .then(res => {
            this.setState({
                assignedLeadsCount:res.data
            })
            })
            .catch(err => {
                console.log(err)
            })
    }

    handlePageChange = (pageNumber) => {
            this.getNewIncomingLeads(pageNumber)
        this.setState({
          activePage: pageNumber,
        });
      };
      handleNewIncomingLeadsPageChange = (pageNumber) => {
        this.getNewIncomingLeads(pageNumber)
        this.setState({
            incomingActiveLeads: pageNumber,
        });
    };
    handleNeedToAssignPageChange = (pageNumber) => {
        this.getNeedAssignedLeads(pageNumber)
        this.setState({
            needToAssignActive: pageNumber,
        });
    };
    handleAssignedLeadPageChange = (pageNumber) => {
        this.getNeedAssignedLeads(pageNumber)
        this.setState({
            assignedActive: pageNumber,
        });
    };
    getNeedAssignedLeads = (pageNumber) => {
        let page = '';
        if(pageNumber !==undefined) {
            page = '?page='+pageNumber;
        }
        axios.get(url + 'get-need-assign-leads'+page)

            .then(res => {

                this.setState({
                        needAssignedLeads: res.data
                    })


            })
            .catch(err => {
                console.log(err)
            })
    }


    getAssignedLeads = (pageNumber) => {
        let page = '';
        if(pageNumber !==undefined) {
            page = '?page='+pageNumber;
        }
        axios.get(url + 'get-assigned-leads')
            .then(res => {

               this.setState({
                   assignedLeads:res.data
               })
            })
            .catch(err => {
                console.log(err)
            })
    }


    getInactiveLeads = () => {
        axios.get(url + 'get-inactive-leads')
            .then(res => {

             this.setState({
                 inactiveLeads:res.data
             })
            })
            .catch(err => {
                console.log(err)
            })
    }



    getNewIncomingLeads = (pageNumber) => {
        let page = '';
        if(pageNumber !==undefined) {
            page = '?page='+pageNumber;
        }
        axios.get(url + 'get-new-incoming-leads'+page)
            .then(res => {

                this.setState({
                newIncomingLeads:res.data
            })
            })
            .catch(err => {
                console.log(err)
            })
    }


    render() {
        const {needAssignedLeads,assignedLeads,inactiveLeads,newIncomingLeads} = this.state;
        const {toggled} = this.props;



        return(
            <>
                <div className={"d-flex" + (toggled ? ' toggled' : '')} id="wrapper">
                    <SideBar isAuth = {this.props.isAuth}/>
                    <div id="page-content-wrapper">

                        <Navbar/>

                        <div className={'page_title'}>
                            <h2>New Incoming</h2>
                        </div>


                        <Tab.Container className = {"table_container"} id={'table_container'} defaultActiveKey="first">
                            <Row>
                                <Col lg={12}>
                                    <Nav variant="pills" className="">
                                        <Nav.Item className={'col-lg-3 col-12 search_tabs new_leads '}>
                                            <Nav.Link eventKey="first">{this.state.incomingCount > 0 ? this.state.incomingCount : ''} New Incoming</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className={'col-lg-3 col-12 search_tabs new_leads  '}>
                                            <Nav.Link eventKey="second" >{this.state.needToAssignCount > 0 ? this.state.needToAssignCount : ''} Need To Be Assigned</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item className={'col-lg-3 col-12  search_tabs new_leads '}>
                                            <Nav.Link eventKey="third">{this.state.assignedLeadsCount > 0 ? this.state.assignedLeadsCount : ''}  Assigned Leads</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item className={'col-lg-3 col-12  search_tabs new_leads '}>
                                            <Nav.Link eventKey="fourth" >{inactiveLeads.length > 0 && inactiveLeads.length} Inactive Leads</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>

                                <Col lg={12}>

                                    <Tab.Content>

                                        <Tab.Pane eventKey="first">
                                            <NewLeadsTable 
                                            incomingActiveLeads={this.state.incomingActiveLeads} 
                                            handlePageChange={this.handleNewIncomingLeadsPageChange} 
                                            totalCount= {this.state.incomingCount} 
                                            newIncomingLeads = {newIncomingLeads}/>
                                        </Tab.Pane>


                                        <Tab.Pane eventKey="second">
                                           <NeedAssignLeads 
                                           ActiveNeedToAssign={this.state.needToAssignActive} 
                                           totalCount= {this.state.needToAssignCount} 
                                           handlePageChange={this.handleNeedToAssignPageChange} 
                                           needAssignedLeads = {needAssignedLeads}/>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third">
                                           <AssignedLeads 
                                            assignedActive={this.state.assignedActive} 
                                            totalCount= {this.state.assignedLeadsCount} 
                                            handlePageChange={this.handleAssignedLeadPageChange} 
                                           assignedLeads = {assignedLeads}/>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="fourth">
                                           <InactiveLeads inactiveLeads = {inactiveLeads} />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>


                    </div>
                </div>
            </>
        )
    }
}


function mapStateToProps(state) {

    return{
        toggled:state.appReducer.toggled
    }

}


export default connect(mapStateToProps,null)(NewLeads);