import React from "react";
import SideBar from "./header/SideBar";
import Navbar from "./header/Navbar";
import '../assets/css/leads_details_styles.css';
import {Col, Nav, Row, Tab} from "react-bootstrap";
import BasicInfo from "./leadDetails/BasicInfo";
import ContactInfo from "./leadDetails/ContactInfo";
import RelatedContacts from "./leadDetails/RelatedContacts";
import LossInfo from "./leadDetails/LossInfo";
import ExtentDamage from "./leadDetails/ExtentDamage";
import CarrierInfo from "./leadDetails/CarrierInfo";
import LossAdress from "./leadDetails/LossAdress";
import BuildingInfo from "./leadDetails/BuildingInfo";
import MortgageInformation from "./leadDetails/MortgageInformation";
import AssignmentInfo from "./leadDetails/AssignmentInfo";
import AssignedHistory from "./leadDetails/AssignedHistory";
import InspectionInfo from "./leadDetails/InspectionInfo";
import Documents from "./leadDetails/Documents/Documents";
import LogEntryModal from "./leadDetails/LogEntryModal";
import SendMessageModal from "./leadDetails/SendMessageModal";
import AssignModal from "./leadDetails/AssignModal";
import ScheduleInspection from "./leadDetails/ScheduleInspection";
import SignClaim from "./leadDetails/SignClaim";
import Inactive from "./leadDetails/Inactive";
import {NavLink} from "react-router-dom";
import LogEntry from "./leadDetails/LogEntry";
import axios from "axios";
import Slider from "react-slick";
import {connect} from 'react-redux'
import {uploads_url, url} from "../config";
import CallModal from "./leadDetails/CallModal";
import {GETLEADID, GETLEEDCONTACT} from "../redux/types";
import {checkAuth, getLeedInspection, openBeginModal, sideBarToggle} from "../redux/actions";
import BeginInspection from "./leadDetails/BeginInspection";


class LeadDetails extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            show:false,
            toggled:false,
            send_message:false,
            assign_show:false,
            schedule_modal:false,
            signClaimModal:false,
            inactive_modal:false,
            call_show:false,
            lead_body:[],
            updated:false,
            slider_key:'first',
            resign:true,
            loading:false,
        }
    }

    componentDidMount() {
        this.props.getLead(this.props.match.params.leadId);
        this.getCurrentLead();
        this.props.checkAuth();
    }




    myCallback = (dataFromChild) => {
        if(dataFromChild){
            this.setState({
                lead_body:'',
                updated:dataFromChild
            })
            this.getCurrentLead();
        }
    }

    changeStatus = (dataFromChild) =>{
        this.setState({
            resign:dataFromChild
        })
    }


    getCurrentLead =  () => {
        const lead_id = this.props.match.params.leadId

        localStorage.setItem('leadId',lead_id)

        axios.get(url + 'get-lead?id=' + lead_id)
            .then(res => {
                this.setState({
                    lead_body:[...this.state.lead_body,res.data]
                })
                this.props.getLeadContact(this.state.lead_body[0]?.client?.contacts[0])
                this.props.getLeadId(lead_id)
            })
            .catch(err => {
                console.log(err)
            })
    }
    addLogEntry = () => this.setState({ show:true })
    
    modalCallBack = (parentDate,name = '') => {

        if(name === 'SendMessage'){
            return this.setState({
                send_message:false
            })
        }


        if(name === 'inactive'){
            return this.setState({
                inactive_modal:false
            })
        }


        if(name === 'signClaim'){
            return this.setState({
                signClaimModal:false
            })
        }


        if(name === 'assign_modal'){
            return this.setState({
                assign_show:false
            })
        }

        if(name === 'schedule_modal'){
            return this.setState({
                schedule_modal:false
            })
        }

        this.setState({
            show:parentDate
        })
    }


    sendMessageModalOpen = () => {
        this.setState({
            send_message:true
        })
    }

    assignModalOpen = (isDisabled) => {
        if(isDisabled != '1') {
            this.setState({
                assign_show:true
            })
        }
    }

    scheduleModalOpen = (isDisabled) => {
        if(isDisabled != '1') {
            this.setState({
                schedule_modal:true
            })
        }
        
    }

    signClaimModalOpen = (isDisabled) => {
        if(isDisabled != '1') {
            this.setState({
                signClaimModal:true
            })
        }
    }

    inactiveModal = (isDisabled) => {
        if(isDisabled != '1') {
            this.setState({
                inactive_modal:true
            })
        }
    }


    handleCallModal = () => {
        this.setState({
            call_show:true
        })
    }


    handleClose = () => {
        this.setState({
            call_show:false
        })
    }

    handleCall = (type=false) => {
        console.log(this.state.lead_body[0],8999);
        axios.get(url + 'get-auth-user-profile', {})
            .then( async res => {
                let data = {
                    roomName:this.state.lead_body[0]?.LIN,
                    number:this.state.lead_body[0]?.contact?.cell_number,
                    type:type,
                    folder_id:this.state.lead_body[0]?.folder_id,
                    auth_name:res.data.first_name +' '+ res.data.last_name

                }
                const response = await axios.post(url + '/room_create',data);
                window.location.href = response.data.localPath
            })

    }


    beginModalOpen = () => {
        this.props.showBeginModal();
    }


    closeScheduleModal = (condition) => {
        this.setState({
            schedule_modal:condition
        })
    }
    markReadyToAssing = () => {
        const data = {};
       let lead_id = this.props.match.params.leadId;
       data.lead_id = lead_id;
        axios
       .post(url + "mark-as-ready-to-assing", data)
       .then( async (res) => {
       await axios.post(url + "add-lead-log", {lead_id:lead_id,details:'Lead marked as Ready To Assign'});
        this.myCallback(true)
        //  this.props.myCallback(true);
       })
       .catch((err) => {
         console.log(err);
       });
    }

    reSign = ()=>{


            axios
                .get(url + "resign-claim?id="+this.state.lead_body[0]?.signed_claim?.sign_req_id, {})
                .then(res=>{
                    console.log(res.data);
                })

    }

    down = ()=> {
        this.setState({loading:true})
        axios
            .post(url + "/download-lead-report", this.state.lead_body[0])
            .then(res=>{
                window.open(uploads_url+"uploads/leadReports/"+res.data.fileName, '_blank');
                this.setState({loading:false})
            })
    }

    render() {
        const {lead_body} = this.state;
        const {toggled} = this.props;


        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 3,
            variableWidth: true
        };



        return(
            <>
                <div className={"d-flex" + (toggled ? ' toggled' : '')} id="wrapper">
                    <SideBar isAuth = {this.props.isAuth}/>
                    <div id="page-content-wrapper">
                        <div>
                            <Navbar  />


                            <div className="row">
                                <div className="col-lg-4 col-6">

                                    <div className={'leads_details_title social'}>
                                        <h3>Leads/Details</h3>

                                        <span onClick={this.addLogEntry}> <i className={'apa-icon-add'}></i>Add Log Entry</span>
                                    </div>
                                </div>



                                <div className="col-lg-8 col-6 leads_details_properties">

                                    <ul className={'d_none'}>
                                        <li key={'1'} onClick={this.sendMessageModalOpen}>
                                            <span><i className={'apa-icon-messaging ' }></i>Send Message</span>
                                        </li>
                                        
                                        <li style={{ display: lead_body[0]?.ready_to_assign && lead_body[0]?.ready_to_assign !== '1' ? 'initial' : 'none'}} key={'7'} 
                                        onClick={() => this.markReadyToAssing()}>
                                            <span><i className="fas fa-check"></i> Ready to Assign</span>
                                        </li>
                                        <li key={'2'} onClick={() => this.assignModalOpen(lead_body[0]?.ready_to_assign === '1' && lead_body[0]?.assignment !== null ? '1' : '')}>
                                            <span className={''+(lead_body[0]?.ready_to_assign === '1' && lead_body[0]?.assignment !== null ? 'disabled' : '' )}><i className={'apa-add-user'}></i>Assign</span>
                                        </li>
                                        <li key={'3'} onClick={() => this.scheduleModalOpen(lead_body[0]?.ready_to_assign === '1' && lead_body[0]?.assignment != null ? '' : '1')} >
                                            <span className={''+(lead_body[0]?.ready_to_assign === '1' && lead_body[0]?.assignment != null ? '' : 'disabled' )}><i className={'apa-icon-calendar'}></i>{!lead_body[0]?.lead_inspection?.suggestion_date ?this.props.schedule_title:'Reschedule'}</span>
                                        </li>

                                        <li key={'4'}
                                            onClick={() =>
                                                !(lead_body[0]?.signed_claim) && (this.state.resign) ?
                                                    this.signClaimModalOpen(lead_body[0]?.ready_to_assign === '1' && lead_body[0]?.assignment != null ? '' : '1')
                                                    : this.signClaimModalOpen(lead_body[0]?.ready_to_assign === '1' && lead_body[0]?.assignment != null ? '' : '1')
                                            }
                                        >
                                            <span 
                                            className={''+( (lead_body[0]?.ready_to_assign === '1' && lead_body[0]?.assignment != null)  ? '' : 'disabled' )}><i className={'apa-icon-sign'}></i>{!(lead_body[0]?.signed_claim) && (this.state.resign) ? 'Sign Claim' : 'Resign'}</span>
                                        </li>

                                        <li key={'5'} onClick={() => this.inactiveModal(lead_body[0]?.inactive === '0' ? '' : '1')} >
                                            <span className={''+( lead_body[0]?.inactive === '0' ? '' : 'disabled' )}><i className={'apa-icon-deactivate'}></i>Inactive</span>
                                        </li>

                                        <li key={'6'} onClick={this.down}>
                                                <span><i className={'apa-icon-export'}/>Export Data</span>
                                            {this.state.loading ?  <div className={'spinner-grow text-primary'} style={{width:'1.3rem',height: '1.3rem',marginLeft:'7px'}}/> :''}

                                        </li>
                                    </ul>
                                    <ul className={'mobile_properties'}>
                                        {lead_body[0] && lead_body[0]?.lead_inspection?.inspection_date && lead_body[0]?.lead_inspection?.status_id != 3 ? (
                                        <li key={'1'} onClick={this.beginModalOpen}>
                                            <span><i className={'apa-icon-messaging'}></i>Begin Inspection</span>
                                        </li>
                                        ) : (
                                            <li key={'1'}></li>
                                        )}
                                        

                                        <li key={'2'}  onClick={this.scheduleModalOpen}>
                                            <span><i className={'apa-icon-calendar'}></i>Reschedule</span>
                                        </li>
                                    </ul>


                                </div>

                            </div>

                            <Tab.Container className = {"table_container"} id={'table_container'} defaultActiveKey="first" onSelect={key => this.setState({slider_key:key})} >
                                <Row>
                                    <Col lg={12}>

                                        <Nav variant="pills" className="">
                                            <Nav.Item  className={'col-md-2 col-lg-4 col-xl-2 col-12 search_tabs new_leads leads_details_tabs desktop_tabs '}>
                                                <Nav.Link eventKey="first">Basic Info</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className={'col-md-2  col-lg-4 col-xl-2 col-12 search_tabs new_leads leads_details_tabs desktop_tabs '}>
                                                <Nav.Link eventKey="second">Loss Info</Nav.Link>
                                            </Nav.Item>

                                            <Nav.Item className={'col-md-2 col-lg-4 col-xl-2 col-12  search_tabs new_leads leads_details_tabs desktop_tabs '}>
                                                <Nav.Link eventKey="third">Loss Address</Nav.Link>
                                            </Nav.Item>

                                            <Nav.Item className={'col-md-2 col-lg-4 col-xl-2 col-12 search_tabs new_leads leads_details_tabs desktop_tabs '}>
                                                <Nav.Link eventKey="fourth">Assignment & Inspection Info</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className={'col-md-2 col-lg-4 col-xl-2 col-12 search_tabs new_leads leads_details_tabs desktop_tabs '}>
                                                <Nav.Link eventKey="five">Documents</Nav.Link>
                                            </Nav.Item>

                                            <Nav.Item className={'col-md-2 col-lg-4 col-xl-2 col-12 search_tabs new_leads leads_details_tabs desktop_tabs '}>
                                                <Nav.Link eventKey="six">Log Entries</Nav.Link>
                                            </Nav.Item>
                                        </Nav>


                                        <div className={'leads_details_mobile_slider'}>


                                            <Slider {...settings}>

                                                    <Nav.Item className={' search_tabs new_leads leads_details_tabs' + (this.state.slider_key === 'first' ? ' active_slider' : '')}>
                                                        <Nav.Link eventKey="first">Basic Info</Nav.Link>
                                                    </Nav.Item>

                                                <div>
                                                    <Nav.Item className={' search_tabs new_leads leads_details_tabs' + (this.state.slider_key === 'second' ? ' active_slider' : '')}>
                                                        <Nav.Link eventKey="second">Loss Info</Nav.Link>
                                                    </Nav.Item>
                                                </div>
                                                <Nav.Item className={'search_tabs new_leads leads_details_tabs' + (this.state.slider_key === 'third' ? ' active_slider' : '')}>
                                                    <Nav.Link eventKey="third">Loss Address</Nav.Link>
                                                </Nav.Item>

                                                <div>
                                                    <Nav.Item className={' search_tabs new_leads leads_details_tabs' + (this.state.slider_key === 'fourth' ? ' active_slider' : '')}>
                                                        <Nav.Link eventKey="fourth">Assignment & Inspection Info</Nav.Link>
                                                    </Nav.Item>
                                                </div>
                                                <div>
                                                    <Nav.Item className={' search_tabs new_leads leads_details_tabs' + (this.state.slider_key === 'five' ? ' active_slider' : '')}>
                                                        <Nav.Link eventKey="five">Documents</Nav.Link>
                                                    </Nav.Item>
                                                </div>
                                                <div>
                                                    <Nav.Item className={' search_tabs new_leads leads_details_tabs' + (this.state.slider_key === 'six' ? ' active_slider' : '')}>
                                                        <Nav.Link eventKey="six">Log Entries</Nav.Link>
                                                    </Nav.Item>
                                                </div>


                                            </Slider>
                                        </div>
                                    </Col>

                                    <Col lg={12}>

                                        <Tab.Content>

                                            <Tab.Pane eventKey="first">

                                                <div className="row">
                                                    <div className="col-lg-4 col-12 leads_details_content">
                                                        <BasicInfo lead_body = {lead_body} myCallback = {this.myCallback} />
                                                    </div>
                                                    <div className="col-lg-4 col-12 leads_details_content">
                                                        <ContactInfo lead_body = {lead_body} myCallback = {this.myCallback}/>
                                                    </div>
                                                    <div className="col-lg-4 col-12 leads_details_content">
                                                        <RelatedContacts leadId = {this.props.match.params.leadId} lead_body = {lead_body} myCallback = {this.myCallback}/>
                                                    </div>
                                                </div>

                                            </Tab.Pane>


                                            <Tab.Pane eventKey="second">
                                                <div className="row">
                                                    <div className="col-lg-4 col-12 leads_details_content">
                                                        <LossInfo lead_body = {lead_body} myCallback = {this.myCallback} />
                                                    </div>
                                                    <div className="col-lg-4 col-12 leads_details_content">
                                                        <ExtentDamage lead_body = {lead_body} myCallback = {this.myCallback}/>
                                                    </div>
                                                    <div className="col-lg-4 col-12 leads_details_content">
                                                        <CarrierInfo lead_body = {lead_body} myCallback = {this.myCallback} />
                                                    </div>
                                                </div>
                                            </Tab.Pane>


                                            <Tab.Pane eventKey="third">
                                                <div className="row">
                                                    <div className="col-lg-4 col-12 leads_details_content">
                                                        <LossAdress setState={this.setState.bind(this)} lead_body = {lead_body} myCallback = {this.myCallback}/>
                                                    </div>
                                                    <div className="col-lg-4 col-12 leads_details_content">
                                                        <BuildingInfo lead_body = {lead_body} myCallback = {this.myCallback}/>
                                                    </div>
                                                    <div className="col-lg-4 col-12 leads_details_content">
                                                        <MortgageInformation lead_body = {lead_body} myCallback = {this.myCallback}/>
                                                    </div>
                                                </div>
                                            </Tab.Pane>


                                            <Tab.Pane eventKey="fourth">
                                                <div className="row">
                                                    <div className="col-lg-4 col-12 leads_details_content">
                                                        <AssignmentInfo lead_body = {lead_body} myCallback = {this.myCallback} />
                                                        <InspectionInfo lead_body = {lead_body} rescheduleInfo = {this.rescheduleInfo} leadId = {this.props.match.params.leadId}  myCallback = {this.myCallback} />

                                                    </div>
                                                    
                                                    <div className="col-lg-8 col-12 leads_details_content">
                                                        <AssignedHistory lead_body = {lead_body} leadId = {this.props.match.params.leadId}/>
                                                    </div>


                                                    

                                                </div>


                                            </Tab.Pane>

                                            <Tab.Pane eventKey="five">
                                                <Documents leadId = {this.props.match.params.leadId}/>
                                            </Tab.Pane>


                                            <Tab.Pane eventKey="six">
                                                <LogEntry lead_body = {lead_body} leadId = {this.props.match.params.leadId}  myCallback = {this.myCallback} />
                                            </Tab.Pane>

                                        </Tab.Content>
                                    </Col>


                                </Row>
                            </Tab.Container>


                        </div>

                    </div>

                    <button onClick={this.handleCallModal} className="call_button button-small">
                        <span className="label-hidden">Initate Call</span>
                        <i className="apa-icon-phone call"></i>

                    </button>
                </div>

                {/*------Modals------*/}

                <LogEntryModal lead_body = {this.state.lead_body} show = {this.state.show}  modalCallBack = {this.modalCallBack}  myCallback = {this.myCallback} />
                <SendMessageModal show = {this.state.send_message} modalCallBack = {this.modalCallBack}/>
                <AssignModal lead_body = {this.state.lead_body} show = {this.state.assign_show} modalCallBack = {this.modalCallBack} myCallback = {this.myCallback} />
                <ScheduleInspection  myCallback = {this.myCallback} closeScheduleModal = {this.closeScheduleModal}
                                     show = {this.state.schedule_modal} lead_body = {this.state.lead_body}
                                     leadId = {this.props.match.params.leadId}
                                     modalCallBack = {this.modalCallBack} />
                <SignClaim lead_body = {this.state.lead_body} show = {this.state.signClaimModal} modalCallBack = {this.modalCallBack} myCallback = {this.changeStatus}/>
                <Inactive lead_body = {this.state.lead_body} myCallback = {this.myCallback} show = {this.state.inactive_modal} modalCallBack = {this.modalCallBack} />
                <CallModal handleClose = {this.handleClose}  call_show = {this.state.call_show} handleCall={this.handleCall} />


                <div className={'mobile_begin_inspection_content'}>
                    <BeginInspection  myCallback = {this.myCallback} lead_id ={this.props.match.params.leadId}/>
                </div>


            </>
        )
    }
}


function mapDispatchToProps(dispatch){

    return{
        getLeadId: (leadId) => dispatch({type:GETLEADID,payload:leadId}),
        checkAuth:() => dispatch(checkAuth()),
        showBeginModal: () => dispatch(openBeginModal()),
        sideBarToggle : (condition) => dispatch(sideBarToggle(condition)),
        getLead:(lead_id) => dispatch(getLeedInspection(lead_id)),
        getLeadContact: (contact) => dispatch({type:GETLEEDCONTACT,payload:contact}),
    }
}


function mapStateToProps(state) {

    return{
        toggled:state.appReducer.toggled,
        schedule_title:state.leadsReducer.schedule_title

    }

}




export default connect(mapStateToProps,mapDispatchToProps)(LeadDetails);
