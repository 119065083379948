import {getStatus, getDate, checkNull, countDaysPassed, hrefLink,note} from '../Helper'

import React from "react";
const sortByStatusId = (rowA, rowB) => {
    const a = rowA?.status?.id;
    const b = rowB?.status?.id;
    if(a && b) {
        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }
    }
    return 0;
};
const sortByLeadSourceId = (rowA, rowB) => {
    const a = rowA?.source?.id;
    const b = rowB?.source?.id;
    if(a && b) {
        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }
    }
    return 0;
};
 export const columns = (option=null)=>{
     let options= [
         {
             name: 'LIN',
             selector: row => row?.LIN,
             sortable: true,
         },
         {
             name: 'Insured Name',
             selector: row => checkNull(row?.contact?.first_name)+' '+checkNull(row?.contact?.last_name),
             sortable: true,
         },
         {
             name: 'Team',
             selector: row => row?.assignment?.team?.name,
             sortable: true,
         },
         {
             name: 'Category',
             selector: row => row?.category?.type,
             sortable: true,
         },
         {
             name: 'Status',
             selector: row => getStatus(row?.status?.id,row?.status?.type),
             sortFunction: sortByStatusId,
             sortable: true,
         },
         {
             name: 'LR',
             selector: row => row?.rep?.name,
             sortable: true,
         },
         {
             name: 'Lead PA',
             selector: row => '',
             sortable: true,
         },
         {
             name: 'Location',
             selector: row => checkNull(row?.loss_address?.city)+', '+checkNull(row?.loss_address?.state),
             sortable: true,
         },
         {
             name: 'Lead Source',
             selector: row => row?.source?.name,
             sortable: true,
             sortFunction: sortByLeadSourceId,
         },
         {
             name: 'Peril',
             selector: row => row?.loss_info?.peril?.name,
             sortable: true,
         },
         {
             name: 'First Contact',
             selector: row => getDate(row?.first_contact),
             sortable: true,
         },
         {
             name: 'DO',
             selector: row => countDaysPassed(row.created_at),
             sortable: true,
         },
         {
             name: '',
             selector: row => hrefLink(row),
             sortable: true,
         },
         {
             name: '',
             selector: row => note(row),
             sortable: true,
         },

     ];

     if(option){
         options.push({
             name: '',
             selector: row =><i onClick={() => option(row)} className={'apa-icon-add'}/>,
             sortable: true,
         })
     }
     return options
 }


