import React from "react";
import {Button, Modal} from "react-bootstrap";
import PerilOptionsEdit from "./PerilOptionsEdit";
import axios from "axios";
import {url} from "../config";
import {connect} from "react-redux";
import {carrierModalCondition, optionEditModalCondition} from "../redux/actions";



class CarrierModal extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            carrier_inputs:[1,2],
            optionsLeast:false,
            carrier_values:[],
            carrier_list:'',
            show:false,
            carrier_inputs_counter:0
        }

    }


    handleAddCarrierInputs = () => {
        this.setState({
            carrier_inputs:this.state.carrier_inputs.concat(this.state.carrier_inputs.length + 1)
        })
    }

    handleOpenOptionList = () => {
        this.setState({
            show:true
        })
        this.props.dispatch(carrierModalCondition(false))

    }

    handleCarrierValue = (e) => {
        e.preventDefault();

        const carrier_values = this.state.carrier_values;
        carrier_values[e.target.dataset.id] = e.target.value;


        if (e.target.value.trim()) {

            this.setState({
                perilArray: carrier_values,
            })
        }
    }


    getAllCarriers = () => {
        axios.get(url + 'get-carrier-types')
            .then(res => {
                this.setState({
                    carrier_list:res.data
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    componentDidMount() {
       this.getAllCarriers();
    }


    carrierEditModal = () => {
      this.setState({
          show:true

      })
    }


    onHide = () => {
        this.setState({
            show:false
        })

        this.props.dispatch(carrierModalCondition(true))
    }


    handleSaveCarrier = ()=> {
        const carrier  = this.state.carrier_values;
        this.props.handleDefaultValue()
        if(carrier.length > 0 ){
            return  axios.post(url + 'add-carrier-types', {carrier})
                .then(res => {

                    this.setState({
                        carrier_values:[]
                    })
                    this.getAllCarriers();
                    this.props.dispatch(carrierModalCondition(false))
                    this.props.periListDataFetch()
                })
                .catch(err => {
                    console.log(err,'errrrr')
                })
        }else {
            this.props.dispatch(carrierModalCondition(false))
            this.props.periListDataFetch()
        }


    }


    handleDeleteCarrier = (carrier_id) => {
       let data = this.state.carrier_values

        data.splice(carrier_id)
        this.setState({
            carrier_values:data
        })
    }



    handleAddMoreCarrier = () => {
        this.setState({
            optionsLeast:false
        })
        this.props.dispatch(carrierModalCondition(true))
    }


    handleClose = () => {
        this.setState({
            optionsLeast:false
        })
        this.props.handleDefaultValue();
      return  this.props.dispatch(carrierModalCondition(false))
    }


    handleCloseModal = () => {
        this.setState({
            optionsLeast:false
        })


        this.props.dispatch(carrierModalCondition(true))

    }

    render() {

        return(
            <>
            <Modal show={this.props.show} onHide={this.handleClose} centered
                   className="discover_modal_content peril_modal" dialogClassName='discover_modal options_modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Add Carrier Option</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {this.state.carrier_inputs.map((item,key) => {
                        return(
                            <input name={'peril_desc' + this.state.carrier_inputs.length + key}
                                   key={this.state.carrier_inputs_counter + key}
                                   data-id = {key}
                                   onChange={this.handleCarrierValue}/>
                        )
                    })}


                    <button onClick={this.handleAddCarrierInputs}>Add More<span><i className={'apa-icon-add '}/></span></button>

                </Modal.Body>


                <Modal.Footer>

                    <button className={'peril_edit'} onClick={this.handleOpenOptionList}><span><i className={'apa-pencil'}/></span>Edit List</button>

                    <Button className={'default_hover'} variant="primary" onClick = {this.handleSaveCarrier}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

                {/*<Modal show={this.state.optionsLeast} onHide = {this.handleCloseModal}   centered className="discover_modal_content peril_list" dialogClassName='discover_modal'>*/}
                {/*    <Modal.Header closeButton>*/}
                {/*        <span className={'peril_list_edit'} onClick={this.carrierEditModal}>*/}
                {/*            <i className={'apa-pencil'}/>*/}
                {/*        </span>*/}

                {/*        <Modal.Title>Carrier Options1</Modal.Title>*/}
                {/*    </Modal.Header>*/}
                {/*    <Modal.Body>*/}
                {/*        <div className={'panir'}>*/}


                {/*            {this.state.carrier_values && this.state.carrier_values.length > 0 && this.state.carrier_values.map((item, key) => {*/}

                {/*                return (*/}
                {/*                    <React.Fragment key={key}>*/}

                {/*                        <i className={'apa-icon-delete delete_icon'} onClick={this.handleDeleteCarrier.bind(this,key)} />*/}
                {/*                        <p>{item}</p>*/}

                {/*                    </React.Fragment>*/}
                {/*                )*/}
                {/*            })}*/}

                {/*        </div>*/}
                {/*        <button className={'add_lead_button'} onClick={this.handleAddMoreCarrier}><i className={'apa-icon-add '}/> <span>Add More</span></button>*/}

                {/*    </Modal.Body>*/}
                {/*    <Modal.Footer>*/}

                {/*        <Button variant="primary" onClick = {this.handleCloseModal} >Submit</Button>*/}

                {/*    </Modal.Footer>*/}
                {/*</Modal>*/}

                 <PerilOptionsEdit getAllCarriers = {this.getAllCarriers} periListDataFetch = {this.props.periListDataFetch}   name = {'Carrier'} onHide = {this.onHide} show={this.state.show} perilList = {this.state.carrier_list} manulAdd = {this.state.carrier_values}/>
            </>
        )
    }
}


function mapStateToProps(state){
    return{
        show:state.leadsReducer.carrier_modal,
        optionsEdit:state.leadsReducer.optionsEdit
    }
}


export default connect(mapStateToProps,null)(CarrierModal)