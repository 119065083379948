import _ from "lodash";
import React from "react";
import axios from "axios";
import { url } from "../../config";
import NoteModal from "./NoteModal";
import { Button, Modal } from "react-bootstrap";

const carrier_info = {};

class CarrierInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      carrier: [],
      inputEdit: false,
      carrier_inputs: [1, 2],
      showAddCarrierModal: false,
    };
  }

  getCarriers = async () => {
    try {
      const carrier = [];
      const { data } = await axios.get(url + "create-lead-third");
      data.carrier_types.map((val, key) => {
        return (carrier[key] = val);
      });
      this.setState({
        carrier: carrier,
      });
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    this.getCarriers();
  }

  handleEdit = () => {
    carrier_info.lead_id = this.props.lead_body[0].id;
    this.setState({
      inputEdit: !this.state.inputEdit,
    });
  };

  handleChangeData = (event) => {
    const { name, value } = event.target;
    if (value === "add") this.setState({ showAddCarrierModal: true });
    else carrier_info[name] = value;
  };

  noteSave = (name, value) => (carrier_info[name] = value);

  handleSaveData = async () => {
    try {
      const data = {};
      data.carrier_info = carrier_info;
      await axios.post(url + "update-lead-loss", data);

      this.props.myCallback(true);
      this.setState({
        inputEdit: !this.state.inputEdit,
      });
    } catch (error) {
      console.error(error);
    }
  };

  handleCarrierValue = (e) => {
    e.preventDefault();
    const { carrier_inputs } = this.state;
    carrier_inputs[e.target.dataset.id] = e.target.value;
  };

  handleAddMoreCarriers = () => {
    this.setState({
      carrier_inputs: [
        ...this.state.carrier_inputs,
        this.state.carrier_inputs.length + 1,
      ],
    });
  };

  handleSaveCarrier = async () => {
    const carrier = this.state.carrier_inputs.filter(
      (it) => typeof it !== "number"
    );

    if (carrier.length > 0) {
      const { data } = await axios.post(url + "add-carrier-types", {
        carrier,
      });

      this.setState((old) => ({
        showAddCarrierModal: false,
        carrier: [...old.carrier, ...data],
      }));
    } else
      this.setState({
        showAddCarrierModal: false,
      });
  };



  closeEdit = () =>{
    this.setState({
      inputEdit: !this.state.inputEdit,
    });
  }

  handleRemoveCarrier = async (id) => {
    const response = await axios.post(`${url}destroy-carrier-type`, {
      id,
    });

    if (response.status === 200) {
      this.setState((old) => ({
        carrier: old.carrier.filter((c) => c.id !== id),
      }));
    }
  };

  render() {
    const { carrier } = this.state;
    const { lead_body } = this.props;

    return (
      <div className={"add_lead_basic lead_details_basic_info"}>
        <div className={"leads_details_edit"}>
          <h3>Carrier Info</h3>
          {this.state.inputEdit ? (
               <>
                <span onClick={this.handleSaveData}>Save</span>
                <div className={'close_b'} onClick={this.closeEdit}><i className="fas fa-times"/></div>
              </>
          ) : (
            <i onClick={this.handleEdit} className={"apa-pencil"}></i>
          )}
        </div>

        {lead_body &&
          lead_body.map((item, key) => {
            return (
              <div className="row" key={key}>
                <div className="col-4">
                  <p>Carrier</p>
                </div>

                <div className="col-8">
                  <input
                    style={
                      this.state.inputEdit
                        ? { display: "none" }
                        : { display: "block" }
                    }
                    name={"carrier_id"}
                    className={this.state.inputEdit ? " edit_inputs" : ""}
                    defaultValue={
                      lead_body[0].carrier &&
                      lead_body[0].carrier.carrier_name &&
                      lead_body[0].carrier.carrier_name.name
                    }
                    readOnly={!this.state.inputEdit}
                  />
                  <div
                    className="form-group selectdiv leads_details_selects"
                    style={
                      !this.state.inputEdit
                        ? { display: "none" }
                        : { display: "block" }
                    }
                  >
                    <select
                      name={"carrier_id"}
                      className={"form-control alterationTypeSelect"}
                      onChange={this.handleChangeData}
                    >
                      <option></option>
                      {carrier &&
                        carrier.map((carriers, key) => {
                          return (
                            <option
                              selected={
                                lead_body[0].carrier &&
                                lead_body[0].carrier.carrier_name &&
                                Number(lead_body[0].carrier.carrier_name.id) ==
                                  Number(carriers.id)
                              }
                              key={key}
                              value={carriers.id}
                            >
                              {carriers.name}
                            </option>
                          );
                        })}
                      <option value={"add"}>Add More</option>
                    </select>
                  </div>
                </div>

                <div className="col-4">
                  <p>Claim number</p>
                </div>

                <div className="col-8">
                  <input
                    onChange={this.handleChangeData}
                    name={"carrier_number"}
                    type={"text"}
                    className={this.state.inputEdit ? " edit_inputs" : ""}
                    defaultValue={item.carrier && item.carrier.carrier_number}
                    readOnly={!this.state.inputEdit}
                  />
                </div>

                <NoteModal
                  id={"carrier_notes"}
                  name={"carrier_notes"}
                  title={"Carrier Notes"}
                  noteSave={this.noteSave}
                  inputEdit={this.state.inputEdit}
                  notes={item.carrier && item.carrier.carrier_notes}
                />

                <Modal
                  centered
                  show={this.state.showAddCarrierModal}
                  className="discover_modal_content peril_modal"
                  dialogClassName="discover_modal options_modal"
                  onHide={() => this.setState({ showAddCarrierModal: false })}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Add Carrier Option</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {_.filter(this?.state?.carrier, { type: "manual" })?.map(
                      (carrier, idx) => {
                        return (
                          <div className={"d-flex"}>
                            <input
                              key={idx}
                              data-id={idx}
                              value={carrier.name}
                              onChange={this.handleCarrierValue}
                              name={
                                "carrier" +
                                this.state.carrier_inputs.length +
                                idx
                              }
                            />
                            <button
                              onClick={() =>
                                this.handleRemoveCarrier(carrier.id)
                              }
                            >
                              delete
                            </button>
                          </div>
                        );
                      }
                    )}

                    {this.state.carrier_inputs.map((_, idx) => {
                      return (
                        <div className={"d-flex"}>
                          <input
                            key={idx}
                            data-id={idx}
                            onChange={this.handleCarrierValue}
                            name={
                              "carrier" + this.state.carrier_inputs.length + idx
                            }
                          />
                          {/* <button
                            onClick={() => {
                              this.setState((old) => ({
                                carrier_inputs: old.carrier_inputs.filter(
                                  (input) => input !== idx
                                ),
                              }));
                            }}
                          >
                            delete
                          </button> */}
                        </div>
                      );
                    })}

                    <button onClick={this.handleAddMoreCarriers}>
                      Add More
                      <span>
                        <i className={"apa-icon-add "} />
                      </span>
                    </button>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" onClick={this.handleSaveCarrier}>
                      Save
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            );
          })}
      </div>
    );
  }
}

export default CarrierInfo;
