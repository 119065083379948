import _ from "lodash";
import React from "react";
import axios from "axios";
import { url } from "../../config";
import DatePicker from "react-datepicker";
import { Button, Modal } from "react-bootstrap";
import NoteModal from "./NoteModal";
import { connect } from "react-redux";
import Moment from 'react-moment';
import moment from 'moment'
let loss_info = {};

class LossInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldData: "",
      date_loss: '',
      DOL: '',
      inputEdit: false,
      discover_modal: false,
      peril_inputs: [1, 2],
      showAddMorePerilModal: false,
      discovery_text_disabled: true,
    };
  }

  handleEdit = () => {
    this.setState({
      inputEdit: !this.state.inputEdit,
    });
    loss_info.lead_id = this.props.lead_body[0]?.id;
  };

  noteSave = (name, value) => (loss_info[name] = value);

  handleSaveData = async () => {
    const data = {
      loss_info,
    };

    await axios.post(url + "update-lead-loss", data);
    this.props.myCallback(true);
    this.setState({
      inputEdit: !this.state.inputEdit,
    });
    loss_info = {};

    this.setState({
      discover_modal: false,
    });
  };

  componentDidMount() {
    this.getLeadsData();
  }

  getLeadsData = () => {
    axios
      .get(url + "create-lead-third", {})
      .then((res) => {
        if (res) {
          this.setState({
            oldData: res.data,
          });
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          window.location.href = "/login";
        }
      });
  };

  handleChangeData = (event) => {
    const { name, value } = event.target;
    if (name === "hot_lead") {
      if (
        this.props.lead_body[0] &&
        this.props.lead_body[0].loss_info &&
        this.props.lead_body[0].loss_info.hot_lead === 0
      ) {
        return (loss_info.hot_lead = "1");
      } else return (loss_info.hot_lead = "0");
    }

    if (name === "loss_reported") {
      if (this.props.lead_body[0].loss_info.loss_reported === "0") {
        return (loss_info.loss_reported = "1");
      } else return (loss_info.loss_reported = "0");
    }

    if (name === "denial") {
      if (
        this.props.lead_body[0].loss_info &&
        this.props.lead_body[0].loss_info.denial === "0"
      ) {
        return (loss_info.denial = "1");
      } else return (loss_info.denial = "0");
    }

    if (name === "denial_letter") {
      if (
        this.props.lead_body[0].loss_info &&
        this.props.lead_body[0].loss_info.denial_letter === "0"
      ) {
        return (loss_info.denial_letter = "1");
      } else return (loss_info.denial_letter = "0");
    }

    if (name === "photos") {
      if (
        this.props.lead_body[0].loss_info &&
        this.props.lead_body[0].loss_info.photos === "0"
      ) {
        return (loss_info.photos = "1");
      } else return (loss_info.photos = "0");
    }

    if (name === "peril")
      if (value === "add") this.setState({ showAddMorePerilModal: true });

    loss_info[name] = value;
  };

  hideDiscoverModal = () => {
    this.setState({
      discover_modal: false,
      showAddMorePerilModal: false,
      discovery_text_disabled: true,
    });
  };

  handleAddMoreTeams = () => {
    this.setState((old) => ({
      peril_inputs: old.peril_inputs.concat([old.peril_inputs.length + 1]),
    }));
  };

  closeEdit = () =>{
  this.setState({
    inputEdit: !this.state.inputEdit,
  });
}

  handleSavePeril = async () => {
    const peril = this.state.peril_inputs.filter(
      (val) => typeof val !== "number"
    );

    if (peril.length > 0) {
      const { data } = await axios.post(url + "add-loss-info", { peril });

      this.setState((old) => ({
        teams_values: [],
        oldData: {
          loss_types: [...old?.oldData?.loss_types, ...data],
        },
        peril_inputs: [1, 2],
        showAddMorePerilModal: false,
      }));
    } else
      this.setState({
        showAddMorePerilModal: false,
      });
  };

  handleDateChange = (name, value) => {
    if (name === "DOL") {
      this.setState({
        DOL: value,
      });

      return (loss_info.DOL = value);
    }

    if (name === "date_loss_reported") {
      this.setState({
        date_loss: value,
      });
      const date = new Date(value);
      return (loss_info.date_loss_reported = value
      );
    }
  };

  openDiscoverModal = () => {
    this.setState({
      discover_modal: true,
    });
  };

  changeDiscover = () => {
    loss_info.lead_id = this.props.lead_body[0].id;
    this.setState({
      discovery_text_disabled: false,
    });
  };

  handlePerilValue = (e) => {
    e.preventDefault();
    const peril_inputs = this.state.peril_inputs;
    peril_inputs[e.target.dataset.id] = e.target.value;
  };

  handleDeletePeril = async (id, value) => {
    const response = await axios.post(`${url}destroy-loss-info`, {
      id,
    });

    if (response.status === 204) {
      this.setState((old) => ({
        oldData: {
          loss_types: _.filter(old?.oldData?.loss_types, (item) => {
            return item.id !== id;
          }),
        },
        peril_inputs: _.filter(old.peril_inputs, (item) => item !== value),
      }));
    }
  };

  // componentDidUpdate() {
  //   console.log(this.state.oldData);
  //   console.log(this.state.peril_inputs);
  // }

  render() {
    const { oldData } = this.state;
    const { lead_body } = this.props;

    return (
      <div
        className={
          "add_lead_basic leads_details_lossInfo lead_details_basic_info"
        }
      >
        <div className={"leads_details_edit"}>
          <h3>Loss Info</h3>
          {lead_body.length !== 0 &&
            (this.state.inputEdit ? (
                <>
                  <span onClick={this.handleSaveData}>Save</span>
                  <div className={'close_b'} onClick={this.closeEdit}><i className="fas fa-times"/></div>
                </>
            ) : (
              <i onClick={this.handleEdit} className={"apa-pencil"}></i>
            ))}
        </div>

        {lead_body &&
          lead_body.map((item, key) => {
            return (
              <div className="row" key={key}>
                <div className="col-5">
                  <p>Hot Lead</p>
                </div>

                <div className="col-7">
                  <input
                    onChange={this.handleChangeData}
                    type="checkbox"
                    id="hot_lead"
                    name="hot_lead"
                    defaultChecked={item?.loss_info?.hot_lead}
                    disabled={!this.state.inputEdit}
                  />
                  <label htmlFor="hot_lead"></label>
                </div>

                <div className="col-5">
                  <p>Peril</p>
                </div>

                <div className="col-7">
                  <input
                    style={
                      this.state.inputEdit
                        ? { display: "none" }
                        : { display: "block" }
                    }
                    name={"peril"}
                    type={"text"}
                    defaultValue={
                      item.loss_info &&
                      item.loss_info.peril &&
                      item.loss_info.peril.name
                    }
                    className={this.state.inputEdit ? " edit_inputs" : ""}
                    readOnly={!this.state.inputEdit}
                  />
                  <div
                    className="form-group selectdiv leads_details_selects"
                    style={
                      !this.state.inputEdit
                        ? { display: "none" }
                        : { display: "block" }
                    }
                  >
                    <select
                      name={"peril"}
                      onChange={this.handleChangeData}
                      defaultValue={item?.loss_info?.peril?.id}
                      className={"form-control alterationTypeSelect"}
                    >
                      <option></option>

                      {oldData?.loss_types?.map((peril, key) => {
                        return (
                          <option
                            key={key}
                            value={peril.id}
                            disabled={
                              item.loss_info &&
                              item.loss_info.peril &&
                              item.loss_info.peril.id === peril.id
                            }
                          >
                            {peril.name}
                          </option>
                        );
                      })}

                      <option value={"add"}>Add More</option>
                    </select>
                  </div>
                </div>

                <div className="col-5">
                  <p>DOL</p>
                </div>

                <div className="col-7">
                  <input
                    style={
                      this.state.inputEdit
                        ? { display: "none" }
                        : { display: "block" }
                    }
                    name={"DOL"}
                    type={"text"}
                    defaultValue={
                      item.loss_info && item?.loss_info.DOL ?
                      moment(new Date(item?.loss_info.DOL)).format("MM/DD/YYYY")
                       : ''}
                    
                    className={this.state.inputEdit ? " edit_inputs" : ""}
                    readOnly={!this.state.inputEdit}
                  />

                  <div
                    style={
                      !this.state.inputEdit
                        ? { display: "none" }
                        : { display: "block" }
                    }
                    className={"details_date_input_content loss_info_dates"}
                  >
                    <DatePicker
                      className={"details_date_inputs "}
                      onChange={this.handleDateChange.bind(this, "DOL")}
                      selected={
                        this.state.DOL ? this.state.DOL :
                        item.loss_info && item.loss_info.DOL ?
                        new Date(item.loss_info.DOL)
                         : new Date()}
                      dateFormat="MM/dd/yyyy"
                    />
                  </div>
                </div>

                <div className="col-5">
                  <p>Loss Reported</p>
                </div>

                <div className="col-7">
                  <input
                    onChange={this.handleChangeData}
                    defaultChecked={Number(
                      item.loss_info && item.loss_info.loss_reported
                    )}
                    disabled={!this.state.inputEdit}
                    type="checkbox"
                    id="loss_report"
                    name="loss_reported"
                  />
                  <label htmlFor="loss_report"></label>
                </div>

                <div className="col-5">
                  <p>Date Loss Reported</p>
                </div>

                <div className="col-7">
                  <input
                    style={
                      this.state.inputEdit
                        ? { display: "none" }
                        : { display: "block" }
                    }
                    name={"date_loss_reported"}
                    type={"text"}
                    defaultValue={
                      item.loss_info && item.loss_info.date_loss_reported ?
                      moment(new Date(item.loss_info.date_loss_reported)).format("MM/DD/YYYY")
                       : ''}
                      
                    className={this.state.inputEdit ? " edit_inputs" : ""}
                    readOnly={!this.state.inputEdit}
                  />
                  <div
                    style={
                      !this.state.inputEdit
                        ? { display: "none" }
                        : { display: "block" }
                    }
                    className={"details_date_input_content loss_info_dates"}
                  >
                    <DatePicker
                      className={"details_date_inputs "}
                      onChange={this.handleDateChange.bind(
                        this,
                        "date_loss_reported"
                      )}
                      selected={
                        this.state.date_loss ? this.state.date_loss :
                        item.loss_info && item.loss_info.date_loss_reported ?
                        new Date(item.loss_info.date_loss_reported)
                         : new Date()}
                      dateFormat="MM/dd/yyyy"
                    />
                  </div>
                </div>

                <div className="col-5">
                  <p>Denial</p>
                </div>

                <div className="col-7">
                  <input
                    onChange={this.handleChangeData}
                    defaultChecked={Number(
                      item.loss_info && item.loss_info.denial
                    )}
                    disabled={!this.state.inputEdit}
                    type="checkbox"
                    id="denial"
                    name="denial"
                  />
                  <label htmlFor="denial"></label>
                </div>

                <div className="col-5">
                  <p>Denial Letter</p>
                </div>

                <div className="col-7">
                  <input
                    onChange={this.handleChangeData}
                    defaultChecked={Number(
                      item.loss_info && item.loss_info.denial_letter
                    )}
                    disabled={!this.state.inputEdit}
                    type="checkbox"
                    id="denial_letter"
                    name="denial_letter"
                  />
                  <label htmlFor="denial_letter"></label>
                </div>
                <NoteModal
                  title={"Loss Reported Note"}
                  name={"loss_reported_note"}
                  notes={item.loss_info && item.loss_info.loss_reported_note}
                  id={"loss_reported_note"}
                  col={5}
                  inputEdit={this.state.inputEdit}
                  noteSave={this.noteSave}
                />

                <div className="col-5">
                  <p>Previous Payment</p>
                </div>

                <div className="col-7">
                  <input
                    onChange={this.handleChangeData}
                    name={"previous_payment"}
                    type={"text"}
                    defaultValue={
                      item.loss_info && item.loss_info.previous_payment
                    }
                    className={this.state.inputEdit ? " edit_inputs" : ""}
                    readOnly={!this.state.inputEdit}
                  />
                </div>

                <div className="col-5">
                  <p>Discovery Description</p>
                </div>

                <div className="col-7">
                  <i
                    onClick={this.openDiscoverModal}
                    className={"apa-icon-view c-pointer"}
                  ></i>
                </div>

                <div className="col-5">
                  <p>Photos</p>
                </div>

                <div className="col-7">
                  <input
                    onChange={this.handleChangeData}
                    defaultChecked={Number(
                      item.loss_info && item.loss_info.photos
                    )}
                    disabled={!this.state.inputEdit}
                    type="checkbox"
                    id="photos"
                    name="photos"
                  />
                  <label htmlFor="photos"></label>
                </div>
              </div>
            );
          })}

        <Modal
          show={this.state.discover_modal}
          onHide={this.hideDiscoverModal}
          centered
          className="discover_modal_content peril_list"
          dialogClassName="discover_modal"
        >
          <Modal.Header closeButton>
            <span className={"peril_list_edit"} onClick={this.perilEditModal}>
              {this.state.discovery_text_disabled ? (
                <i
                  onClick={this.changeDiscover}
                  className={"apa-pencil default_hover"}
                ></i>
              ) : (
                <span className={"default_hover"} onClick={this.handleSaveData}>
                  Save
                </span>
              )}
            </span>

            <Modal.Title>Discovery Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea
              name={"discovery_description"}
              onChange={this.handleChangeData}
              className={
                "steps_text_area" +
                (this.state.discovery_text_disabled ? " disabled_textarea" : "")
              }
              defaultValue={
                lead_body[0] &&
                lead_body[0].loss_info &&
                lead_body[0].loss_info.discovery_description
              }
              readOnly={this.state.discovery_text_disabled}
            />
          </Modal.Body>
          <Modal.Footer>
            {/*<Button variant="primary">Submit</Button>*/}
          </Modal.Footer>
        </Modal>

        <Modal
          centered
          onHide={this.hideDiscoverModal}
          show={this.state.showAddMorePerilModal}
          className="discover_modal_content peril_modal"
          dialogClassName="discover_modal options_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Peril Option</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {_.filter(this.state.oldData?.loss_types, { type: "manual" })?.map(
              (p, idx) => {
                return (
                  <div key={idx} className={"d-flex"}>
                    <input
                      data-id={idx}
                      value={p.name}
                      onChange={this.handlePerilValue}
                      name={"perils" + this.state.teams_inputs_counter + idx}
                    />

                    <button
                      onClick={() => this.handleDeletePeril(p.id, p.name)}
                    >
                      delete
                    </button>
                  </div>
                );
              }
            )}

            {this.state.peril_inputs.map((_, idx) => {
              return (
                <input
                  key={idx}
                  data-id={idx}
                  onChange={this.handlePerilValue}
                  name={"perils" + this.state.teams_inputs_counter + idx}
                />
              );
            })}

            <button onClick={this.handleAddMoreTeams}>
              Add More
              <span>
                <i className={"apa-icon-add "} />
              </span>
            </button>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleSavePeril}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    note_data: state.leadsReducer.note_data,
  };
}

export default connect(mapStateToProps)(LossInfo);
