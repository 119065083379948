import React from "react";


function Input(props){

    const {options,handleChange,inputEdit} = props

    return(
        <>
            <div className="col-4">
                <p>{options.label}</p>
            </div>
            <div className="col-8">
                <input
                    onChange={handleChange}
                    name={options.name}
                    type={options.type}
                    className={options.class + (inputEdit ? " edit_inputs" : "")}
                    defaultValue={options.value}
                    readOnly={!inputEdit}
                />
            </div>
        </>
    )
}

export default Input;