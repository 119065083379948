import React from "react";
import axios from "axios";
import { url } from "../../config";
import Moment from "react-moment";
import _ from "lodash";
import DatePicker from "react-datepicker";
import NoteModal from "./NoteModal";
const assignmentInfo = {};

class AssignmentInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputEdit: false,
      DOL: "",
      pa_DOL: "",
      teams: "",
      users: "",
      noPaSelected: "No Lead PA"
    };
  }

  componentDidMount() {
    this.getTeam();
    this.getUsers();
  }

  getFormattedDate(date) {
    if (!date) return "";
    let todayTime = new Date(date);
    let month = todayTime.getMonth() + 1;
    let day = todayTime.getDate();
    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;
    let year = todayTime.getFullYear();

    return month + "/" + day + "/" + year;
  }
  noteSave = (name,value)=> {
    assignmentInfo[name] = value;
  }
  handleEdit = () => {
    assignmentInfo.lead_id = this.props.lead_body[0].id;
    this.setState({
      inputEdit: !this.state.inputEdit,
    });
  };

  handleDateChange = (name, value) => {
    if (name === "DOL") {
      this.setState({
        DOL: value,
      });
      assignmentInfo["assigned_date"] = value;
    }
    if (name === "pa_DOL") {
      this.setState({
        pa_DOL: value,
      });
      assignmentInfo["pa_first_contact"] = value;
    }
  };

  updateInspectionInfoValues = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    assignmentInfo[name] = value;
  };

  getTeam = () => {
    axios.get(url + "teams").then((res) => {
      this.setState({
        teams: res.data,
      });
    });
  };

  closeEdit = () =>{
    this.setState({
      inputEdit: !this.state.inputEdit,
    });
  }

  getUsers = () => {
    axios
      .get(url + "/get-user-roles")
      .then((res) => {
        this.setState({
          users: res.data.users,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleSave = async () => {

    const data = {};
    data.assignmentInfo = assignmentInfo;
    let isChanged = null;
    if(data.assignmentInfo.team_id && data.assignmentInfo.team_id != this.props.lead_body[0]?.assignment?.team_id) {
      isChanged = true;
    }
    if(data.assignmentInfo.pa_id && data.assignmentInfo.pa_id != this.props.lead_body[0]?.assignment?.pa_id) {
      isChanged = true;
    }
    const objectLength = Object.keys(data.assignmentInfo).length;
    if (objectLength > 1) {
     await axios
        .post(url + "update-lead-assignment", data)
        .then(async (res) => {
          
          let team_name = '';
          let pa_name = '';
          let message = 'Lead Assigned to '

            if(data.assignmentInfo.team_id && this.state.teams) {
              let filteredTeams = this.state.teams.filter(item => item.id == data.assignmentInfo.team_id)
              team_name = filteredTeams[0].name
              message = message + 'Team: '+team_name;
              }

            if(data.assignmentInfo.pa_id && this.state.users) {
              let filteredUsers = this.state.users.filter(item => item.id == data.assignmentInfo.pa_id)
              pa_name = filteredUsers[0].username
              message = message + ' PA: '+pa_name;
              }
              if(isChanged && isChanged === true) {
                await axios.post(url + "add-lead-log", {lead_id:data.assignmentInfo.lead_id,details:message});
              }
          this.setState({
            noPaSelected:"",
            inputEdit: !this.state.inputEdit,
          })
        
            this.props.myCallback(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  render() {
    const { lead_body } = this.props;
    return (
      <div
        className={
          "add_lead_basic lead_details_basic_info " +
          (lead_body[0]?.assignment == null ? "inactive_box" : "")
        }
      >
        <div className={"leads_details_edit "}>
          <h3>Assignment Info</h3>
          {this.state.inputEdit ? (
              <>
                <span onClick={this.handleSave}>Save</span>
                <div className={'close_b'} onClick={this.closeEdit}><i className="fas fa-times"/></div>
              </>
          ) : lead_body[0]?.assignment !== null ? (
            <i onClick={this.handleEdit} className={"apa-pencil"}></i>
          ) : null}
        </div>


        <div className="row">
          <div className="col-6">
            <p>Team</p>
          </div>
          <div className="col-6">

            {this.state.inputEdit ? (
              <div className="form-group selectdiv leads_details_selects">
                <select
                  name={"team_id"}
                  className={"form-control alterationTypeSelect"}
                  onChange={this.updateInspectionInfoValues}
                  disabled={!this.state.inputEdit}
                >
                  <option value={null}></option>
                  {this.state.teams &&
                    this.state.teams.map((item, key) => {
                      return (
                        <option
                          value={item.id}
                          key={key}
                          selected={
                            lead_body[0]?.assignment?.team_id === item.id
                          }
                        >
                          {item.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            ) : (
               <p>{lead_body[0]?.assignment?.team?.name}</p>
            )}
          </div>

          <div className="col-6">
            <p>Lead PA</p>
          </div>
          <div className="col-6">
            {this.state.inputEdit ? (
              <div className={`form-group selectdiv leads_details_selects`}>
                <select
                  name={"pa_id"}
                  className={"form-control alterationTypeSelect"}
                  onChange={this.updateInspectionInfoValues}
                  disabled={!this.state.inputEdit}

                >
                  <option ></option>
                  {this.state.users &&
                    this.state.users.map((item, key) => {
                      return (
                        <option
                          value={item.id}
                          key={key}
                          selected={
                            item.id === lead_body[0]?.assignment?.pa_id
                          }
                        >
                          {item.username}
                        </option>
                      );
                    })}
                </select>
              </div>
            ) : (
              <p>
                {lead_body[0]?.assignment?.lead_pa ? (
                  lead_body[0]?.assignment?.lead_pa
                    )
                  : this.state.noPaSelected}
              </p>
            )}
          </div>

          <div className="col-6">
            <p>Dates Assigned</p>
          </div>
          <div className="col-6">
            <input
              style={
                this.state.inputEdit
                  ? { display: "none" }
                  : { display: "block" }
              }
              name={"assigned_date"}
              type={"text"}
              value={`${this.getFormattedDate(
                lead_body[0] &&
                  lead_body[0].assignment &&
                  lead_body[0].assignment.assigned_date
              ).toString()}`}
              className={this.state.inputEdit ? " edit_inputs" : ""}
              readOnly={!this.state.inputEdit}
            />

            <div
              style={
                !this.state.inputEdit
                  ? { display: "none" }
                  : { display: "block" }
              }
              className={"details_date_input_content loss_info_dates"}
            >
              <DatePicker
                className={"details_date_inputs "}
                onChange={this.handleDateChange.bind(this, "DOL")}
                selected={
                  this.state.DOL ? this.state.DOL :
                  lead_body[0] &&
                  lead_body[0].assignment &&
                  lead_body[0].assignment.assigned_date ?
                  new Date(lead_body[0].assignment.assigned_date)
                   : new Date()}
                dateFormat="MM/dd/yyyy"
              />
            </div>
          </div>

          <div className="col-6">
            <p>PA First Contact Date</p>
          </div>

          <div className="col-6">
            <input
              style={
                this.state.inputEdit
                  ? { display: "none" }
                  : { display: "block" }
              }
              name={"pa_first_contact"}
              type={"text"}
              defaultValue={`${this.getFormattedDate(
                lead_body[0] &&
                  lead_body[0].assignment &&
                  lead_body[0].assignment.pa_first_contact
              ).toString()}`}
              className={this.state.inputEdit ? " edit_inputs" : ""}
              readOnly={!this.state.inputEdit}
            />

            <div
              style={
                !this.state.inputEdit
                  ? { display: "none" }
                  : { display: "block" }
              }
              className={"details_date_input_content loss_info_dates"}
            >
              <DatePicker
                className={"details_date_inputs "}
                onChange={this.handleDateChange.bind(this, "pa_DOL")}
                selected={
                  this.state.pa_DOL ? this.state.pa_DOL :
                  lead_body[0] &&
                  lead_body[0].assignment &&
                  lead_body[0].assignment.pa_first_contact ?
                  new Date(lead_body[0].assignment.pa_first_contact)
                   : new Date()}
              />
            </div>
          </div>

          <NoteModal title={'Notes'} col={6} name={'notes'} notes={lead_body[0] && lead_body[0].notes}
                           id={'notes'} inputEdit={this.state.inputEdit} noteSave = {this.noteSave} />
        </div>
      </div>
    );
  }
}

export default AssignmentInfo;

